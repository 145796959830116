import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import {
  Dialog,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import LockIcon from '@mui/icons-material/Lock'
import CloseIcon from '@mui/icons-material/Close'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import ContactMailIcon from '@mui/icons-material/ContactMail'

import {
  IoPencilOutline,
  IoTrashOutline,
  IoDownloadOutline,
  IoCopyOutline,
  IoEyeOutline,
} from 'react-icons/io5'

import { IoEllipsisHorizontal } from 'react-icons/io5'

import SurveyOffersSlide from './SurveyOffersSlide'
import { formatDate, handleCopy, makeSurveyLink } from '../common/helpers'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'

const OfferRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'topRow',
})(({ topRow }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2px 0',
  borderTop: topRow ? 'none' : '1px solid #d3d3d3',
}))

const SurveyOffersList = ({ offers, handleDelete, handleEdit, events }) => {
  const findLockedEvent = (offer) => {
    return events.find((event) => event.lockedOfferCode === offer.code)
  }

  return (
    offers &&
    offers.map((offer, i) => (
      <SurveyOfferListItem
        key={i}
        rowIndex={i}
        offer={offer}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        lockedEvent={findLockedEvent(offer)}
      />
    ))
  )
}

const SurveyOfferListItem = ({
  offer,
  handleDelete,
  handleEdit,
  rowIndex,
  lockedEvent,
}) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { authData, setNotification } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)

  const { user } = authData
  const shortlink = selectedGroup ? selectedGroup.shortlink : user?.shortlink

  const handleOpenDropdown = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  const handleCopyCodeLink = (copyText) => {
    handleCopy(copyText)
    return setNotification('Code Link Copied!', 'success')
  }

  const QuestionIcon = () => (
    <Tooltip title="Custom question active on this offer" placement="top">
      <Typography variant="body1" id="custom-question-label">
        Q
      </Typography>
    </Tooltip>
  )

  const offerUrl = offer.downloadUrl ? offer.downloadUrl : offer.link
  const regex = /^((http|https):\/\/)/
  const url = !regex.test(offerUrl) ? '//' + offerUrl : offerUrl

  // We create this offer code for all users, and it can't be changed
  const isDefaultOffer = offer.code === 'TALK'

  const openOffer = () => {
    return window.open(url, '_blank', 'noreferrer')
  }

  return (
    <OfferRow
      key={offer.id}
      className="survey-offers__offer"
      topRow={rowIndex === 0}>
      <Typography variant="body2" component="div" id="offer-code">
        {offer.code}
      </Typography>
      <Typography variant="body2" component="div" id="offer-description">
        {offer.name} {offer.customQuestion && <QuestionIcon />}
        {'   '}
        {offer.disableLeadgen && (
          <Tooltip title="Leadgen is OFF for this offer" placement="top">
            <PersonOffIcon style={{ color: 'red' }} />
          </Tooltip>
        )}{' '}
        {offer.disableBookingFlow && (
          <Tooltip
            title="Booking Flow is OFF for this offer, but email and name collection is on"
            placement="top">
            <ContactMailIcon style={{ marginLeft: '5px' }} />
          </Tooltip>
        )}{' '}
        {lockedEvent && (
          <Tooltip
            placement="top"
            title={`All responses that use this offer code will always go to the event: ${
              lockedEvent.talkTitle
            } from ${formatDate(lockedEvent.date)}`}>
            <LockIcon />
          </Tooltip>
        )}
      </Typography>
      <div className="survey-offers__actions">
        <IconButton
          color="inherit"
          id="long-button"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenDropdown}
          aria-label="Open to show more"
          title="Open to show more">
          <IoEllipsisHorizontal />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          {/* TODO: add mobile download option here, also for the one that shows after creating a new code */}
          <MenuItem key="0" onClick={() => setOpen(true)}>
            <ListItemIcon>
              <IoDownloadOutline />
            </ListItemIcon>
            <ListItemText>Download Slide</ListItemText>
          </MenuItem>
          {!isDefaultOffer && (
            <MenuItem key="1" onClick={openOffer} disabled={!offerUrl}>
              <ListItemIcon>
                <IoEyeOutline />
              </ListItemIcon>
              <ListItemText>View Bonus</ListItemText>
            </MenuItem>
          )}
          {!isDefaultOffer && (
            <MenuItem key="2" onClick={() => handleEdit(offer)}>
              <ListItemIcon>
                <IoPencilOutline />
              </ListItemIcon>
              <ListItemText>Edit Code</ListItemText>
            </MenuItem>
          )}
          {!isDefaultOffer && (
            <MenuItem
              key="3"
              onClick={() =>
                handleCopyCodeLink(
                  makeSurveyLink({ shortlink, code: offer.code })
                )
              }>
              <ListItemIcon>
                <IoCopyOutline />
              </ListItemIcon>
              <ListItemText>Copy Code Link</ListItemText>
            </MenuItem>
          )}
          {!isDefaultOffer && (
            <MenuItem key="4" onClick={() => handleDelete(offer.id)}>
              <ListItemIcon>
                <IoTrashOutline />
              </ListItemIcon>
              <ListItemText>Delete Code</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </div>
      <Dialog
        open={open}
        fullwidth="true"
        maxWidth="xl"
        sx={{ minWidth: '960px' }}
        onClose={() => setOpen(false)}>
        <CloseIcon
          className="modal-close-icon"
          sx={{ fill: 'white' }}
          onClick={() => setOpen(false)}
        />
        <SurveyOffersSlide code={offer.code} />
      </Dialog>
    </OfferRow>
  )
}

export default SurveyOffersList
