import { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import {
  Skeleton,
  Dialog,
  DialogContent,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import YouTube from 'react-youtube'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from './groupContext'
import Api from '../../services/api'

import {
  initialCodesState,
  initialGroupOfferState,
} from '../../constants/codes/initialOfferStates'
import SurveyLinkSummary from '../dashboard/SurveyLinkSummary'
import EventsParent from '../events/EventsParent'
import SurveyOffersForm from '../dashboard/SurveyOffersForm'
import EventFormParent from '../events/EventFormParent'
import GroupEventSettings from './GroupEventSettings'

import '../dashboard/Dashboard.scss'

const initialEventState = {
  id: null,
  name: null,
  talkTitle: null,
  inPerson: null,
  virtual: null,
  eventOrganization: null,
  startTime: null,
  duration: null,
  date: null,
  compensation: null,
}

const DashboardContainer = styled('div')({
  padding: '0 1rem',
})

const VideoDialogContent = styled(DialogContent)({
  minHeight: '600px',
  padding: 0,
})

const VideoContainer = styled('div')({
  height: '600px',
})

const GuestSpeakerDashboard = () => {
  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(false)
  const [surveyLink, setSurveyLink] = useState(null)
  const [surveyLoading, setSurveyLoading] = useState(false)
  const [offers, setOffers] = useState(initialCodesState)
  const [surveyOfferOpen, setSurveyOfferOpen] = useState(false)
  const [offer, setOffer] = useState(initialGroupOfferState)
  const [event, setEvent] = useState(initialEventState)
  const [eventFormOpen, setEventFormOpen] = useState(false)
  const [shortlink, setShortlink] = useState(null)
  const [defaultEmojiKeywords, setDefaultEmojiKeywords] = useState([])
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [videoId, setVideoId] = useState(null)
  const [surveyOffersFormModalSize, setSurveyOffersFormModalSize] =
    useState('md')
  const [surveyOffersFormModalFullWidth, setSurveyOffersFormModalFullWidth] =
    useState(true)
  const { authData, setNotification } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup, groups },
    setSelectedGroup,
    setGroups,
  } = useContext(GroupContext)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { fullPageLoader } = authData

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setEventsLoading(true)
        const res = await Api.loadGroupEvents({ group_id: selectedGroup.id })
        if (!res.errors) {
          setEventsLoading(false)
          setEvents(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        setEventsLoading(false)
        return setNotification(err, 'error')
      }
    }

    const loadSurvey = async () => {
      try {
        setSurveyLoading(true)

        const res = await Api.getGroupSurveyLink(selectedGroup.id)

        if (!res.errors) {
          setSurveyLink(res.shortlink)
          setShortlink(res.shortlink)
          setOffers(offers.concat(res.offers))
          setSurveyLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setSurveyLoading(false)
        setNotification(err, 'error')
      }
    }

    const getDefaultEmojiKeywords = async () => {
      try {
        const res = await Api.fetchDefaultEmojiKeywords()

        if (!res.errors) {
          setDefaultEmojiKeywords(res)
        }
      } catch (err) {}
    }

    loadSurvey()
    fetchEvents()
    getDefaultEmojiKeywords()
  }, [])

  const updateGroup = (updatedAttribute) => {
    setSelectedGroup({ ...selectedGroup, ...updatedAttribute })
  }

  const updateGroups = (updatedAttribute) => {
    setGroups(
      groups.map((group) =>
        group.id === selectedGroup.id
          ? { ...group, ...updatedAttribute }
          : group
      )
    )
  }

  const updateShortlink = (shortlink) => {
    updateGroup(shortlink)
    setSurveyLink(shortlink)
    setShortlink(shortlink)
  }

  const handleClose = () => {
    setOffer(initialGroupOfferState)
    setSurveyOfferOpen(false)
  }

  const handleEdit = (offer) => {
    setOffer(offer)
    setSurveyOfferOpen(true)
  }

  const handleOpenCreateOfferForm = () => {
    return setSurveyOfferOpen(true)
  }

  const handleOpenEventForm = async (clickedEvent) => {
    // For Chapters, create an event on initial click so we can use the tag speakers feature
    // In the future, we may want to refactor the entire tag speakers flow as
    // this is a bit of a hack
    if (clickedEvent) {
      setEvent(clickedEvent)
    } else {
      await createEvent(event)
    }

    setEventFormOpen(true)
  }

  const handleCloseEventForm = () => {
    setEventFormOpen(false)
    setEvent(initialEventState)
  }

  const handleVideoModalClose = () => {
    setVideoModalOpen(false)
    setVideoId('')
  }

  const createEvent = async (params) => {
    try {
      const res = await Api.createGroupEvent({
        ...params,
        group_id: selectedGroup.id,
      })

      if (res.errors) {
        throw res.errors
      } else {
        // On the newly created event, don't use the
        // date the server sends up. Allow the chapter
        // president to select it
        setEvent({ ...res, date: null })
        setEvents([res, ...events])
      }
    } catch (err) {
      setNotification(err, 'error')
    }
  }

  const editEvent = async (eventId, params) => {
    const groupEventParams = {
      ...params,
      group_id: selectedGroup.id,
    }
    try {
      const res = await Api.editGroupEvent(eventId, groupEventParams)

      if (res.errors) {
        throw res.errors
      } else {
        setEvents(events.map((event) => (event.id === res.id ? res : event)))
      }
    } catch (err) {
      setNotification(err, 'error')
    }
  }

  const updateEventSpeakers = (speakers, eventId) => {
    setEvents(
      events.map((event) => {
        if (event.id === eventId) {
          return { ...event, speakers: speakers }
        } else {
          return event
        }
      })
    )
  }

  const renderSecondaryContent = () => (
    <GroupEventSettings
      selectedGroup={selectedGroup}
      updateGroup={updateGroup}
      updateGroups={updateGroups}
      setNotification={setNotification}
      updateShortlink={updateShortlink}
    />
  )

  const videoOpts = {
    playerVars: {
      autoplay: 1,
    },
    width: '100%',
    height: '100%',
  }

  return (
    <DashboardContainer className="dashboard">
      {fullPageLoader.isActive && (
        <Skeleton variation="rectangular" width={'100%'} height={'50px'} />
      )}
      <div className="dashboard__container">
        <SurveyLinkSummary
          offers={offers}
          setOffers={setOffers}
          handleEdit={handleEdit}
          handleOpenCreateOfferForm={handleOpenCreateOfferForm}
          surveyLink={surveyLink}
          surveyLoading={surveyLoading}
          events={events}
          shortlink={shortlink}
        />
        {renderSecondaryContent()}
      </div>
      {/* TODO: Swap out with TalksTable component */}
      <EventsParent
        events={events}
        eventsLoading={eventsLoading}
        setEvents={setEvents}
        handleOpenEventForm={handleOpenEventForm}
      />
      <Dialog
        open={surveyOfferOpen}
        fullScreen={fullScreen}
        fullWidth={surveyOffersFormModalFullWidth}
        maxWidth={surveyOffersFormModalSize}
        onClose={() => handleClose()}>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <SurveyOffersForm
          setSurveyOfferOpen={setSurveyOfferOpen}
          handleClose={handleClose}
          offers={offers}
          setOffers={setOffers}
          setOffer={setOffer}
          offer={offer}
          defaultEmojiKeywords={defaultEmojiKeywords}
          setVideoId={setVideoId}
          setVideoModalOpen={setVideoModalOpen}
          setSurveyOffersFormModalSize={setSurveyOffersFormModalSize}
          setSurveyOffersFormModalFullWidth={setSurveyOffersFormModalFullWidth}
          surveyLink={surveyLink}
        />
      </Dialog>
      <Dialog
        open={eventFormOpen}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={() => handleCloseEventForm()}>
        <CloseIcon
          className="modal-close-icon"
          onClick={() => handleCloseEventForm()}
        />
        <EventFormParent
          eventInitialState={event}
          handleClose={handleCloseEventForm}
          updateEventSpeakers={updateEventSpeakers}
          editEvent={editEvent}
          createEvent={createEvent}
        />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={videoModalOpen}
        onClose={handleVideoModalClose}>
        <VideoDialogContent>
          <VideoContainer>
            <YouTube
              videoId={videoId}
              opts={videoOpts}
              style={{ width: '100%', height: '100%' }}
            />
          </VideoContainer>
        </VideoDialogContent>
      </Dialog>
    </DashboardContainer>
  )
}

export default GuestSpeakerDashboard
