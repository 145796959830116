import { useState, useContext, createRef } from 'react'
import { Typography, Dialog, Tooltip, Skeleton } from '@mui/material'
import QrCodeIcon from '@mui/icons-material/QrCode'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'

import envConfig from '../../envConfig'
import * as Helpers from '../common/helpers'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'

import Card from '../common/Card'
import SurveyOffersParent from './SurveyOffersParent'
import SurveyOfferSlide from './SurveyOffersSlide'

var QRCode = require('qrcode.react')

const SurveyLinkSummary = ({
  offers,
  surveyLink,
  surveyLoading,
  setOffers,
  handleEdit,
  handleOpenCreateOfferForm,
  events,
  shortlink,
}) => {
  const [open, setOpen] = useState(false)
  const { setNotification } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const qrDownloadRef = createRef()
  const fullSpeakerLink = `${envConfig.REACT_APP_SHORTLINK}/${surveyLink}`
  const speakerShortlink = shortlink?.toUpperCase()

  const DownloadableQrCode = () => {
    return (
      <div
        ref={qrDownloadRef}
        style={{ height: '180px', width: '180px', marginBottom: '-180px' }}>
        <QRCode
          level={'M'}
          value={`https://${fullSpeakerLink}`}
          size={180}
          id="qr-code-dl"
          title="talkadot-review-link"
          style={{ visibility: 'hidden' }}
        />
      </div>
    )
  }

  const handleCopy = () => {
    Helpers.handleCopy(fullSpeakerLink)
    return setNotification('Copied!', 'success')
  }

  const SpeakerLinkActions = () => {
    const exportQrCode = () => {
      return Helpers.downloadInvisibleComponent(
        qrDownloadRef,
        'qr-code-dl',
        `Talkadot-QR-${speakerShortlink}`
      )
    }

    return (
      <div className="speaker-link-share">
        <div
          className="speaker-link-share__share-type"
          onClick={() =>
            window.open(`http://${fullSpeakerLink}?mode=test`, '_blank')
          }>
          <div className="speaker-link-share__icon">
            <BuildOutlinedIcon />
          </div>
          <div>Test My Talkadot Flow</div>
        </div>
        <div className="speaker-link-share__share-type" onClick={exportQrCode}>
          <div className="speaker-link-share__icon">
            <QrCodeIcon />
          </div>
          <div>Download My QR Code</div>
        </div>
      </div>
    )
  }

  const linkText = () => {
    if (selectedGroup) {
      return 'This is your guest speaker Talkadot handle'
    } else {
      return 'Your Talkadot handle'
    }
  }
  const shareLinkText = () =>
    selectedGroup
      ? 'Have your speaker share this Talkadot handle to collect audience feedback!'
      : 'Share your Talkadot handle to get audience feedback and generate leads!'

  const renderSurveySummary = () => (
    <Card title="" isLoading={surveyLoading} cx="survey-link-summaries">
      <Typography
        component="div"
        variant="h6"
        className="dashboard-link-summary">
        <div>
          <b>{linkText()}</b>
        </div>
        <div className="dashboard-link-summary__link-section">
          <span onClick={handleCopy} className="copyable-survey-link">
            <b>talk.ac/{surveyLink}</b>
          </span>
          <Tooltip title="Copy Survey Link" placement="top">
            <ContentCopyIcon onClick={handleCopy} />
          </Tooltip>
        </div>
      </Typography>
      <Typography component="div" variant="body2" className="subheader">
        {shareLinkText()}
      </Typography>
      <div className="survey-link-summary">
        <DownloadableQrCode />
        <SpeakerLinkActions />
        <Dialog
          open={open}
          fullwidth
          maxWidth="xl"
          sx={{ minWidth: '960px' }}
          onClose={() => setOpen(false)}>
          <CloseIcon
            className="modal-close-icon"
            sx={{ fill: 'white' }}
            onClick={() => setOpen(false)}
          />
          <SurveyOfferSlide code={''} />
        </Dialog>
      </div>
      <SurveyOffersParent
        offers={offers}
        setOffers={setOffers}
        handleEdit={handleEdit}
        handleOpenCreateOfferForm={handleOpenCreateOfferForm}
        events={events}
      />
    </Card>
  )

  const renderSkeleton = () => (
    <Card title="" isLoading={surveyLoading}>
      <Skeleton
        variant="rectangualar"
        sx={{ height: '200px', width: '100%' }}
      />
      <Skeleton
        variant="rectangular"
        sx={{ width: '100%', height: '150px', marginTop: '20px' }}
      />
    </Card>
  )

  return surveyLoading ? renderSkeleton() : renderSurveySummary()
}

export default SurveyLinkSummary
