import React, { useContext, useState } from 'react'
import TextField from '@mui/material/TextField'
import { AuthenticationContext } from '../authentication/authenticationContext'

const CustomUrlPicker = ({ onChangeOverride, shortlinkValue }) => {
  const { setProfileAttribute, authData } = useContext(AuthenticationContext)
  const { user } = authData
  const [shortlinkLengthError, setShortlinkLengthError] = useState(null)
  const previousShortlink = user.shortlink

  const setUserShortlink = async (shortlink) => {
    // If a legacy user has a shortlink greater than 19 characters,
    // warn them that to save it it needs to be less than the new max
    if (previousShortlink?.length > 19) {
      setShortlinkLengthError('To change this, must be less than 20 characters')
      return setProfileAttribute('shortlink', shortlink)
    }

    if (shortlink?.length > 19) {
      return setShortlinkLengthError('Must be 19 characters or less')
    }

    if (shortlink?.length <= 19) {
      setShortlinkLengthError(null)
    }

    setProfileAttribute('shortlink', shortlink)
  }

  return (
    <div id="url-builder">
      <div className="link-label">talk.ac/</div>
      <TextField
        variant="standard"
        type="text"
        error={shortlinkLengthError}
        helperText={!!shortlinkLengthError && shortlinkLengthError}
        value={shortlinkValue !== undefined ? shortlinkValue : user.shortlink}
        placeholder="<my name>"
        onChange={async (e) => {
          !!onChangeOverride
            ? await onChangeOverride(e.target.value.trim())
            : await setUserShortlink(e.target.value.trim())
        }}
        autoFocus
      />
    </div>
  )
}

export default CustomUrlPicker
