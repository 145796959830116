import { styled, useTheme } from '@mui/system'
import { Typography } from '@mui/material'

const Chip = styled(
  ({ component: Component = 'span', ...otherProps }) => (
    <Component {...otherProps} />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'backgroundColor' && prop !== 'sizeStyling',
  }
)(({ backgroundColor, sizeStyling }) => ({
  padding: '4px 6px',
  background: backgroundColor,
  ...sizeStyling,
}))

const ChipText = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'textColor' &&
    prop !== 'fontWeight' &&
    prop !== 'applyStrikeThrough',
})(({ textColor, fontWeight, applyStrikeThrough, theme }) => ({
  color: textColor,
  fontWeight: theme.fontWeights[fontWeight],
  textWrap: 'nowrap',
  textDecoration: applyStrikeThrough ? 'line-through' : 'none',
}))

const StyledIcon = styled('span')({
  marginRight: '2px',
  verticalAlign: 'middle',
})

const TableChip = ({
  text,
  color,
  variant = 'paragraph00',
  fontWeight = 'regular',
  size = 'small',
  applyStrikeThrough = false,
  component = 'span',
  icon = null,
}) => {
  const theme = useTheme()
  let textColor = ''
  let backgroundColor = ''

  const sizing = {
    small: {
      borderRadius: theme.shape.borderRadius.xxs,
    },
    medium: {
      borderRadius: theme.shape.borderRadius.sm,
    },
  }

  switch (color) {
    case 'lightBlue':
      textColor = theme.palette.secondary.main
      backgroundColor = theme.palette.secondary.extraLight
      break
    case 'blue':
      textColor = theme.palette.secondary.veryDark
      backgroundColor = theme.palette.secondary.veryLight
      break
    case 'green':
      textColor = theme.palette.success.semiDark
      backgroundColor = theme.palette.success.extraLight
      break
    case 'purple':
      textColor = theme.palette.purple.main
      backgroundColor = theme.palette.purple.lightest
      break
    case 'pink':
      textColor = theme.palette.pink.main
      backgroundColor = theme.palette.pink.lightest
      break
    case 'orange':
      textColor = theme.palette.primary.main
      backgroundColor = theme.palette.primary.extraLight
      break
    case 'grey':
      textColor = theme.palette.neutral.main
      backgroundColor = theme.palette.neutral.extraLight
      break
    case 'red':
      textColor = theme.palette.error.semiDark
      backgroundColor = theme.palette.error.extraLight
      break
    default:
      textColor = theme.palette.error.main
      backgroundColor = theme.palette.secondary.extraLight
  }

  return (
    <Chip
      component={component}
      backgroundColor={backgroundColor}
      sizeStyling={sizing[size]}>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <ChipText
        component="span"
        variant={variant}
        fontWeight={fontWeight}
        textColor={textColor}
        applyStrikeThrough={applyStrikeThrough}>
        {text}
      </ChipText>
    </Chip>
  )
}

export default TableChip
