import * as ActionTypes from './actionTypes'

export const initialGroupState = {
  selectedGroup: null,
  groups: null
}

export const groupReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload
      }
    case ActionTypes.SET_GROUPS:
      return {
        ...state,
        groups: action.payload
      }
    case ActionTypes.CLEAR_GROUP_STATE:
      return {
        ...initialGroupState
      }
    default:
      return state
  }
}
