import { useState, useEffect } from 'react'
import { OrganizationsList } from './referralsHelper'
import { Typography, TextField, Autocomplete } from '@mui/material'
import { styled } from '@mui/system'

const OrganizationListHeader = styled(Typography)({
  fontSize: '1.25rem',
  marginTop: '25px',
  marginBottom: '15px',
})

const AutocompleteContainer = styled(Autocomplete)({
  // overwrites incorrect styling from .onboarding button in stylesheet
  button: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

const ReferralOrganizationPicker = ({
  setProfileAttribute,
  existingReferrerChapter,
  existingReferrerOrganization,
  setReferrerChapter,
  setReferrerOrganization,
}) => {
  const [chapterList, setChapterList] = useState(null)

  useEffect(() => {
    if (existingReferrerOrganization?.chapterList) {
      setChapterList(
        formatChapterList(existingReferrerOrganization?.chapterList)
      )
    }
  }, [])

  const formatChapterList = (chapterList) => {
    return chapterList.map((chapterName) => {
      return { label: chapterName }
    })
  }

  const handleReferrerChange = (selectedOrganization) => {
    if (!selectedOrganization) {
      return setReferrerOrganization('')
    }

    setReferrerOrganization(selectedOrganization)
    setProfileAttribute('referrer', selectedOrganization.label)

    if (selectedOrganization?.chapterList) {
      return setChapterList(formatChapterList(selectedOrganization.chapterList))
    }

    return setChapterList(null)
  }

  const handleChapterSelect = (selectedChapter) => {
    // If I don't select a chapter, set the referrer to be the parent organization
    if (!selectedChapter) {
      setProfileAttribute('referrer', existingReferrerOrganization.label)
      return setReferrerChapter('')
    }

    setReferrerChapter(selectedChapter)
    return setProfileAttribute('referrer', selectedChapter.label)
  }

  return (
    <div>
      <OrganizationListHeader>
        Which primary organization did you hear from?
      </OrganizationListHeader>
      <AutocompleteContainer
        disablePortal
        id="referrer-organizations-autocomplete"
        options={OrganizationsList}
        value={existingReferrerOrganization?.label || ''}
        onChange={(e, value) => handleReferrerChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select Your Organization"
          />
        )}
      />
      {chapterList?.length > 0 && (
        <>
          <OrganizationListHeader component="div">
            Which chapter?
          </OrganizationListHeader>
          <AutocompleteContainer
            disablePortal
            id="referrer-chapters-autocomplete"
            options={chapterList}
            value={existingReferrerChapter?.label || ''}
            onChange={(e, value) => handleChapterSelect(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Your Chapter"
                variant="outlined"
              />
            )}
          />
        </>
      )}
    </div>
  )
}

export default ReferralOrganizationPicker
