import { Typography, Skeleton } from '@mui/material'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useTheme } from '@mui/material/styles'

ChartJS.register(ArcElement, Tooltip)

const TalkReattend = ({ reattendBreakdown, isLoading, speaker, eventType }) => {
  const theme = useTheme()
  const { d_yes, p_yes, p_no, d_no, total_reattend_answers } = reattendBreakdown
  const reattendRate =
    parseInt(((d_yes + p_yes) / total_reattend_answers) * 100) || 0

  const chartData = {
    labels: ['Definitely', 'Probably', 'Probably Not', 'Definitely Not'],
    datasets: [
      {
        data: [d_yes, p_yes, p_no, d_no],
        backgroundColor: [
          theme.palette.eventReport.circleChartBlue,
          theme.palette.eventReport.circleChartLightBlue,
          theme.palette.eventReport.circleChartGrey,
          theme.palette.eventReport.circleChartLightGrey,
        ],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const renderHeaderContent = () => {
    if (!eventType && !speaker.name) {
      return ' want to hear this speaker again'
    }

    if (!eventType && speaker.name) {
      return ` want to hear ${speaker.name} speak again`
    }

    if (eventType.toUpperCase() === 'GLOBAL') {
      return ' want to attend this event again'
    }

    if (eventType.toUpperCase() === 'WORKSHOP') {
      return ' want to attend a session like this again'
    }

    if (eventType.toUpperCase() === 'TOTAL_STATISTICS') {
      return ' want to hear the presenters again'
    }

    return ' want to hear this speaker again'
  }

  return (
    <div className="stat-widget-section">
      {isLoading ? (
        <Skeleton width={200} height={30} />
      ) : (
        <Typography variant="h6" component="div" className="widget-title">
          <span className="stat-percentage">
            <b>{reattendRate}%</b>
          </span>
          {renderHeaderContent()}
        </Typography>
      )}
      {isLoading ? (
        <Skeleton width={400} height={200} variant="rectangular" />
      ) : (
        <div className="stat-widget-section__widgets" id="reattend-widgets">
          <div className="stat-widget-section__donut-chart">
            <Doughnut data={chartData} options={options} />
          </div>
          <div className="stat-widget-section__progress-bar-section">
            <div className="stat-widget-section__progress-bar-container">
              <Typography
                variant="body1"
                component="div"
                className="progress-stat-label">
                Definitely Yes
              </Typography>
              <CircularProgressbar
                value={d_yes}
                maxValue={total_reattend_answers}
                text={d_yes}
                strokeWidth={10}
                className="stat-widget-section__progress-bar"
                styles={buildStyles({
                  textSize: '25px',
                  textColor: 'black',
                  pathColor: theme.palette.eventReport.circleChartBlue,
                })}
              />
            </div>
            <div className="stat-widget-section__progress-bar-container">
              <Typography
                variant="body1"
                component="div"
                className="progress-stat-label">
                Probably Yes
              </Typography>
              <CircularProgressbar
                value={p_yes}
                maxValue={total_reattend_answers}
                text={p_yes}
                strokeWidth={10}
                className="stat-widget-section__progress-bar"
                styles={buildStyles({
                  textColor: 'black',
                  pathColor: theme.palette.eventReport.circleChartLightBlue,
                  textSize: '25px',
                })}
              />
            </div>
          </div>
          <div className="stat-widget-section__progress-bar-section">
            <div className="stat-widget-section__progress-bar-container negative-answer">
              <Typography
                variant="body1"
                component="div"
                className="progress-stat-label">
                Definitely Not
              </Typography>
              <CircularProgressbar
                value={d_no}
                maxValue={total_reattend_answers}
                text={d_no}
                strokeWidth={10}
                counterClockwise={true}
                className="stat-widget-section__progress-bar"
                styles={buildStyles({
                  textSize: '25px',
                  textColor: 'black',
                  pathColor: theme.palette.eventReport.circleChartLightGrey,
                })}
              />
            </div>
            <div className="stat-widget-section__progress-bar-container negative-answer">
              <Typography
                variant="body1"
                component="div"
                className="progress-stat-label">
                Probably Not
              </Typography>
              <CircularProgressbar
                value={p_no}
                maxValue={total_reattend_answers}
                text={p_no}
                counterClockwise={true}
                strokeWidth={10}
                className="stat-widget-section__progress-bar"
                styles={buildStyles({
                  textColor: 'black',
                  pathColor: theme.palette.eventReport.circleChartGrey,
                  textSize: '25px',
                })}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TalkReattend
