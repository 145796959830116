import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'

import { ResponsesSvg } from './ResponsesSvg'
import { ValuableSvg } from './ValuableSvg'
import { ReattendSvg } from './ReattendSvg'
import { LocationSvg } from './LocationSvg'

const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  borderRadius: '2px',
  height: '192px',
})

const CardHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})(({ bgColor, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  minWidth: '230px',
  background: bgColor ? bgColor : theme.palette.secondary.main,
  borderBottomRightRadius: '10px',
  padding: '12px 10px',
  paddingRight: '40px',
}))

const HeaderLogo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
})

const HeaderText = styled('div')({
  color: 'white',
  fontSize: '1.25rem',
  fontWeight: 'bold',
  fontFamily: 'Sora, sans-serif',
  // TODO: reduce fontSize on mobile to 1.15rem
})

export const ProfileStatCard = ({ type, children }) => {
  const theme = useTheme()

  const headerText = (type) => {
    switch (type) {
      case 'responses':
        return 'Audience Feedback'
      case 'valuable':
        return 'Find Valuable'
      case 'reattend':
        return 'Reattend Rate'
      case 'location':
        return 'Talks Given'
      case 'sessions':
        return 'Sessions Given'
      default:
        return ''
    }
  }

  const headerLogo = (type) => {
    switch (type) {
      case 'responses':
        return <ResponsesSvg />
      case 'valuable':
        return <ValuableSvg />
      case 'reattend':
        return <ReattendSvg />
      case 'location':
        return <LocationSvg />
      case 'sessions':
        return <LocationSvg />
      default:
        return <ResponsesSvg />
    }
  }

  const widgetColor = (type) => {
    switch (type) {
      case 'responses':
        return theme.palette.secondary.main
      case 'valuable':
        return theme.palette.profileWidget.lightBlue
      case 'reattend':
        return theme.palette.profileWidget.teal
      case 'location':
        return theme.palette.profileWidget.green
      case 'sessions':
        return theme.palette.profileWidget.green
      default:
        return theme.palette.secondary.main
    }
  }

  return (
    <CardContainer>
      <CardHeader bgColor={widgetColor(type)}>
        <HeaderLogo>{headerLogo(type)}</HeaderLogo>
        <HeaderText>{headerText(type)}</HeaderText>
      </CardHeader>
      {children}
    </CardContainer>
  )
}
