import { useContext, useState } from 'react'
import moment from 'moment'
import { styled } from '@mui/system'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'

import EventButton from './EventButton'
import EventListParent from './EventListParent'
import EventBulkActions from './EventBulkActions'
import PageHeader from '../common/PageHeader'
import ConfirmDialog from '../common/ConfirmDialog'

import { formatDate } from '../common/helpers'
import './Events.scss'

const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const EventsParent = ({
  events,
  eventsLoading,
  setEvents,
  eventGroupId,
  handleOpenEventForm,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [deleteEvent, setDeleteEvent] = useState(null)
  // const [mergeActive, setMergeActive] = useState(false)
  const { setNotification, showFullPageLoader, hideFullPageLoader, authData } =
    useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const primeEvent = events?.find((event) => event.isPrimeEvent)
  const mergableEvents = events?.filter(
    (event) => event.isSelected && !event.isPrimeEvent
  )

  const mergeEvents = async () => {
    try {
      showFullPageLoader()

      const params = {
        event_merges: {
          event_ids: mergableEvents.map((event) => event.id),
          prime_event_id: primeEvent.id,
        },
        ...(selectedGroup ? { group_id: selectedGroup.id } : {}),
      }

      let res
      if (selectedGroup) {
        res = await Api.mergeGroupEvent(params)
      } else {
        res = await Api.mergeEvent(params)
      }

      if (res.errors) {
        throw res.errors
      } else {
        setNotification('Event Merge Successful!', 'success')
        setEvents(res)
        hideFullPageLoader()
      }
    } catch (err) {
      hideFullPageLoader()
      setNotification(err, 'error')
    }
  }

  const handleConfirmDelete = async () => {
    if (deleteEvent?.id) {
      try {
        let res
        if (selectedGroup) {
          res = await Api.deleteGroupEvent(deleteEvent.id, selectedGroup.id)
        } else {
          res = await Api.deleteEvent(deleteEvent.id)
        }

        if (!res.errors) {
          setNotification('Delete Success!', 'success')
          setEvents(events.filter((event) => event.id !== deleteEvent.id))
          setDeleteEvent(null)
        } else {
          throw res.errors
        }
      } catch (err) {
        setNotification(err, 'error')
        setDeleteEvent(null)
      }
    }
  }

  const handleDeleteEvent = (event) => {
    setDeleteEvent(event)
    setConfirmOpen(true)
  }

  const enoughResponses = (event) => {
    return event.responses > 0
  }

  const validMetaData = (event) => {
    return event.talkTitle || event.organization
  }

  const isFutureEvent = (event) => {
    return moment(event.date) > moment().add(-1, 'days')
  }

  const shouldShowEvent = (event) => {
    // Note:
    // This function needs to remain backwards compatible from before we
    // added the ability to track if an event is system generated or not

    // Hide any system generated event without enough responses
    if (event.isSystemGenerated && !enoughResponses(event)) {
      return false
    }

    return (
      enoughResponses(event) || validMetaData(event) || isFutureEvent(event)
    )
  }

  const setEventVisibility = () => {
    return events?.filter((event) => shouldShowEvent(event))
  }

  const renderPageHeader = () => {
    let headerText

    if (selectedGroup) {
      headerText = 'Chapter Speaking Events'
    } else if (authData?.user?.isEventPlanner) {
      headerText = 'Feedback By Session'
    } else {
      headerText = 'Your Speaking Engagements'
    }

    return <PageHeader header={headerText} />
  }

  return (
    <div className="events-parent">
      <HeaderWrapper>
        {renderPageHeader()}
        {events?.length > 0 && (
          <EventButton
            event={null}
            handleOpenEventForm={handleOpenEventForm}
            user={authData.user}
          />
        )}
      </HeaderWrapper>
      {primeEvent && mergableEvents?.length > 0 && (
        <EventBulkActions
          mergableEvents={mergableEvents}
          primeEvent={primeEvent}
          mergeEvents={mergeEvents}
        />
      )}
      {/* <EventActionsWrapper>
        {events?.length > 0 && (
          <>
            <EventBulkActions
              mergableEvents={mergableEvents}
              primeEvent={primeEvent}
              mergeEvents={mergeEvents}
              setMergeActive={setMergeActive}
            />
            <EventButton
              event={null}
              handleOpenEventForm={handleOpenEventForm}
              user={authData.user}
            />
          </>
        )}
      </EventActionsWrapper> */}
      <EventListParent
        eventsLoading={eventsLoading}
        handleOpenEventForm={handleOpenEventForm}
        deleteEvent={handleDeleteEvent}
        events={setEventVisibility(events)}
        setEvents={setEvents}
        eventGroupId={eventGroupId}
        mergeEvents={mergeEvents}
        mergableEvents={mergableEvents}
        primeEvent={primeEvent}
      />
      <ConfirmDialog
        title="Are you sure you want to delete this event?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirmDelete}
        onCancel={() => setDeleteEvent(null)}
        buttonColor="black"
        buttonText="I understand the consequences, delete this event and related data"
        cancelButtonText="Cancel request, keep this event"
        manualConfirm={true}
        columnLayout={true}
        confirmText="DELETE ALL">
        {deleteEvent?.responses > 0 || deleteEvent?.leads > 0 ? (
          <>
            You will also be deleting{' '}
            {deleteEvent?.responses > 0
              ? `${deleteEvent.responses} responses`
              : ''}
            {deleteEvent?.leads > 0 ? ` and ${deleteEvent.leads} leads` : ''}{' '}
            related to{' '}
            {deleteEvent?.talkTitle ? (
              <>"{deleteEvent.talkTitle}"</>
            ) : (
              'this event'
            )}{' '}
            on {formatDate(deleteEvent.date, deleteEvent.offset)}.
          </>
        ) : (
          <></>
        )}
      </ConfirmDialog>
    </div>
  )
}

export default EventsParent
