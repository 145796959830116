import { useState } from 'react'
import { handleCopy } from '../common/helpers'
import {
  Typography,
  Tooltip,
  Chip,
  Button,
  Alert,
  CircularProgress,
} from '@mui/material'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import { styled } from '@mui/system'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import envConfig from '../../envConfig'
import Api from '../../services/api'
import 'react-multi-email/dist/style.css'

const InvitationLink = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  color: theme.palette.secondary.main,
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}))

const EmailChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.extraLight,
  borderRadius: '0px',
  margin: '7px',
}))

const InvitationActionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '15px',
})

const EmailAlert = styled(Alert)({
  marginBottom: '10px',
})

const GroupInviteParent = ({ group, setPendingMembers, setGroupMembers, setParentComponentLoading }) => {
  const [copied, setCopied] = useState(false)
  const [emails, setEmails] = useState([])
  const [focused, setFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorText, setErrorText] = useState(null)

  const invitationLink = `${envConfig.REACT_APP_CLIENT_ENDPOINT}/accept-group-invitation?git=${group?.invitation_token}`

  const handleCopyInvitation = () => {
    setCopied(true)
    handleCopy(invitationLink)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  const handleSendInvitations = async () => {
    setErrorText(null)

    const validatedEmails = emails.filter((email) => {
      return isEmail(email)
    })

    if (!validatedEmails.length) {
      return setErrorText('Enter some valid emails to send invitations.')
    }

    try {
      setIsLoading(true)
      setParentComponentLoading(true)

      const reqParams = {
        group_invitation: {
          emails: validatedEmails,
          group_id: group.id,
        },
      }

      const res = await Api.sendGroupInvitations(reqParams)

      if (!res.errors) {
        setPendingMembers(res.invitations)
        setGroupMembers(res.members)
        setEmails([])
        setParentComponentLoading(false)
        setIsLoading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setParentComponentLoading(false)
      setIsLoading(false)
      setErrorText(
        'Sorry, something went wrong sending these invitations. Please double check the emails you are sending to. If this problem persists, please contact support at hello@talkadot.com'
      )
    }
  }

  return (
    <>
      {errorText && <EmailAlert severity="error">{errorText}</EmailAlert>}
      <form>
        <ReactMultiEmail
          emails={emails}
          onChange={(_emails) => {
            if (errorText) {
              setErrorText(null)
            }
            setEmails(_emails)
          }}
          focused={focused}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          style={{ height: '125px', overflow: 'scroll' }}
          placeholder="Enter emails here ..."
          getLabel={(email, index, removeEmail) => {
            return (
              <EmailChip label={email} onDelete={() => removeEmail(index)} />
            )
          }}
        />
      </form>{' '}
      <InvitationActionContainer>
        <Tooltip placement="top" title="copied!" open={copied} arrow>
          <InvitationLink color="secondary" onClick={handleCopyInvitation}>
            <ContentCopyIcon />
            <Typography>Copy invite link to {group?.name}</Typography>
          </InvitationLink>
        </Tooltip>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={handleSendInvitations}>
          {isLoading ? (
            <CircularProgress size="1.2rem" color="inherit" />
          ) : (
            'Add to Chapter'
          )}
        </Button>
      </InvitationActionContainer>
    </>
  )
}

export default GroupInviteParent
