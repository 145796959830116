import { useState, useContext } from 'react'
import Api from '../../services/api'
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@mui/material'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { IoSyncCircle } from 'react-icons/io5'

const EventSpeakerflowSyncMenuItem = ({ event }) => {
  const [isSyncing, setIsSyncing] = useState(false)
  const { setNotification } = useContext(AuthenticationContext)

  const syncLeadsWithSpeakerflow = async () => {
    try {
      setIsSyncing(true)
      const params = {
        event_leads: {
          event_id: event.id,
        },
      }

      const res = await Api.syncEventLeadsWithSpeakerflow(params)

      if (!res.errors) {
        setIsSyncing(false)
        setNotification(
          'Your leads from this event are syncing with speakerflow!',
          'success'
        )
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsSyncing(false)
      setNotification(
        'Oops - looks like there was something wrong with syncing your leads with Speakerflow. Please check your Speakerflow settings on the account settings page and try again. If the problem persists, contact us at hello@talkadot.com!'
      )
    }
  }

  return (
    <MenuItem key="7" onClick={syncLeadsWithSpeakerflow} disabled={isSyncing}>
      <ListItemIcon>
        {isSyncing ? <CircularProgress size={25} /> : <IoSyncCircle />}
      </ListItemIcon>
      <ListItemText>Sync Leads With Speakerflow</ListItemText>
    </MenuItem>
  )
}

export default EventSpeakerflowSyncMenuItem
