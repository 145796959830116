import { styled } from '@mui/system'

const Header = styled('div')(({ theme }) => ({
  marginBottom: '1.875rem',
  color: theme.palette.base.white,
  fontFamily: 'Figtree, sans-serif',
  fontSize: '1.5625rem',
  zIndex: 1002,
  width: '100%',
  '@media (max-width: 1132px)': {
    fontSize: '1.3rem',
    marginBottom: '1.5rem',
  },
  '@media (max-width: 850px)': {
    fontSize: '1rem',
    marginBottom: '1rem',
  },
}))

const SurveyOffersOnboardingHeader = ({ children }) => {
  return <Header>{children}</Header>
}

export default SurveyOffersOnboardingHeader
