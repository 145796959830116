import { styled } from '@mui/system'

const Card = styled('div')(({ theme }) => ({
  margin: theme.spacing(3.25, 0, 6.5, 0),
  background: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xxs,
}))

const CardNotifications = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2.5),
  padding: theme.spacing(1.25, 2.5),
  maxWidth: '720px',
  width: '100%',
}))

const CardInnerReferral = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2.5),
  padding: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: theme.spacing(6, 2.5),
  },
}))

const CardInnerTrial = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center',
  padding: theme.spacing(6, 2.5),
}))

const CardVideoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(6, 2.5),
  },
}))

const CardInnerVideo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  maxWidth: '640px',
  width: '100%',
}))

export {
  Card,
  CardNotifications,
  CardInnerReferral,
  CardInnerTrial,
  CardVideoContainer,
  CardInnerVideo,
}
