import { useContext, useEffect } from 'react'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Logout from '@mui/icons-material/Logout'
import { isMobileOnly } from 'react-device-detect'

import Api from '../../services/api'

import { OnboardingContext, OnboardingTypes } from './onboardingContext'
import { AuthenticationContext } from '../authentication/authenticationContext'
import history from '../../routes/history'
import { device } from '../../responsive'

import './Onboarding.scss'

const SubmitWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hideOkButton',
})(({ hideOkButton }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  visibility: hideOkButton ? 'hidden' : 'visible',
}))

const SubmitButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isLastStep',
})(({ isLastStep }) => ({
  marginBottom: isLastStep ? '10px' : 0,
  marginRight: isLastStep ? 0 : '15px',
  width: isLastStep ? '100%' : 'auto',
}))

const EnterText = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
})

// TODO: move media queries out of Onboarding.scss
const InnerContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  flexGrow: 0.4,
  maxWidth: '450px',
  margin: '0 auto',
})

const LogOutButton = styled(IconButton)({
  position: 'absolute',
  top: '20px',
  right: '20px',
  // TODO: remove coupling with .onboarding>button styling
  padding: '5px !important',
  span: {
    fontSize: '1rem',
    marginLeft: '5px',
  },
  [`@media ${device.mobile}`]: {
    top: '5px',
    right: '5px',
    span: {
      fontSize: '0.8rem',
    },
  },
})

const LogoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const MainLogo = styled('img')(({ theme }) => ({
  width: '214px',
  height: 'auto',
}))

const OnboardingParent = () => {
  const {
    onboardingState,
    getCurrentStep,
    setCurrentStep,
    goBack,
    steps,
    setOnboardingSurveyOffer,
    resetOnboardingData,
  } = useContext(OnboardingContext)
  const { authData, logoutUser } = useContext(AuthenticationContext)
  const { user } = authData
  const { isLoading } = onboardingState
  const currentStep = getCurrentStep()

  // Since we adjust the amount of steps in our flow so often, it's possible that
  // legacy users have a currentStep of nil, so handle this case here
  const progress = currentStep
    ? Math.ceil((currentStep.id / steps.length) * 100)
    : 0
  const hideOkButton =
    currentStep?.type === OnboardingTypes.Avatar && !user.avatar

  useEffect(() => {
    if (user.onboarding_complete) {
      if (isMobileOnly) {
        return history.push('/dashboard')
      }

      return history.push('/survey-onboarding')
    }
  }, [user])

  useEffect(() => {
    const fetchSurveyOffers = async () => {
      try {
        const res = await Api.getAllSurveyOffers()

        if (res.errors) {
          throw res.errors
        }

        if (res.length >= 0) {
          // Set the survey offer to the last one created
          setOnboardingSurveyOffer(res[0])
        }
      } catch (err) {
        // nothing to do here yet
      }
    }

    fetchSurveyOffers()
  }, [])

  useEffect(() => {
    const keyPressHandler = (event) => {
      if (event.key === 'Enter' && !hideOkButton) {
        event.preventDefault()
        currentStep.submitAction()
      }
    }

    document.addEventListener('keypress', keyPressHandler)

    return () => {
      document.removeEventListener('keypress', keyPressHandler)
    }
  }, [currentStep, hideOkButton])

  const handleLogout = () => {
    logoutUser()
    resetOnboardingData()
  }

  const renderOnboardingControls = () => {
    return (
      <div className="onboarding__actions">
        <div className="onboarding__step-progress">
          <Typography component="div" variant="body2" sx={{ fontWeight: 200 }}>
            {progress}% complete
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
        </div>
        <div className="onboarding__actions__action-buttons">
          {!currentStep.isFirstStep && (
            <Button variant="outlined" onClick={goBack}>
              <ArrowBackIcon />
            </Button>
          )}
          {isLoading ? (
            <CircularProgress style={{ color: 'orange' }} />
          ) : (
            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={currentStep.submitAction}>
              <ArrowForwardIcon />
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="yellow-paint-graphic">
        <div className="yellow-paint-graphic__inner">
          <Typography component="div" variant="h4" sx={{ color: 'white' }}>
            Welcome To Talkadot!
          </Typography>
        </div>
      </div>
      <div className="onboarding">
        <div className="onboarding__step-content">
          <LogoContainer>
            <MainLogo
              src="https://d3qvkz8495pjoq.cloudfront.net/talkadotLogoLg.svg"
              alt="Talkadot!"
            />
          </LogoContainer>
          <LogOutButton aria-label="log out" onClick={handleLogout}>
            <Logout fontSize="small" />
            <span>Log Out</span>
          </LogOutButton>
          <InnerContent className="onboarding__step-inner-content">
            <div className="onboarding__step-title">
              <Typography component="div" variant="h6" className="subtitle">
                <span
                  dangerouslySetInnerHTML={{
                    __html: currentStep.title,
                  }}></span>
              </Typography>
            </div>
            <div>
              {currentStep.children}
              {!currentStep.hideMainAction && (
                <div className="onboarding__step__main-action">
                  {isLoading ? (
                    <CircularProgress style={{ color: 'orange' }} />
                  ) : (
                    <SubmitWrapper hideOkButton={hideOkButton}>
                      <SubmitButton
                        className="orange-button"
                        onClick={currentStep.submitAction}
                        disabled={hideOkButton}
                        isLastStep={currentStep.isLastStep}>
                        <Typography
                          variant={currentStep.isLastStep ? 'h6' : 'body1'}
                          component="span">
                          {currentStep.isLastStep ? 'Finish!' : 'Ok'}
                        </Typography>
                      </SubmitButton>
                      <EnterText>
                        press&nbsp;<b>Enter ↵</b>
                      </EnterText>
                    </SubmitWrapper>
                  )}
                  {currentStep.skipable && (
                    <div
                      className="orange"
                      id="skip-action"
                      onClick={() =>
                        setCurrentStep(currentStep.skipQuestionId)
                      }>
                      I'll do this later
                    </div>
                  )}
                </div>
              )}
            </div>
          </InnerContent>
          {renderOnboardingControls()}
        </div>
      </div>
    </>
  )
}

export default OnboardingParent
