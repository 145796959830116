import { useEffect, useState } from 'react'
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import TalksTableHeader from './TalksTableHeader'
import TalksTableRow from './TalksTableRow'
import TalksTablePlaceHolder from './TalksTablePlaceHolder'
import TablePaginationFooter from '../common/TablePaginationFooter'

import { getComparator } from '../../utils/sort'

const TalksTable = ({
  events,
  loading,
  handleOpenEventForm,
  handleDeleteEvent,
  handleMergeEventsClick,
  handleMarkEventAsViewed,
  handleDeleteRestrictions,
  canDeleteRestrictions,
  mergableEvents,
  selectEvent,
  deselectEvent,
}) => {
  const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(5)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('')
  const [visibleRows, setVisibleRows] = useState([])

  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const rowsPerPage = 25

  useEffect(() => {
    setVisibleRows(
      events
        .slice()
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    )
  }, [events, page, rowsPerPage, order, orderBy])

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - events.length) : 0

  let skeletonColumns = 7
  if (isSmallScreen) {
    skeletonColumns = 2
  } else if (isMediumScreen) {
    skeletonColumns = 4
  } else if (isLargeScreen) {
    skeletonColumns = 5
  }

  const renderLoadingSkeleton = () =>
    Array.from({ length: 3 }).map((_, index) => (
      <TableRow key={`skeleton-row-${index}`}>
        <TableCell />
        {Array.from({ length: skeletonColumns }).map((_, cellIndex) => (
          <TableCell key={`skeleton-table-cell-${cellIndex}`}>
            <Skeleton variant="text" />
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    ))

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const renderTableRows = () => {
    if (loading) {
      return renderLoadingSkeleton()
    }

    if (visibleRows.length === 0) {
      return <TalksTablePlaceHolder handleOpenEventForm={handleOpenEventForm} />
    }

    return visibleRows.map((event) => (
      <TalksTableRow
        key={event.id}
        event={event}
        handleOpenEventForm={handleOpenEventForm}
        handleDeleteEvent={handleDeleteEvent}
        handleMergeEventsClick={handleMergeEventsClick}
        handleMarkEventAsViewed={handleMarkEventAsViewed}
        handleDeleteRestrictions={handleDeleteRestrictions}
        canDeleteRestrictions={canDeleteRestrictions}
        selectEvent={selectEvent}
        deselectEvent={deselectEvent}
        mergableEvents={mergableEvents}
      />
    ))
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="talks table">
          <TalksTableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {renderTableRows()}
            {/* NOTE: Commented this out because with 25 rows as the default this looks bad  */}
            {/* {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 57 * emptyRows,
                }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePaginationFooter
        rows={events}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
      />
    </>
  )
}

export default TalksTable
