import { FormControlLabel, Radio } from '@mui/material'

import { ReactComponent as UncheckedIcon } from '../../icons/radioUncheckedIcon.svg'
import { ReactComponent as CheckedIcon } from '../../icons/radioCheckedIcon.svg'

const RadioButton = ({ value, label }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />}
      label={label}
    />
  )
}

export default RadioButton
