import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { PersistentNotifications } from '../../common/Notification'
import { OFFER_CODE_LIMIT } from '../../../utils/permission_helper'

const NotificationTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: 'inherit',
  '& a': {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
  '& .upgrade-link': {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
}))

const NotificationParent = ({ notifications }) => {
  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  const buildNotificationContent = (notification) => {
    switch (notification.type) {
      case 'trialNotification': {
        const { message, ctaText } = notification
        return (
          <>
            <span>
              {message}
              &nbsp;&nbsp;
            </span>
            <span
              className="upgrade-link"
              onClick={() =>
                toggleUpgradeModal(true, 'Upgrade Now!', OFFER_CODE_LIMIT)
              }>
              {ctaText}
            </span>
          </>
        )
      }
      case 'codeLimit': {
        const { message } = notification
        return (
          <>
            <span>
              {message}
              &nbsp;&nbsp;
            </span>
            <span
              className="upgrade-link"
              onClick={() =>
                toggleUpgradeModal(
                  true,
                  'Upgrade for more codes!',
                  OFFER_CODE_LIMIT
                )
              }>
              Upgrade for more codes.
            </span>
          </>
        )
      }
      case 'missingProfilePic':
        return (
          <>
            <span>You are missing a profile photo.&nbsp;&nbsp;</span>
            <Link to="/account/settings">
              Add it now to complete your feedback form.
            </Link>
          </>
        )
      case 'missingSocialLinks':
        return (
          <>
            <span>You are missing your socials.&nbsp;&nbsp;</span>
            <Link to="/account/settings#social-links">
              Add them so your audience can keep in touch!
            </Link>
          </>
        )
      case 'missingBookingLink':
        return (
          <>
            <span>
              You haven’t set up your booking calendar link.&nbsp;&nbsp;
            </span>
            <Link to="/account/settings#booking-link">
              Let qualified leads schedule time with you.
            </Link>
          </>
        )
      case 'missingEmailFollowup':
        return (
          <>
            <span>
              You haven’t turned on post event followup emails.&nbsp;&nbsp;
            </span>
            <Link to="/leads/follow-up">Set it up now.</Link>
          </>
        )
      default:
        return null
    }
  }

  const buildNotificationDetails = () => {
    return notifications.map((notification) => {
      return {
        variant: 'greyWarning',
        content: (
          <NotificationTypography variant="body1">
            {buildNotificationContent(notification)}
          </NotificationTypography>
        ),
      }
    })
  }

  return (
    <PersistentNotifications notificationDetails={buildNotificationDetails()} />
  )
}

export default NotificationParent
