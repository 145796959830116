import { useLocation } from 'react-router-dom'

/**
 * Matches a given path name against a specific link pattern.
 * This function checks if the path contains the specified link,
 * followed by an optional trailing slash or query parameters.
 *
 * @param {string} pathName - The path name to match against.
 * @param {string} link - The link pattern to match. Should not include leading or trailing slashes.
 * @param {boolean} [strictMatch=true] - Whether to enforce strict matching.
 * @returns {boolean} - Returns true if the path matches the link pattern, otherwise false.
 *
 * @example
 * // returns true
 * matchPath('base_url/leads', 'leads')
 * matchPath('base_url/something/leads/something-else', 'leads')
 * matchPath('base_url/leads?search=s', 'leads')
 * matchPath('base_url/event-planner/leads', 'leads')
 *
 * // returns false
 * matchPath('base_url/leads/integrations', 'leads', false)
 * matchPath('base_url/leadss/', 'leads')
 */
const matchPath = (pathName, link, strictMatch = true) => {
  const regex = strictMatch
    ? new RegExp(`/${link}($|\\?)`)
    : new RegExp(`/${link}($|\\/|\\?)`)
  return regex.test(pathName)
}

export const usePageType = (strictMatch = true) => {
  const location = useLocation()
  const pathName = location.pathname

  if (matchPath(pathName, 'leads/follow-up', strictMatch)) {
    return 'emailCampaigns'
  }
  if (matchPath(pathName, 'codes/edit', false)) {
    return 'surveyBuilder'
  }
  if (matchPath(pathName, 'leads', strictMatch)) {
    return 'leads'
  }
  if (matchPath(pathName, 'codes', strictMatch)) {
    return 'codes'
  }
  if (matchPath(pathName, 'talks', strictMatch)) {
    return 'talks'
  }

  return null
}

export const useIsEventPlannerPage = () => {
  const location = useLocation()
  return matchPath(location.pathname, 'event-planner', false)
}

export const useIsChapterPage = () => {
  const location = useLocation()
  return matchPath(location.pathname, 'groups', false)
}
