import { createContext, useReducer, useContext } from 'react'

import { onboardingReducer, initialOnboardingState } from './onboardingReducer'
import { AuthenticationContext } from '../authentication/authenticationContext'
import * as ActionTypes from './actionTypes'
import history from '../../routes/history'

import UserAttributeForm from './UserAttributeForm'
import ShortlinkPicker from './ShortlinkPicker'
import MultipleChoiceQuestion from './MultipleChoiceQuestion'
import ReferralQuestionParent from './ReferralQuestionParent'
import ReferralOrganizationPicker from './ReferralOrganizationPicker'
import EditAvatar from '../accountSettings/EditAvatar'

import Api from '../../services/api'
import { trackOnboardingComplete } from '../../services/metrics'
import { validateShortlinkFormat } from '../../utils/validators'
import { sanitizeUserName } from '../../utils/string'

export const OnboardingContext = createContext({
  onboardingState: initialOnboardingState,
})

export const OnboardingTypes = {
  FirstName: 'firstName',
  LastName: 'lastName',
  Avatar: 'avatar',
  SurveyLink: 'surveyLink',
  ReferrerParent: 'referrer_parent',
  ReferrerFriend: 'referrer_friend',
  ReferrerOrganization: 'referrer_organization',
  ReferrerChild: 'referrer_child',
  NextTalk: 'nextTalk',
  MainBenefits: 'mainBenefits',
  TalksPerYear: 'talksPerYear',
  SlideDownload: 'slideDownload',
  WelcomeVideo: 'welcomeVideo',
}

const QuestionTypes = {
  NextTalk: 'next_talk',
  MainBenefits: 'main_benefits',
  ReferrerOptions: 'referral_options',
  TalksPerYear: 'talks_per_year',
}

const ReferrerTypes = {
  Friend: 'Through a friend or fellow speaker',
  Organization: 'From an organization or group',
  Other: 'Other',
}

const ReferrerOptions = [
  { id: 1, text: ReferrerTypes.Friend, additionalContextRequired: false },
  { id: 2, text: ReferrerTypes.Organization, additionalContextRequired: false },
  { id: 3, text: ReferrerTypes.Other, additionalContextRequired: true },
]

const OnboardingContextProvider = (props) => {
  const [onboardingState, dispatch] = useReducer(
    onboardingReducer,
    initialOnboardingState
  )
  const {
    authData,
    setProfileAttribute,
    setNotification,
    canToggleTalkadotBranding,
    setUserMembership,
  } = useContext(AuthenticationContext)
  const { user } = authData

  const defaultShortLink = user
    ? sanitizeUserName(user.first_name + user.last_name)
    : ''

  const toggleIsLoading = (isLoading) => {
    dispatch({
      type: ActionTypes.TOGGLE_IS_LOADING,
      payload: isLoading,
    })
  }

  const toggleFormError = (formError) => {
    dispatch({
      type: ActionTypes.SET_FORM_ERROR,
      payload: formError,
    })
  }

  const setReferrerDataAttribute = (attributeType, attributeValue) => {
    dispatch({
      type: ActionTypes.SET_REFERRER_DATA_ATTRIBUTE,
      payload: {
        attrType: attributeType,
        attrValue: attributeValue,
      },
    })
  }

  const setSelectedReferrerOption = (referrerOption) => {
    return setReferrerDataAttribute('selectedReferrerOption', referrerOption)
  }

  const setAdditionalReferrerContext = (additionalReferrerContext) => {
    return setReferrerDataAttribute(
      'additionalReferrerContext',
      additionalReferrerContext
    )
  }

  const setReferrerOrganization = (referrerOrganization) => {
    return setReferrerDataAttribute(
      'selectedReferrerOrganization',
      referrerOrganization
    )
  }

  const setReferrerChapter = (referrerChapter) => {
    return setReferrerDataAttribute('selectedReferrerChapter', referrerChapter)
  }

  const submitCustomUrl = async () => {
    const { shortlink } = user
    const { valid, message } = validateShortlinkFormat(shortlink)

    if (!valid) {
      return toggleFormError(message)
    }

    const params = {
      user: {
        id: user.id,
        shortlink: user.shortlink.trim(),
      },
    }

    await submitProfileAttribute(params)
  }

  const submitSocialMediaData = async () => {
    let params = []
    const { socialLinks } = user

    for (let socialProvider in socialLinks) {
      params.push({
        provider: socialProvider,
        url: socialLinks[socialProvider],
      })
    }

    try {
      toggleIsLoading(true)

      const res = await Api.submitSocialMediaData({
        social_media_links: params,
      })

      if (!res.errors) {
        toggleIsLoading(false)
        return
      } else {
        throw res.errors
      }
    } catch (err) {
      toggleIsLoading(false)
      setNotification(err)
    }
  }

  const handleOnboardingComplete = async () => {
    const params = {
      user: {
        id: user.id,
        onboarding_complete: true,
      },
    }

    try {
      const res = await Api.updateUser(params)
      if (!res.errors) {
        setProfileAttribute('onboarding_complete', true)

        trackOnboardingComplete({
          userId: user.id,
          benefits: user.onboarding_info?.main_benefits,
          nextTalk: user.onboarding_info?.next_talk,
        })
      }
    } catch (err) {
      setNotification(err)
    }

    return history.push('/dashboard')
  }

  const findNextStep = (currentStep) => {
    return steps.filter((step) => step.id === currentStep.id + 1)[0]
  }

  const isReferrerQuestion = (nextStep) => {
    return (
      nextStep.type === OnboardingTypes.ReferrerParent ||
      nextStep.type === OnboardingTypes.ReferrerChild
    )
  }

  // If the next question is a referral tracking question
  // and the user already has a valid referrer, skip this question entirely
  // OR
  // If the user selects "other" for referrer and does not input a referral option
  // OR
  // If the user selects "friend" for referrer and does not input a referral value
  const shouldSkipNextStep = (nextStep) => {
    const { selectedReferrerOption } = onboardingState.referrerData
    return (
      (isReferrerQuestion(nextStep) && user.referrer && !nextStep.isLastStep) ||
      (selectedReferrerOption === ReferrerTypes.Other &&
        !user.referrer &&
        !nextStep.isLastStep) ||
      (selectedReferrerOption === ReferrerTypes.Friend &&
        nextStep.subquestionType === OnboardingTypes.ReferrerOrganization)
    )
  }

  const goForward = async () => {
    const currentStep = getCurrentStep()

    if (currentStep.isLastStep) {
      return await handleOnboardingComplete()
    }

    const nextStep = findNextStep(currentStep)

    if (shouldSkipNextStep(nextStep)) {
      return setCurrentStep(nextStep.skipQuestionId)
    }

    try {
      const params = {
        user: {
          last_onboarding_step: onboardingState.currentStep,
        },
      }

      await Api.updateUser(params)
    } catch (err) {
      // just catch the error
    }

    setCurrentStep(onboardingState.currentStep + 1)
  }

  const setCurrentStep = (step) => {
    dispatch({
      type: ActionTypes.SET_CURRENT_STEP,
      payload: step,
    })
  }

  const goBack = () => {
    const currentStep = getCurrentStep()

    if (currentStep.isFirstStep) {
      return
    }

    const backStepId = currentStep.backQuestionId
      ? currentStep.backQuestionId
      : currentStep.id - 1

    dispatch({
      type: ActionTypes.SET_CURRENT_STEP,
      payload: backStepId,
    })
  }

  const submitFirstName = async () => {
    if (
      !user.first_name ||
      !user.first_name.trim() ||
      user.first_name.trim().length === 0
    ) {
      return toggleFormError(
        'Please fill out a valid first name before continueing'
      )
    }

    const params = {
      user: {
        id: user.id,
        first_name: user.first_name.trim(),
      },
    }

    return await submitProfileAttribute(params)
  }

  const submitEmail = async () => {
    if (!user.email || !user.email.trim()) {
      return toggleFormError('Please fill out a valid email before continuing')
    }

    const params = {
      user: {
        id: user.id,
        email: user.email.trim(),
      },
    }

    return await submitProfileAttribute(params)
  }

  const submitToggleTalkadotBranding = async (showBranding) => {
    // Don't toggle if they don't have permissions
    if (!canToggleTalkadotBranding()) {
      return
    }

    const params = {
      user: {
        id: user.id,
        show_talkadot_branding: showBranding,
      },
    }

    return await submitProfileAttribute(params)
  }

  const submitAvatar = async (avatarLink) => {
    const params = {
      user: {
        id: user.id,
        avatar: avatarLink,
      },
    }

    try {
      toggleIsLoading(true)

      const res = await Api.updateUser(params)

      if (!res.errors) {
        setProfileAttribute('avatar', res.avatar)
        toggleIsLoading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      toggleIsLoading(false)
      setNotification(err)
    }
  }

  const submitLastNameFromAccountForm = async () => {
    if (!user.last_name || !user.last_name.trim()) {
      return toggleFormError(
        'Please fill out a valid last name before continuing'
      )
    }

    const params = {
      user: {
        id: user.id,
        last_name: user.last_name.trim(),
      },
    }

    return await submitProfileAttribute(params)
  }

  const goToNextQuestionFromSubquestionType = (subquestionType) => {
    const nextStep = steps.find(
      (step) => step.subquestionType === subquestionType
    )
    return setCurrentStep(nextStep.id)
  }

  const formatReferrerType = (referrerType) => {
    if (referrerType === ReferrerTypes.Friend) {
      return 'FRIEND'
    }

    if (referrerType === ReferrerTypes.Organization) {
      return 'ORGANIZATION'
    }

    return 'OTHER'
  }

  const syncReferrerData = async () => {
    const { selectedReferrerOption, additionalReferrerContext } =
      onboardingState.referrerData

    const params = {
      user: {
        id: user.id,
        referrer: user.referrer || additionalReferrerContext,
      },
      referrer_type: formatReferrerType(selectedReferrerOption),
    }

    try {
      toggleIsLoading(true)

      const res = await Api.updateReferrer(params)

      if (!res.errors) {
        toggleIsLoading(false)
        setUserMembership(res)
        return goForward()
      } else {
        throw res.errors
      }
    } catch (err) {
      toggleIsLoading(false)
      setNotification(err)
    }
  }

  const submitReferrer = async () => {
    const { selectedReferrerOption } = onboardingState.referrerData
    const currentStep = getCurrentStep()

    if (currentStep.type === OnboardingTypes.ReferrerParent) {
      if (!selectedReferrerOption) {
        // Skip referrer questions entirely if this is the case
        return setCurrentStep(currentStep.skipQuestionId)
      }

      if (selectedReferrerOption === ReferrerTypes.Friend) {
        return goToNextQuestionFromSubquestionType(
          OnboardingTypes.ReferrerFriend
        )
      }

      if (selectedReferrerOption === ReferrerTypes.Organization) {
        return goToNextQuestionFromSubquestionType(
          OnboardingTypes.ReferrerOrganization
        )
      }

      if (selectedReferrerOption === ReferrerTypes.Other) {
        setProfileAttribute(
          'referrer',
          onboardingState.referrerData?.additionalReferrerContext
        )

        await syncReferrerData()
      }

      return setCurrentStep(currentStep.skipQuestionId)
    }

    return await syncReferrerData()
  }

  const submitOnboardingInfo = async () => {
    if (!user.onboarding_info) {
      return goForward()
    }

    const nextTalk = user.onboarding_info.next_talk
    const mainBenefits = user.onboarding_info.main_benefits
    const talksPerYear = user.onboarding_info.talks_per_year

    const params = {
      onboarding_info: {
        next_talk: nextTalk,
        main_benefits: mainBenefits,
        talks_per_year: talksPerYear,
      },
    }
    return await submitOnboardingInfoData(params)
  }

  const submitLastName = async () => {
    if (!user.last_name || user.last_name.trim() === '') {
      return toggleFormError(
        'Please fill out a valid last name before continueing'
      )
    }

    const params = {
      user: {
        id: user.id,
        last_name: user.last_name.trim(),
      },
    }

    // During the onboarding flow, we want to set the users
    // talkadot shortlink option for them after they give us
    // their first name and last name
    setProfileAttribute('shortlink', defaultShortLink)

    return await submitProfileAttribute(params)
  }

  const submitProfileAttribute = async (params) => {
    try {
      toggleIsLoading(true)

      const res = await Api.updateUser(params)
      if (!res.errors) {
        toggleIsLoading(false)

        // Note: only call goForward in onboarding flow
        if (!user.onboarding_complete) {
          return goForward()
        }

        return
      } else {
        throw res.errors
      }
    } catch (err) {
      toggleIsLoading(false)
      setNotification(err)
    }
  }

  const submitOnboardingInfoData = async (params) => {
    try {
      toggleIsLoading(true)

      const res = await Api.updateOnboardingInfo(params)

      if (!res.errors) {
        toggleIsLoading(false)
        return goForward()
      } else {
        throw res.errors
      }
    } catch (err) {
      toggleIsLoading(false)
      setNotification(err)
    }
  }

  const submitSurveyOffersOnboardingInfoData = async (params) => {
    try {
      toggleIsLoading(true)

      const res = await Api.updateOnboardingInfo(params)
      if (!res.errors) {
        toggleIsLoading(false)
        return res
      } else {
        throw res.errors
      }
    } catch (err) {
      toggleIsLoading(false)
      setNotification(err)
    }
  }

  // TODO:
  // This is for adding a survey offer in the onboarding flow
  // Perhaps move this into a surveyOffer context & reducer.
  const setOnboardingSurveyOfferAttribute = (attributeType, attributeValue) => {
    dispatch({
      type: ActionTypes.SET_ONBOARDING_SURVEY_OFFER_ATTRIBUTE,
      payload: {
        attrType: attributeType,
        attrValue: attributeValue,
      },
    })
  }

  const setOnboardingSurveyOffer = (onboardingSurveyOffer) => {
    dispatch({
      type: ActionTypes.SET_ONBOARDING_SURVEY_OFFER,
      payload: onboardingSurveyOffer,
    })
  }

  const resetOnboardingData = () => {
    dispatch({
      type: ActionTypes.RESET_ONBOARDING_DATA,
    })
  }

  const onboardingQuestions = {
    nextTalk: {
      type: QuestionTypes.NextTalk,
      title: 'When is your next talk?',
      multipleSelection: false,
      options: [
        { id: 1, text: 'This week!' },
        { id: 2, text: 'In the next 1-2 weeks' },
        { id: 3, text: 'In the next month' },
        { id: 4, text: 'In the next 2-3 months' },
        { id: 5, text: 'No set time yet' },
      ],
    },
    mainBenefits: {
      type: QuestionTypes.MainBenefits,
      title: 'What are the main benefits you hope to get from Talkadot?',
      multipleSelection: true,
      options: [
        { id: 1, text: 'Have talk highlights to share on social media' },
        { id: 2, text: 'Get reports to show my event hosts' },
        { id: 3, text: 'See what the audience thought' },
        { id: 4, text: 'Get booking leads from my audience' },
        { id: 5, text: 'Get rebooking from my event hosts' },
      ],
    },
    talksPerYear: {
      type: QuestionTypes.TalksPerYear,
      title: 'How many talks do you expect to give this year?',
      multipleSelection: false,
      options: [
        { id: 1, text: '1-10' },
        { id: 2, text: '11-30' },
        { id: 3, text: '31-50' },
        { id: 4, text: '51+' },
        { id: 5, text: "Don't know yet, I'm just getting started!" },
      ],
    },
  }

  const steps = [
    {
      id: 1,
      type: OnboardingTypes.FirstName,
      title: "Hello! What's your <b>first name<b>?",
      children: (
        <UserAttributeForm
          formValue={user ? user.first_name : ''}
          formLabel="first name"
          profileAttribute={'first_name'}
        />
      ),
      isFirstStep: true,
      submitAction: submitFirstName,
    },
    {
      id: 2,
      type: OnboardingTypes.LastName,
      title: "What's your <b>last name<b>?",
      children: (
        <UserAttributeForm
          formValue={user ? user.last_name : ''}
          formLabel="last name"
          profileAttribute={'last_name'}
        />
      ),
      submitAction: submitLastName,
    },
    {
      id: 3,
      type: OnboardingTypes.Avatar,
      title:
        'Let’s add your <b>profile pic</b>! This will show on your surveys, event reports, and other talkadot pages.',
      children: <EditAvatar currentAvatar={user.avatar} />,
      submitAction: goForward,
      skipable: true,
      skipQuestionId: 4,
      skipableText: `I'll do this later`,
    },
    {
      id: 4,
      type: OnboardingTypes.SurveyLink,
      title:
        'Claim your <b>Talkadot handle</b>. This is your unique feedback link.',
      children: (
        <ShortlinkPicker
          formValue={user ? user.shortlink : ''}
          formLabel=""
          profileAttribute="shortlink"
        />
      ),
      submitAction: submitCustomUrl,
    },
    {
      id: 5,
      type: OnboardingTypes.ReferrerParent,
      title: '<b>How did you hear about Talkadot?</b>',
      skipQuestionId: 8,
      backQuestionId: 4,
      children: (
        <ReferralQuestionParent
          referrerOptions={ReferrerOptions}
          setSelectedReferrerOption={setSelectedReferrerOption}
          selectedReferrerOption={
            onboardingState.referrerData.selectedReferrerOption
          }
          setAdditionalReferrerContext={setAdditionalReferrerContext}
          additionalReferrerContext={
            onboardingState.referrerData.additionalReferrerContext
          }
        />
      ),
      submitAction: submitReferrer,
    },
    {
      id: 6,
      type: OnboardingTypes.ReferrerChild,
      subquestionType: OnboardingTypes.ReferrerFriend,
      skipQuestionId: 8,
      backQuestionId: 5,
      title:
        '<b>You mentioned hearing about Talkadot from someone.</b><br /><br />Would you share their name so we can thank them?',
      children: (
        <UserAttributeForm
          formValue={user ? user.referrer : ''}
          formLabel="Their Name"
          profileAttribute={'referrer'}
        />
      ),
      submitAction: submitReferrer,
    },
    {
      id: 7,
      type: OnboardingTypes.ReferrerChild,
      subquestionType: OnboardingTypes.ReferrerOrganization,
      skipQuestionId: 8,
      backQuestionId: 5,
      title:
        '<b>You mentioned hearing about Talkadot from an organization.</b>',
      children: (
        <ReferralOrganizationPicker
          setProfileAttribute={setProfileAttribute}
          setReferrerChapter={setReferrerChapter}
          setReferrerOrganization={setReferrerOrganization}
          existingReferrerChapter={
            onboardingState.referrerData.selectedReferrerChapter
          }
          existingReferrerOrganization={
            onboardingState.referrerData.selectedReferrerOrganization
          }
        />
      ),
      submitAction: submitReferrer,
    },
    {
      id: 8,
      type: OnboardingTypes.NextTalk,
      backQuestionId: 5,
      title: 'When is your next talk?',
      children: (
        <MultipleChoiceQuestion
          question={onboardingQuestions.nextTalk}
          user={user}
          profileAttribute="onboarding_info"
        />
      ),
      submitAction: submitOnboardingInfo,
    },
    {
      id: 9,
      type: OnboardingTypes.MainBenefits,
      title: 'What are the main benefits you hope to get from Talkadot?',
      children: (
        <MultipleChoiceQuestion
          question={onboardingQuestions.mainBenefits}
          user={user}
          profileAttribute="onboarding_info"
        />
      ),
      submitAction: submitOnboardingInfo,
    },
    {
      id: 10,
      type: OnboardingTypes.TalksPerYear,
      title: onboardingQuestions.talksPerYear.title,
      children: (
        <MultipleChoiceQuestion
          question={onboardingQuestions.talksPerYear}
          user={user}
          profileAttribute="onboarding_info"
        />
      ),
      submitAction: submitOnboardingInfo,
      isLastStep: true,
    },
  ]

  const getCurrentStep = () => {
    const currentStep = steps.filter(
      (step) => onboardingState.currentStep === step.id
    )[0]
    // In case step is out of sync due to change in onboardingSteps
    if (!currentStep) {
      return steps[steps.length - 1]
    }
    return currentStep
  }

  const findStartingStep = (lastCompletedOnboardingStep) => {
    if (!lastCompletedOnboardingStep) {
      return 1
    }

    return lastCompletedOnboardingStep + 1
  }

  return (
    <OnboardingContext.Provider
      value={{
        onboardingState,
        getCurrentStep,
        goBack,
        goForward,
        submitCustomUrl,
        toggleFormError,
        submitSocialMediaData,
        submitFirstName,
        submitLastName,
        submitAvatar,
        submitLastNameFromAccountForm,
        submitEmail,
        submitToggleTalkadotBranding,
        submitSurveyOffersOnboardingInfoData,
        setOnboardingSurveyOffer,
        setOnboardingSurveyOfferAttribute,
        toggleIsLoading,
        setCurrentStep,
        findStartingStep,
        resetOnboardingData,
        steps,
      }}>
      {props.children}
    </OnboardingContext.Provider>
  )
}

export default OnboardingContextProvider
