// See Rewardful Javascript API Docs Here:
// https://developers.rewardful.com/javascript-api/overview

export const getRewardfulReferralId = () => {
  return window.Rewardful?.referral

  // window.rewardful('ready', function() {
  //   if(window.Rewardful.referral) {
  //     // The current website visitor is a referral from an affiliate.
  //     return window.Rewardful.referral
  //   }

  //   return null
  // });
}
