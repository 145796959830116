import { styled } from '@mui/material/styles'
import { Typography, Tooltip, Avatar } from '@mui/material'

const BannerContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'font',
})(({ theme, font }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '100%',
  padding: '15px 0',
  display: 'flex',
  backgroundColor: '#178ecb',
  marginBottom: '15px',
  // fontFamily: font ? font : 'inherit',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: '25px',
    width: '85%',
    clipPath: `polygon(0 0, 100% 0, 100% 0, calc(100% - 150px) 100%, 0 100%)`,
  },
}))

const BannerAvatar = styled(Avatar)(({ theme }) => ({
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  img: {
    width: '102%',
    height: '102%',
  },
  marginRight: '20px',
  [theme.breakpoints.down('md')]: {
    marginRight: '0',
  },
}))

const Summary = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '10px 40px 0',
    textAlign: 'center',
  },
}))

const TalkOrganization = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'font' &&
    prop !== 'longName' &&
    prop !== 'isProfile' &&
    prop !== 'nameLength',
})(({ theme, font, nameLength }) => ({
  color: 'white',
  // fontFamily: font ? font : 'inherit',
  [theme.breakpoints.down('sm')]: {
    fontSize:
      nameLength === 'long'
        ? '18px'
        : nameLength === 'medium'
        ? '22px'
        : '26px',
  },
}))

const TalkTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'font',
})(({ theme }) => ({
  color: 'white',
  paddingRight: '15%',
  // fontFamily: font ? font : 'inherit',
  [theme.breakpoints.down('md')]: {
    paddingRight: '0',
  },
}))

const TalkDate = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'font',
})(({ theme }) => ({
  // fontFamily: font ? font : 'inherit',
  color: 'white',
  paddingTop: '5px',
}))

const Banner = ({
  speaker,
  talkTitle,
  organization,
  date,
  speakers,
  isProfile = false,
  font,
  type,
}) => {
  const renderOrganization = () => {
    const orgContent = isProfile ? speaker.fullName : organization

    let nameLength = 'standard'

    const speakerNameLength = speaker.name?.length || 0
    const speakerLastNameLength = speaker.lastName?.length || 0

    if (speakerNameLength > 20 || speakerLastNameLength > 20) {
      nameLength = 'long'
    } else if (speakerNameLength > 16 || speakerLastNameLength > 16) {
      nameLength = 'medium'
    }

    if (isProfile || organization) {
      return (
        <TalkOrganization
          component="div"
          variant="h4"
          font={font}
          nameLength={nameLength}>
          <div>{orgContent}</div>
        </TalkOrganization>
      )
    }
  }

  const renderTalkTitle = () => {
    if (isProfile) return
    return (
      <TalkTitle variant="h4">
        {talkTitle ? `${talkTitle} Report` : 'Speaking Engagement Report'}
      </TalkTitle>
    )
  }

  const renderTalkDate = () => {
    if (type === 'group') return speaker.description

    if (isProfile) return 'Speaker Feedback Overview'

    return `Audience Feedback on ${date}`
  }

  return (
    // Note: the font prop is a temporary solution until we fully switch the app over to Sora font
    <BannerContainer font={font}>
      <BannerAvatar
        sx={{ width: 100, height: 100 }}
        alt={speaker.name}
        src={speaker.avatar}
      />
      <Summary>
        {renderOrganization()}
        {renderTalkTitle()}
        <TalkDate variant="body1">{renderTalkDate()}</TalkDate>
        <div style={{ marginTop: '10px', display: 'flex' }}>
          {speakers?.length >= 0 &&
            speakers.map((speaker) => {
              return (
                <Tooltip
                  key={speaker.id}
                  title={speaker.first_name}
                  placement="top">
                  <BannerAvatar
                    alt={speaker.first_name}
                    src={speaker.avatar || speaker.first_name?.charAt(0)}
                    sx={{ width: 30, height: 30, marginRight: '5px' }}
                  />
                </Tooltip>
              )
            })}
        </div>
      </Summary>
    </BannerContainer>
  )
}

export default Banner
