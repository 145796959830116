import { useState } from 'react'
import { styled } from '@mui/system'
import {
  ListItemAvatar,
  Avatar,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material'

import { IoEllipsisHorizontal, IoEyeOutline } from 'react-icons/io5'

import { GroupTableCell } from './GroupUsersRow'

import {
  eventReportCardShareLink,
  formatDate,
} from '../../features/common/helpers'

import { device } from '../../responsive'

const GroupTableRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr 2fr repeat(3, 1fr) 60px',
  textAlign: 'left',
  padding: '0 1.5rem',
  [`@media ${device.mobile}`]: {
    gridTemplateColumns: '1fr 2fr 2fr auto',
  },
})

const SpeakerListItem = styled(ListItem)({
  paddingLeft: '0px',
  paddingRight: '0px',
})

const ClickableText = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
})

const GroupEventsRow = ({ event, selectedGroup }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const eventReportShortlink = selectedGroup
    ? selectedGroup.shortlink
    : event?.eventUserShortlink
  const reportShortlink = eventReportCardShareLink(
    eventReportShortlink,
    event?.shareKey
  )

  const handleOpenDropdown = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  const renderMenuItems = (event) => {
    return (
      <div>
        <MenuItem
          component="a"
          href={reportShortlink}
          target="_blank"
          rel="noreferrer">
          <ListItemIcon>
            <IoEyeOutline />
          </ListItemIcon>
          <ListItemText primary="View" />
        </MenuItem>
      </div>
    )
  }

  const handleViewEventReport = (event) => {
    window.open(reportShortlink, '_blank').focus()
  }

  const renderSpeakerAvatars = (groupSpeakers) => {
    return groupSpeakers?.map((speaker) => (
      <SpeakerListItem key={speaker.id}>
        <ListItemAvatar>
          <Avatar src={speaker.avatar} />
        </ListItemAvatar>
        {groupSpeakers.length === 1 && window.innerWidth > 768 && (
          <ListItemText>
            {speaker.first_name} {speaker.last_name}
          </ListItemText>
        )}
      </SpeakerListItem>
    ))
  }

  return (
    <GroupTableRow key={event.id}>
      <GroupTableCell mobile>
        {formatDate(event.date, event.utcOffset)}
      </GroupTableCell>
      <GroupTableCell mobile onClick={() => handleViewEventReport(event)}>
        <ClickableText>{event.talkTitle}</ClickableText>
      </GroupTableCell>
      <GroupTableCell mobile>
        {renderSpeakerAvatars(event.speakers)}
      </GroupTableCell>
      <GroupTableCell centered>
        {event.rating ? event.rating : 'N/A'}
      </GroupTableCell>
      <GroupTableCell centered>
        {event.reattend ? event.reattend : 'N/A'}
      </GroupTableCell>
      <GroupTableCell centered>
        {event.responses ? event.responses : 'N/A'}
      </GroupTableCell>
      <GroupTableCell centered mobile>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenDropdown}
          aria-label="Open to show more"
          title="Open to show more">
          <IoEllipsisHorizontal />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          {renderMenuItems(event)}
        </Menu>
      </GroupTableCell>
    </GroupTableRow>
  )
}

export default GroupEventsRow
