import { useState, useRef } from 'react'
import { styled } from '@mui/system'

import {
  Button,
  ButtonGroup,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Grow,
  ClickAwayListener,
  Tooltip,
} from '@mui/material'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const StyledButton = styled(Button)(({ theme }) => ({
  svg: {
    path: {
      stroke: theme.palette.neutral.semiLight,
    },
  },
  fontWeight: '400',
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 3,
}))

const SplitButton = ({ options, variant = 'text', color = 'secondary' }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleClick = () => {
    options[selectedIndex].onClick()
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const renderMenuItem = ({
    text,
    disabled = false,
    tooltipText,
    tooltipPlacement = 'bottom',
    index,
  }) => {
    const menuItem = (
      <MenuItem
        key={text}
        selected={index === selectedIndex}
        onClick={(event) => handleMenuItemClick(event, index)}
        disabled={disabled}>
        {text}
      </MenuItem>
    )

    return tooltipText ? (
      <Tooltip
        title={tooltipText}
        placement={tooltipPlacement}
        disableInteractive
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14],
                },
              },
            ],
          },
        }}>
        <div>{menuItem}</div>
      </Tooltip>
    ) : (
      menuItem
    )
  }

  return (
    <>
      <ButtonGroup
        variant={variant}
        color={color}
        ref={anchorRef}
        aria-label="Button group with a nested menu">
        <StyledButton
          onClick={handleClick}
          startIcon={options[selectedIndex].icon || ''}
          disabled={options[selectedIndex].disabled}>
          {options[selectedIndex].text}
        </StyledButton>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select option"
          aria-haspopup="menu"
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) =>
                    renderMenuItem({ ...option, index })
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}

export default SplitButton
