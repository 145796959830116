import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Api from '../../services/api'

import ReportCardStatImage from './ReportCardStatImage'
import TestimonialStatImage from './TestimonialStatImage'

const initialReportDataState = {
  speaker: {
    id: null,
    name: null,
    avatar: null,
    isEventPlanner: null,
  },
  id: null,
  talkTitle: null,
  organization: null,
  date: null,
  totalResponses: null,
  ratingsBreakdown: {},
  feedback: [],
  improvement: [],
  takeaways: [],
  reattendBreakdown: {},
  isEventOwner: false,
  engagementBreakdown: [],
  speakers: [],
  eventType: null,
  isUserEvent: null,
  showBranding: null,
  access: {
    isEventOwner: null,
    isEventSpeaker: null,
    canShare: null,
  },
}

const SocialShareImageParent = () => {
  let params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [reportData, setReportData] = useState(initialReportDataState)
  const shareKey = params.sharelink
  const answerId = params.answerId

  useEffect(() => {
    const loadReportCardData = async () => {
      if (!params.sharelink) {
        return
      }

      const reqParams = {
        event_report: {
          share_key: params.sharelink,
        },
      }

      try {
        setIsLoading(true)
        const res = await Api.getEventReportCard(reqParams)
        if (!res.errors) {
          setReportData(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
      }
    }

    loadReportCardData()
  }, [])

  const sortedFeedback = reportData.takeaways
  const filteredFeedback = sortedFeedback.filter(
    (feedback) => feedback.public_share === true
  )

  let customPickedFeedback = null

  if (answerId) {
    customPickedFeedback = filteredFeedback.filter(
      (feedback) => feedback.answer_id === parseInt(answerId)
    )

    // if no custom picked feedback is found in the takeaways, then try to find it in the reportData.feedback
    if (customPickedFeedback.length === 0) {
      const sortedFeedback = reportData.feedback
      const filteredFeedback = sortedFeedback.filter(
        (feedback) => feedback.public_share === true
      )
      customPickedFeedback = filteredFeedback.filter(
        (feedback) => feedback.answer_id === parseInt(answerId)
      )
    }
  }
  return (
    <>
      <ReportCardStatImage
        reportData={reportData}
        shareKey={shareKey}
        isLoading={isLoading}
      />
      <TestimonialStatImage
        reportData={reportData}
        sortedFeedback={filteredFeedback}
        shareKey={shareKey}
        customPickedFeedback={customPickedFeedback}
      />
    </>
  )
}

export default SocialShareImageParent
