import { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

import SpeakerSlide from '../../common/SpeakerSlideResponsive'
import AlertCard from '../../common/AlertCard'
import { ReactComponent as Arrow } from '../../../icons/arrow.svg'

const AlertContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'windowWidth',
})(({ windowWidth }) => ({
  position: 'absolute',
  top: '30%',
  left: `calc(-260px + ${
    windowWidth > 960 + 540
      ? '0px'
      : `${Math.min(240, 960 + 540 - windowWidth)}px`
  })`,
  '@media (max-width: 1132px)': {
    left: `calc(-260px + ${
      windowWidth > 640 + 540
        ? '0px'
        : `${Math.min(240, 640 + 540 - windowWidth)}px`
    })`,
    top: '40%',
  },
  '@media (max-width: 850px)': {
    left: `calc(-260px + ${
      windowWidth > 480 + 540
        ? '0px'
        : `${Math.min(240, 480 + 540 - windowWidth)}px`
    })`,
    top: '50%',
  },
}))

const ArrowContainer = styled('div')({
  position: 'absolute',
  top: '100px',
  left: '-10px',
  transform: 'rotate(340deg)',
  '@media (max-width: 1132px)': {
    top: '75px',
    left: '-14px',
    transform: 'rotate(330deg)',
  },
  '@media (max-width: 850px)': {
    top: '60px',
    left: '-18px',
    transform: 'rotate(320deg)',
  },
})

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const SkipButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Figtree, sans-serif',
  color: theme.palette.secondary.main,
  textTransform: 'none',
  marginTop: '2rem',
}))

const SurveyOffersOnboardingStepTwo = ({ user, advanceLastStep }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <SpeakerSlide
        avatar={user.avatar}
        surveyLink={user.shortlink}
        socialLinks={user.socialLinks}
        email={user.email}
        name={user.first_name}
        size={'lg'}
        missingAvatar={true}
        callback={advanceLastStep}
        onboardingPlaceholder={true}
      />
      <AlertContainer windowWidth={windowWidth}>
        <AlertCard title={'You are missing a profile photo'} severity={'error'}>
          Your photo appears on your slide, and on your feedback form.
          <br />
          <br />
          Click on the circle to upload an image. You can always change it
          later.
          <ButtonContainer>
            <SkipButton variant="text" onClick={() => advanceLastStep()}>
              I promise I'll do this later.
            </SkipButton>
          </ButtonContainer>
        </AlertCard>
      </AlertContainer>
      <ArrowContainer>
        <Arrow />
      </ArrowContainer>
    </>
  )
}

export default SurveyOffersOnboardingStepTwo
