import { useContext } from 'react'
import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'

import { AuthenticationContext } from '../authentication/authenticationContext'

import { ReactComponent as UpgradeIcon } from '../../icons/upgradeIcon.svg'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-around',
  backgroundColor: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.sm,
  padding: theme.spacing(2.5, 1.5),
  marginBottom: theme.spacing(4.5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const StatItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flex: 1,
}))

const StatItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  padding: theme.spacing(0, 1),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}))

const StatItemIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'svgType',
})(({ theme, svgType }) => ({
  marginBottom: theme.spacing(1),
  svg: {
    width: '36px',
    height: '36px',
    path: {
      fill: svgType === 'fill' ? theme.palette.neutral.light : '',
      stroke: svgType === 'stroke' ? theme.palette.neutral.light : '',
    },
  },
  [theme.breakpoints.down('md')]: {
    svg: {
      width: '24px',
      height: '24px',
    },
  },
}))

const StatItemValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'useErrorFontColor',
})(({ theme, useErrorFontColor }) => ({
  color: !useErrorFontColor
    ? theme.palette.text.navText
    : theme.palette.error.main,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
}))

const StatItemLabel = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.disabled,
}))

const UpgradeButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  svg: {
    minWidth: '14px',
    marginRight: theme.spacing(1),
    path: {
      stroke: theme.palette.secondary.main,
    },
  },
}))

const Divider = styled('div')(({ theme }) => ({
  '&::before': {
    content: '""',
    display: 'block',
    width: '1px',
    height: '100%',
    backgroundColor: theme.palette.border.light,
  },
  [theme.breakpoints.down('sm')]: {
    '&::before': {
      display: 'none',
    },
  },
}))

const StatsSummaryHeader = ({ statItems }) => {
  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  return (
    <Container>
      {statItems?.map((item, index) => (
        <StatItemContainer key={index}>
          <StatItemWrapper>
            <StatItemIcon svgType={item.svgType}>{item.icon}</StatItemIcon>
            <StatItemValue
              variant="h2"
              useErrorFontColor={item.useErrorFontColor}>
              {item.value}
            </StatItemValue>
            <StatItemLabel variant="body1">{item.label}</StatItemLabel>
            {item.showUpgradeButton && (
              <UpgradeButton
                variant="text"
                color="secondary"
                onClick={() =>
                  toggleUpgradeModal(true, item.upgradeButtonText)
                }>
                <UpgradeIcon />
                {item.upgradeButtonText}
              </UpgradeButton>
            )}
          </StatItemWrapper>
          {index < statItems.length - 1 && <Divider />}
        </StatItemContainer>
      ))}
    </Container>
  )
}

export default StatsSummaryHeader
