import { styled } from '@mui/system'
import { Skeleton } from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import { useTheme } from '@mui/material/styles'

ChartJS.register(ArcElement, Tooltip)

const StatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  padding: '14px 0 14px 14px',
})

const StatWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
})

const StatPercentage = styled('div')({
  fontFamily: 'Sora, sans-serif',
  fontSize: '2.8125rem',
  fontWeight: 500,
})

const CircleChartWrapper = styled('div')({
  marginLeft: '30px',
  width: '120px',
  height: '80px',
})

const StatText = styled('div')({
  fontFamily: 'Sora, sans-serif',
  textAlign: 'left',
})

export const ReattendStatContent = ({
  reattendBreakdown,
  isLoading,
  speaker,
}) => {
  const theme = useTheme()
  const { d_yes, p_yes, p_no, d_no, total_reattend_answers } = reattendBreakdown
  const reattendRate =
    parseInt(((d_yes + p_yes) / total_reattend_answers) * 100) || 0

  const chartData = {
    labels: ['Definitely', 'Probably', 'Probably Not', 'Definitely Not'],
    datasets: [
      {
        data: [d_yes, p_yes, p_no, d_no],
        backgroundColor: [
          theme.palette.profileWidget.teal,
          theme.palette.profileWidget.chartReattendPYes,
          theme.palette.tertiary.light,
          theme.palette.tertiary.light,
        ],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      // Note: this is a workaround to prevent tooltips from being cut off
      padding: {
        right: 40,
        bottom: 40,
      },
    },
  }

  return isLoading ? (
    <Skeleton width={200} height={30} />
  ) : (
    <StatContainer>
      {reattendRate > 0 && (
        <>
          <StatWrapper>
            <StatPercentage>{reattendRate}%</StatPercentage>
            <CircleChartWrapper>
              <Doughnut data={chartData} options={options} />
            </CircleChartWrapper>
          </StatWrapper>
          <StatText>
            Want to hear {speaker ? speaker?.name : 'the speaker'} again
          </StatText>
        </>
      )}
    </StatContainer>
  )
}
