import { useEffect, useState } from 'react'
import Api from '../../services/api'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material'
import { styled } from '@mui/system'
import { Typography } from '@material-ui/core'
import Papa from 'papaparse'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import SmsFailedIcon from '@mui/icons-material/SmsFailed'

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  width: '100%',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}))
const PayoutDownloadContainer = styled('div')({
  display: 'flex',
  marginBottom: '20px',
  justifyContent: 'space-between',
})

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}))

const CommissionPayoutParent = () => {
  const [parsedCsvDetails, setParsedCsvDetails] = useState([])
  const [file, setFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [successfulUpdates, setSuccessfulUpdates] = useState([])
  const [failedUpdates, setFailedUpdates] = useState([])
  const [showStatusCell, setShowStatusCell] = useState(false)

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setParsedCsvDetails(results.data)
      },
    })
  }

  const handleUpload = async () => {
    try {
      setIsLoading(true)

      const res = await Api.uploadTransactions({
        referral_commission: {
          commissions: parsedCsvDetails,
        },
      })

      if (!res.errors) {
        alert('Upload Successful!')
        setSuccessfulUpdates(res.successfulUpdates)
        setFailedUpdates(res.failedUpdates)
        setShowStatusCell(true)
        setIsLoading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsLoading(false)
      alert(err)
    }
  }

  const renderUpdatedStatusCell = (commission) => {
    // Check to make sure the new commission record got updated correctly
    const successfullyUpdatedCommission = successfulUpdates.find(
      (c) => c.id == commission.id
    )

    if (successfullyUpdatedCommission) {
      return (
        <TableCell>
          <CheckCircleOutlineIcon color="primary" />
        </TableCell>
      )
    } else {
      return (
        <TableCell>
          <SmsFailedIcon color="red" />
        </TableCell>
      )
    }
  }

  return (
    <StyledTableContainer>
      <PayoutDownloadContainer>
        <StyledBox>
          <input type="file" accept=".csv" onChange={handleFileChange} />
          {file && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={!!isLoading}>
              {!isLoading ? 'Upload' : 'Updating Commissions ...'}
            </Button>
          )}
        </StyledBox>
      </PayoutDownloadContainer>
      <Typography variant="h6">Summary of transactions to upload:</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Commission ID</StyledTableCell>
            <StyledTableCell>Payee Name</StyledTableCell>
            <StyledTableCell>Payee Email</StyledTableCell>
            <StyledTableCell>Speaker Name</StyledTableCell>
            <StyledTableCell>Speaker Email</StyledTableCell>
            <StyledTableCell>Transaction Id</StyledTableCell>
            <StyledTableCell>Update Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parsedCsvDetails.map((commission, index) => (
            <TableRow key={index}>
              <TableCell>{commission.id}</TableCell>
              <TableCell>{commission.payee_name}</TableCell>
              <TableCell>{commission.payee_email}</TableCell>
              <TableCell>{commission.speaker_name}</TableCell>
              <TableCell>{commission.speaker_email}</TableCell>
              <TableCell>{commission.transaction_id}</TableCell>
              {showStatusCell && renderUpdatedStatusCell(commission)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <br />
    </StyledTableContainer>
  )
}

export default CommissionPayoutParent
