import React, { createContext, useReducer } from 'react'
import { groupReducer, initialGroupState } from './groupReducer.js'

export const GroupContext = createContext({
  groupState: initialGroupState,
})

const GroupContextProvider = (props) => {
  const [groupState, dispatch] = useReducer(groupReducer, initialGroupState)

  const setSelectedGroup = (group) => {
    dispatch({ type: 'SET_SELECTED_GROUP', payload: group })
  }

  const setGroups = (groups) => {
    dispatch({ type: 'SET_GROUPS', payload: groups })
  }

  const clearGroupState = () => {
    dispatch({ type: 'CLEAR_GROUP_STATE' })
  }

  const getPrimaryGroupId = () => {
    if (!groupState.groups || groupState.groups.length === 0) {
      return null
    }

    const sortedGroups = [...groupState.groups].sort((a, b) => {
      const rolePriorityA =
        a.role === 'owner' ? 1 : a.role === 'manager' ? 2 : 3
      const rolePriorityB =
        b.role === 'owner' ? 1 : b.role === 'manager' ? 2 : 3

      if (rolePriorityA !== rolePriorityB) {
        return rolePriorityA - rolePriorityB
      }

      return a.id - b.id
    })

    return sortedGroups[0].id
  }

  const getPrimaryGroup = () => {
    const primaryId = getPrimaryGroupId()

    if (!primaryId) {
      return null
    }

    return groupState.groups.find((group) => group.id === primaryId)
  }

  const getGroupById = (id) => {
    return groupState.groups.find((group) => group.id === id)
  }

  return (
    <GroupContext.Provider
      value={{
        groupState,
        setSelectedGroup,
        setGroups,
        clearGroupState,
        getPrimaryGroupId,
        getPrimaryGroup,
        getGroupById,
      }}>
      {props.children}
    </GroupContext.Provider>
  )
}

export default GroupContextProvider
