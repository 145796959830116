import { useEffect, useState } from 'react'
import Api from '../../services/api'
import { Button, Box, Typography, Alert } from '@mui/material'
import { styled } from '@mui/system'
import Papa from 'papaparse'

const PayoutDownloadContainer = styled('div')({
  display: 'flex',
  marginBottom: '20px',
  justifyContent: 'space-between',
})

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}))

const ReferralUploadParent = () => {
  const [parsedCsvDetails, setParsedCsvDetails] = useState([])
  const [file, setFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setParsedCsvDetails(results.data)
      },
    })
  }

  const handleUpload = async () => {
    try {
      setIsLoading(true)
      const res = await Api.uploadReferrals({
        referral_backfill_data: parsedCsvDetails,
      })

      if (!res.error) {
        setIsLoading(false)
        alert('Referral Data Has Been Uploaded!')
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsLoading(false)
      alert(err)
    }
  }

  return (
    <div>
      <Typography variant="h2">Upload Referrals To Backfill</Typography>
      <br />
      <Alert severity="warning">
        Note: This is for uploading data from a specific referral spreadsheet
        into the app to backfill data. If you don't know what this referrs to,
        do not use this.{' '}
      </Alert>
      <PayoutDownloadContainer>
        <StyledBox>
          <input type="file" accept=".csv" onChange={handleFileChange} />
          {file && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={!!isLoading}>
              {!isLoading ? 'Upload' : 'Uploading Data ...'}
            </Button>
          )}
        </StyledBox>
      </PayoutDownloadContainer>
    </div>
  )
}

export default ReferralUploadParent
