import { styled } from '@mui/system'
import { IconButton, Typography, LinearProgress } from '@mui/material'

import { ReactComponent as TrashIcon } from '../../../icons/trash2Icon_16x16.svg'
import { ReactComponent as FileIcon } from '../../../icons/fileIcon_16x16.svg'
import { ReactComponent as CancelIcon } from '../../../icons/closeIcon_16x16.svg'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2.5),
  padding: theme.spacing(2, 1.5),
}))

const FileIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '26px',
  height: '26px',
  borderRadius: '50%',
  backgroundColor: theme.palette.neutral.lightest,
  marginRight: theme.spacing(1.5),
  svg: {
    maxWidth: '12px',
    path: {
      stroke: theme.palette.neutral.main,
    },
  },
}))

const FileNameContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const FileName = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.darkest,
  fontWeight: '500',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const UploadDetails = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
}))

const FileCta = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  svg: {
    path: {
      stroke: theme.palette.error.semiDark,
    },
  },
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '8px',
  borderRadius: theme.shape.borderRadius.xxs,
  margin: theme.spacing(1, 0),
}))

const CodeBuilderUploadFile = ({
  fileName,
  fileSize,
  uploading,
  uploadProgress,
  handleUploadCancel,
  handleUploadDelete,
}) => {
  const renderCta = () => {
    if (uploading) {
      return (
        <FileCta onClick={handleUploadCancel}>
          <CancelIcon />
        </FileCta>
      )
    }

    return (
      <FileCta onClick={handleUploadDelete}>
        <TrashIcon />
      </FileCta>
    )
  }

  return (
    <Container>
      <FileIconContainer>
        <FileIcon />
      </FileIconContainer>
      <FileNameContainer>
        <FileName variant="body1">{fileName}</FileName>
        {uploading && (
          <>
            <StyledLinearProgress
              variant="determinate"
              color="secondary"
              value={uploadProgress}
            />
            <UploadDetails variant="caption">
              {(fileSize / 1000000).toFixed(1)}MB • {uploadProgress}% uploading
            </UploadDetails>
          </>
        )}
      </FileNameContainer>
      {renderCta()}
    </Container>
  )
}

export default CodeBuilderUploadFile
