import { styled } from '@mui/system'
import SpeakerSlide from './SpeakerSlide'

const SpeakerslideContainer = styled('div')({
  width: '960px',
  height: '540px',
  overflow: 'hidden',
  position: 'relative',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  '@media (max-width: 1132px)': {
    width: '640px',
    height: '360px',
  },
  '@media (max-width: 850px)': {
    width: '480px',
    height: '270px',
  },
})

const ScaleWrapper = styled('div')({
  position: 'absolute',
  transform: 'scale(1)',
  transformOrigin: 'left top',
  top: '0',
  left: '0',
  width: '960px',
  height: '540px',
  '@media (max-width: 1132px)': {
    width: '640px',
    height: '360px',
    transform: 'scale(0.666667)',
  },
  '@media (max-width: 850px)': {
    width: '480px',
    height: '270px',
    transform: 'scale(0.5)',
  },
})

const SpeakerSlideResponsive = ({
  avatar,
  surveyLink,
  socialLinks,
  email,
  name,
  code,
  componentRef,
  missingAvatar,
  callback,
  onboardingPlaceholder,
}) => {
  return (
    <SpeakerslideContainer>
      <ScaleWrapper>
        <SpeakerSlide
          avatar={avatar}
          surveyLink={surveyLink}
          socialLinks={socialLinks}
          email={email}
          name={name}
          size="lg"
          componentRef={componentRef}
          code={code}
          missingAvatar={missingAvatar}
          callback={callback}
          onboardingPlaceholder={onboardingPlaceholder}
        />
      </ScaleWrapper>
    </SpeakerslideContainer>
  )
}

export default SpeakerSlideResponsive
