import { useHistory } from 'react-router-dom'
import { TableRow, TableCell } from '@mui/material'
import { styled } from '@mui/system'

import { formatDate } from '../common/helpers'

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'index',
})(({ theme, index }) => ({
  cursor: 'pointer',
  // TODO: clean up colors
  background: index % 2 === 0 ? 'white' : 'rgba(204, 204, 204, 0.1)',
  '&:hover': {
    // background: theme.palette.tertiary.light,
    background: '#ebebeb',
  },
}))

const DateCell = styled(TableCell)({
  minWidth: '90px',
  padding: '18px 16px 18px 30px',
  fontFamily: 'Sora, sans-serif',
})

const TitleCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: 'Sora, sans-serif',
}))

const ResponsesCell = styled(TableCell)({
  padding: '14px 30px 14px 16px',
  fontFamily: 'Sora, sans-serif',
})

const StyledTableCell = styled(TableCell)({
  fontFamily: 'Sora, sans-serif',
})

const ProfileEventListRow = ({ event, index, shortlink }) => {
  const history = useHistory()

  const goToEventReport = () => {
    history.push(`/s/${shortlink}/event-report/${event.shareKey}`)
  }

  const handleClick = ({ value, allowEditDetails = false }) => {
    if (allowEditDetails && !value) {
      // open event details dialog
      return null
    } else {
      goToEventReport()
    }
  }

  const renderStandardRow = () => (
    <StyledTableRow key={event.id} index={index}>
      <DateCell
        align="left"
        onClick={() =>
          handleClick({
            value: event.date,
            allowEditDetails: false,
          })
        }>
        {formatDate(event.date, event.utcOffset)}
      </DateCell>
      <TitleCell
        align="left"
        onClick={() =>
          handleClick({
            value: event.talkTitle,
            allowEditDetails: false,
          })
        }>
        {event.talkTitle}
      </TitleCell>
      <StyledTableCell
        align="left"
        onClick={() =>
          handleClick({
            value: event.eventOrganization,
            allowEditDetails: false,
          })
        }>
        {event.eventOrganization}
      </StyledTableCell>
      <StyledTableCell align="left" onClick={goToEventReport}>
        {event.location}
      </StyledTableCell>
      <StyledTableCell align="center" onClick={goToEventReport}>
        {event.rating}%
      </StyledTableCell>
      <StyledTableCell align="center" onClick={goToEventReport}>
        {event.reattend}%
      </StyledTableCell>
      <ResponsesCell align="center" onClick={goToEventReport}>
        {event.responses}
      </ResponsesCell>
    </StyledTableRow>
  )

  return renderStandardRow()
}
export default ProfileEventListRow
