import { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'

// For some reason this won't import from the above grouped import
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import FormSection from '../common/FormSection'
import Api from '../../services/api'
import { styled } from '@mui/material/styles'
import moment from 'moment'

const initialEventGroupState = {
  id: null,
  name: null,
  startDate: null,
  endDate: null,
  inPerson: true,
  virtual: null,
  thankYouMessage: null,
  thankYouButtonText: null,
}

const ActionButton = styled(Button)({
  textTransform: 'none',
  color: 'white',
  marginBottom: '16px',
})

const OptionalSettingsContainer = styled('div')(({ theme }) => ({
  background: theme.palette.tertiary.light,
  h6: {
    color: 'orange',
    textTransform: 'uppercase',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
}))

export const FormDialogContent = styled(DialogContent)({
  padding: '0px',
})

export const FormDialogActions = styled(DialogActions)(({ theme }) => ({
  background: theme.palette.tertiary.light,
}))

const FormContainer = styled('div')({
  padding: '10px 25px',
})

export const EventGroupFormFields = ({ eventGroup, setEventGroup }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <FormContainer>
      <FormSection stepNumber="1" description="What's the name of the event?">
        <TextField
          required
          variant="standard"
          placeholder="Talk Title"
          value={eventGroup.name}
          fullWidth
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) =>
            setEventGroup({ ...eventGroup, name: e.target.value })
          }
        />
      </FormSection>
      <FormSection stepNumber="2" description="What day does the event start?">
        <div className="time-fields">
          <DesktopDatePicker
            inputFormat="MM/dd/yyyy"
            variant="standard"
            value={moment(eventGroup.startDate)}
            onChange={(e) => {
              setEventGroup({
                ...eventGroup,
                startDate: moment(e).format('LL'),
                utcOffset: moment(e).utcOffset(),
              })
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </FormSection>
      <FormSection stepNumber="3" description="What day does the event end?">
        <div className="time-fields">
          <DesktopDatePicker
            inputFormat="MM/dd/yyyy"
            variant="standard"
            value={moment(eventGroup.endDate)}
            onChange={(e) => {
              setEventGroup({
                ...eventGroup,
                endDate: moment(e).format('LL'),
                utcOffset: moment(e).utcOffset(),
              })
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </FormSection>
      <FormSection
        stepNumber="4"
        description="Is the event in person or virtual?">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            className="radio-picker"
            value={eventGroup.virtual ? 'Virtual' : 'In Person'}
            onChange={(e) => {
              if (e.target.value === 'Virtual') {
                setEventGroup({
                  ...eventGroup,
                  virtual: true,
                  inPerson: false,
                })
              } else {
                setEventGroup({
                  ...eventGroup,
                  virtual: false,
                  inPerson: true,
                })
              }
            }}>
            <FormControlLabel
              value="In Person"
              control={<Radio />}
              label="In Person"
              className={eventGroup.inPerson && 'radio-active'}
            />
            <FormControlLabel
              value="Virtual"
              control={<Radio />}
              label="Virtual"
              className={eventGroup.virtual && 'radio-active'}
            />
          </RadioGroup>
        </FormControl>
      </FormSection>
    </FormContainer>
    <OptionalSettingsContainer>
      <FormContainer>
        <Typography variant="h6">
          <b>Optional Settings</b>
        </Typography>
        <FormSection
          stepNumber="5"
          heading="Custom Thank You Message"
          subheading="This will only appear at the end of your GLOBAL survey flow">
          <TextField
            required
            variant="standard"
            placeholder="Add your custom thank you message here ..."
            value={eventGroup.thankYouMessage}
            fullWidth
            onChange={(e) =>
              setEventGroup({ ...eventGroup, thankYouMessage: e.target.value })
            }
          />
        </FormSection>
        <FormSection
          stepNumber="6"
          heading="Custom CTA Content"
          subheading="Do you want specific text on the CTA button at the end of your GLOBAL survey?">
          <TextField
            required
            variant="standard"
            placeholder="Add your custom button text here ..."
            value={eventGroup.thankYouButtonText}
            fullWidth
            onChange={(e) =>
              setEventGroup({
                ...eventGroup,
                thankYouButtonText: e.target.value,
              })
            }
          />
        </FormSection>
      </FormContainer>
    </OptionalSettingsContainer>
  </LocalizationProvider>
)

const CreateEventGroupButton = ({ setEventGroups, eventGroups }) => {
  const [eventGroup, setEventGroup] = useState(initialEventGroupState)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setEventGroup(initialEventGroupState)
    setOpen(false)
  }

  const handleSubmit = async () => {
    const params = {
      event_group: {
        name: eventGroup.name,
        start_date: eventGroup.startDate,
        end_date: eventGroup.endDate,
        in_person: eventGroup.inPerson,
        virtual: eventGroup.virtual,
        thank_you_message: eventGroup.thankYouMessage,
        thank_you_button_text: eventGroup.thankYouButtonText,
      },
    }

    try {
      setIsLoading(true)
      const res = await Api.createEventGroup(params)

      if (!res.errors) {
        setEventGroups([res, ...eventGroups])
        setIsLoading(false)
        handleClose()
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsLoading(false)
      handleClose()
      alert(err)
    }
  }

  return (
    <div>
      <ActionButton
        onClick={() => setOpen(true)}
        variant="contained"
        color="primary">
        Add New Event
      </ActionButton>
      <Dialog open={open} fullWidth maxWidth="md" onClose={() => handleClose()}>
        <DialogTitle>Create An Event!</DialogTitle>
        <FormDialogContent>
          <EventGroupFormFields
            setEventGroup={setEventGroup}
            eventGroup={eventGroup}
          />
        </FormDialogContent>
        <FormDialogActions>
          {isLoading ? (
            <CircularProgress sx={{ color: 'orange' }} />
          ) : (
            <div>
              <Button style={{ color: 'orange' }} onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                className="orange-button"
                onClick={handleSubmit}>
                Create Event
              </Button>
            </div>
          )}
        </FormDialogActions>
      </Dialog>
    </div>
  )
}

export default CreateEventGroupButton
