import React, { useContext } from 'react'
import Papa from 'papaparse'
import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { Button } from '@mui/material'

const ScheduleUploader = ({ setEvents, eventGroupId }) => {
  const { showFullPageLoader, hideFullPageLoader } = useContext(
    AuthenticationContext
  )

  const handleUpload = async (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        createSchedule(results.data)
      },
    })
  }

  const createSchedule = async (data) => {
    showFullPageLoader()

    const params = {
      event_group_schedule: {
        event_group_id: eventGroupId,
        schedule: data,
      },
    }

    try {
      const res = await Api.uploadEventGroupSchedule(params)

      if (res.errors) {
        throw res.errors
      } else {
        setEvents(res)
        hideFullPageLoader()
        return alert('Upload Success!!!')
      }
    } catch (err) {
      hideFullPageLoader()
      return alert(err.errors)
    }
  }

  return (
    <Button variant="contained" component="label" sx={{ color: 'white' }}>
      Upload Event Schedule
      <input
        type="file"
        name="file"
        accept=".csv"
        hidden
        onChange={handleUpload}
      />
    </Button>
  )
}

export default ScheduleUploader
