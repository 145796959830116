import { useEffect, useState, useContext } from 'react'
import { styled } from '@mui/system'
import { motion } from 'framer-motion/dist/framer-motion'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { convertStringToArray } from '../../utils/array'
import { device } from '../../responsive'

const FieldOption = styled(motion.div)(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: active ? 'black 0px 0px 0px 2px inset' : 'black 0px 0px 0px 1px',
  backgroundColor: active ? theme.palette.primary.main : 'none',
  marginTop: '10px',
  padding: '10px 16px',
  borderRadius: 0,
  fontSize: '1.25rem',
  cursor: 'pointer',
  transition: '200ms all',
  '&:hover': {
    backgroundColor: active
      ? theme.palette.primary.main
      : theme.palette.primary.light,
  },
  [`@media ${device.mobile}`]: {
    fontSize: '1rem',
  },
}))

const MultipleChoiceQuestion = ({ profileAttribute, question, user }) => {
  const [selected, setSelected] = useState([])
  const [values, setValues] = useState([])
  const { setProfileAttribute } = useContext(AuthenticationContext)

  useEffect(() => {
    const type = question.type
    const options = question.options

    if (user?.[profileAttribute]?.[type]) {
      const onboardingAnswers = user[profileAttribute][type]
      const onboardingAnswersArray = convertStringToArray(onboardingAnswers)
      const onboardingAnswersValues = onboardingAnswersArray.map((value) => {
        return { text: value }
      })

      const userSelection = options
        .map((option) => {
          return onboardingAnswersArray.map((answer) => {
            if (answer === option.text) {
              return option.id
            }
            return null
          })
        })
        .flat()
        .filter(Boolean)

      setSelected(userSelection)
      setValues(onboardingAnswersValues)
    } else {
      setSelected([])
      setValues([])
    }
  }, [user, question, profileAttribute])

  const options = question.options

  const handleSelection = (profileAttribute, type, id, optionValue) => {
    if (question.multipleSelection) {
      let newSelected
      let newValues
      if (selected.includes(id)) {
        newSelected = selected.filter((s) => s !== id)
        newValues = values.filter((v) => v.text !== optionValue.text)
      } else {
        newSelected = [...selected, id]
        newValues = [...values, optionValue]
      }
      setSelected(newSelected)
      setValues(newValues)

      const parsedValues = newValues?.map((value) => value.text)

      const onboarding_info = { ...user.onboarding_info }
      onboarding_info[type] = parsedValues

      setProfileAttribute(profileAttribute, onboarding_info)
    } else {
      const onboarding_info = { ...user.onboarding_info }
      onboarding_info[type] = optionValue.text

      setSelected([id])
      setValues([optionValue])
      setProfileAttribute(profileAttribute, onboarding_info)
    }
  }

  return options.map((option, i) => {
    const optionValue = { text: option.text }

    return (
      <FieldOption
        key={i}
        whileHover={{ scale: 1.01, transition: { duration: 0.05 } }}
        whileTap={{ scale: 0.99 }}
        onClick={() =>
          handleSelection(
            profileAttribute,
            question.type,
            option.id,
            optionValue
          )
        }
        active={selected.includes(option.id)}>
        <div>{option.text}</div>
      </FieldOption>
    )
  })
}

export default MultipleChoiceQuestion
