import { useContext } from 'react'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

import { GroupContext } from '../groups/groupContext'

const ButtonWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'editAttribute',
})(({ editAttribute }) => ({
  textAlign: editAttribute === 'compensation' ? 'center' : 'left',
}))

const EditEventButton = styled('div')({
  color: 'skyblue',
  fontWeight: 'bold',
  cursor: 'pointer',
})

const NewEventButton = styled(Button)({
  color: 'white',
})

const EventButton = ({
  event,
  handleOpenEventForm,
  user,
  buttonText,
  editAttribute,
}) => {
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)

  const newEventButtonText = () => {
    if (selectedGroup) {
      return window.innerWidth < 600
        ? 'Add Chapter Event'
        : 'Add New Chapter Event'
    }

    if (user?.isEventPlanner) {
      return window.innerWidth < 600 ? 'Add Session' : 'Add New Session'
    } else {
      return window.innerWidth < 600
        ? 'Add Speaking Engagement'
        : 'Add New Speaking Engagement'
    }
  }

  return (
    <ButtonWrapper editAttribute={editAttribute}>
      {/* This tells us what type of link UX to render, because this component is used
           to both create new events and to edit existing ones */}
      {event ? (
        <EditEventButton
          onClick={() => {
            handleOpenEventForm(event)
          }}>
          {buttonText}
        </EditEventButton>
      ) : (
        <NewEventButton
          variant="contained"
          color="primary"
          onClick={() => {
            handleOpenEventForm(event)
          }}>
          {newEventButtonText()}
        </NewEventButton>
      )}
    </ButtonWrapper>
  )
}

export default EventButton
