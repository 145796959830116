import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { styled } from '@mui/system'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
} from '@mui/material'

import { AuthenticationContext } from '../authentication/authenticationContext'
import Api from '../../services/api'

import Banner from '../eventReportCards/Banner'
import { ProfileStatCard } from './ProfileStatCard'
import { ResponsesStatContent } from './ResponsesStatContent'
import { ValuableStatContent } from './ValuableStatContent'
import { ReattendStatContent } from './ReattendStatContent'
import { LocationStatContent } from './LocationStatContent'
import { ProfileAttributes } from './ProfileAttributes'
import ProfileEventList from './ProfileEventList'
import ProfileBiography from './ProfileBiography'
import PageContainer from '../common/PageContainer'

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const ProfileStatCardsWrapper = styled('div')({
  display: 'grid',
  // TODO: reduce min width on mobile to 275px? to better fit small screens
  gridTemplateColumns: 'repeat(auto-fit, minmax(295px, 1fr))',
  gap: '40px',
  marginTop: '40px',
  marginBottom: '70px',
  fontFamily: 'Sora, sans-serif',
})

const PublicProfileAlert = styled(Alert)(({ theme }) => ({
  marginBottom: '20px',
  textAlign: 'left',
  fontFamily: 'Sora, sans-serif',
  div: {
    fontFamily: 'Sora, sans-serif',
  },
  a: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}))

const initialProfileState = {
  events: [],
  totalResponses: {
    total: null,
    positives: null,
    negatives: null,
    id: null,
  },
  reattendBreakdown: {
    total_reattend_answers: null,
    d_yes: null,
    p_yes: null,
    p_no: null,
    d_no: null,
    id: null,
  },
  engagementBreakdown: [],
  speaker: {
    avatar: null,
    id: null,
    isEventPlanner: null,
    name: null,
  },
}

const PublicProfileParent = () => {
  const params = useParams()

  const { authData, setNotification } = useContext(AuthenticationContext)
  const [isLoading, setIsLoading] = useState(true)
  const [profileData, setProfileData] = useState(initialProfileState)
  const [open, setOpen] = useState(true)
  const [formValues, setFormValues] = useState({ password: '' })
  const [authorized, setAuthorized] = useState(false)
  const [profileSettings, setProfileSettings] = useState(null)
  const { user } = authData

  useEffect(() => {
    const loadProfileSettings = async () => {
      if (!params.shortlink) {
        return setNotification('Profile not found')
      }

      const reqParams = {
        public_profile: {
          shortlink: params.shortlink,
        },
      }

      try {
        const res = await Api.getPublicProfileSettings(reqParams)

        if (!res.errors) {
          setProfileSettings(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
        setNotification(err)
      }
    }
    loadProfileSettings()
  }, [])

  useEffect(() => {
    const loadPublicProfileData = async () => {
      if (!params.shortlink) {
        return setNotification('Profile not found')
      }

      const reqParams = {
        public_profile: {
          shortlink: params.shortlink,
        },
      }

      try {
        setIsLoading(true)
        const res = await Api.getPublicProfile(reqParams)

        if (!res.errors) {
          setProfileData(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
        setNotification(err)
      }
    }
    if (profileSettings && !profileSettings?.password_protected) {
      loadPublicProfileData()
    }
  }, [profileSettings])

  const handleClose = () => {
    setOpen(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handlePasswordSubmit = async (e) => {
    e.preventDefault()
    const reqParams = {
      public_profile: {
        shortlink: params.shortlink,
        password: formValues.password,
      },
    }
    try {
      setIsLoading(true)
      const res = await Api.getPublicProtectedProfile(reqParams)
      if (!res.errors) {
        setProfileData(res)
        setIsLoading(false)
        setOpen(false)
        setAuthorized(true)
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsLoading(false)
      setNotification(err)
    }
  }

  const locationBreakdown = () => {
    if (!profileData?.events) return

    const locationData = { totalEvents: 0, inPerson: 0, virtual: 0 }

    profileData.events.forEach((event) => {
      locationData.totalEvents += 1

      if (event.in_person) {
        return (locationData.inPerson += 1)
      }
      if (event.virtual) {
        return (locationData.virtual += 1)
      }
      // use in_person default value if both values are nil
      return (locationData.inPerson += 1)
    })

    return locationData
  }

  const unauthenticated = () =>
    !user?.id || user?.id !== profileData?.speaker?.id

  const renderMetaTags = () => (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  )

  const renderProfilePasswordForm = () => (
    <PageContainer>
      {renderMetaTags()}
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handlePasswordSubmit}>
          <DialogTitle>Enter Profile Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This Talkadot profile is password protected, please enter the
              password here.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" sx={{ color: 'white' }}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </PageContainer>
  )

  const renderProfileSettingsHeader = () => {
    return (
      <>
        {user?.id === profileData?.speaker?.id &&
          !user?.profileSettings?.is_public && (
            <PublicProfileAlert severity="info">
              <AlertTitle>Public Profile Visibility</AlertTitle>
              Your profile is currently <strong>only visible to you</strong>. To
              make your <strong>profile publicly accessible</strong> toggle it
              ON in your <strong>Profile Settings</strong> on your{' '}
              <Link to={`/account`}>Settings</Link> page.
            </PublicProfileAlert>
          )}

        {user?.id === profileData?.speaker?.id &&
          user?.profileSettings?.is_public && (
            <PublicProfileAlert severity="warning">
              <AlertTitle>Public Profile Visibility</AlertTitle>
              Your profile is currently <strong>public</strong>. To make your{' '}
              <strong>profile private</strong> toggle it OFF in your{' '}
              <strong>Profile Settings</strong> on your{' '}
              <Link to={`/account`}>Settings</Link> page.
            </PublicProfileAlert>
          )}
      </>
    )
  }

  const renderProfileData = () => (
    <PageContainer>
      {!profileSettings.search_indexed && renderMetaTags()}
      {renderProfileSettingsHeader()}

      <Banner
        speaker={profileData?.speaker}
        isProfile={true}
        font="Sora, sans-serif"
      />
      <ProfileStatCardsWrapper>
        <ProfileStatCard type="responses">
          <ResponsesStatContent
            totalResponses={profileData?.totalResponses?.total}
            isLoading={isLoading}
          />
        </ProfileStatCard>
        <ProfileStatCard type="valuable">
          <ValuableStatContent
            ratingsBreakdown={profileData?.totalResponses}
            isLoading={isLoading}
          />
        </ProfileStatCard>
        <ProfileStatCard type="reattend">
          <ReattendStatContent
            reattendBreakdown={profileData?.reattendBreakdown}
            isLoading={isLoading}
            speaker={profileData?.speaker}
          />
        </ProfileStatCard>
        <ProfileStatCard type="location">
          <LocationStatContent
            locationBreakdown={locationBreakdown()}
            isLoading={isLoading}
          />
        </ProfileStatCard>
      </ProfileStatCardsWrapper>
      <ProfileAttributes
        speaker={profileData?.speaker}
        engagementBreakdown={profileData?.engagementBreakdown}
      />
      <ProfileBiography authUser={user} speaker={profileData?.speaker} />
      <ProfileEventList
        authUser={user}
        events={profileData?.events}
        eventsLoading={isLoading}
        shortlink={params.shortlink}
      />
    </PageContainer>
  )

  const renderPrivateProfileAlert = () => (
    <PageContainer>
      {renderMetaTags()}
      <PublicProfileAlert severity="info">
        <AlertTitle>Public Profile Visibility</AlertTitle>
        This profile is <strong>private</strong>.
      </PublicProfileAlert>
    </PageContainer>
  )

  const renderProfile = () => {
    if (isLoading) return <LoadingSpinner />

    if (!profileSettings) return <>{renderMetaTags()}</>

    if (profileSettings.password_protected && !authorized) {
      return renderProfilePasswordForm()
    } else if (!profileSettings.is_public && unauthenticated()) {
      return renderPrivateProfileAlert()
    } else {
      return renderProfileData()
    }
  }

  return renderProfile()
}

export default PublicProfileParent
