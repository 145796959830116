import { useState, useContext } from 'react'
import { styled, useTheme } from '@mui/system'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

import { Button, TextField, Typography } from '@mui/material'

import CountrySelect from '../common/CountrySelect'

import { ReactComponent as UpgradeIcon } from '../../icons/upgradeIcon.svg'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { trackPurchase, trackConversion } from '../../services/metrics'
import { addAlpha } from '../../utils/color'

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '240px',
}))

const FormInputsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  width: '100%',
}))

const FormFieldWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  width: '100%',
}))

const MultiInputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const InputLabel = styled(Typography)({
  fontWeight: '600',
})

const InputWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.shape.borderRadius.xxs,
  padding: theme.spacing(1.5, 2),
  width: '100%',
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    padding: theme.spacing(1.5, 2),
    '&::placeholder': {
      color: theme.palette.neutral.main,
      fontWeight: '400',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.border.main,
      borderRadius: theme.shape.borderRadius.xxs,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.neutral.semiLight,
      borderWidth: 1,
    },
    '&:hover': {
      fieldset: {
        borderColor: theme.palette.neutral.semiLight,
      },
    },
  },
}))

const SubmitButton = styled(Button)(({ theme }) => ({
  color: theme.palette.base.white,
  fontSize: '1rem',
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(0.5),
  svg: {
    marginRight: theme.spacing(1),
  },
}))

const TermsLink = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

const CheckoutForm = ({ selectedPlan, isTrialing }) => {
  const stripe = useStripe()
  const elements = useElements()
  const theme = useTheme()

  const [cardName, setCardName] = useState('')
  const [cardNameError, setCardNameError] = useState(null)
  const [country, setCountry] = useState('')
  const [postalCode, setPostalCode] = useState('')

  const [checkoutLoading, setCheckoutLoading] = useState(false)

  const {
    showFullPageLoader,
    hideFullPageLoader,
    setNotification,
    toggleUpgradeModal,
  } = useContext(AuthenticationContext)

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Dont allow double clicks
    if (checkoutLoading) {
      return false
    }

    if (!stripe || !elements) {
      setCheckoutLoading(false)
      return
    }

    if (!cardName) {
      setCheckoutLoading(false)
      return setCardNameError('Please fill out name on card')
    }

    const cardElement = elements.getElement(CardNumberElement)

    if (!cardElement) {
      return
    }

    setCheckoutLoading(true)
    showFullPageLoader('Hang Tight While We Upgrade Your Account!')

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardName,
        address: {
          country,
          postal_code: postalCode,
        },
      },
    })

    if (payload.error) {
      setCheckoutLoading(false)
      hideFullPageLoader()
      return setNotification(payload.error.message)
    }

    try {
      const params = {
        membership_plan_token: selectedPlan?.token,
        stripe_card_info: {
          ...payload.paymentMethod,
        },
      }

      const res = await Api.upgradePlan(params)

      if (!res.errors) {
        trackPurchase({
          plan: selectedPlan,
          value: selectedPlan?.price,
          // Note: the transaction_id is the charge_id from the payment_intent in latest_invoice returned from Stripe Subscription.create
          transactionId: res?.transaction_id,
          // Note: Don't send the rebate coupon if the user is not on a trial
          coupon: isTrialing ? selectedPlan?.rebate_coupon : '',
        })

        trackConversion({
          plan: selectedPlan?.name,
          value: selectedPlan?.price,
        })

        toggleUpgradeModal(false)
        setCheckoutLoading(false)
        hideFullPageLoader()

        // Note:
        // If payment is completed via the WelcomeModal we do not update the OnboardingInfo record to "viewed_upgrade_info = true",
        // instead we rely on the current plan type to handle the WelcomeModal render logic

        // Reload window to make sure data gets refreshed wherever they are
        return window.location.reload()
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      setCheckoutLoading(false)
      return setNotification(err)
    }
  }

  const stripeElementOptions = {
    style: {
      base: {
        fontFamily: 'Figtree, sans-serif',
        '::placeholder': {
          color: addAlpha(theme.palette.neutral.main, 0.5),
          fontWeight: '400',
        },
      },
    },
  }

  return (
    <FormContainer onSubmit={(e) => handleSubmit(e)} autoComplete="on">
      <FormInputsContainer>
        <FormFieldWrapper>
          <InputLabel>Cardholder's Name</InputLabel>
          <StyledTextField
            fullWidth
            variant="outlined"
            type="text"
            helperText={cardNameError && cardNameError}
            value={cardName}
            error={cardNameError}
            placeholder="Name On Card"
            autoComplete="cc-name"
            onChange={(e) => {
              setCardNameError(null)
              setCardName(e.target.value)
            }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <InputLabel>Credit Card Number</InputLabel>
          <InputWrapper>
            <CardNumberElement options={stripeElementOptions} />
          </InputWrapper>
        </FormFieldWrapper>
        <MultiInputContainer>
          <FormFieldWrapper>
            <InputLabel>Expiration</InputLabel>
            <InputWrapper>
              <CardExpiryElement options={stripeElementOptions} />
            </InputWrapper>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <InputLabel>CVC</InputLabel>
            <InputWrapper>
              <CardCvcElement options={stripeElementOptions} />
            </InputWrapper>
          </FormFieldWrapper>
        </MultiInputContainer>
        <MultiInputContainer>
          <FormFieldWrapper>
            <InputLabel>Country</InputLabel>
            <CountrySelect value={country} onChange={setCountry} />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <InputLabel>Postal Code</InputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              type="text"
              placeholder="00000"
              autoComplete="postal-code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </FormFieldWrapper>
        </MultiInputContainer>
      </FormInputsContainer>
      <SubmitButton
        variant="contained"
        color="primary"
        type="submit"
        disabled={checkoutLoading || !stripe}>
        <UpgradeIcon />
        Upgrade to {selectedPlan?.planType}!
      </SubmitButton>
      <Typography variant="body1">
        By continuing, you agree to the Talkadot{' '}
        <TermsLink
          href="https://www.talkadot.com/terms-of-service"
          target="_blank"
          rel="noreferrer">
          terms and conditions
        </TermsLink>
      </Typography>
    </FormContainer>
  )
}

export default CheckoutForm
