// Copy and paste of https://github.com/ncoughlin/scroll-to-hash-element
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToHashElement = () => {
  const location = useLocation()

  useEffect(() => {
    const { hash } = location

    const removeHashCharacter = (str) => {
      const result = str.slice(1)
      return result
    }

    if (hash) {
      const element = document.getElementById(removeHashCharacter(hash))

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
        })
      }
    }
  }, [location])

  return null
}

export default ScrollToHashElement
