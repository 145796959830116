import { useContext } from 'react'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { AdminContext } from './adminContext'

const BannerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.error.main,
  color: theme.palette.base.white,
  flex: 1,
  height: '100%',
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.base.white,
}))

const StopImpersonating = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
}))

const ImpersonationBanner = () => {
  const { adminState, stopImpersonatingUser } = useContext(AdminContext)
  const { impersonation } = adminState

  return (
    <BannerContainer>
      <StyledTypography variant="h6">
        Currently viewing as{' '}
        <b>
          {impersonation?.user?.first_name} {impersonation?.user?.last_name} -{' '}
        </b>
        <StopImpersonating onClick={stopImpersonatingUser}>
          <b>Stop Viewing</b>
        </StopImpersonating>
      </StyledTypography>
    </BannerContainer>
  )
}

export default ImpersonationBanner
