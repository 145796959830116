export const ValuableSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.324"
      height="22.465"
      viewBox="0 0 18.324 22.465">
      <g id="accessibility-outline" transform="translate(-63.052 -15.1)">
        <circle
          id="Ellipse_182"
          data-name="Ellipse 182"
          cx="1.721"
          cy="1.721"
          r="1.721"
          transform="translate(70.495 16)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="1.8"
        />
        <path
          id="Path_263"
          data-name="Path 263"
          d="M69.988,134.231a4.266,4.266,0,0,0-.022-2.048,1.56,1.56,0,0,0-1-.867l-3.981-1.288c-.516-.172-1-.46-1.033-1.03a.983.983,0,0,1,1.248-1.033,25.094,25.094,0,0,0,7.013,1.34,24.716,24.716,0,0,0,6.97-1.334A1,1,0,0,1,80.476,129c0,.611-.473.827-1.033,1.03L75.658,131.4a2.181,2.181,0,0,0-1.119.782,3.566,3.566,0,0,0-.09,2.048L74.7,135.5l1.61,7.052a1.009,1.009,0,0,1-.835,1.194,1.072,1.072,0,0,1-1.191-.833l-1.616-4.988q-.117-.359-.207-.726l-.248-1.01-.228.932q-.108.445-.25.881l-1.588,4.907a1.07,1.07,0,0,1-1.188.837,1.027,1.027,0,0,1-.837-1.194l1.609-7.049Z"
          transform="translate(0 -107.111)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="1.8"
        />
      </g>
    </svg>
  )
}
