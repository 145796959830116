import { Link, useLocation } from 'react-router-dom'
import { styled } from '@mui/system'
import { Box, Button } from '@mui/material'

import {
  makeReferralLink,
  makeRegistrationLink,
  extractShortlinkFromUrl,
} from '../common/helpers'

const UnAuthContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '1rem 0',
  img: {
    width: '120px',
  },
})

const LogoLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
})

const NavButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  a: {
    textDecoration: 'none',
  },
  'a:first-child': {
    marginRight: '1.125rem',
  },
}))

const NavButton = styled(Button)(({ type, theme }) => ({
  textTransform: 'none',
  color: type === 'register' ? theme.palette.base.white : '',
  fontSize: '1rem',
  height: '46px',
}))

const UpperNavUnAuth = () => {
  const location = useLocation()
  const signupLinkUrl = () => {
    // if the current page is the public profile page or the event report page, we want the signup link to point to the referral link for the owner of the profile
    if (location.pathname.includes('/s/')) {
      const shortlink = extractShortlinkFromUrl(location.pathname)
      const referralLink = makeReferralLink(shortlink)

      return referralLink
    }

    return makeRegistrationLink()
  }
  return (
    <UnAuthContainer>
      <LogoLink href="https://www.talkadot.com" alt="Talkadot!">
        <img
          src="https://d3qvkz8495pjoq.cloudfront.net/talkadotLogoLg.svg"
          alt="Talkadot!"
        />
      </LogoLink>
      <NavButtonWrapper>
        <Link to={{ pathname: '/login' }}>
          <NavButton variant="outlined" color="tertiary">
            Sign In
          </NavButton>
        </Link>
        <a href={signupLinkUrl()}>
          <NavButton variant="contained" disableElevation type="register">
            Free Sign Up
          </NavButton>
        </a>
      </NavButtonWrapper>
    </UnAuthContainer>
  )
}

export default UpperNavUnAuth
