import { useState } from 'react'
import { styled } from '@mui/system'
import { Tooltip } from '@material-ui/core'
import { handleCopy } from '../common/helpers'

import { ReactComponent as CopyIcon } from '../../icons/copyIcon_16x16.svg'

const StyledIcon = styled('div')({
  display: 'flex',
  cursor: 'pointer',
})

const CopyContent = ({ contentToCopy }) => {
  const [copyNotification, setCopyNotification] = useState('Click To Copy')

  const handleCopyClick = () => {
    handleCopy(contentToCopy)
    setCopyNotification('Copied!')

    setTimeout(() => {
      setCopyNotification('Click To Copy')
    }, 2000)
  }

  return (
    <Tooltip title={copyNotification} placement="top">
      <StyledIcon>
        <CopyIcon onClick={handleCopyClick} />
      </StyledIcon>
    </Tooltip>
  )
}

export default CopyContent
