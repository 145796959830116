import React, { useContext } from 'react'
import { TextField } from '@material-ui/core'
import { AuthenticationContext } from '../authentication/authenticationContext'

const EditPasswordForm = () => {
  const { setProfileAttribute, authData } = useContext(AuthenticationContext)
  const { user } = authData

  return(
    <div className="full-name-form-container">
      <form noValidate autoComplete="off" id="password-reset">
        <TextField
          required
          id="filled-required"
          variant="standard"
          type="password"
          margin="normal"
          className="pword-reset"
          placeholder='current password'
          value={user.currentPassword}
          onChange={(e) => {
            setProfileAttribute('currentPassword', e.target.value)}}
        />
        <TextField
          required
          id="filled-required"
          variant="standard"
          type="password"
          margin="normal"
          className="pword-reset"
          placeholder='new password'
          value={user.newPassword}
          onChange={(e) => {
            setProfileAttribute('newPassword', e.target.value)}}
        />
      </form>
    </div>  
  )
}

export default EditPasswordForm