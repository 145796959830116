import { Link } from 'react-router-dom'
import { humanizeError } from '../../utils/errorFormatter'
import { styled } from '@mui/system'
import Alert from '@mui/material/Alert'

const EmailAlert = styled(Alert)({
  textAlign: 'left',
  '& a': {
    color: 'inherit',
    fontWeight: 'bold',
  },
})

const RegistrationErrorFormatter = ({ email, error, errorType }) => {
  const renderStripeError = () => {
    return <Alert severity="error">{humanizeError(error.message)}</Alert>
  }

  const renderRailsError = () => {
    const railsErrorMessage = humanizeError(error)
    const emailTakenError = /Email is already in use/.test(railsErrorMessage)

    if (emailTakenError) {
      return (
        <EmailAlert severity="error" color="warning">
          Looks like we already have an account for {email}! Would you like to{' '}
          <Link to="/login">sign in</Link> instead?
        </EmailAlert>
      )
    }

    return <Alert severity="error">{railsErrorMessage}</Alert>
  }

  return (
    <>{errorType === 'stripe' ? renderStripeError() : renderRailsError()}</>
  )
}

export default RegistrationErrorFormatter
