import { styled } from '@mui/system'

const StyledIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fillType',
})(({ theme, fillType }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    path: {
      fill: fillType === 'fill' ? theme.palette.neutral.main : '',
      stroke: fillType === 'stroke' ? theme.palette.neutral.main : '',
    },
  },
}))

const CodeBuilderInputIcon = ({ icon, fillType }) => {
  return <StyledIcon fillType={fillType}>{icon}</StyledIcon>
}

export default CodeBuilderInputIcon
