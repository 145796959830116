import { useEffect, useState, useRef } from 'react'
import { debounce } from 'lodash'
import { styled } from '@mui/system'
import { Tooltip } from '@mui/material'
import {
  SurveyContainer,
  SurveyHeader,
  SurveyActions,
  AnswersContainer,
  QuestionContainerParent,
  CodeInputTemplate,
  BooleanPossibleAnswerTemplate,
  SimpleTextPossibleAnswerTemplate,
  EmojiScalePossibleAnswerTemplate,
  MultipleChoicePossibleAnswerTemplate,
  FreeFlowPossibleAnswerTemplate,
  CheckboxPossibleAnswerTemplate,
  ThankYouParent,
} from '@talkadot/survey-component-library'

import { ReactComponent as EyeOffIcon } from '../../../icons/eyeOffIcon_16x16.svg'

import { parseAutoFillTags, parseSpeakerName } from '../../../utils/string'
import { makeSurveyLink } from '../../../features/common/helpers'
import { titleize } from '../../../utils/string'

const BASE_PREVIEW_PANE_HEIGHT = 538

const PreviewPaneContainer = styled('div')({
  height: '100%',
  overflowY: 'auto',
  position: 'relative',
})

const IframeContainer = styled('div')({
  width: '318px',
  height: '495px',
  overflow: 'hidden',
})

const StyledIframe = styled('iframe')({
  width: '450px',
  height: '700px',
  border: 'none',
  transformOrigin: 'top left',
  transform: 'scale(0.707)',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
})

const PreviewPaneOverlay = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'questionEnabled' && prop !== 'previewOverlayHeight',
})(({ theme, questionEnabled, previewOverlayHeight }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: previewOverlayHeight ? `${previewOverlayHeight}px` : '100%',
  backgroundColor: theme.palette.base.white,
  opacity: questionEnabled ? 0 : 0.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  '&:hover': {
    opacity: questionEnabled ? 0.3 : 0.5,
  },
  transition: 'opacity 0.3s ease',
}))

const DisabledIcon = styled(EyeOffIcon)(({ theme }) => ({
  width: '64px',
  height: '64px',
  path: {
    stroke: theme.palette.error.semiDark,
  },
}))

const ScaledSurveyContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'scale',
})(({ scale }) => ({
  transform: `scale(${scale})`,
  transformOrigin: 'top',
  top: '0',
  left: '0',
  height: `calc(${BASE_PREVIEW_PANE_HEIGHT * scale}px)`,
}))

const HeaderContainer = styled('div')({
  width: '100%',
})

const ContentContainer = styled('div')({
  flexGrow: 1,
  width: '100%',
  paddingBottom: '8px',
  display: 'flex',
  alignSelf: 'center',
  justifyContent: 'space-around',
})

const CodeBuilderPreviewPane = ({ currentQuestion, user, code, bonusUrl }) => {
  const [scale, setScale] = useState(1)
  const [previewOverlayHeight, setPreviewOverlayHeight] = useState(null)

  const previewPaneRef = useRef(null)
  const headerRef = useRef(null)
  const contentRef = useRef(null)

  const handleResize = () => {
    const height = previewPaneRef.current?.getBoundingClientRect().height
    const heightScale = height / BASE_PREVIEW_PANE_HEIGHT || 1

    if (heightScale < 1) {
      const scale = Math.max(0.3, heightScale)
      setScale(scale)
    } else if (scale !== 1) {
      setScale(1)
    }

    const headerHeight = headerRef.current?.getBoundingClientRect().height
    const contentHeight = contentRef.current?.getBoundingClientRect().height

    if (headerHeight && contentHeight) {
      const previewContentHeight = headerHeight + contentHeight
      const previewOverlayHeight =
        previewContentHeight > height ? previewContentHeight : ''

      setPreviewOverlayHeight(previewOverlayHeight)
    }
  }

  useEffect(() => {
    // Run handleResize on initial render and when window is resized
    const debouncedResize = debounce(handleResize, 10)

    window.addEventListener('resize', debouncedResize)

    return () => window.removeEventListener('resize', debouncedResize)
  }, [])

  useEffect(() => {
    // Run handleResize when currentQuestion changes
    const debouncedResize = debounce(handleResize, 100)

    debouncedResize()
  }, [currentQuestion])

  const name = user.first_name + ' ' + user.last_name
  const { avatar, socialLinks, booking_link } = user
  const membershipPlanTier = user?.membership?.plan?.planTier

  const isBookingFlowQuestion =
    currentQuestion?.config?.templateIdentifier?.includes('BOOKING')

  const isSurveyQuestion = Number.isInteger(currentQuestion?.id)

  const renderAnswer = ({ possibleAnswer, index }) => {
    const key = possibleAnswer.id || index

    switch (possibleAnswer.answerType) {
      case 'BOOLEAN':
        return (
          <BooleanPossibleAnswerTemplate
            key={key}
            selected={false}
            handleClick={() => {}}
            content={possibleAnswer.content}
          />
        )
      case 'MULTIPLE_CHOICE':
        return (
          <MultipleChoicePossibleAnswerTemplate
            key={key}
            selected={false}
            handleClick={() => {}}
            content={possibleAnswer.content || 'Enter an option ...'}
          />
        )
      case 'FREE_FLOW':
        return (
          <FreeFlowPossibleAnswerTemplate
            key={key}
            placeholder={possibleAnswer.subContent}
            handleChange={() => {}}
            rows={8}
            answerValue={null}
          />
        )
      case 'OPPORTUNITY_DETAILS':
        return (
          <FreeFlowPossibleAnswerTemplate
            key={index}
            placeholder={possibleAnswer.subContent}
            handleChange={() => {}}
            rows={8}
            answerValue={null}
          />
        )
      case 'EMAIL':
      case 'NAME':
      case 'JOB_TITLE':
      case 'ORGANIZATION':
      case 'PHONE':
        return (
          <SimpleTextPossibleAnswerTemplate
            key={key}
            fieldValue={''}
            possibleAnswer={possibleAnswer}
            handleChange={() => {}}
            error={renderErrorMessage(possibleAnswer)}
            handleBlur={() => {}}
            placeholder={possibleAnswer.helperText}
          />
        )
      case 'EMOJI_SCALE':
        return (
          <EmojiScalePossibleAnswerTemplate
            key={key}
            handleClick={() => {}}
            answerValue={possibleAnswer?.answerValue}
          />
        )
      case 'CHECKBOX':
        return (
          <CheckboxPossibleAnswerTemplate
            key={key}
            handleChange={() => {}}
            checked={false}
            content={parseSpeakerName(possibleAnswer, user.first_name).content}
          />
        )
      // NOTE: Do we even need this anymore?
      // case Survey.ANSWER_TYPES.OPT_OUT_LINK:
      //   return (
      //     <OptOutLinkPossibleAnswer
      //       key={index}
      //       possibleAnswer={possibleAnswer}
      //     />
      //   )
      default:
        return <div key={key} />
    }
  }

  const renderAnswers = () => {
    const sortedPossibleAnswers = currentQuestion?.possibleAnswers?.sort(
      (a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder)
    )

    const questionType = currentQuestion.questionType

    // We don't show disabled possible answers if the question is enabled
    const filteredPossibleAnswers = !currentQuestion.enabled
      ? sortedPossibleAnswers
      : sortedPossibleAnswers?.filter(
          (possibleAnswer) => possibleAnswer.enabled
        )

    return filteredPossibleAnswers?.map((possibleAnswer, i) => {
      return renderAnswer({ possibleAnswer, questionType, index: i })
    })
  }

  const contentAlign = () => {
    if (
      currentQuestion?.questionType === 'BOOLEAN' ||
      (currentQuestion?.questionType === 'MULTI_INPUT' &&
        !isBookingFlowQuestion)
    ) {
      return 'center'
    }

    return 'left'
  }

  const codeContent = () => {
    return (
      <QuestionContainerParent
        content={`<h1>Hi! &#128075; Thanks for your time!</h1>
      <br />
      <br />
      <div>We promise, this is quick.<div>`}
        contentAlign="center">
        <CodeInputTemplate
          value={code}
          handleChange={() => {}}
          speakerName={name}
          invalidValue={false}
        />
        <SurveyActions
          shouldRenderActions={true}
          disabled={false}
          handleClick={() => {}}
          actionText={"Let's Start!"}
        />
      </QuestionContainerParent>
    )
  }

  const renderErrorMessage = (possibleAnswer) => {
    if (!possibleAnswer.inputRequired) return ''

    return `${titleize(
      possibleAnswer.answerType.split('_').join(' ')
    )} is required`
  }

  const shouldShowActions =
    currentQuestion.questionType !== 'BOOLEAN' &&
    currentQuestion.questionType !== 'MULTIPLE_CHOICE' &&
    currentQuestion.questionType !== 'EMOJI_SCALE'

  const surveyLinkSrc = makeSurveyLink({
    shortlink: user.shortlink,
    code,
  })

  const renderPreviewContent = () => {
    if (!currentQuestion.questionType) {
      return <></>
    }

    switch (currentQuestion.questionType) {
      case 'code':
        return codeContent()
      case 'downloadSlide':
        return (
          <IframeContainer>
            <StyledIframe
              src={`${surveyLinkSrc}&mode=test`}
              title="Survey Preview"
            />
          </IframeContainer>
        )
      case 'bonus':
        return (
          <ThankYouParent
            thankYouText="All Done! Thank you so much! 😁"
            speakerName={name}
            showBookingLink={membershipPlanTier > 0 && !!booking_link}
            showBonusLink={bonusUrl}
            handleBookingLinkClick={() => {}}
            handleBonusLinkClick={() => {}}
            bonusLinkText="Get your bonus"
            bookingLinkText="Book a Meeting"
            socialLinks={socialLinks}
            showSocialLinks={Object.values(socialLinks).some(
              (value) => !!value
            )}
            showOfferError={false}
          />
        )

      default:
        return (
          <QuestionContainerParent
            content={parseAutoFillTags({
              question: currentQuestion,
              speakerName: user?.first_name,
            })}
            addExtraBottomPadding={currentQuestion.identifier > 0}
            reduceMargins={false}
            contentAlign={contentAlign()}>
            <AnswersContainer
              questionType={currentQuestion.questionType}
              isLoading={false}
              renderAnswers={renderAnswers}
            />
            <SurveyActions
              shouldRenderActions={shouldShowActions}
              handleClick={() => {}}
              actionText={'Next'}
            />
          </QuestionContainerParent>
        )
    }
  }

  return (
    <PreviewPaneContainer ref={previewPaneRef}>
      <Tooltip
        // placement="top"
        title={
          isSurveyQuestion && !currentQuestion.content
            ? 'Questions without content will not be shown.'
            : currentQuestion.enabled
            ? 'This is a preview of what your audience would see.'
            : 'Question is disabled, it will not be shown when you use this code. Toggle to enable question.'
        }
        // slotProps={{
        //   popper: {
        //     modifiers: [
        //       {
        //         name: 'offset',
        //         options: {
        //           offset: [0, -210],
        //         },
        //       },
        //     ],
        //   },
        // }}
        followCursor={true}>
        <PreviewPaneOverlay
          questionEnabled={
            (!isSurveyQuestion || currentQuestion.content) &&
            currentQuestion.enabled
          }
          previewOverlayHeight={previewOverlayHeight}>
          {(!currentQuestion.content || !currentQuestion.enabled) && (
            <DisabledIcon />
          )}
        </PreviewPaneOverlay>
      </Tooltip>
      <ScaledSurveyContainer scale={scale}>
        <SurveyContainer hideOverflow={true}>
          {currentQuestion.questionType !== 'downloadSlide' && (
            <HeaderContainer ref={headerRef}>
              <SurveyHeader
                survey={{ speaker: { name, avatar }, percentComplete: 0 }}
              />
            </HeaderContainer>
          )}
          <ContentContainer ref={contentRef}>
            {renderPreviewContent()}
          </ContentContainer>
        </SurveyContainer>
      </ScaledSurveyContainer>
    </PreviewPaneContainer>
  )
}

export default CodeBuilderPreviewPane
