import React from 'react'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const TosContent = styled(Typography)({
  fontWeight: '100',
  fontSize: '10px',
  textAlign: 'left',
  padding: '0 5px',
})

const TermsOfService = () => (
  <TosContent
    component="div"
    variant="body2"
    sx={{ fontWeight: '100', fontSize: '12px' }}>
    By registering, you agree to our{' '}
    <a
      href="https://www.talkadot.com/terms-of-service"
      target="_blank"
      rel="noreferrer">
      Terms of Service
    </a>
    ,{' '}
    <a
      href="https://www.talkadot.com/privacy-policy"
      target="_blank"
      rel="noreferrer">
      Privacy Policy
    </a>{' '}
    and{' '}
    <a
      href="https://www.talkadot.com/data-processing-agreement"
      target="_blank"
      rel="noreferrer">
      Data Processing Agreement
    </a>
    .
  </TosContent>
)

export default TermsOfService
