export const LocationSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19">
      <g id="happy-outline" transform="translate(-47.156 -47.156)">
        <ellipse
          id="Ellipse_179"
          data-name="Ellipse 179"
          cx="0.5"
          cy="1.5"
          rx="0.5"
          ry="1.5"
          transform="translate(53.156 54.156)"
          fill="#fff"
        />
        <path
          id="Path_262"
          data-name="Path 262"
          d="M164.1,307.381a4.2,4.2,0,0,1-4.045-2.952.338.338,0,0,1,.331-.43h7.426a.338.338,0,0,1,.331.43A4.192,4.192,0,0,1,164.1,307.381Z"
          transform="translate(-107.442 -245.317)"
          fill="#fff"
        />
        <circle
          id="Ellipse_180"
          data-name="Ellipse 180"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(58.156 54.156)"
          fill="#fff"
        />
        <circle
          id="Ellipse_181"
          data-name="Ellipse 181"
          cx="8.5"
          cy="8.5"
          r="8.5"
          transform="translate(48.156 48.156)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
