import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'

import { ReactComponent as CheckIcon } from '../../icons/checkMarkIcon_16x16.svg'
import { ReactComponent as EliteIcon } from '../../icons/membershipPlanIconElite.svg'
import { ReactComponent as ProIcon } from '../../icons/membershipPlanIconPro.svg'
import { ReactComponent as LiteIcon } from '../../icons/membershipPlanIconLite.svg'

import {
  trackAddToCart,
  trackDowngradeContactSupport,
} from '../../services/metrics'
import { trialEndDate } from '../../utils/dateTime'
import { cleanBulletItem } from './helpers'

import UpgradeModalLauncher from '../common/UpgradeModalLauncher'
import moment from 'moment'

const PricingGridParent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mostPopularPlan',
})(({ mostPopularPlan, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  border: mostPopularPlan
    ? `1px solid ${theme.palette.primary.main}`
    : `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.shape.borderRadius.xxs,
}))

const PlanNameWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '208px',
  padding: theme.spacing(5, 3, 2.5, 3),
  background: theme.palette.base.white,
  borderTopLeftRadius: theme.shape.borderRadius.xxs,
  borderTopRightRadius: theme.shape.borderRadius.xxs,
}))

const PlanIcon = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  svg: {
    height: '30px',
  },
}))

const PlanName = styled(Typography)(({ theme, color }) => ({
  letterSpacing: '-1.56px',
  fontWeight: '700',
  color: color ? theme.palette[color].main : '',
}))

const PlanNameSubtext = styled(Typography)({})

const PlanPriceBulletsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mostPopularPlan',
})(({ theme, mostPopularPlan }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: theme.spacing(3),
  background: mostPopularPlan
    ? theme.palette.primary.extraLight
    : theme.palette.surface.subtle,
  borderBottomLeftRadius: theme.shape.borderRadius.xxs,
  borderBottomRightRadius: theme.shape.borderRadius.xxs,
}))

const FeatureBulletsHeader = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  marginBottom: theme.spacing(2),
}))

const FeatureBullets = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

const FeatureBullet = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  svg: {
    width: '10px',
    minWidth: '10px',
    marginRight: theme.spacing(1.5),
    marginTop: '3px',
  },
}))

const PlanPrice = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isDiscounted',
})(({ theme, isDiscounted }) => ({
  fontWeight: '700',
  color: isDiscounted ? theme.palette.error.semiDark : '',
}))

const PlanPriceInterval = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.neutral.main,
  marginLeft: theme.spacing(1),
  paddingTop: theme.spacing(1),
}))

const PlanPriceSubtext = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
  span: {
    textDecoration: 'line-through',
  },
}))

const RebateAlert = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'inWelcomeFlow',
})(({ theme }) => ({
  background: theme.palette.error.semiDark,
  color: theme.palette.base.white,
  padding: theme.spacing(0.5, 1),
  marginBottom: theme.spacing(3),
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius.xxxs,
}))

const UpgradeButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'buttonColor',
})(({ theme, buttonColor }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(4),
  fontSize: '1.125rem',
  color: buttonColor === 'tertiary' ? 'white' : theme.palette.neutral.darkest,
  border:
    buttonColor === 'white' ? `1px solid ${theme.palette.border.main}` : '',
  '&:hover': {
    backgroundColor: buttonColor === 'white' ? theme.palette.base.white : '',
  },
}))

const FlexRowContainer = styled('div')({
  display: 'flex',
})

const FlexColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const PriceCell = ({
  plan,
  inWelcomeFlow,
  upgradingPaidPlan,
  trialDaysRemaining,
  subscribedWhileTrialing,
  isTrialing,
  isCurrentPlan,
}) => {
  let annualPrice = plan.price
  let retailPrice = null
  let hasOneTimeDiscount = false
  let hasRecurringDiscount = false
  let hasImpliedDiscount = false

  if (subscribedWhileTrialing && plan.hasRecurringDiscount && isCurrentPlan) {
    annualPrice = plan.discountedPrice
  }

  if (isTrialing) {
    if (
      plan.hasOneTimeDiscount ||
      plan.hasRecurringDiscount ||
      plan.hasImpliedDiscount
    ) {
      annualPrice = plan.discountedPrice
      retailPrice = plan.retailPrice
    }

    hasRecurringDiscount = plan.hasRecurringDiscount
    hasOneTimeDiscount = plan.hasOneTimeDiscount
    hasImpliedDiscount = plan.hasImpliedDiscount
  }

  const renderSubText = () => {
    if (hasOneTimeDiscount || hasRecurringDiscount || hasImpliedDiscount) {
      return (
        <>
          Normally{' '}
          <span>
            ${Math.ceil(retailPrice / 12)} / month or ${retailPrice} / year
          </span>
        </>
      )
    }

    // Keep as spacer to keep layout consistent
    return <span style={{ visibility: 'hidden' }}>${annualPrice} / year</span>
  }

  const renderRebateAlert = () => {
    if (hasRecurringDiscount) {
      return (
        <RebateAlert variant="body1">
          Upgrade by {trialEndDate(trialDaysRemaining)} and save ${plan.price - annualPrice}/
          year!
        </RebateAlert>
      )
    }

    if (hasImpliedDiscount) {
      return (
        <RebateAlert variant="body1">
          Upgrade by {trialEndDate(trialDaysRemaining)} and save ${retailPrice - annualPrice}/
          year!
        </RebateAlert>
      )
    }

    if (hasOneTimeDiscount) {
      return (
        <RebateAlert variant="body1">
          Upgrade by {trialEndDate(trialDaysRemaining)} and save ${plan.price - annualPrice}{' '}
          on your first year!
        </RebateAlert>
      )
    }

    if (plan.offerExpiration && !isCurrentPlan) {
      return (
        <RebateAlert variant="body1">
          Deal ends {moment(plan.offerExpiration).format('MMMM D')}!
        </RebateAlert>
      )
    }

    // NOTE: this functions as a spacer to keep the layout consistent
    if (isTrialing && !hasRecurringDiscount && !hasOneTimeDiscount) {
      return (
        <RebateAlert variant="body1" style={{ visibility: 'hidden' }}>
          Upgrade by {trialEndDate(trialDaysRemaining)} and save $
          {plan.price - annualPrice}/ year!
        </RebateAlert>
      )
    }
  }

  if (plan.price) {
    return (
      <>
        {renderRebateAlert()}
        <FlexRowContainer>
          <PlanPrice
            variant="h1"
            isDiscounted={
              hasOneTimeDiscount || hasRecurringDiscount || hasImpliedDiscount
            }>
            ${Math.ceil(annualPrice / 12)}
          </PlanPrice>
          <FlexColumnContainer>
            <PlanPriceInterval variant="paragraph00">/ month</PlanPriceInterval>
            <PlanPriceInterval variant="paragraph00">
              (${annualPrice} billed annually)
            </PlanPriceInterval>
          </FlexColumnContainer>
        </FlexRowContainer>
        <PlanPriceSubtext variant="body1">{renderSubText()}</PlanPriceSubtext>
      </>
    )
  }

  return (
    <>
      {renderRebateAlert()}
      <FlexRowContainer>
        <PlanPrice variant="h1">$0</PlanPrice>
      </FlexRowContainer>
      <PlanPriceSubtext variant="body1">Free Forever</PlanPriceSubtext>
      {(!plan.price || plan.price === 0) && isTrialing && inWelcomeFlow && (
        <RebateAlert variant="body1">
          Your account will automatically revert to this after{' '}
          {trialDaysRemaining} days.
        </RebateAlert>
      )}
    </>
  )
}

const PricingGrid = ({
  plan,
  setSelectedPlan,
  selectedPlan,
  currentPlan,
  isCurrentPlan,
  isTrialing,
  subscribedWhileTrialing,
  trialDaysRemaining,
  inWelcomeFlow,
  user,
  upgradingPaidPlan = false,
}) => {
  const isDowngrade = currentPlan?.planTier > plan.planTier

  const handleClick = () => {
    if (isDowngrade) {
      trackDowngradeContactSupport({
        email: user?.email,
        plan: plan?.name,
        value: plan?.price,
      })

      window.open(
        `mailto:hello@talkadot.com?subject=${
          encodeURIComponent("I'd like to downgrade my Talkadot plan") || ''
        }`
      )
    } else {
      trackAddToCart({ plan: plan?.name, value: plan?.price })
      setSelectedPlan(plan)
    }
  }

  const isOnFreePlan =
    (!plan.price || plan.price === 0) && isTrialing && inWelcomeFlow

  const isTrialPlan = isTrialing && plan?.planType === 'Pro'
  const isTrialDowngrade = isTrialing && plan?.planType === 'Lite'

  const renderCta = () => {
    let buttonText = 'Upgrade Now'

    if (isTrialPlan) {
      buttonText = 'On Trial - Upgrade Now!'
    } else if (isOnFreePlan) {
      buttonText = 'Continue to Trial'
    } else if (isCurrentPlan) {
      buttonText = 'Your Current Plan'
    } else if (isTrialDowngrade) {
      buttonText = 'Your Plan After Trial Ends'
    } else if (isDowngrade) {
      buttonText = 'Contact Support'
    }

    const buttonColor = mostPopularPlan
      ? 'tertiary'
      : isCurrentPlan
      ? 'neutral'
      : 'white'

    if (!selectedPlan) {
      return (
        <UpgradeButton
          color={buttonColor}
          variant="contained"
          onClick={handleClick}
          disabled={isCurrentPlan || isTrialDowngrade}
          buttonColor={buttonColor}>
          {buttonText}
        </UpgradeButton>
      )
    }
    if (inWelcomeFlow && isTrialing) {
      return (
        <UpgradeModalLauncher
          content="Upgrade Now"
          variant="contained"
          color="tertiary"
          upgradeHelperText="Upgrade To Unlock New Features!"
          permissionType={null}
        />
      )
    }
  }

  const mostPopularPlan = plan.name === 'Talkadot Pro' && !isCurrentPlan

  let planIcon = null
  let planName = ''
  let planNameSubtext = ''

  switch (plan.name?.trim()) {
    case 'Talkadot Lite':
      planIcon = <LiteIcon />
      planName = 'Lite'
      planNameSubtext = 'for speaking hobbyists'
      break
    case 'Talkadot Pro':
      planIcon = <ProIcon />
      planName = 'Pro'
      planNameSubtext = 'for speaking professionals'
      break
    case 'NSA Talkadot Pro':
      planIcon = <ProIcon />
      planName = 'NSA Pro'
      planNameSubtext = 'for speaking professionals'
      break
    case 'Talkadot Elite':
      planIcon = <EliteIcon />
      planName = 'Elite'
      planNameSubtext = 'for serious speaking professionals with small teams'
      break
    case 'Talkadot Charter':
      planIcon = <EliteIcon />
      planName = 'Charter'
      planNameSubtext = 'for early adopters'
      break
    default:
      break
  }

  return (
    <PricingGridParent mostPopularPlan={mostPopularPlan}>
      <PlanNameWrapper>
        <PlanIcon>{planIcon}</PlanIcon>
        <PlanName
          variant="h2"
          // TODO: Add a prop for color
          color={
            plan.name === 'Talkadot Elite'
              ? 'secondary'
              : plan.name === 'Talkadot Pro'
              ? 'primary'
              : ''
          }>
          {planName}
        </PlanName>
        <PlanNameSubtext>{planNameSubtext}</PlanNameSubtext>
      </PlanNameWrapper>
      <PlanPriceBulletsWrapper mostPopularPlan={mostPopularPlan}>
        <PriceCell
          isTrialing={isTrialing}
          plan={plan}
          inWelcomeFlow={inWelcomeFlow}
          upgradingPaidPlan={upgradingPaidPlan}
          subscribedWhileTrialing={subscribedWhileTrialing}
          trialDaysRemaining={trialDaysRemaining}
          isCurrentPlan={isCurrentPlan}
        />
        {renderCta()}
        {planName !== 'Lite' && (
          <FeatureBulletsHeader variant="body1">
            Everything in {planName.includes('Pro') ? 'Lite' : 'Pro'}, plus:
          </FeatureBulletsHeader>
        )}
        <FeatureBullets>
          {plan?.featureBullets?.map((item, i) => {
            return (
              <FeatureBullet key={i}>
                <CheckIcon />
                <Typography>{cleanBulletItem(item)}</Typography>
              </FeatureBullet>
            )
          })}
        </FeatureBullets>
      </PlanPriceBulletsWrapper>
    </PricingGridParent>
  )
}

export default PricingGrid
