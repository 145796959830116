import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import {
  Checkbox,
  Typography,
  TableRow,
  TableCell,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material'

import LockIcon from '@mui/icons-material/Lock'

import { useTheme } from '@mui/material/styles'

import {
  IoEllipsisHorizontal,
  IoTrashOutline,
  IoEyeOutline,
  IoGitPullRequestOutline,
} from 'react-icons/io5'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { usePermissionHelper } from '../../utils/permission_helper'
import { GroupContext } from '../groups/groupContext'
import { AdminContext } from '../admin/adminContext'
import { formatDate } from '../common/helpers'
import { renderPercentage } from '../../utils/number'

import EventButton from './EventButton'
import EditEventIcon from './EditEventIcon'
import EventLeadsDownloadMenuItem from './EventLeadsDownloadMenuItem'
import EventRawDataDownloadMenuItem from './EventRawDataDownloadMenuItem'
import EventSpeakerflowSyncMenuItem from './EventSpeakerflowSyncMenuItem'
import { eventReportCardShareLink } from '../common/helpers'
import ConfirmDialog from '../common/ConfirmDialog'

const SpeakersListAvatar = styled(Avatar)({
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  img: {
    width: '102%',
    height: '102%',
  },
})

const EventCodeCell = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const SpeakerTableRow = styled(TableRow, {
  shouldForwardProp: (prop) =>
    prop !== 'eventMergable' &&
    prop !== 'isPrimeEvent' &&
    prop !== 'isEventOwner',
})(({ eventMergable, isPrimeEvent, isEventOwner }) => ({
  backgroundColor: isPrimeEvent
    ? '#4da02e !important'
    : eventMergable
    ? '#d3e8cb !important'
    : isEventOwner
    ? 'unset'
    : 'snow',
  td: {
    color: isPrimeEvent && 'white',
  },
}))

const SessionFromEventIcon = styled(Typography)({
  fontWeight: 'bold',
  background: 'lightgrey',
  borderRadius: '50px',
  width: '25px',
  height: '25px',
  textAlign: 'center',
  marginLeft: '8px',
})

const initialConfirmProps = {
  title: '',
  message: '',
  onConfirm: () => {},
}

const EventListRow = ({
  event,
  deleteEvent,
  handleOpenEventForm,
  selectEvent,
  deselectEvent,
  handleShareEvent,
  mergeEvents,
  mergableEvents,
  primeEvent,
}) => {
  const history = useHistory()
  const { authData } = useContext(AuthenticationContext)
  const { adminState } = useContext(AdminContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const { canDownloadEventLeads } = usePermissionHelper()

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState(initialConfirmProps)

  const theme = useTheme()
  const { user } = authData
  const { isAssociate, speakerflow_url, speakerflowEnabled } = authData?.user
  const isEventOwner =
    user?.id === event?.ownerId || selectedGroup?.id === event?.ownerId
  const eventMergable = event.isSelected && !event.isPrimeEvent
  const eventReportShortlink = selectedGroup
    ? selectedGroup.shortlink
    : user.shortlink

  const handleMergeEvents = async () => {
    await mergeEvents()
    setConfirmOpen(false)
  }

  const handleMergeEventsClick = () => {
    if (mergableEvents.length > 0) {
      const mergeContent = `${mergableEvents.length} event${
        mergableEvents?.length > 1 ? 's' : ''
      } into ${formatDate(primeEvent.date, primeEvent.utcOffset)} ${
        primeEvent.talkTitle ? `- ${primeEvent.talkTitle}` : ''
      }`

      setConfirmProps({
        title: 'Merge Events?',
        message: `Are you sure you want to merge these events? Merging ${mergeContent} is NOT reversible.`,
        onConfirm: () => handleMergeEvents(),
      })

      setConfirmOpen(true)
    }
  }

  const renderEditableCell = ({
    value,
    buttonText = 'Add Details!',
    editAttribute = '',
    attributeName,
  }) => {
    if (isEventOwner) {
      return value ? (
        value
      ) : (
        <EventButton
          event={event}
          handleOpenEventForm={handleOpenEventForm}
          buttonText={buttonText}
          editAttribute={editAttribute}
        />
      )
    }
    if (attributeName === 'talkTitle') {
      return value
    } else {
      return null
    }
  }

  const goToEventReport = () => {
    // If admin is impersonating, don't go to a new page
    if (adminState?.impersonation?.isActive) {
      return (
        !event.isSelected && history.push(`/event-report/${event.shareKey}`)
      )
    }

    // Don't go to the event report if the event is currently selected as a merge event
    return (
      !event.isSelected &&
      window.open(
        eventReportCardShareLink(eventReportShortlink, event.shareKey),
        '_blank',
        'noreferrer'
      )
    )
  }

  const handleClick = ({ value, allowEditDetails = false }) => {
    if (allowEditDetails && !value) {
      // open event details dialog
      return null
    } else {
      goToEventReport()
    }
  }

  const ShareCell = () => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleOpenDropdown = (e) => {
      setAnchorEl(e.currentTarget)
    }
    const handleCloseDropdown = () => {
      setAnchorEl(null)
    }

    return (
      <TableCell className="fixed-w-s" align="center">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenDropdown}
          aria-label="Open to show more"
          title="Open to show more">
          <IoEllipsisHorizontal />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <MenuItem key="0" onClick={goToEventReport}>
            <ListItemIcon>
              <IoEyeOutline />
            </ListItemIcon>
            <ListItemText>View Report</ListItemText>
          </MenuItem>
          {isEventOwner && (
            <EditEventIcon
              existingEvent={event}
              handleOpenEventForm={handleOpenEventForm}
            />
          )}
          {/* {isEventOwner &&
            !selectedGroup &&
            (hasGroupsFeatureFlag || isAdmin) && (
              <MenuItem key="5" onClick={() => handleShareEvent(event)}>
                <ListItemIcon>
                  <IoPersonAddOutline />
                </ListItemIcon>
                <ListItemText>Share Event</ListItemText>
              </MenuItem>
            )} */}
          {isEventOwner && canDownloadEventLeads() && (
            <div>
              <EventLeadsDownloadMenuItem event={event} group={selectedGroup} />
              <EventRawDataDownloadMenuItem
                event={event}
                group={selectedGroup}
              />
              {speakerflowEnabled && speakerflow_url && (
                <EventSpeakerflowSyncMenuItem event={event} />
              )}
            </div>
          )}
          {isEventOwner && (
            <MenuItem key="6" onClick={_handleSelectEvent}>
              <ListItemIcon>
                <IoGitPullRequestOutline />
              </ListItemIcon>
              <ListItemText>Merge Events</ListItemText>
            </MenuItem>
          )}
          {isEventOwner && !isAssociate && (
            <MenuItem key="2" onClick={() => deleteEvent(event)}>
              <ListItemIcon>
                <IoTrashOutline />
              </ListItemIcon>
              <ListItemText>Delete Event</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    )
  }

  const _handleSelectEvent = () => {
    return event.isSelected ? deselectEvent(event) : selectEvent(event)
  }

  const renderSpeakerList = () => {
    return (
      <List>
        {event.speakers.map((speaker) => {
          return renderSpeakerListItem(speaker)
        })}
      </List>
    )
  }

  const getChipColor = (eventType) => {
    if (!eventType) {
      return
    }

    const et = eventType.toUpperCase()

    if (et === 'KEYNOTE') {
      return 'success'
    }

    if (et === 'WORKSHOP') {
      return 'primary'
    }

    if (et === 'GLOBAL') {
      return 'warning'
    }
  }

  const renderEventType = () => {
    const { eventType } = event
    const color = getChipColor(eventType)

    if (eventType) {
      return <Chip label={eventType} color={color} />
    }
  }

  const renderSpeakerListItem = (speaker) => (
    <ListItem key={speaker.id} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
      <ListItemIcon>
        <SpeakersListAvatar
          alt={speaker.full_name}
          src={speaker.avatar || speaker.full_name?.charAt(0)}
          sx={{ width: 38, height: 38 }}
        />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body2">{speaker.full_name}</Typography>
      </ListItemText>
    </ListItem>
  )

  const renderResponsesCell = () => {
    if (event.audienceSize) {
      return (
        <span>
          {event.responses}
          <Typography
            variant="body2"
            component="span"
            sx={{ fontWeight: '300', fontSize: '12px' }}>
            {' '}
            / {event.audienceSize}
          </Typography>
        </span>
      )
    } else {
      return <span>{event.responses}</span>
    }
  }

  const renderEventPlannerRow = () => (
    <TableRow
      className={`table-row ${eventMergable ? 'event-mergable' : ''}`}
      key={event.id}
      id={event.isPrimeEvent && 'event-prime'}>
      <TableCell className="fixed-w-xs">
        <Checkbox
          color="primary"
          checked={!!event.isSelected}
          onChange={_handleSelectEvent}
          inputProps={{
            'aria-labelledby': event.id,
          }}
        />
      </TableCell>
      <TableCell className="fixed-w-xs"></TableCell>
      <TableCell align="left" className="fixed-w-m">
        <div>{formatDate(event.date, event.utcOffset)}</div>
        <div className="subheader">{event.startTime}</div>
      </TableCell>
      <TableCell
        align="left"
        className="fixed-w-l"
        onClick={() =>
          handleClick({
            value: event.talkTitle,
            allowEditDetails: true,
          })
        }>
        {renderEditableCell({ value: event.talkTitle })}
      </TableCell>
      {event.isSelected && !event.isPrimeEvent && (
        <TableCell align="left" colspan={7} sx={{ textAlign: 'center' }}>
          Merge into selected event
        </TableCell>
      )}
      {event.isSelected && event.isPrimeEvent && (
        <TableCell align="left" colspan={7} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" onClick={handleMergeEventsClick}>
            <b>Click here to merge selected events into this event</b>
          </Typography>
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell align="left" className="fixed-w-l" onClick={goToEventReport}>
          {renderSpeakerList()}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-s"
          onClick={goToEventReport}>
          {renderEventType()}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-ms"
          onClick={goToEventReport}>
          {renderPercentage(event.reattend)}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-s"
          onClick={goToEventReport}>
          {renderPercentage(event.rating)}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-ms"
          onClick={goToEventReport}>
          {renderResponsesCell()}
        </TableCell>
      )}
      {!event.isSelected && <ShareCell />}
    </TableRow>
  )

  const renderGroupGuestSpeakerRow = () => (
    <SpeakerTableRow
      key={event.id}
      isPrimeEvent={event.isPrimeEvent}
      eventMergable={eventMergable}
      isEventOwner={isEventOwner}>
      <TableCell className="fixed-w-xs">
        <Checkbox
          color="primary"
          checked={!!event.isSelected}
          onChange={_handleSelectEvent}
          inputProps={{
            'aria-labelledby': event.id,
          }}
        />
      </TableCell>
      <TableCell className="fixed-w-xs"></TableCell>
      <TableCell
        align="left"
        className="fixed-w-m"
        onClick={() =>
          handleClick({
            value: event.date,
            allowEditDetails: false,
          })
        }>
        {formatDate(event.date, event.utcOffset)}
      </TableCell>
      <TableCell
        align="left"
        className="fixed-w-l"
        onClick={() =>
          handleClick({
            value: event.talkTitle,
            allowEditDetails: true,
          })
        }>
        {renderEditableCell({ value: event.talkTitle })}
      </TableCell>
      {event.isSelected && !event.isPrimeEvent && (
        <TableCell align="left" colspan={8} sx={{ textAlign: 'center' }}>
          Merge into selected event
        </TableCell>
      )}
      {event.isSelected && event.isPrimeEvent && (
        <TableCell align="left" colspan={8} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" onClick={handleMergeEventsClick}>
            {mergableEvents.length > 0 ? (
              <b>Click here to merge selected events into this event</b>
            ) : (
              <b>Select other events to merge into this event</b>
            )}
          </Typography>
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell align="left" className="fixed-w-l" onClick={goToEventReport}>
          {renderSpeakerList()}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell align="left" className="fixed-w-s" onClick={goToEventReport}>
          {event.location}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-ms"
          onClick={goToEventReport}>
          {event.reattend}%
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-s"
          onClick={goToEventReport}>
          {event.rating}%
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-ms"
          onClick={goToEventReport}>
          {event.responses}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-ms"
          onClick={goToEventReport}>
          <EventCodeCell>
            {isEventOwner && event.eventCode?.toUpperCase()}
            {isEventOwner && event.lockedOfferCode && (
              <Tooltip
                placement="top"
                title="All responses that use this offer code will always go to this event">
                <LockIcon />
              </Tooltip>
            )}
          </EventCodeCell>
        </TableCell>
      )}
      {!event.isSelected && <ShareCell />}
    </SpeakerTableRow>
  )

  const renderStandardRow = () => (
    <SpeakerTableRow
      key={event.id}
      isPrimeEvent={event.isPrimeEvent}
      eventMergable={eventMergable}
      isEventOwner={isEventOwner}>
      <TableCell className="fixed-w-xs">
        {!isEventOwner ? (
          <Tooltip
            placement="top"
            title="This session is from a talk you gave at an event that was organized by someone else.">
            <SessionFromEventIcon>E</SessionFromEventIcon>
          </Tooltip>
        ) : (
          <Checkbox
            color="primary"
            checked={!!event.isSelected}
            onChange={_handleSelectEvent}
            inputProps={{
              'aria-labelledby': event.id,
            }}
          />
        )}
      </TableCell>
      <TableCell className="fixed-w-xs"></TableCell>
      <TableCell
        align="left"
        className="fixed-w-m"
        onClick={() =>
          handleClick({
            value: event.date,
            allowEditDetails: false,
          })
        }>
        {formatDate(event.date, event.utcOffset)}
      </TableCell>
      <TableCell
        className="fixed-w-l"
        align="left"
        onClick={() =>
          handleClick({
            value: event.eventOrganization,
            allowEditDetails: true,
          })
        }>
        {renderEditableCell({ value: event.eventOrganization })}
      </TableCell>
      <TableCell
        align="left"
        className="fixed-w-l"
        onClick={() =>
          handleClick({
            value: event.talkTitle,
            allowEditDetails: true,
          })
        }>
        {renderEditableCell({
          value: event.talkTitle,
          attributeName: 'talkTitle',
        })}
      </TableCell>
      {event.isSelected && !event.isPrimeEvent && (
        <TableCell align="left" colspan={8} sx={{ textAlign: 'center' }}>
          Merge into selected event
        </TableCell>
      )}
      {event.isSelected && event.isPrimeEvent && (
        <TableCell align="left" colspan={8} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" onClick={handleMergeEventsClick}>
            {mergableEvents.length > 0 ? (
              <b>Click here to merge selected events into this event</b>
            ) : (
              <b>Select other events to merge into this event</b>
            )}
          </Typography>
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell align="left" className="fixed-w-m" onClick={goToEventReport}>
          {event.location}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-s"
          onClick={() =>
            handleClick({
              value: event.compensation,
              allowEditDetails: true,
            })
          }>
          {renderEditableCell({
            value: event.compensation !== null && `$${event.compensation}`,
            buttonText: 'add',
            editAttribute: 'compensation',
          })}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-ms"
          onClick={goToEventReport}>
          {event.reattend}%
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-s"
          onClick={goToEventReport}>
          {event.rating}%
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-ms"
          onClick={goToEventReport}>
          {event.responses}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-s"
          onClick={goToEventReport}>
          {isEventOwner ? event.leads : 'N/A'}
        </TableCell>
      )}
      {!event.isSelected && (
        <TableCell
          align="center"
          className="fixed-w-ms"
          onClick={goToEventReport}>
          <EventCodeCell>
            {isEventOwner && event.eventCode?.toUpperCase()}
            {isEventOwner && event.lockedOfferCode && (
              <Tooltip
                placement="top"
                title="All responses that use this offer code will always go to this event">
                <LockIcon />
              </Tooltip>
            )}
          </EventCodeCell>
        </TableCell>
      )}
      {!event.isSelected && <ShareCell />}
    </SpeakerTableRow>
  )

  const renderRow = () => {
    if (user?.isEventPlanner) {
      return renderEventPlannerRow()
    } else if (selectedGroup) {
      return renderGroupGuestSpeakerRow()
    } else {
      return renderStandardRow()
    }
  }

  return (
    <>
      {renderRow()}
      <ConfirmDialog
        title={confirmProps.title}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmProps.onConfirm}
        buttonColor="primary"
        textColor={theme.palette.base.white}>
        {confirmProps.message}
      </ConfirmDialog>
    </>
  )
}
export default EventListRow
