import { useEffect, useState } from 'react'

import CodeBuilderInputText from './CodeBuilderInputText'
import CodeBuilderInputSelect from './CodeBuilderInputSelect'

import { usePermissionHelper } from '../../../utils/permission_helper'
import { capitalize } from '../../../utils/string'

import Api from '../../../services/api'

import {
  parseEmojiKeywords,
  parseSpeakerName,
  stripHtmlTags,
  stripEmojiTags,
} from '../../../utils/string'

const CodeBuilderSettingsQuestion = ({
  question,
  updateQuestion,
  setCurrentQuestion,
  questionLoading,
  speakerName,
  emojiKeywords,
  setActiveTab,
}) => {
  const [error, setError] = useState(null)
  const [defaultKeywordOptions, setDefaultKeywordOptions] = useState([])
  const [keywordsLoading, setKeywordsLoading] = useState(false)

  const { questionType } = question

  const { canAccessAction } =
    usePermissionHelper()

  useEffect(() => {
    if (questionType === 'EMOJI_SCALE' && !defaultKeywordOptions.length) {
      fetchKeywordOptions()
    }
  }, [questionType])

  const fetchKeywordOptions = async () => {
    try {
      setKeywordsLoading(true)

      const res = await Api.fetchDefaultEmojiKeywords()

      if (!res.errors) {
        const lowerCasedKeywords = res.map((keyword) => ({
          ...keyword,
          keyword: keyword.keyword.toLowerCase(),
        }))

        setDefaultKeywordOptions(lowerCasedKeywords)
        setKeywordsLoading(false)
      }
    } catch (err) {
      setKeywordsLoading(false)
    }
  }

  const textInputValidation = (value) => {
    if (value?.length === 0) {
      return {
        message: 'Value cannot be blank',
        allowContinue: true,
      }
    }

    if (value?.length > 100) {
      return {
        message: 'Value must be 100 characters or less',
        allowContinue: false,
        allowSubmit: true,
      }
    }
  }

  const handleTextInputOnChange = ({ newValue, question }) => {
    setCurrentQuestion({ ...question, content: newValue })
    setActiveTab(1)
  }

  const handleSelectInputOnChange = ({ questionId, newValue }) => {
    updateQuestion(questionId, { replacement_value: newValue })
    setActiveTab(1)
  }

  const renderInput = () => {
    switch (questionType) {
      case 'EMOJI_SCALE':
        const parsedEmojiContent = stripHtmlTags(
          stripEmojiTags(parseSpeakerName(question, speakerName))
        )

        // If this is a custom question with emoji scale type (i.e., a non-talkadot-question)
        // We don't want to render the select options.  Instead we want to let the
        // user just type whatever content they want
        if (!question.config?.isTalkadotQuestion) {
          return (
            <CodeBuilderInputText
              label="Question"
              placeholder="Enter a question"
              value={parsedEmojiContent.content}
              canEdit={question.config.canEditContent && canAccessAction(question.config.editContentTier)}
              error={error}
              setError={setError}
              handleOnChange={(newValue) =>
                handleTextInputOnChange({ newValue, question })
              }
              handleOnBlur={(newValue) =>
                updateQuestion(question.id, { content: newValue })
              }
              inputValidation={textInputValidation}
              disabled={questionLoading}
              autoFocus={true}
            />
          )
        }

        // Filter out the currently selected emoji keywords except for one for the current question
        const availableKeywordOptions = defaultKeywordOptions.filter(
          (keyword) =>
            !emojiKeywords.includes(keyword.keyword) ||
            keyword.keyword === question.replacementValue.toLowerCase()
        )

        return (
          <CodeBuilderInputSelect
            placeholder="Select a keyword"
            value={question.replacementValue.toLowerCase()}
            options={availableKeywordOptions}
            disabled={
              questionLoading ||
              keywordsLoading ||
              !canAccessAction(question.config.editViaDropdownTier)
            }
            canEdit={canAccessAction(question.config.editViaDropdownTier)}
            handleOnChange={(newValue) =>
              handleSelectInputOnChange({
                questionId: question.id,
                newValue: capitalize(newValue),
              })
            }
            parsedEmojiContent={parsedEmojiContent}
          />
        )
      default:
        const parsedContent = stripHtmlTags(
          parseSpeakerName(parseEmojiKeywords(question), speakerName)
        )

        return (
          <CodeBuilderInputText
            label="Question"
            placeholder="Enter a question"
            value={parsedContent.content}
            canEdit={question.config.canEditContent && canAccessAction(question.config.editContentTier)}
            editConfig={question.config.canEditContent}
            questionEnabled={question.enabled}
            error={error}
            setError={setError}
            handleOnChange={(newValue) =>
              handleTextInputOnChange({ newValue, question })
            }
            handleOnBlur={(newValue) =>
              updateQuestion(question.id, { content: newValue })
            }
            inputValidation={textInputValidation}
            disabled={questionLoading}
            autoFocus={true}
          />
        )
    }
  }

  return <>{renderInput()}</>
}

export default CodeBuilderSettingsQuestion
