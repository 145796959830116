import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { IoPencilOutline } from 'react-icons/io5'

const EditEventIcon = ({ existingEvent, handleOpenEventForm }) => {
  return (
    <>
      <MenuItem
        key="1"
        onClick={() => {
          handleOpenEventForm(existingEvent)
        }}>
        <ListItemIcon>
          <IoPencilOutline />
        </ListItemIcon>
        <ListItemText>Edit Event</ListItemText>
      </MenuItem>
    </>
  )
}

export default EditEventIcon
