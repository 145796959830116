import { useRef, useState } from 'react'
import { styled } from '@mui/system'
import { Button, CircularProgress } from '@mui/material'
import { motion } from 'framer-motion/dist/framer-motion'

import SurveyOffersOnboardingHeader from './SurveyOffersOnboardingHeader'
import SpeakerSlide from '../../common/SpeakerSlideResponsive'
import ConfirmDialog from '../../common/ConfirmDialog'

import { ReactComponent as DownloadIcon } from '../../../icons/downloadIcon.svg'

import { downloadSlideAsPng } from '../../common/helpers'

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '2rem',
})

const StyledButton = styled(motion(Button))(({ theme }) => ({
  fontFamily: 'Figtree, sans-serif',
  color: theme.palette.base.white,
  textTransform: 'none',
  fontSize: '1.125rem',
  minWidth: '242px',
  padding: '0.75rem 1rem',
  borderRadius: '5px',
  svg: {
    marginRight: '0.5rem',
    path: {
      stroke: theme.palette.base.white,
    },
  },
}))

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  svg: {
    marginRight: '0',
  },
}))

const SkipButton = styled(Button)({
  textTransform: 'none',
  marginTop: '1rem',
  fontFamily: 'Figtree, sans-serif',
  fontSize: '1rem',
})

const FlexWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const SurveyOffersOnboardingStepSeven = ({
  user,
  advanceLastStep,
  setLoading,
  loading,
  code,
  shortLink,
}) => {
  const [slideDownloaded, setSlideDownloaded] = useState(false)
  const [open, setOpen] = useState(false)
  const slideRef = useRef(null)

  const downloadCallback = () => {
    // add a delay to make sure the file browser has time to open before opening the dialog
    setTimeout(() => {
      setSlideDownloaded(true)
      setLoading(false)
      setOpen(true)
    }, 500)
  }

  const handleDownloadSlide = () => {
    downloadSlideAsPng(
      slideRef,
      `Talkadot-Slide-${shortLink}-${code}`,
      downloadCallback
    )
  }

  const handleButtonClick = () => {
    setLoading(true)
    handleDownloadSlide()
  }

  const handleConfirm = () => {
    advanceLastStep()
  }

  const handleCancel = () => {
    setSlideDownloaded(false)
    setOpen(false)
  }

  const renderButtonContent = () => {
    return (
      <>
        {loading ? (
          <LoadingSpinner size={32} />
        ) : (
          <>
            <DownloadIcon />
            Download my slide!
          </>
        )}
      </>
    )
  }

  const pulsatingAnimation = {
    scale: [1, 1.02, 1],
    transition: {
      repeat: Infinity,
      duration: 1.5,
      ease: 'easeInOut',
    },
  }

  return (
    <>
      <SurveyOffersOnboardingHeader>
        Congrats on making your first slide!
        <br />
        Click on the orange button to download your slide.
      </SurveyOffersOnboardingHeader>
      <FlexWrapper>
        <SpeakerSlide
          avatar={user.avatar}
          surveyLink={user.shortlink}
          socialLinks={user.socialLinks}
          email={user.email}
          name={user.first_name}
          size={'lg'}
          componentRef={slideRef}
          code={code}
        />
      </FlexWrapper>
      {!slideDownloaded && (
        <ButtonContainer>
          <StyledButton
            variant="contained"
            onClick={handleButtonClick}
            disabled={loading}
            color="primary"
            animate={pulsatingAnimation}>
            {renderButtonContent()}
          </StyledButton>
          <SkipButton
            variant="text"
            color="white"
            onClick={() => advanceLastStep()}
            disabled={loading}>
            I'll download it later
          </SkipButton>
        </ButtonContainer>
      )}
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        title="Did you download your slide?"
        buttonText="Yes, let's continue"
        cancelButtonText="No, download my slide"
        cancelButtonTextColor="secondary"
        cancelButtonVariant="text"
        handleConfirm={handleDownloadSlide}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        variant="info"
        buttonColor="primary"
        buttonOrder="confirmFirst"
        textColor="white"
        disableOutsideClick={true}>
        <>
          Make sure you've downloaded your slide so you can collect feedback.
          <br />
          <br />
          Once you've done that, hit continue to go to the next step.
        </>
      </ConfirmDialog>
    </>
  )
}

export default SurveyOffersOnboardingStepSeven
