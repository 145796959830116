import Avatar from '@mui/material/Avatar'
import { styled } from '@mui/material/styles'

import EditAvatar from '../accountSettings/EditAvatar'

import './SpeakerSlide.scss'
import talkadotLogo from '../../icons/talkadot-logo.png'
import { makeSurveyLink } from './helpers'

var QRCode = require('qrcode.react')

const SpeakerAvatar = styled(Avatar)({
  marginRight: '20px',
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  img: {
    width: '102%',
    height: '102%',
  },
})

const SurveyLink = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  fontSize: '1.3125rem',
  fontWeight: 'bold',
  margin: '1rem 0',
}))

const CodeHeader = styled('div')({
  fontSize: '0.875rem',
  fontFamily: 'Sora, sans-serif',
  marginBottom: '0.75rem',
})

const SpeakerSlide = ({
  avatar,
  surveyLink,
  componentRef,
  name,
  size,
  sx,
  code,
  id,
  missingAvatar,
  callback,
  onboardingPlaceholder,
}) => {
  const avatarSize = {
    sm: 40,
    md: 60,
    lg: 84,
    mini: 25,
  }

  const qrCodeSize = {
    sm: 140,
    md: 180,
    lg: 270,
    mini: 90,
  }

  const placeholderCode = onboardingPlaceholder ? 'YOUR CODE HERE' : 'TALK'
  const codeToDisplay = code ? code.toUpperCase() : placeholderCode

  return (
    <div
      className={`speaker-slide ${size}`}
      style={sx}
      id={id}
      ref={componentRef}>
      <div className="speaker-slide__overlay" />
      <div className="header">
        {missingAvatar ? (
          <EditAvatar
            currentAvatar={avatar}
            size={avatarSize[size]}
            inSpeakerSlide={true}
            callback={callback}
          />
        ) : (
          <SpeakerAvatar
            sx={{
              width: avatarSize[size],
              height: avatarSize[size],
            }}
            alt={name}
            src={`${avatar}`}
          />
        )}
        <div style={{ width: '100%' }}>Give feedback to {name}</div>
      </div>
      <div className="content">
        <div className="content__section">
          <div className="content-header">
            <b>Scan this QR code</b>
          </div>
          <div className="qr-code">
            <QRCode
              level={'M'}
              value={makeSurveyLink({
                shortlink: surveyLink,
                code: codeToDisplay,
              })}
              size={qrCodeSize[size]}
            />
          </div>
        </div>
        <div className="content__section">
          <div className="content-header">
            <b>Or go to</b>
          </div>
          <SurveyLink>{makeSurveyLink({ shortlink: surveyLink })}</SurveyLink>
          <div className="code-and-contact-container">
            <CodeHeader>and enter this code when prompted</CodeHeader>
            <div className="offer-code">
              <div className="code-text">{codeToDisplay}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <span>Powered By</span>
        <img src={talkadotLogo} alt="Talkadot!" />
      </div>
    </div>
  )
}

export default SpeakerSlide
