import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Dialog, useTheme, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import EventsParent from '../events/EventsParent'
import Api from '../../services/api'
import { trackFirstEventCreated } from '../../services/metrics'
import { AuthenticationContext } from '../authentication/authenticationContext'
import EventGroupReport from './EventGroupReport'
import EventFormParent from '../events/EventFormParent'

const initialEventState = {
  id: null,
  name: null,
  talkTitle: null,
  inPerson: null,
  virtual: null,
  eventOrganization: null,
  startTime: null,
  duration: null,
  date: null,
  compensation: null,
}

const EventGroupParent = () => {
  const [events, setEvents] = useState([])
  const [event, setEvent] = useState(initialEventState)
  const [eventFormOpen, setEventFormOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { eventGroupId } = useParams()
  const {
    setNotification,
    authData: { user },
  } = useContext(AuthenticationContext)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setIsLoading(true)
        const res = await Api.fetchEventGroup(eventGroupId)
        if (!res.errors) {
          setIsLoading(false)
          setEvents(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
        return setNotification(err, 'error')
      }
    }

    if (eventGroupId) {
      fetchEvents()
    }
  }, [eventGroupId])

  const createEvent = async (params) => {
    try {
      const res = await Api.createEvent(params)

      if (res.errors) {
        throw res.errors
      } else {
        if (events?.length === 0) {
          const params = {
            user_id: user?.id,
            user_email: user?.email,
          }
          trackFirstEventCreated(params)
        }
        setEvents([res, ...events])
      }
    } catch (err) {
      setNotification(err, 'error')
    }
  }

  const editEvent = async (eventId, params) => {
    try {
      const res = await Api.editEvent(eventId, params)

      if (res.errors) {
        throw res.errors
      } else {
        setEvents(events.map((event) => (event.id === res.id ? res : event)))
      }
    } catch (err) {
      setNotification(err, 'error')
    }
  }

  const updateEventSpeakers = (speakers, eventId) => {
    setEvents(
      events.map((event) => {
        if (event.id === eventId) {
          return { ...event, speakers: speakers }
        } else {
          return event
        }
      })
    )
  }

  const handleOpenEventForm = (clickedEvent) => {
    if (clickedEvent) {
      setEvent(clickedEvent)
    }

    setEventFormOpen(true)
  }

  const handleCloseEventForm = () => {
    setEventFormOpen(false)
    setEvent(initialEventState)
  }

  return (
    <div>
      <EventGroupReport eventGroupId={eventGroupId} />
      <EventsParent
        events={events}
        eventsLoading={isLoading}
        setEvents={setEvents}
        eventGroupId={eventGroupId}
        handleOpenEventForm={handleOpenEventForm}
      />
      <Dialog
        open={eventFormOpen}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={() => handleCloseEventForm()}>
        <CloseIcon
          className="modal-close-icon"
          onClick={() => handleCloseEventForm()}
        />
        <EventFormParent
          eventInitialState={event}
          handleClose={handleCloseEventForm}
          updateEventSpeakers={updateEventSpeakers}
          editEvent={editEvent}
          createEvent={createEvent}
          eventGroupId={eventGroupId}
        />
      </Dialog>
    </div>
  )
}

export default EventGroupParent
