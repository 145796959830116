import { useEffect, useState, useRef, useContext } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { motion } from 'framer-motion/dist/framer-motion'
import { styled } from '@mui/system'
import { Dialog, Typography, Skeleton } from '@mui/material'
import { DialogTitle, DialogContent } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'

import TestimonialStatImage from './TestimonialStatImage'
import FeedbackAnswersParent from './FeedbackAnswersParent'
import { Testimonial } from './Testimonial'

const FeedbackItem = styled(motion.div, {
  shouldForwardProp: (prop) =>
    prop !== 'backgroundColour' && prop !== 'feedbackType',
})(({ backgroundColour, feedbackType }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: feedbackType === 'improvement' ? '5px' : '25px',
  alignItems: 'flex-start',
  borderRadius: '10px',
  backgroundColor: backgroundColour,
}))

const MobileImg = styled('img')({
  width: '100%',
  height: 'auto',
  display: 'block',
})

const ReportCardFeedbackList = ({
  sortedFeedback,
  title,
  isLoading,
  subTitle,
  reportData,
  shareKey,
  canShareReport,
  canUseFeedbackActions,
  canFavourite = false,
  feedbackType = null,
  setReportData,
}) => {
  const downloadRef = useRef()

  const [open, setOpen] = useState(false)
  const [testimonialOpen, setTestimonialOpen] = useState(false)
  const [feedbackDataOpen, setFeedbackDataOpen] = useState(false)
  const [favLoadingId, setFavLoadingId] = useState('')
  const [selectedTestimonial, setSelectedTestimonial] = useState(null)
  const [mobileImgSrc, setMobileImgSrc] = useState('')
  const [mobileShareOpen, setMobileShareOpen] = useState(false)
  const [priorityFeedbackItems, setPriorityFeedbackItems] = useState([])
  const [secondaryFeedbackItems, setSecondaryFeedbackItems] = useState([])

  const { showFullPageLoader, hideFullPageLoader, setNotification } =
    useContext(AuthenticationContext)

  useEffect(() => {
    if (sortedFeedback.length > 0) {
      const priorityFeedback =
        feedbackType === 'improvement'
          ? sortedFeedback.slice(0, 3)
          : sortedFeedback.slice(0, 6)
      const secondaryFeedback =
        feedbackType === 'improvement'
          ? sortedFeedback.slice(3, 6)
          : sortedFeedback.slice(6, 12)
      setPriorityFeedbackItems(priorityFeedback)
      setSecondaryFeedbackItems(secondaryFeedback)
    }
  }, [sortedFeedback, feedbackType])

  const handleViewTestimonialData = (feedback) => {
    setSelectedTestimonial(feedback)
    setFeedbackDataOpen(true)
  }

  const handleMobileImageDownload = async ({ selector, feedback }) => {
    const params = {
      social_share_download: {
        share_key: shareKey,
        element_id: selector,
        answer_id: feedback.answer_id,
      },
    }

    showFullPageLoader()

    const res = await Api.getSocialShareImage(params)

    setMobileImgSrc(res)

    hideFullPageLoader()

    setMobileShareOpen(true)
  }

  const handleDownloadTestimonial = async (feedback) => {
    if (isMobileOnly || window.innerWidth < 960) {
      handleMobileImageDownload({
        selector: 'testimonials-share-image',
        feedback,
      })
    } else {
      setSelectedTestimonial(feedback)
      setTestimonialOpen(true)
    }
  }

  const handleToggleFavourite = async (feedback) => {
    try {
      setFavLoadingId(feedback.answer_id)

      const params = {
        answer: {
          id: feedback.answer_id,
          submission_id: feedback.submission_id,
          favourite: feedback.favourite ? !feedback.favourite : true,
        },
      }

      const res = await Api.updateAnswer(params)

      if (!res.errors) {
        setFavLoadingId('')
        setReportData(res)
      } else {
        throw res.errors
      }
    } catch (err) {
      setFavLoadingId('')
      setNotification(err)
    }
  }

  const renderInnerFeedback = (feedbackType, feedback) => {
    return (
      <Testimonial
        feedback={feedback}
        feedbackType={feedbackType}
        canShareReport={canShareReport}
        canUseFeedbackActions={canUseFeedbackActions}
        canFavourite={canFavourite}
        handleDownloadTestimonial={handleDownloadTestimonial}
        handleViewTestimonialData={handleViewTestimonialData}
        handleToggleFavourite={handleToggleFavourite}
        favLoading={favLoadingId === feedback.answer_id}
      />
    )
  }

  const spring = {
    type: 'spring',
    stiffness: 400,
    damping: 30,
  }

  const renderList = (list) => {
    return list.map((feedback, i) => {
      let backgroundColour = 'aliceblue'
      if (i % 2 === 0) {
        backgroundColour = 'lightBlue'
      }
      if (feedbackType === 'improvement') {
        backgroundColour = 'white'
      }

      return (
        <FeedbackItem
          key={feedback.submission_id}
          layout
          backgroundColour={backgroundColour}
          feedbackType={feedbackType}
          transition={spring}>
          {renderInnerFeedback(feedbackType, feedback)}
        </FeedbackItem>
      )
    })
  }

  return (
    <div className="report-card-feedback-list">
      <Typography variant="h6" component="div" sx={{ marginBottom: '20px' }}>
        <b>{title}</b>
        {!isLoading && sortedFeedback?.length > 0 && (
          <Typography
            variant="body2"
            component="span"
            onClick={() => setOpen(true)}
            sx={{
              color: 'blue',
              cursor: 'pointer',
              marginLeft: '7px',
            }}>
            (View All)
          </Typography>
        )}
      </Typography>
      {subTitle && (
        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
          "{subTitle}"
        </Typography>
      )}
      <div className="report-card-feedback-list__content">
        {priorityFeedbackItems.length > 0 && (
          <div className="report-card-feedback-list__content__list">
            {isLoading ? (
              <Skeleton variant="rectangular" width={'50%'} height={50} />
            ) : (
              renderList(priorityFeedbackItems)
            )}
          </div>
        )}
        {secondaryFeedbackItems.length > 0 && (
          <div className="report-card-feedback-list__content__list">
            {isLoading ? (
              <Skeleton variant="rectangular" width={'50%'} height={50} />
            ) : (
              renderList(secondaryFeedbackItems)
            )}
          </div>
        )}
      </div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}>
        <CloseIcon
          className="modal-close-icon"
          onClick={() => setOpen(false)}
        />
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{renderList(sortedFeedback)}</DialogContent>
      </Dialog>

      <Dialog
        open={testimonialOpen}
        maxWidth="md"
        onClose={() => setTestimonialOpen(false)}>
        <CloseIcon
          className="modal-close-icon"
          sx={{ fill: 'white', zIndex: '100' }}
          onClick={() => setTestimonialOpen(false)}
        />
        <TestimonialStatImage
          reportData={reportData}
          shareKey={shareKey}
          customPickedFeedback={[selectedTestimonial]}
          ref={downloadRef}
        />
      </Dialog>

      <Dialog
        open={feedbackDataOpen}
        maxWidth="md"
        onClose={() => setFeedbackDataOpen(false)}>
        <CloseIcon
          className="modal-close-icon"
          sx={{ fill: 'white', zIndex: '100' }}
          onClick={() => setFeedbackDataOpen(false)}
        />
        <FeedbackAnswersParent feedback={selectedTestimonial} />
      </Dialog>
      <Dialog
        open={mobileShareOpen}
        fullwidth="true"
        maxWidth="xl"
        onClose={() => setMobileShareOpen(false)}>
        <DialogContent sx={{ padding: 0 }}>
          {mobileImgSrc && (
            <MobileImg src={`data:image/png;base64,${mobileImgSrc}`} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ReportCardFeedbackList
