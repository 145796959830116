import Api from './api'

export const syncEventLeadsWithSpeakerflow = async ({ event }) => {
  const params = {
    event_leads: {
      event_id: event.id,
    },
  }

  return await Api.syncEventLeadsWithSpeakerflow(params)
}
