export const initialCodesState = [
  {
    name: 'Use this code to skip giving an offer',
    code: 'TALK',
  },
]

export const initialOfferState = {
  id: '',
  link: '',
  name: '',
  code: '',
  fileKey: '',
  downloadUrl: '',
  fileName: '',
  customQuestion: '',
  emojiKeywords: [],
  disableLeadgen: false,
}

export const initialGroupOfferState = {
  ...initialOfferState,
  disableLeadgen: true,
}
