import { useState } from 'react'
import { styled } from '@mui/system'
import { CircularProgress, Typography, Button } from '@mui/material'

import { ReactComponent as EditIcon } from '../../icons/editIcon_16x16.svg'
import speakerflowLogo from '../../icons/speakerflow.png'

import { SocialLinkIcon } from '@talkadot/survey-component-library'
import CopyContent from '../common/CopyContent'

export const EditDetailsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isEditing' && prop !== 'detailType',
})(({ theme, isEditing, detailType }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 3fr auto',
  gridTemplateAreas: `
    "item1 item2 item3"
  `,
  alignItems: 'center',
  padding: '25px 0',
  gap: theme.spacing(1),
  '.full-name-form-container': {
    flex: '1',
  },
  [theme.breakpoints.down('sm')]: {
    ...(isEditing && {
      gridTemplateColumns: 'auto 1fr',
      gridTemplateAreas: `
      "item1 item2"
      ". item3"
      `,
    }),
  },
  [theme.breakpoints.down('md')]: {
    '.integration-image': {
      width: '180px',
    },
    ...(detailType?.toLowerCase().includes('speakerflow') && {
      gridTemplateColumns: '1fr auto',
      gridTemplateAreas: `
        "item1 item1"
        "item2 item3"
      `,
    }),
  },
  '& > :nth-child(1)': {
    gridArea: 'item1',
  },
  '& > :nth-child(2)': {
    gridArea: 'item2',
  },
  '& > :nth-child(3)': {
    gridArea: 'item3',
  },
}))

const EditActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  svg: {
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    div: {
      display: 'none',
    },
  },
}))

export const StyledTitle = styled(Typography)({
  minWidth: '65px',
})

const StyledSocialTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '120px',
  span: {
    marginLeft: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '24px',
    span: {
      display: 'none',
    },
  },
}))

export const StaticDetailValue = styled(Typography)(({ theme }) => ({
  flex: '1',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  alignItems: 'center',
  display: 'flex',
  span: {
    marginRight: theme.spacing(1),
  },
}))

const EditDetails = ({
  handleUpdate,
  detailType,
  detailValue,
  copiable,
  children,
  beforeSubmitValidation,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const handleSubmit = async () => {
    if (beforeSubmitValidation) {
      const { valid } = beforeSubmitValidation()

      // invalid submission, do not change isEditing
      if (!valid) return
    }

    setIsLoading(true)
    await handleUpdate()
    setIsLoading(false)
    setIsEditing(false)
  }

  const renderDetailType = () => {
    if (detailType.toLowerCase().includes('linkedin')) {
      return (
        <StyledSocialTitle variant="body1">
          <SocialLinkIcon socialType="linkedin" />
          <span>{detailType}</span>
        </StyledSocialTitle>
      )
    }

    if (detailType.toLowerCase().includes('twitter')) {
      return (
        <StyledSocialTitle variant="body1">
          <SocialLinkIcon socialType="twitter" />
          <span>{detailType}</span>
        </StyledSocialTitle>
      )
    }

    if (detailType.toLowerCase().includes('instagram')) {
      return (
        <StyledSocialTitle variant="body1">
          <SocialLinkIcon socialType="instagram" />
          <span>{detailType}</span>
        </StyledSocialTitle>
      )
    }

    if (detailType.toLowerCase().includes('youtube')) {
      return (
        <StyledSocialTitle variant="body1">
          <SocialLinkIcon socialType="youtube" />
          <span>{detailType}</span>
        </StyledSocialTitle>
      )
    }

    if (detailType.toLowerCase().includes('speakerflow')) {
      return (
        <img
          className="integration-image"
          src={speakerflowLogo}
          alt="Speakerflow"
        />
      )
    }

    return <StyledTitle variant="body1">{detailType}</StyledTitle>
  }

  const renderStaticData = () => (
    <EditDetailsContainer detailType={detailType}>
      {renderDetailType()}
      <StaticDetailValue variant="body1" component="div">
        <span>{detailValue}</span>
        {copiable && <CopyContent contentToCopy={detailValue} />}
      </StaticDetailValue>
      <EditActionsWrapper onClick={() => setIsEditing(true)}>
        <EditIcon />
        <Typography variant="body2" component="div">
          Edit
        </Typography>
      </EditActionsWrapper>
    </EditDetailsContainer>
  )

  const renderEditableData = () => (
    <EditDetailsContainer isEditing={true} detailType={detailType}>
      {renderDetailType()}
      {children}
      <EditActionsWrapper>
        {isLoading ? (
          <CircularProgress style={{ color: 'orange' }} />
        ) : (
          <>
            <Button variant="text" color="secondary" onClick={handleSubmit}>
              Save
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </>
        )}
      </EditActionsWrapper>
    </EditDetailsContainer>
  )

  return isEditing ? renderEditableData() : renderStaticData()
}

export default EditDetails
