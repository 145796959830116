import { useState, useEffect, useContext } from 'react'

import { styled } from '@mui/system'
import { Tabs, Tab } from '@mui/material'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from './groupContext'

import { GroupsTabPanel } from './GroupsTabPanel'
import { ProfileStatCard } from '../publicProfile/ProfileStatCard'
import { ResponsesStatContent } from '../publicProfile/ResponsesStatContent'
import { ValuableStatContent } from '../publicProfile/ValuableStatContent'
import { ReattendStatContent } from '../publicProfile/ReattendStatContent'
import { SessionsStatContent } from './SessionsStatContent'
import { ProfileStatCardsWrapper } from './ChapterGroupsParent'
import GroupUsersTable from './GroupUsersTable'
import GroupEventsTable from './GroupEventsTable'
import Banner from '../eventReportCards/Banner'

const GroupContainer = styled('div')({
  paddingBottom: '2rem',
})

const GroupHeader = styled('div')({
  display: 'flex',
  fontSize: '2.5rem',
  marginBottom: '1rem',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
})

const GroupTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: '1rem',
  borderBottom: `1px solid ${theme.palette.tertiary.light}`,
}))

const GroupMembersParent = ({ isLoading }) => {
  const [groupUsers, setGroupUsers] = useState(null)
  const [groupUsersLoading, setGroupUsersLoading] = useState(false)
  const [groupEvents, setGroupEvents] = useState(null)
  const [groupEventsLoading, setGroupEventsLoading] = useState(false)
  const [tab, setTab] = useState(0)

  const { setNotification } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)

  useEffect(() => {
    if (!selectedGroup) {
      return
    }

    const fetchGroupUsers = async () => {
      try {
        setGroupUsersLoading(true)
        const res = await Api.getGroupUsers(selectedGroup.id)
        if (!res.errors) {
          setGroupUsers(res)
          setGroupUsersLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setGroupUsersLoading(false)
        setNotification(err)
      }
    }

    const fetchGroupEvents = async () => {
      try {
        setGroupEventsLoading(true)
        const res = await Api.getGroupEvents(selectedGroup.id)

        if (!res.errors) {
          setGroupEvents(res)
          setGroupEventsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setGroupEventsLoading(false)
        setNotification(err)
      }
    }

    if (tab === 0) {
      fetchGroupUsers()
    }

    if (tab === 1) {
      fetchGroupEvents()
    }
  }, [selectedGroup?.id, tab])

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  // TODO: this is not utilizing the loading state properly for the ProfileStatCards
  return (
    <GroupContainer>
      <GroupHeader>
        <Banner
          speaker={{
            fullName: selectedGroup?.name,
            avatar: selectedGroup?.avatar,
            name: selectedGroup?.name,
            description: selectedGroup?.description,
          }}
          isProfile={true}
          type="group"
        />
      </GroupHeader>
      <ProfileStatCardsWrapper>
        <ProfileStatCard type="responses">
          <ResponsesStatContent
            totalResponses={
              selectedGroup?.group_events_aggregate?.totalResponses?.total
            }
            isLoading={isLoading}
          />
        </ProfileStatCard>
        <ProfileStatCard type="valuable">
          <ValuableStatContent
            ratingsBreakdown={
              selectedGroup?.group_events_aggregate?.totalResponses
            }
            isLoading={isLoading}
          />
        </ProfileStatCard>
        <ProfileStatCard type="reattend">
          <ReattendStatContent
            reattendBreakdown={
              selectedGroup?.group_events_aggregate?.reattendBreakdown
            }
            isLoading={isLoading}
          />
        </ProfileStatCard>
        <ProfileStatCard type="sessions">
          <SessionsStatContent
            sessions={selectedGroup?.group_events_aggregate?.totalEvents}
            isLoading={isLoading}
          />
        </ProfileStatCard>
      </ProfileStatCardsWrapper>
      <GroupTabs
        value={tab}
        onChange={handleChangeTab}
        aria-label="group tabs"
        indicatorColor="secondary"
        textColor="secondary">
        <Tab label="Group Users" {...a11yProps(0)} />
        <Tab label="Group User Events" {...a11yProps(1)} />
      </GroupTabs>
      <GroupsTabPanel value={tab} index={0}>
        {/* TODO: add loading spinner */}
        {groupUsersLoading ? (
          'Loading...'
        ) : (
          <GroupUsersTable
            groupUsers={groupUsers}
            groupUsersLoading={groupUsersLoading}
          />
        )}
      </GroupsTabPanel>
      <GroupsTabPanel value={tab} index={1}>
        <GroupEventsTable
          groupEvents={groupEvents}
          groupEventsLoading={groupEventsLoading}
        />
      </GroupsTabPanel>
    </GroupContainer>
  )
}

export default GroupMembersParent
