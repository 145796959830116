import { styled } from '@mui/system'

import { ReactComponent as DisabledBadge } from '../../icons/disabledBadgeIcon.svg'

const Wrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
})

const BadgeWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '-6px',
  right: '-5px',
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  backgroundColor: theme.palette.error.lightest,
}))

const DisabledBadgeIconWrapper = ({ children }) => (
  <Wrapper>
    {children}
    <BadgeWrapper>
      <DisabledBadge />
    </BadgeWrapper>
  </Wrapper>
)

export default DisabledBadgeIconWrapper
