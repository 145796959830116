import { useContext, useEffect, useState } from 'react'

import { Divider, Typography } from '@mui/material'
import {
  AccountSettingsCard,
  AccountSettingsCardColumn,
  AccountSettingsCardInner,
  AccountSettingsCardTitle,
} from './AccountSettingsParent'
import EditAvatar from './EditAvatar'
import EditPasswordForm from './EditPasswordForm'
import ConfirmDialog from '../common/ConfirmDialog'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { OnboardingContext } from '../onboarding/onboardingContext'
import UserAttributeForm from '../onboarding/UserAttributeForm'
import EditDetails from './EditDetails'
import CustomUrlPicker from '../onboarding/CustomUrlPicker'
import {
  validateShortlinkFormat,
  validateEmailFormat,
  validateNameFormat,
} from '../../utils/validators'

import { styled } from '@mui/system'

const AvatarContainer = styled('div')({
  alignSelf: 'flex-start',
  marginTop: '20px',
  marginRight: '25px',
})

const MainUserSettings = () => {
  const {
    submitEmail,
    submitCustomUrl,
    submitFirstName,
    submitLastNameFromAccountForm,
  } = useContext(OnboardingContext)
  const { authData, resetPassword, setProfileAttribute, setNotification } =
    useContext(AuthenticationContext)
  const { user } = authData
  const { isAssociate } = user

  const [originalShortlinkLocked, setOriginalShortlinkLocked] = useState(false)
  const [originalCustomUrl, setOriginalCustomUrl] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    if (user?.shortlink && !originalShortlinkLocked) {
      setOriginalCustomUrl(user.shortlink)
      setOriginalShortlinkLocked(true)
    }
  }, [user])

  const beforeSubmitValidation = (attribute) => {
    let valid = true
    let message = ''

    switch (attribute) {
      case 'shortlink':
        const { valid: shortlinkValid, message: shortlinkMessage } =
          validateShortlinkFormat(user.shortlink)

        valid = shortlinkValid
        message = shortlinkMessage
        break
      case 'email':
        const { valid: emailValid, message: emailMessage } =
          validateEmailFormat(user.email)

        valid = emailValid
        message = emailMessage
        break
      case 'first_name':
      case 'last_name':
        const { valid: nameValid, message: nameMessage } = validateNameFormat(
          user[attribute]
        )

        valid = nameValid
        message = nameMessage
        break
      default:
        break
    }

    if (!valid) {
      setNotification(message)
    }

    return { valid }
  }

  const handleCustomUrlUpdate = () => {
    setConfirmOpen(true)
  }

  const renderPasswordField = () => {
    if (isAssociate) {
      return <></>
    }

    return user?.oauthProviders?.length <= 0 ? (
      <EditDetails
        handleUpdate={resetPassword}
        detailType="Password"
        detailValue="">
        <EditPasswordForm />
      </EditDetails>
    ) : (
      <div className="edit-details__container">
        <Typography
          variant="body1"
          component="div"
          className="edit-details__title">
          Password
        </Typography>
        <Typography
          variant="body1"
          component="div"
          id="password-type"
          sx={{ flex: '0.95', fontWeight: '300' }}>
          You're currently logged in with {user.oauthProviders?.[0]}
        </Typography>
      </div>
    )
  }

  return (
    <>
      <AccountSettingsCard>
        <AccountSettingsCardTitle variant="h5">
          {isAssociate ? 'Account Owner' : 'Your Account'} Settings
        </AccountSettingsCardTitle>
        <AccountSettingsCardInner>
          <AvatarContainer>
            <EditAvatar currentAvatar={user.avatar} />
          </AvatarContainer>
          <AccountSettingsCardColumn>
            <EditDetails
              handleUpdate={submitFirstName}
              detailType="First Name"
              detailValue={user?.first_name}
              beforeSubmitValidation={() =>
                beforeSubmitValidation('first_name')
              }>
              <UserAttributeForm
                formValue={user ? user.first_name : ''}
                formLabel="first name"
                profileAttribute={'first_name'}
              />
            </EditDetails>
            <EditDetails
              handleUpdate={submitLastNameFromAccountForm}
              detailType="Last Name"
              detailValue={user?.last_name}
              beforeSubmitValidation={() =>
                beforeSubmitValidation('last_name')
              }>
              <UserAttributeForm
                formValue={user ? user.last_name : ''}
                formLabel="last name"
                profileAttribute={'last_name'}
              />
            </EditDetails>
            <Divider light />
            {!isAssociate && (
              <EditDetails
                handleUpdate={submitEmail}
                detailType="Email"
                detailValue={user?.email}
                beforeSubmitValidation={() => beforeSubmitValidation('email')}>
                <UserAttributeForm
                  formValue={user ? user.email : ''}
                  formLabel="email"
                  profileAttribute={'email'}
                />
              </EditDetails>
            )}
            <Divider light />
            {renderPasswordField()}
            <Divider light />
            <EditDetails
              handleUpdate={handleCustomUrlUpdate}
              detailType="Talkadot Handle"
              detailValue={`talk.ac/${user?.shortlink}`}
              copiable={true}
              beforeSubmitValidation={() =>
                beforeSubmitValidation('shortlink')
              }>
              <CustomUrlPicker />
            </EditDetails>
          </AccountSettingsCardColumn>
        </AccountSettingsCardInner>
      </AccountSettingsCard>

      <ConfirmDialog
        title="Update Feedback Link"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={submitCustomUrl}
        onCancel={() => {
          setProfileAttribute('shortlink', originalCustomUrl)
        }}>
        <span>Are you sure you want to update your Feedback Link?</span>
        <p>
          If you update your feedback link, make sure to re-download all of your
          slides and QR codes and add them to your presentations!
        </p>
      </ConfirmDialog>
    </>
  )
}

export default MainUserSettings
