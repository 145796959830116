import { useState, useContext } from 'react'

import { styled } from '@mui/system'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from './groupContext'
import Api from '../../services/api'

import GroupsList from './GroupsList'
import PageContainer from '../common/PageContainer'
import FormSection from '../common/FormSection'

const GroupsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  fontFamily: 'Figtree, sans-serif',
})

export const ProfileStatCardsWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(295px, 1fr))',
  gap: '40px',
  marginTop: '40px',
  marginBottom: '50px',
  fontFamily: 'Sora, sans-serif',
})

const HeaderContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'canCreateGroup',
})(({ canCreateGroup }) => ({
  display: 'grid',
  gridTemplateColumns: canCreateGroup ? '1fr 1fr' : '1fr',
}))

const HeaderTextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const HeaderText = styled('div')({
  textAlign: 'left',
  fontSize: '1.5rem',
  fontWeight: 500,
  marginBottom: '1.25rem',
})

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  button: {
    textTransform: 'none',
    fontFamily: 'Figtree, sans-serif',
    fontWeight: 'bold',
    color: theme.palette.base.white,
  },
  span: {
    marginLeft: '1rem',
  },
}))

const initialGroupState = {
  id: null,
  name: '',
  description: '',
  shortlink: null,
  retain_report_access: false,
}

const ChapterGroupsParent = () => {
  const { setNotification, authData } = useContext(AuthenticationContext)
  const {
    groupState: { groups },
    setGroups,
  } = useContext(GroupContext)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [group, setGroup] = useState(initialGroupState)

  const { user } = authData

  const handleClose = () => {
    setOpen(false)
    // timeout to allow the modal to close before resetting the group state
    setTimeout(() => {
      setGroup(initialGroupState)
    }, 100)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setGroup({
      ...group,
      [name]: value,
    })
  }

  const handleApiRequest = async (reqParams) => {
    try {
      setLoading(true)
      if (group?.id) {
        const res = await Api.updateGroup(group.id, reqParams)
        if (!res.errors) {
          setNotification('Chapter updated successfully', 'success')
          setLoading(false)
          setGroup(res)
          setGroups(groups.map((g) => (g.id === res.id ? res : g)))
          // NOTE: closing the modal here feels weird when toggling the report retention switch
          // setOpen(false)
        } else {
          throw res.errors
        }
      } else {
        if (reqParams.group.name) {
          const res = await Api.createGroup(reqParams)
          if (!res.errors) {
            setGroups([...groups, res])
            setLoading(false)
            setOpen(false)
          } else {
            throw res.errors
          }
        }
      }
    } catch (err) {
      setLoading(false)
      setNotification(err)
    }
  }

  const handleGroupSubmit = async (e) => {
    e.preventDefault()
    const reqParams = {
      group: {
        name: group.name,
        description: group.description,
        retain_report_access: group.retain_report_access,
        shortlink: group.shortlink, // ignored by api on update
      },
    }
    handleApiRequest(reqParams)
  }

  const handleDeleteGroup = async (groupId) => {
    try {
      const res = await Api.deleteGroup(groupId)
      if (!res.errors) {
        setGroups(groups.filter((g) => g.id !== groupId))
        setNotification('Chapter deleted successfully', 'success')
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleRemoveFromGroup = async (groupId) => {
    try {
      const params = {
        group_membership: {
          group_id: groupId,
        },
      }
      const res = await Api.deleteGroupMembership(params)
      if (!res.errors) {
        setGroups(groups.filter((g) => g.id !== groupId))
        setNotification('Chapter left successfully', 'success')
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const canCreateGroup = user?.canCreateGroup

  return (
    <PageContainer>
      <GroupsContainer>
        <HeaderContainer canCreateGroup={canCreateGroup}>
          <HeaderTextWrapper>
            <HeaderText>My Chapters</HeaderText>
          </HeaderTextWrapper>
          {/* TODO: Might need to change this based on permssion requirements */}
          {canCreateGroup && (
            <ButtonWrapper>
              <Button
                variant="contained"
                color="primary"
                sx={{ color: 'white' }}
                onClick={() => setOpen(true)}>
                +<span>Add Chapter</span>
              </Button>
            </ButtonWrapper>
          )}
        </HeaderContainer>
        {/* TODO: clean up loading handling */}
        <GroupsList
          groups={groups}
          setGroup={setGroup}
          setOpen={setOpen}
          setSettingsOpen={false}
          groupsLoading={!groups}
          handleDeleteGroup={handleDeleteGroup}
          handleRemoveFromGroup={handleRemoveFromGroup}
        />

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <form onSubmit={handleGroupSubmit}>
            <DialogTitle>
              {group?.id ? 'Edit Chapter Details' : 'Create A New Chapter'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {group?.id
                  ? 'Edit the details of your chapter here.'
                  : 'Use Chapters to organize your guest speaking engagements.'}
              </DialogContentText>
              <FormSection stepNumber="1" heading="Add a name for your Chapter">
                <TextField
                  margin="dense"
                  id="name"
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={group?.name}
                  onChange={handleInputChange}
                />
              </FormSection>
              <FormSection
                stepNumber="2"
                heading="Add a description for your Chapter">
                <TextField
                  margin="dense"
                  id="description"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={group?.description}
                  onChange={handleInputChange}
                />
              </FormSection>
              {!group?.id && (
                <FormSection
                  stepNumber="3"
                  heading="Add a shortlink for your Chapter">
                  <TextField
                    margin="dense"
                    id="shortlink"
                    name="shortlink"
                    label="This is your unique feedback link for this chapter (This cannot be changed)"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={group?.shortlink}
                    onChange={handleInputChange}
                  />
                </FormSection>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ color: 'white' }}
                loading={loading}
                disabled={!group?.name || !group?.shortlink}>
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      </GroupsContainer>
    </PageContainer>
  )
}

export default ChapterGroupsParent
