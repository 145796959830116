import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import FormFieldError from '../../common/FormFieldError'

const Container = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'marginTop' && prop !== 'containerHeight',
})(({ marginTop, containerHeight }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: marginTop || 0,
  height: containerHeight || '100%',
  width: '100%',
}))

const InputContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFocused',
})(({ theme, isFocused }) => ({
  display: 'flex',
  alignItems: 'center',
  border: isFocused
    ? `1px solid ${theme.palette.secondary.main}`
    : `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.shape.borderRadius.xxs,
  transition: '200ms all ease-in-out',
  marginBottom: theme.spacing(1.25),
}))

const InputDescription = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'visibility' && prop !== 'isFocused' && prop !== 'svgFillType',
})(({ theme, visibility, isFocused, svgFillType }) => ({
  display: 'flex',
  visibility: visibility || 'visible',
  justifyContent: 'center',
  alignItems: 'center',
  padding: visibility === 'hidden' ? '0 0.875rem' : '0.875rem',
  backgroundColor: isFocused
    ? theme.palette.secondary.extraLight
    : theme.palette.neutral.extraLight,
  borderTopLeftRadius: theme.shape.borderRadius.xxs,
  borderBottomLeftRadius: theme.shape.borderRadius.xxs,
  minHeight: visibility === 'hidden' ? '0' : '48px',
  minWidth: '90px',
  p: {
    color: isFocused
      ? theme.palette.secondary.main
      : theme.palette.neutral.main,
  },
  svg: {
    path: {
      fill:
        isFocused && svgFillType === 'fill' ? theme.palette.secondary.main : '',
      stroke:
        isFocused && svgFillType === 'stroke'
          ? theme.palette.secondary.main
          : '',
    },
  },
  transition: '200ms all ease-in-out',
}))

const InputWrapper = styled('div')(({ theme, canEdit }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  height: '100%',
  backgroundColor: canEdit
    ? theme.palette.base.white
    : theme.palette.surface.subtle,
  borderTopRightRadius: theme.shape.borderRadius.xxs,
  borderBottomRightRadius: theme.shape.borderRadius.xxs,
}))

const HintContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const InputHint = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
  padding: theme.spacing(0, 1.25, 1.25, 1.25),
}))

const CodeBuilderInputWrapper = ({
  children,
  hint,
  inputDescription,
  marginTop,
  containerHeight,
  error,
  canEdit = false,
  isFocused,
}) => {
  const renderInputDescription = () => {
    if (inputDescription) {
      return inputDescription
    }

    return <Typography variant="body1">Question</Typography>
  }

  const renderHint = () => {
    return (
      hint && (
        <HintContainer>
          <InputDescription visibility="hidden">
            {renderInputDescription()}
          </InputDescription>
          <InputHint variant="caption">{hint}</InputHint>
        </HintContainer>
      )
    )
  }

  return (
    <Container marginTop={marginTop} containerHeight={containerHeight}>
      <InputContainer isFocused={isFocused}>
        <InputDescription
          isFocused={isFocused}
          svgFillType={inputDescription?.props?.fillType}>
          {renderInputDescription()}
        </InputDescription>
        <InputWrapper canEdit={canEdit}>{children}</InputWrapper>
      </InputContainer>
      {renderHint()}
      {error && <FormFieldError errorMsg={error.message} allowClose={false} />}
    </Container>
  )
}

export default CodeBuilderInputWrapper
