import { useContext } from 'react'

import { Typography } from '@mui/material'
import {
  AccountSettingsCard,
  AccountSettingsCardColumn,
  AccountSettingsCardInner,
  AccountSettingsCardTitle,
} from './AccountSettingsParent'
import { AuthenticationContext } from '../authentication/authenticationContext'

import EditDetails, {
  EditDetailsContainer,
  StaticDetailValue,
} from './EditDetails'

import UpgradeModalLauncher from '../common/UpgradeModalLauncher'

import UserAttributeForm from '../onboarding/UserAttributeForm'

const ConfigurationOptions = () => {
  const { authData, canShowBookingLink, submitBookingLink } = useContext(
    AuthenticationContext
  )
  const { user } = authData

  const renderShowBookingLink = () => {
    if (canShowBookingLink) {
      return (
        <EditDetails
          id="booking-link"
          handleUpdate={submitBookingLink}
          detailType="Calendar link"
          detailValue={user?.booking_link}>
          <UserAttributeForm
            formValue={user ? user.booking_link : ''}
            formLabel="Calendar link - keep blank if you don't want to use this feature"
            profileAttribute={'booking_link'}
          />
        </EditDetails>
      )
    } else {
      return (
        <EditDetailsContainer>
          <Typography variant="body1" id="booking-link">
            Calendar link
          </Typography>
          <StaticDetailValue variant="body1" component="div">
            <span>
              Add Your Booking Link To The End Of Your Talkadot Survey!
            </span>
            <UpgradeModalLauncher
              content="Upgrade To Add"
              variant="outlined"
              upgradeHelperText="Upgrade To Add Your Calendar Link To Your Talkadot Flow!"
              permissionType={null}
            />
          </StaticDetailValue>
          <div style={{ width: '58px' }} />
        </EditDetailsContainer>
      )
    }
  }

  return (
    <AccountSettingsCard>
      <AccountSettingsCardTitle variant="h5">
        {/* Note - since we've removed talkadot branding as an option, 
            this is all that lives in this section. Renaming it to be more clear */}
        Calendar Link Options
      </AccountSettingsCardTitle>
      <AccountSettingsCardInner>
        <AccountSettingsCardColumn>
          {renderShowBookingLink()}
        </AccountSettingsCardColumn>
      </AccountSettingsCardInner>
    </AccountSettingsCard>
  )
}

export default ConfigurationOptions
