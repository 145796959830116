import React, { useState, useEffect } from 'react'
import { Input, InputAdornment, LinearProgress } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { styled } from '@mui/system'

import { debounce } from '@mui/material/utils'

const AdminSearchInputContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '50%',
  margin: '0 auto 20px auto',
})

const AdminSearchInputBar = styled(Input)({
  width: '100%',
  fontSize: '20px',
  marginTop: '20px',
})

const LinearProgressContainer = styled('div')({
  minHeight: '5px',
})

const makeSearch = async (searchTerm, fetchUsers, setIsLoading) => {
  setIsLoading(true)
  await fetchUsers(searchTerm)
  setIsLoading(false)
}

const debouncedMakeSearch = debounce(makeSearch, 1000)

const AdminSearchBar = ({ fetchUsers }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    debouncedMakeSearch(searchTerm, fetchUsers, setIsLoading)
  }, [searchTerm, debouncedMakeSearch])

  const handleChange = (value) => {
    setSearchTerm(value)
  }

  return (
    <AdminSearchInputContainer>
      <AdminSearchInputBar
        placeholder="Enter a name, email or shortlink..."
        value={searchTerm}
        onChange={(e) => handleChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <CancelIcon onClick={() => handleChange('')} fontSize="1rem" />
          </InputAdornment>
        }
      />
      <LinearProgressContainer>
        {isLoading && <LinearProgress color="primary" />}
      </LinearProgressContainer>
    </AdminSearchInputContainer>
  )
}

export default AdminSearchBar
