import React, { useEffect, useState, useContext } from 'react'
import { AuthenticationContext } from '../authentication/authenticationContext'
import Api from '../../services/api'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
  TableContainer,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  IconButton,
  Paper,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { IoEllipsisHorizontal } from 'react-icons/io5'
import VisibilityIcon from '@mui/icons-material/Visibility'

import CreateEventGroupButton from './CreateEventGroupButton'
import EditEventGroupButton from './EditEventGroupButton'
import EventActivationSwitch, { BoldBlue } from './EventActivationSwitch'

const EventGroupListContainer = styled('div')({
  paddingBottom: '30px',
})

const HoverRow = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.04)',
  },
})

const EventGroupListTableTabContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '50px',
  borderBottom: '1px solid lightgrey',
})

const TabHeader = styled(Typography)(({ theme }) => ({
  background: theme.palette.tabBlue.main,
  color: 'white',
  fontWeight: 'bold',
  padding: '10px 20px',
  borderRadius: '0px 15px 0px 0px',
}))

const StyledTableContainer = styled(TableContainer)({
  maxHeight: '80vh',
})

const StyledTableCell = styled(TableCell)({
  background: 'skyBlue',
  fontWeight: 'bold',
})

const EventGroupList = () => {
  const [eventGroups, setEventGroups] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { setNotification, authData } = useContext(AuthenticationContext)
  const history = useHistory()

  useEffect(() => {
    const getEventGroups = async () => {
      try {
        setIsLoading(true)
        const res = await Api.fetchAllEventGroups()

        if (!res.errors) {
          setEventGroups(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
        return setNotification(err, 'error')
      }
    }

    getEventGroups()
  }, [])

  const renderSkeletonTable = () => {
    return [1, 2, 3, 4, 5].map((i) => {
      return (
        <TableRow key={i}>
          <TableCell colSpan={13}>
            <Skeleton />
          </TableCell>
        </TableRow>
      )
    })
  }

  const ShareCell = ({ eventGroup }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleOpenDropdown = (e) => {
      setAnchorEl(e.currentTarget)
    }
    const handleCloseDropdown = () => {
      setAnchorEl(null)
    }

    return (
      <TableCell className="fixed-w-s" align="center">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenDropdown}
          aria-label="Open to show more"
          title="Open to show more">
          <IoEllipsisHorizontal />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <MenuItem key="0" onClick={() => goToEventGroup(eventGroup.id)}>
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText>View Event</ListItemText>
          </MenuItem>
          <EditEventGroupButton
            eventGroup={eventGroup}
            eventGroups={eventGroups}
            setEventGroups={setEventGroups}
          />
        </Menu>
      </TableCell>
    )
  }

  const HEADER_CELLS = [
    {
      id: 'activate-event-placeholder',
      cx: 'fixed-w-xs',
      label: null,
      numeric: false,
      sortable: false,
    },
    {
      id: 'date',
      cx: 'fixed-w-m',
      label: 'Date',
      numeric: false,
      sortable: true,
      align: 'left',
    },
    {
      id: 'event-name',
      cx: 'fixed-w-l',
      label: 'Event',
      numeric: false,
      sortable: false,
      align: 'left',
    },
    {
      id: 'location',
      cx: 'fixed-w-l',
      label: 'Location',
      numeric: false,
      sortable: false,
      align: 'left',
    },
    // TODO:
    // To implement in later iteration
    // {
    //   id: 'attendance',
    //   cx: 'fixed-w-ms',
    //   label: 'Attendance',
    //   numeric: false,
    //   sortable: false,
    //   align: 'left',
    // },
    // {
    //   id: 'reattend',
    //   cx: 'fixed-w-ms',
    //   label: 'Reattend',
    //   sortable: true,
    //   numeric: true,
    //   tooltip:
    //     'Percent who answered positively to if they would attend another one of your talks.',
    //   align: 'center',
    // },
    // {
    //   id: 'rating',
    //   cx: 'fixed-w-s',
    //   label: 'Rating',
    //   sortable: true,
    //   numeric: true,
    //   tooltip: 'Percent who answered yes to if they found your talk valuable.',
    //   align: 'center',
    // },
    {
      id: 'sessions',
      cx: 'fixed-w-ms',
      label: 'Sessions',
      sortable: true,
      numeric: true,
      tooltip: 'Total number of sessions at this event.',
      align: 'center',
    },
    {
      id: 'speakers',
      cx: 'fixed-w-ms',
      label: 'Speakers',
      sortable: true,
      numeric: true,
      tooltip: 'Total number of speakers at this event.',
      align: 'center',
    },
    {
      id: 'actions',
      cx: 'fixed-w-s',
      label: 'Actions',
      sortable: false,
      numeric: false,
      align: 'center',
    },
  ]

  const goToEventGroup = (eventGroupId) => {
    return history.push(`/event-planner/event-groups/${eventGroupId}`)
  }

  const renderHeaderCellContent = (headCell) => {
    if (headCell.tooltip) {
      return (
        <Tooltip placement="top" title={headCell.tooltip}>
          <span>{headCell.label}</span>
        </Tooltip>
      )
    } else {
      return <span>{headCell.label}</span>
    }
  }

  const renderTableHeader = () => (
    <TableRow>
      {HEADER_CELLS.map((headCell) => (
        <StyledTableCell
          className={headCell.cx}
          key={headCell.id}
          align={headCell.align ? headCell.align : 'center'}>
          {renderHeaderCellContent(headCell)}
        </StyledTableCell>
      ))}
    </TableRow>
  )

  const renderEventGroupShortlink = (shortlink) => (
    <BoldBlue>({shortlink?.toUpperCase()})</BoldBlue>
  )

  const renderTableData = () => {
    return eventGroups.map((eventGroup, i) => {
      return (
        <HoverRow key={i}>
          <TableCell className="fixed-w-xs">
            <EventActivationSwitch
              eventGroup={eventGroup}
              setEventGroups={setEventGroups}
              eventGroups={eventGroups}
              setNotification={setNotification}
              mainShortlink={authData?.user?.shortlink}
            />
          </TableCell>
          <TableCell
            className="fixed-w-m"
            onClick={() => goToEventGroup(eventGroup.id)}>
            {eventGroup.date}
          </TableCell>
          <TableCell
            className="fixed-w-l"
            onClick={() => goToEventGroup(eventGroup.id)}>
            {eventGroup.name}
          </TableCell>
          <TableCell
            className="fixed-w-l"
            onClick={() => goToEventGroup(eventGroup.id)}>
            {(eventGroup.inPerson || eventGroup.virtual) &&
            eventGroup.inPerson ? (
              <span>
                In Person{' '}
                {eventGroup.shortlink &&
                  renderEventGroupShortlink(eventGroup.shortlink)}
              </span>
            ) : (
              <span>
                Virtual{' '}
                {eventGroup.shortlink &&
                  renderEventGroupShortlink(eventGroup.shortlink)}
              </span>
            )}
          </TableCell>
          <TableCell
            className="fixed-w-ms"
            align="center"
            onClick={() => goToEventGroup(eventGroup.id)}>
            {eventGroup.sessions}
          </TableCell>
          <TableCell
            className="fixed-w-ms"
            align="center"
            onClick={() => goToEventGroup(eventGroup.id)}></TableCell>
          <ShareCell eventGroup={eventGroup} />
        </HoverRow>
      )
    })
  }

  const renderTable = () => (
    <StyledTableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>{renderTableHeader()}</TableHead>
        <TableBody>
          {isLoading ? renderSkeletonTable() : renderTableData()}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )

  return (
    <EventGroupListContainer>
      <EventGroupListTableTabContainer>
        <TabHeader variant="h5">All Events</TabHeader>
        <CreateEventGroupButton
          eventGroups={eventGroups}
          setEventGroups={setEventGroups}
        />
      </EventGroupListTableTabContainer>
      {renderTable()}
    </EventGroupListContainer>
  )
}

export default EventGroupList
