import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import { TextField } from '@mui/material'
import { ClickAwayListener } from '@mui/base'
import { motion } from 'framer-motion/dist/framer-motion'

import { IoPencil } from 'react-icons/io5'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'

import { ProfileSectionHeader } from './ProfileSectionHeader'

const BiographySectionContainer = styled('div')(({ theme }) => ({
  // NOTE: can't quite match the design here, because of the page padding
  // background: theme.palette.tertiary.extraLight,
  // padding: '20px 0',
  maxWidth: '800px',
}))

const BiographyWrapper = styled('div')({
  marginTop: '18px',
  marginBottom: '40px',
  fontFamily: 'Sora, sans-serif',
  textarea: {
    fontFamily: 'Sora, sans-serif',
    fontSize: '0.875rem',
    padding: '13.5px 12px',
    lineHeight: '1.875rem',
    fontWeight: 400,
  },
})

const PlaceHolderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.secondary.extraLight,
  padding: '26px',
  marginTop: '60px',
  cursor: 'pointer',
  maxWidth: '500px',
  'div:first-child': {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
}))

const BiographyTextWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  textAlign: 'left',
  padding: '30px',
  maxHeight: '510px',
  overflowY: 'scroll',
  background: theme.palette.tertiary.extraLight,
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.875rem',
  whiteSpace: 'pre-line',
}))

const IconWrapper = styled(motion.div)({
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
})

const EditIcon = styled(IoPencil)(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.palette.secondary.main,
}))

const MAX_BIOGRAPHY_LENGTH = 700
// const CHARACTERS_PER_LINE = 100
// const MAX_LINES = 11

const ProfileBiography = ({ authUser, speaker }) => {
  const { setNotification } = useContext(AuthenticationContext)
  const [isEditing, setIsEditing] = useState(false)
  const [biography, setBiography] = useState(speaker?.biography || '')

  const isProfileOwner = authUser?.id === speaker?.id && !authUser?.isAssociate

  const handleClickAway = async () => {
    const params = {
      profile: { biography },
    }

    try {
      const res = await Api.updateProfile(params)
      if (!res.errors) {
        setIsEditing(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleTextClick = () => {
    if (isProfileOwner) {
      setIsEditing(true)
    }

    return
  }

  // const linesRemaining = () => {
  //   const newLineSplit = biography.split('\n')

  //   let numberOfLines = 0

  //   newLineSplit.forEach((line) => {
  //     numberOfLines += Math.ceil(line.length / CHARACTERS_PER_LINE)

  //     if (line.length === 0) {
  //       numberOfLines += 1
  //     }
  //   })

  //   return MAX_LINES - numberOfLines + 1
  // }

  // const lastLineLengthRemaining = () => {
  //   const newLineSplit = biography.split('\n')
  //   const lastLine = newLineSplit[newLineSplit.length - 1]

  //   return CHARACTERS_PER_LINE - lastLine.length
  // }

  // const isDeleting = (e) => {
  //   if (e.target.value.length < biography.length) {
  //     return true
  //   }
  // }

  // const handleBiographyChange = (e) => {
  //   if (
  //     linesRemaining(e) > 0 ||
  //     (linesRemaining() === 0 && lastLineLengthRemaining() >= 0) ||
  //     isDeleting(e)
  //   ) {
  //     setBiography(e.target.value)
  //   }
  // }

  let charactersRemaining =
    MAX_BIOGRAPHY_LENGTH - biography.length > 0
      ? MAX_BIOGRAPHY_LENGTH - biography.length
      : 0

  const renderPlaceholder = () => (
    <PlaceHolderContainer onClick={() => setIsEditing(true)}>
      <div>Add your bio, or more information about you here!</div>
      <div>Tap anywhere to start.</div>
    </PlaceHolderContainer>
  )

  const renderBiographyText = () => {
    if (!biography && isProfileOwner) {
      return renderPlaceholder()
    }
    return (
      <BiographyTextWrapper onClick={handleTextClick}>
        {isProfileOwner && (
          <IconWrapper
            whileHover={{ scale: 1.05, transition: { duration: 0.05 } }}
            whileTap={{ scale: 0.95 }}>
            <EditIcon />
          </IconWrapper>
        )}
        {biography}
      </BiographyTextWrapper>
    )
  }

  const renderBiographyTextArea = () => {
    if (isEditing) {
      return (
        <ClickAwayListener onClickAway={handleClickAway}>
          {/* TODO: Set character limit? Gets complicated with handling line breaks. */}
          <TextField
            value={biography}
            color="secondary"
            helperText={`${charactersRemaining} characters remaining`}
            // helperText={`${linesRemaining()} lines remaining`}
            fullWidth
            multiline
            minRows={8}
            maxRows={15}
            placeholder="Add your bio, or more information about you here!"
            onChange={(e) => setBiography(e.target.value)}
            // onChange={(e) => handleBiographyChange(e)}
            onBlur={handleClickAway}
            inputProps={{ maxLength: MAX_BIOGRAPHY_LENGTH }}
          />
        </ClickAwayListener>
      )
    }
    return renderBiographyText()
  }

  const renderBiographyContent = () => {
    if (isProfileOwner) {
      return renderBiographyTextArea()
    }
    return renderBiographyText()
  }

  const headerText = `About ${speaker?.name}`

  return !isProfileOwner && !speaker?.biography ? (
    <></>
  ) : (
    <BiographySectionContainer>
      <ProfileSectionHeader headerText={headerText} />
      <BiographyWrapper>{renderBiographyContent()}</BiographyWrapper>
    </BiographySectionContainer>
  )
}

export default ProfileBiography
