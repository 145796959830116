import { useState } from 'react'
import { Notification } from './Notification'
import { Collapse } from '@mui/material'

const FormFieldError = ({ errorMsg, allowClose }) => {
  const [open, setOpen] = useState(true)
  return (
    <Collapse in={open}>
      <Notification
        variant={'redWarning'}
        hideClose={!allowClose}
        onClose={allowClose ? () => setOpen(false) : null}>
        {errorMsg}
      </Notification>
    </Collapse>
  )
}

export default FormFieldError
