import { Typography } from '@mui/material'

import {
  StyledHeading,
  StyledPaper,
  StyledSubheading,
  StyledCodeBlock,
} from './ApiDocumentationParent'

const AuthDocumentation = () => {
  return (
    <StyledPaper>
      <StyledHeading variant="h4">OAuth Controller API</StyledHeading>

      <Typography variant="body1" paragraph>
        This API provides OAuth endpoints for user authorization and token
        management.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Base URL:</strong> <code>/api/v1/oauth</code>
      </Typography>

      <StyledSubheading variant="h6">Endpoints</StyledSubheading>

      {/* Repeat this pattern for each section and endpoint */}
      <StyledSubheading variant="subtitle1">1. Authorize User</StyledSubheading>

      <Typography variant="body1" paragraph>
        <strong>POST /authorize</strong>
      </Typography>

      <Typography variant="body1" paragraph>
        Authorize a user and obtain an authorization code for OAuth token
        exchange.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Parameters:</strong>
        <ul>
          <li>
            <code>client_id</code> (string, required): The ID of the OAuth
            client.
          </li>
          <li>
            <code>redirect_uri</code> (string, required): The URI to redirect
            after authorization.
          </li>
          <li>
            <code>state</code> (string): An optional state parameter for
            security.
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Request:</strong>
        <StyledCodeBlock>
          {`{
  "client_id": "your_client_id",
  "redirect_uri": "https://your-redirect-uri.com",
  "state": "optional_state_parameter"
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Response:</strong>
        <StyledCodeBlock>
          {`{
  "code": "authorization_code"
}`}
        </StyledCodeBlock>
      </Typography>

      <StyledSubheading variant="subtitle1">2. Token Exchange</StyledSubheading>

      <Typography variant="body1" paragraph>
        <strong>POST /token</strong>
      </Typography>

      <Typography variant="body1" paragraph>
        Exchange an authorization code for an access token.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Parameters:</strong>
        <ul>
          <li>
            <code>client_id</code> (string, required): The ID of the OAuth
            client.
          </li>
          <li>
            <code>code</code> (string, required): The authorization code
            obtained during authorization.
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Request:</strong>
        <StyledCodeBlock>
          {`{
  "client_id": "your_client_id",
  "code": "authorization_code"
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Response:</strong>
        <StyledCodeBlock>
          {`{
  "access_token": "encrypted_access_token",
  refresh_token: "encrypted_refresh_token",
  "token_type": "bearer",
  "expires_in": 3600
}`}
        </StyledCodeBlock>
      </Typography>

      <StyledSubheading variant="subtitle1">3. Refresh Token</StyledSubheading>

      <Typography variant="body1" paragraph>
        <strong>POST /refresh_token</strong>
      </Typography>

      <Typography variant="body1" paragraph>
        Refresh an expired access token.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Parameters:</strong>
        <ul>
          <li>
            <code>refresh_token</code> (string, required): The encrypted refresh
            token.
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Request:</strong>
        <StyledCodeBlock>
          {`{
  "refresh_token": "encrypted_refresh_token"
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Response:</strong>
        <StyledCodeBlock>
          {`{
  "access_token": "new_encrypted_access_token",
  "refresh_token": "new_encrypted_refresh_token",
  "token_type": "bearer",
  "expires_in": 3600
}`}
        </StyledCodeBlock>
      </Typography>

      <StyledSubheading variant="subtitle1">4. Get User Info</StyledSubheading>

      <Typography variant="body1" paragraph>
        <strong>GET /user</strong>
      </Typography>

      <Typography variant="body1" paragraph>
        Get information about the authenticated user.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Response:</strong>
        <StyledCodeBlock>
          {`{
  "email": "user@example.com"
}`}
        </StyledCodeBlock>
      </Typography>

      {/* Repeat the above pattern for other endpoints */}

      {/* Add error responses section */}
      <StyledSubheading variant="h6">Error Responses</StyledSubheading>

      <Typography variant="body1" paragraph>
        <strong>400 Bad Request:</strong> Missing or invalid parameters.
        <StyledCodeBlock>
          {`{
  "error": "invalid_grant",
  "error_description": "Invalid authorization code"
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>401 Unauthorized:</strong> Invalid client credentials.
        <StyledCodeBlock>
          {`{
  "error": "unauthorized_client",
  "error_description": "Invalid client credentials"
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>403 Forbidden:</strong> Insufficient permissions.
        <StyledCodeBlock>
          {`{
  "error": "forbidden",
  "error_description": "Sorry, you need to upgrade your membership to use this feature."
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>500 Internal Server Error:</strong> Something went wrong on our
        server.
        <StyledCodeBlock>
          {`{
  "error": "server_error",
  "error_description": "Something went wrong"
}`}
        </StyledCodeBlock>
      </Typography>

      {/* Repeat the above pattern for other error responses */}
    </StyledPaper>
  )
}

export default AuthDocumentation
