import { styled } from '@mui/system'
import { Skeleton } from '@mui/material'
import { FaUsers } from 'react-icons/fa'

const ResponseStatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  padding: '14px 0 14px 45px',
})

const StatWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
})

const ResponsesIcon = styled(FaUsers)({
  fontSize: '3.625rem',
})

const ResponseCount = styled('div')({
  fontSize: '2.8125rem',
  marginLeft: '10px',
  fontWeight: 500,
  fontFamily: 'Sora, sans-serif',
})

const StatText = styled('div')({
  textAlign: 'left',
  fontFamily: 'Sora, sans-serif',
})

export const ResponsesStatContent = ({ totalResponses, isLoading }) => {
  return isLoading ? (
    <Skeleton width={200} height={30} />
  ) : (
    <ResponseStatContainer>
      {totalResponses > 0 && (
        <>
          <StatWrapper>
            <ResponsesIcon />
            <ResponseCount>{totalResponses?.toLocaleString()}</ResponseCount>
          </StatWrapper>
          <StatText>Responses from attendees</StatText>
        </>
      )}
    </ResponseStatContainer>
  )
}
