import React, { useState } from 'react'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Switch,
} from '@mui/material'
import Api from '../../services/api'
import { styled } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'

export const BoldBlue = styled('span')(({ theme }) => ({
  color: theme.palette.tabBlue.main,
  fontWeight: 'bold',
}))

const EventActivationSwitch = ({
  eventGroup,
  eventGroups,
  setEventGroups,
  setNotification,
  mainShortlink,
}) => {
  const [alertOpen, setAlertOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleAssignShortlink = async () => {
    try {
      setIsLoading(true)
      const res = await Api.assignEventGroupShortlink(eventGroup.id)

      if (!res.errors) {
        setEventGroups(res)
        setIsLoading(false)
        setAlertOpen(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setAlertOpen(false)
      setIsLoading(false)
      return setNotification(err, 'error')
    }
  }

  const handleUnassignShortlink = async () => {
    try {
      setIsLoading(true)
      const res = await Api.unassignEventGroupShortlink(eventGroup.id)

      if (!res.errors) {
        setAlertOpen(false)
        setIsLoading(false)
        setEventGroups(eventGroups.map((eg) => (eg.id === res.id ? res : eg)))
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsLoading(false)
      setAlertOpen(false)
      setNotification(err, 'error')
    }
  }

  const toggleEventGroupShortlink = async () => {
    if (!eventGroup.shortlink) {
      await handleAssignShortlink()
    } else {
      await handleUnassignShortlink()
    }
  }

  return (
    <div>
      <Switch
        checked={!!eventGroup.shortlink}
        onChange={() => setAlertOpen(true)}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={alertOpen}
        onClose={() => setAlertOpen(false)}>
        <DialogTitle>
          {eventGroup.shortlink ? (
            <div>Stop showing this event?</div>
          ) : (
            <div>Make this the primary event?</div>
          )}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {eventGroup.shortlink ? (
              <div>
                This event list will no longer show up when attendees go to your{' '}
                <BoldBlue>talk.ac/{mainShortlink}</BoldBlue> link.
              </div>
            ) : (
              <div>
                This is the event list attendees will see when they go to your{' '}
                <BoldBlue>talk.ac/{mainShortlink}</BoldBlue> link.
              </div>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={toggleEventGroupShortlink}
            sx={{ color: 'white' }}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EventActivationSwitch
