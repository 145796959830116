import { useRef } from 'react'
import { styled } from '@mui/system'
import QRCode from 'qrcode.react'

import { Typography } from '@mui/material'

import { ReactComponent as DownloadIcon } from '../../icons/downloadIcon.svg'

import { downloadShareCodeAsPng } from '../common/helpers'

const MainContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2.5),
}))

const QrCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadius.sm,
  boxShadow: theme.shape.boxShadow.xs,
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    boxShadow: 'none',
    padding: theme.spacing(0),
  },
}))

const QrDownloadWrapper = styled('div')(({ theme }) => ({
  width: '210px',
  height: '210px',
}))

const QrActions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

const ScanCodeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
  marginRight: 'auto',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const DownloadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
}))

const QrParent = ({ referralLink }) => {
  const qrRef = useRef()

  const handleDownload = () => {
    downloadShareCodeAsPng(qrRef, 'Talkadot-Share-Code')
  }

  return (
    <MainContentContainer>
      <QrCard>
        <QrDownloadWrapper ref={qrRef}>
          <QRCode value={referralLink} size={210} level="H" />
        </QrDownloadWrapper>
      </QrCard>
      <QrActions>
        <ScanCodeText variant="body1">Scan Code to Share</ScanCodeText>
        <DownloadIcon onClick={handleDownload} style={{ cursor: 'pointer' }} />
        <DownloadText onClick={handleDownload}>Download</DownloadText>
      </QrActions>
    </MainContentContainer>
  )
}

export default QrParent
