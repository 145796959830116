import { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/system'
import {
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Popover,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
  Tooltip,
  Checkbox,
} from '@mui/material'

import { debounce } from 'lodash'
import { isMobileOnly } from 'react-device-detect'

import FileUploadIcon from '@mui/icons-material/FileUpload'
import CloseIcon from '@mui/icons-material/Close'

import Api from '../../services/api'
import { trackFirstOfferCreated } from '../../services/metrics'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'

import {
  usePermissionHelper,
  CUSTOMIZATIONS,
} from '../../utils/permission_helper'

import SurveyOffersSlide from './SurveyOffersSlide'
import EmojiKeywordSelect from './EmojiKeywordSelect'

import FormSection from '../common/FormSection'
import FormFieldError from '../common/FormFieldError'
import ConfirmDialog from '../common/ConfirmDialog'

import * as Helpers from '../common/helpers'
import { humanizeError } from '../../utils/errorFormatter'

import { device } from '../../responsive'

const NavBanner = styled('div')(({ theme }) => ({
  color: theme.palette.base.white,
  fontSize: '1.25rem',
  fontWeight: 'bold',
  display: 'grid',
  gridTemplateColumns: '3fr 1fr',
}))

const NavBannerLink = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'disabled',
})(({ theme, active, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '1rem 1.5rem',
  cursor: disabled ? 'not-allowed' : 'pointer',
  background: active ? theme.palette.primary.main : theme.palette.primary.light,
  [`@media ${device.mobile}`]: {
    paddingRight: '2.5rem',
  },
}))

const Heading = styled(Typography)({
  [`@media ${device.mobile}`]: {
    fontSize: '1.8rem',
  },
})

const Subheading = styled(Typography)({
  marginTop: '0.875rem',
})

const OptionalSettingsContainer = styled('div')({
  position: 'relative',
})

const FormDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== 'minHeight',
})(({ minHeight }) => ({
  padding: '0px',
  minHeight: minHeight ? minHeight : '60vh',
}))

const IframeContainer = styled('div')({
  width: '360px',
  height: '560px',
  overflow: 'hidden',
})

const StyledIframe = styled('iframe')({
  width: '450px',
  height: '700px',
  border: 'none',
  transformOrigin: 'top left',
  transform: 'scale(0.8)',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
})

const ActionsContainer = styled('div')({
  padding: '10px 25px',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  width: '100%',
})

const SubmitWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gridColumn: '2',
})

const FormButtonsContainer = styled(DialogActions)({
  display: 'flex',
  justifyContent: 'flex-start',
  button: {
    textTransform: 'none',
    fontSize: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  [`@media ${device.mobile}`]: {
    button: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
})

const SubmitButton = styled(Button)(({ theme }) => ({
  color: theme.palette.base.white,
}))

const PaddedSection = styled('div')({
  padding: '10px 25px',
})

const FormContainer = styled('div')({
  padding: '10px 25px',
})

const InnerFormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const OfferUploadFieldset = styled('div')({
  display: 'flex',
  alignItems: 'center',
  svg: {
    cursor: 'pointer',
    '&:hover': {
      fill: 'orange',
    },
  },
})

const DeleteFileIcon = styled(CloseIcon)({
  marginLeft: '0.5rem',
  '&:hover': {
    fill: 'red !important',
  },
})

const UpgradeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  fontWeight: 'bold',
  [`@media ${device.mobile}`]: {
    fontSize: '0.75rem',
    marginLeft: '0rem',
    marginBottom: '0.5rem',
  },
}))

const EmojiKeywordChipContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 'max-content',
  [`@media ${device.mobile}`]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
})

const EmojiKeywordChipRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '2px',
})

const EmojiKeywordRowText = styled('div')({
  [`@media ${device.mobile}`]: {
    display: 'none',
  },
})

const FormSectionWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  minHeight: '160px',
})

const FormSectionDescription = styled('div', {
  shouldForwardProp: (prop) => prop !== 'marginTop' && prop !== 'width',
})(({ theme, marginTop, width }) => ({
  background: theme.palette.tertiary.extraLight,
  padding: '0.75rem',
  fontSize: '0.875rem',
  width: width ? width : '285px',
  minWidth: '285px',
  marginTop: marginTop ? marginTop : '5px',
  [`@media ${device.mobile}`]: {
    display: 'none',
  },
}))

const PreviewSectionWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '1rem',
  [`@media ${device.mobile}`]: {
    gridTemplateColumns: '1fr',
  },
  padding: '10px 25px',
})

const PreviewDescriptionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  [`@media ${device.mobile}`]: {
    display: 'none',
  },
})

const FormSectionDescriptionParagraph = styled('div')({
  margin: '0px 0px 1rem 0px',
})

const FormSectionDescriptionLink = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
}))

const FormSectionSubheading = styled('div')({
  fontSize: '1rem',
  fontWeight: 'bold',
  margin: '1.5rem 0 0.5rem',
  width: 'fit-content',
})

const FormSectionInputWrapper = styled('div')({
  flex: '1',
})

const StyledFormControlLabel = styled(FormControlLabel)({
  alignItems: 'flex-start',
})

const StyledRadio = styled(Radio)({
  paddingTop: '0px',
})

const DescriptionInputContainer = styled('div')({
  maxWidth: '400px',
  width: '100%',
})

const TextButton = styled('span')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.secondary.main,
  textDecoration: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  marginTop: '1.5rem',
}))

const PopoverContent = styled('div')({
  maxWidth: '324px',
  padding: '1.5rem 1rem',
  fontSize: '0.75rem',
  fontWeight: '300',
})

const PopoverHeader = styled('div')({
  fontSize: '1rem',
  fontWeight: 'bold',
  marginBottom: '0.5rem',
})

const CopyLinkContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const CopyLinkText = styled('div')({
  marginBottom: '0.5rem',
})

const CopyableLink = styled('div')(({ theme }) => ({
  margin: '0px 5px 0px 0px',
  padding: '5px 15px',
  background: theme.palette.base.white,
  border: '1px solid black',
  cursor: 'pointer',
  fontSize: '1.125rem',
  fontWeight: 'bold',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const RegularText = styled('span')({
  fontWeight: 400,
})

const SurveyOffersForm = ({
  offers,
  setOffers,
  offer,
  setOffer,
  handleClose,
  defaultEmojiKeywords,
  setVideoId,
  setVideoModalOpen,
  setSurveyOffersFormModalSize,
  setSurveyOffersFormModalFullWidth,
  surveyLink,
  isOnboarding,
  advanceLastStep,
  setCode,
}) => {
  const {
    id,
    link,
    code,
    name,
    fileKey,
    fileName,
    disableLeadgen,
    disableBookingFlow,
    customQuestion,
    isCustomQuestionPublic,
    emojiKeywords,
  } = offer

  const LEADGEN_OPTIONS = {
    COLLECT_ALL_DATA: 'COLLECT_ALL_DATA',
    DISABLE_BOOKING_FLOW: 'DISABLE_BOOKING_FLOW',
    DISABLE_LEADGEN: 'DISABLE_LEADGEN',
  }

  const [offerCode, setOfferCode] = useState('')
  const [offerName, setOfferName] = useState('')
  const [offerLink, setOfferLink] = useState('')
  const [offerEmojiKeywords, setOfferEmojiKeywords] = useState([])
  const [leadgenOption, setLeadgenOption] = useState(
    LEADGEN_OPTIONS.COLLECT_ALL_DATA
  )
  const [offerCustomQuestion, setOfferCustomQuestion] = useState('')
  const [offerIsCustomQuestionPublic, setOfferIsCustomQuestionPublic] =
    useState(false)
  const [offerFile, setOfferFile] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [codeError, setCodeError] = useState(null)
  const [nameError, setNameError] = useState(null)
  const [linkError, setLinkError] = useState(null)
  const [charactersRemaining, setCharactersRemaining] = useState(75)
  const [showSlide, setShowSlide] = useState(false)
  const [genericInlineError, setGenericInlineError] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [formStep, setFormStep] = useState(1)
  const [pendingSubmission, setPendingSubmission] = useState(false)
  const [showDisableBookingFlowAlert, setShowDisableBookingFlowAlert] =
    useState(false)
  const [showDisableLeadgenAlert, setShowDisableLeadgenAlert] = useState(false)
  const [codeEditDialogOpen, setCodeEditDialogOpen] = useState(false)
  const { toggleUpgradeModal, authData, setNotification, updatePermission } =
    useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const { canAddCustomQuestion, findPermission } = usePermissionHelper()

  const defaultEmojiKeywordsForOffer = defaultEmojiKeywords.filter(
    (keyword) => !!keyword.emoji_template_identifier
  )

  const CODEREGEX = /^[a-zA-Z0-9]+$/i

  const ACCEPTED_FILE_TYPES = [
    'png',
    'jpg',
    'jpeg',
    'doc',
    'docx',
    'pdf',
    'mp3',
    'mp4',
    // 'csv',
    // 'xls',
    // 'xlsx',
    // 'zip'
  ]

  useEffect(() => {
    if (offerCustomQuestion) {
      setCharactersRemaining(75 - offerCustomQuestion.length)
    }
  }, [offerCustomQuestion])

  useEffect(() => {
    if (disableLeadgen) {
      setLeadgenOption(LEADGEN_OPTIONS.DISABLE_LEADGEN)
    } else if (disableBookingFlow) {
      setLeadgenOption(LEADGEN_OPTIONS.DISABLE_BOOKING_FLOW)
    } else {
      setLeadgenOption(LEADGEN_OPTIONS.COLLECT_ALL_DATA)
    }

    if (offer.id) {
      setOfferCode(code)
      setOfferName(name)
      setOfferLink(link)
      setOfferCustomQuestion(customQuestion)
      setOfferIsCustomQuestionPublic(isCustomQuestionPublic)

      if (emojiKeywords?.length === 0) {
        setOfferEmojiKeywords(defaultEmojiKeywordsForOffer)
      } else {
        setOfferEmojiKeywords(emojiKeywords)
      }
    }
  }, [offer])

  useEffect(() => {
    if (pendingSubmission) {
      handleSubmit()
    }
  }, [pendingSubmission])

  const { user } = authData
  const hasAccessCustomQuestion = canAddCustomQuestion()

  const invalidFileType = (fileType) => {
    return !ACCEPTED_FILE_TYPES.includes(fileType.toLowerCase())
  }
  const sendFileToAmazon = async (presignedUrl) => {
    const res = await fetch(presignedUrl, {
      method: 'PUT',
      body: offerFile,
      headers: {
        'Content-Type': offerFile.type,
      },
    })

    if (!res.ok) {
      throw 'Sorry, something went wrong uploading your file'
    }

    return
  }
  const handleSubmit = async (advanceFormStep = false) => {
    if (codeError || nameError || linkError || !offerCode || !offerName) {
      return
    }

    // offerFile is only set when the user is uploading a new file
    if (!offerFile) {
      setIsLoading(true)
      await createOrEditSurveyOffer()
      setIsLoading(false)
    } else {
      setIsLoading(true)
      await saveFileInS3AndCreateOffer()
      setIsLoading(false)
    }

    if (advanceFormStep) {
      setFormStep(formStep + 1)
    }

    if (pendingSubmission) {
      setPendingSubmission(false)
    }
  }

  const saveFileInS3AndCreateOffer = async () => {
    // This handles scenarios where people use extra periods in their file names
    let index = offerFile.name.lastIndexOf('.')
    let fileName = offerFile.name.slice(0, index)
    // Use this parsed file type to verify file types we support
    let fileType = offerFile.name.slice(index + 1)

    if (invalidFileType(fileType)) {
      setOfferFile(null)
      return setGenericInlineError('Invalid file type.')
    }

    try {
      const params = {
        presign_url: {
          file_name: fileName,
          // S3 presign urls require the raw file type
          file_type: offerFile.type,
        },
      }

      const res = await Api.presignUrl(params)
      await sendFileToAmazon(res.presignedUrl)
      return await createOrEditSurveyOffer(res.fileKey)
    } catch (err) {
      handleCreateError(err)
    }
  }

  const createOrEditSurveyOffer = async (storageKey = null) => {
    if (!id) {
      await createOffer(storageKey)
    } else {
      await editOffer(storageKey)
    }
  }

  // For Editing an offer
  // If I have a file key, I need to use the same one
  // If the file key changes, I need a new one
  const editOffer = async (storageKey) => {
    const params = {
      survey_offer: {
        link: offerLink,
        code: offerCode,
        name: offerName,
        // If there's a new storage key, save it. If not, leave the old key
        file_key: storageKey ? storageKey : fileKey,
        // If there's a new filename, save it, if not, keep the old one
        file_name: offerFile ? offerFile.name : fileName,
        custom_question: offerCustomQuestion,
        is_answer_public: offerIsCustomQuestionPublic,
        disable_leadgen: leadgenOption === LEADGEN_OPTIONS.DISABLE_LEADGEN,
        disable_booking_flow:
          leadgenOption === LEADGEN_OPTIONS.DISABLE_BOOKING_FLOW,
        emoji_keywords: offerEmojiKeywords,
      },
      ...(selectedGroup ? { group_id: selectedGroup.id } : {}),
    }

    try {
      let res
      if (selectedGroup) {
        res = await Api.editGroupSurveyOffer(params, id)
      } else {
        res = await Api.editSurveyOffer(params, id)
      }

      if (!res.errors) {
        handleEditSuccess(res)
      } else {
        throw res.errors
      }
    } catch (err) {
      handleCreateError(err)
    }
  }

  const handleEditSuccess = (res, showSlide = true) => {
    setOffers(
      offers.map((offer) =>
        offer.id === res.id ? { ...offer, ...res } : { ...offer }
      )
    )
  }

  const createOffer = async (storageKey) => {
    const params = {
      survey_offer: {
        link: offerLink,
        code: offerCode,
        name: offerName,
        file_key: storageKey,
        file_name: offerFile ? offerFile.name : null,
        custom_question: offerCustomQuestion,
        is_answer_public: offerIsCustomQuestionPublic,
        disable_leadgen: leadgenOption === LEADGEN_OPTIONS.DISABLE_LEADGEN,
        disable_booking_flow:
          leadgenOption === LEADGEN_OPTIONS.DISABLE_BOOKING_FLOW,
        emoji_keywords: offerEmojiKeywords,
      },
      ...(selectedGroup ? { group_id: selectedGroup.id } : {}),
    }

    try {
      let res
      if (selectedGroup) {
        res = await Api.createGroupSurveyOffer(params)
      } else {
        res = await Api.createSurveyOffer(params)
      }

      if (!res.errors) {
        handleCreateSuccess(res)
      } else {
        throw res.errors
      }
    } catch (err) {
      handleCreateError(err)
    }
  }

  const handleCreateSuccess = (res) => {
    // NOTE: Default offer is TALK, so first offer created is the second offer
    if (offers?.length === 1) {
      const params = {
        user_id: user.id,
        user_email: user.email,
      }
      trackFirstOfferCreated(params)
    }

    setOffer(res)
    setOffers([...offers, res])

    // Update the upgrade widget content
    const codePermission = findPermission('OFFER_CODE_LIMIT')
    updatePermission({
      ...codePermission,
      consumed: codePermission.consumed + 1,
    })
  }

  const handleCreateError = (err) => {
    setGenericInlineError(humanizeError(err))
  }

  const advanceFormStep = () => {
    // if we're in the SurveyOffers onboarding flow, we want to advance the step by 2
    // so that we skip the advanced settings step
    if (isOnboarding) {
      setFormStep(formStep + 2)
    } else {
      setFormStep(formStep + 1)
    }
  }

  const handleBackClick = () => {
    if (isOnboarding) {
      setFormStep(formStep - 2)
    } else {
      setFormStep(formStep - 1)
    }
  }

  const showDownloadSlide = () => {
    setShowSlide(true)
    setSurveyOffersFormModalSize('lg')
    setSurveyOffersFormModalFullWidth(false)
  }

  const submitButtonDisabled = () => {
    return (
      isLoading ||
      !offerCode ||
      !offerName ||
      offerCode === 'TALK' ||
      codeError ||
      nameError ||
      linkError
    )
  }

  const SurveyOfferActions = () => {
    let ctaText = ''
    let handleCtaClick = () => {}

    switch (formStep) {
      case 1:
        if (isOnboarding) {
          ctaText =
            isMobileOnly || window.innerWidth < 960
              ? 'Preview'
              : 'Next - Preview'
        } else {
          ctaText =
            isMobileOnly || window.innerWidth < 960
              ? 'Next'
              : 'Next - Advanced Settings'
        }
        handleCtaClick = advanceFormStep
        break
      case 2:
        ctaText =
          isMobileOnly || window.innerWidth < 960
            ? 'Preview'
            : 'Preview this feedback flow'
        handleCtaClick = advanceFormStep
        break
      case 3:
        if (isOnboarding) {
          ctaText =
            isMobileOnly || window.innerWidth < 960
              ? 'Continue'
              : 'All tested! Continue'
          handleCtaClick = () => {
            setCode(offerCode)
            advanceLastStep()
          }
        } else {
          ctaText =
            isMobileOnly || window.innerWidth < 960
              ? 'Download'
              : 'Looks good, download my slide!'
          handleCtaClick = showDownloadSlide
        }
        break
      default:
        ctaText = 'Next'
        break
    }

    return (
      <ActionsContainer>
        {formStep > 1 && (
          <Button sx={{ color: 'orange' }} onClick={handleBackClick}>
            &lt; Back
          </Button>
        )}
        <SubmitWrapper>
          {!isOnboarding && (
            <Button
              onClick={() => handleClose()}
              sx={{ color: 'orange' }}
              disabled={isLoading}>
              Cancel
            </Button>
          )}
          <Tooltip
            title="Please Enter a Code and Description"
            arrow
            placement="top"
            disableHoverListener={!submitButtonDisabled()}>
            <span>
              <SubmitButton
                variant="contained"
                onClick={handleCtaClick}
                disabled={submitButtonDisabled()}>
                {isLoading ? (
                  <CircularProgress style={{ color: 'orange' }} size={30} />
                ) : (
                  ctaText
                )}
              </SubmitButton>
            </span>
          </Tooltip>
        </SubmitWrapper>
      </ActionsContainer>
    )
  }

  const usingCustomEmoji = () => {
    return defaultEmojiKeywords.some((defaultKeyword) => {
      return defaultEmojiKeywordsForOffer.find((keyword) => {
        return (
          keyword.emoji_template_identifier &&
          defaultKeyword.emoji_template_identifier ===
            keyword.emoji_template_identifier &&
          defaultKeyword.keyword !== keyword.keyword
        )
      })
    })
  }

  const handleResetEmojiKeywords = () => {
    setOfferEmojiKeywords(defaultEmojiKeywords)
    setPendingSubmission(true)
  }

  const handleOpenVideoModal = (videoId) => {
    setVideoId(videoId)
    setVideoModalOpen(true)
  }

  const handleLeadPopoverClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleLeadsPopoverClose = () => {
    setAnchorEl(null)
  }

  const handleDeleteFile = async () => {
    if (!isLoading) {
      setOfferFile(null)

      if (id && fileName) {
        const params = {
          survey_offer: {
            file_key: null,
            file_name: null,
          },
          ...(selectedGroup ? { group_id: selectedGroup.id } : {}),
        }

        try {
          setIsLoading(true)
          let res
          if (selectedGroup) {
            res = await Api.editGroupSurveyOffer(params, id)
          } else {
            res = await Api.editSurveyOffer(params, id)
          }

          if (!res.errors) {
            setOffer(res)
            handleEditSuccess(res, false)
            setIsLoading(false)
          } else {
            throw res.errors
          }
        } catch (err) {
          handleCreateError(err)
          setIsLoading(false)
        }
      }
    }
  }

  const handleCodeChange = (e) => {
    if (codeError) {
      setCodeError(null)
    }

    if (genericInlineError) {
      setGenericInlineError(null)
    }

    if (e.target.value?.toUpperCase().trim() === 'TALK') {
      setCodeError(
        'sorry, but the code TALK is reserved. Please use a different code'
      )
    }

    if (e.target.value?.length > 0 && !CODEREGEX.test(e.target.value)) {
      setCodeError(
        'Talkadot codes can only be letters and numbers, no spaces or symbols'
      )
    }

    if (e.target.value?.length > 15) {
      return setCodeError('Must be 15 characters or less')
    }

    setOfferCode(e.target.value.toUpperCase())
  }

  const handleUpdateLeadgenOptions = (e) => {
    setShowDisableBookingFlowAlert(false)
    setShowDisableLeadgenAlert(false)

    if (e.target.value === LEADGEN_OPTIONS.DISABLE_BOOKING_FLOW) {
      setShowDisableBookingFlowAlert(true)
    } else if (e.target.value === LEADGEN_OPTIONS.DISABLE_LEADGEN) {
      setShowDisableLeadgenAlert(true)
    } else {
      setShowDisableBookingFlowAlert(false)
      setShowDisableLeadgenAlert(false)
    }

    setLeadgenOption(e.target.value)
    setPendingSubmission(true)
  }

  const handleUpdateCustomQuestionVisibility = (e) => {
    setOfferIsCustomQuestionPublic(e.target.checked)
    setPendingSubmission(true)
  }

  const setFieldError = (field) => {
    switch (field) {
      case 'code':
        if (!offerCode) {
          return setCodeError('you must enter a code')
        }

        if (offerCode.length < 3) {
          return setCodeError('your code must be at least 3 characters')
        }

        break
      case 'name':
        if (!offerName) {
          return setNameError('Please add a description for your code')
        }
        break
      case 'link':
        // TODO: implement url validation
        // if (offerLink && !Helpers.isValidUrl(offerLink)) {
        //   return setLinkError('Please enter a valid URL')
        // }
        break
      default:
        break
    }
  }

  const handleBlur = (e) => {
    // Don't save on blur if the value hasn't changed
    if (e.target.value && e.target.value === offer[e.target.name]) return

    setFieldError(e.target.name)

    // if editing code, open the ConfirmDialog here
    if (e.target.name === 'code' && id) {
      setCodeEditDialogOpen(true)
      return
    }

    // Use debounce to prevent onBlur event from swallowing the click event
    debounce(() => handleSubmit(), 200)()
  }

  const surveyLinkSrc = Helpers.makeSurveyLink({
    shortlink: surveyLink,
    code: offerCode,
  })

  const handleCopy = () => {
    Helpers.handleCopy(surveyLinkSrc)
    return setNotification('Copied!', 'success')
  }

  const leadsPopoverOpen = Boolean(anchorEl)

  const navLinks = [
    {
      label: 'SETUP',
      onClick: () => setFormStep(1),
      active: formStep < 3,
    },
    {
      label: 'PREVIEW',
      onClick: () => setFormStep(3),
      active: formStep === 3,
      disabled: !code || !name,
    },
  ]

  const renderNavBanner = () => {
    return (
      <NavBanner>
        {navLinks.map((link, i) => {
          return (
            <NavBannerLink
              key={i}
              active={link.active}
              disabled={link.disabled}
              onClick={!link.disabled ? link.onClick : null}>
              {link.label}
            </NavBannerLink>
          )
        })}
      </NavBanner>
    )
  }

  const renderBasicSettings = () => {
    return (
      <>
        <DialogTitle>
          <Heading variant="h4" component={'div'}>
            {/* This gets weird with submit onBlur handling */}
            {/* {id ? 'Edit Talkadot code' : 'Setup a Talkadot code'} */}
            Setup a Talkadot code
          </Heading>
          <Subheading variant="body2">
            Configure what your audience sees when they go to your feedback
            form.
          </Subheading>
        </DialogTitle>
        {genericInlineError && (
          <Alert severity="error">{genericInlineError}</Alert>
        )}
        <FormDialogContent>
          <FormContainer>
            <InnerFormContainer>
              <FormSectionWrapper>
                <FormSectionInputWrapper>
                  <FormSection
                    stepNumber="1"
                    heading="Do you want to collect leads or email addresses?">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="disableLeadgen"
                        value={leadgenOption}
                        onChange={(e) => handleUpdateLeadgenOptions(e)}>
                        <StyledFormControlLabel
                          value={LEADGEN_OPTIONS.COLLECT_ALL_DATA}
                          control={<StyledRadio />}
                          label="Yes, collect speaking leads and email addresses. (Default, recommended.)"
                        />
                        <br />
                        <StyledFormControlLabel
                          value={LEADGEN_OPTIONS.DISABLE_BOOKING_FLOW}
                          control={<StyledRadio />}
                          label="Email addresses only (No speaking leads. Skips booking questions.)"
                        />
                        {showDisableBookingFlowAlert && (
                          <FormHelperText>
                            <FormFieldError
                              errorMsg={
                                <>
                                  <span>Are you sure?</span>{' '}
                                  <RegularText>
                                    Chosing this option means we won't be asking
                                    your audience questions about whether they
                                    book paid speaking engagements.
                                  </RegularText>
                                </>
                              }
                              allowClose={true}
                            />
                          </FormHelperText>
                        )}
                        <br />
                        <StyledFormControlLabel
                          value={LEADGEN_OPTIONS.DISABLE_LEADGEN}
                          control={<StyledRadio />}
                          label="I don't want to collect any information. (No speaking leads. No emails collected.)"
                        />
                        {showDisableLeadgenAlert && (
                          <FormHelperText>
                            <FormFieldError
                              errorMsg={
                                <>
                                  <span>Are you sure?</span>{' '}
                                  <RegularText>
                                    This means we won't collect ANY personal
                                    information or email addresses from your
                                    audience.
                                  </RegularText>
                                </>
                              }
                              allowClose={true}
                            />
                          </FormHelperText>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </FormSection>
                </FormSectionInputWrapper>
                <FormSectionDescription>
                  <FormSectionDescriptionParagraph>
                    Leave this on unless you are speaking to an audience where
                    collecting leads is not permitted.
                  </FormSectionDescriptionParagraph>
                  <FormSectionDescriptionLink
                    aria-describedby="leadsEnabled"
                    onClick={handleLeadPopoverClick}>
                    See some examples of when you might turn this off.
                  </FormSectionDescriptionLink>
                  <Popover
                    id="leadsEnabled"
                    open={leadsPopoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleLeadsPopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <PopoverContent>
                      <PopoverHeader>Leave this code on when...</PopoverHeader>
                      <div>
                        There might be someone in the audience that is
                        interested in booking you.
                      </div>
                      <br />
                      <div>
                        Say you might be speaking to a high school audience, but
                        there are administrators who book speakers. We recommend
                        keeping the code on to maximize your chances you capture
                        contact information of someone who can book you.
                      </div>
                      <br />
                      <PopoverHeader>Turn this off when...</PopoverHeader>
                      <div>
                        You are are speaking to high school or college students.
                      </div>
                      <br />
                      <div>
                        You to an audience that doesn't include decision makers.
                      </div>
                      <br />
                      <div>
                        Your host would not want you to collect information.
                      </div>
                    </PopoverContent>
                  </Popover>
                </FormSectionDescription>
              </FormSectionWrapper>
              <FormSectionWrapper>
                <FormSectionInputWrapper>
                  <FormSection
                    stepNumber="2"
                    heading="Create your Talkadot code">
                    <TextField
                      required
                      variant="standard"
                      placeholder="Enter a short word"
                      label="Enter code"
                      error={!!codeError}
                      helperText={
                        codeError ? (
                          <FormFieldError
                            errorMsg={codeError}
                            allowClose={false}
                          />
                        ) : null
                      }
                      value={offerCode}
                      name="code"
                      onChange={(e) => handleCodeChange(e)}
                      onBlur={(e) => handleBlur(e)}
                    />
                    <DescriptionInputContainer>
                      <FormSectionSubheading>
                        Add a description
                      </FormSectionSubheading>
                      <TextField
                        required
                        variant="standard"
                        placeholder="Add text to help you remember which code this is"
                        value={offerName}
                        name="name"
                        label="Enter description"
                        fullWidth
                        error={!!nameError}
                        helperText={
                          nameError ? (
                            <FormFieldError
                              errorMsg={nameError}
                              allowClose={false}
                            />
                          ) : null
                        }
                        onChange={(e) => {
                          setGenericInlineError(null)
                          setNameError(null)
                          setOfferName(e.target.value)
                        }}
                        onBlur={(e) => handleBlur(e)}
                      />
                    </DescriptionInputContainer>
                  </FormSection>
                </FormSectionInputWrapper>
                <FormSectionDescription>
                  <FormSectionDescriptionParagraph>
                    This is the code you give your audience.
                  </FormSectionDescriptionParagraph>
                  <FormSectionDescriptionParagraph>
                    Keep it short to minimize typos!
                  </FormSectionDescriptionParagraph>
                </FormSectionDescription>
              </FormSectionWrapper>
              <FormSectionWrapper>
                <FormSection
                  stepNumber="3"
                  heading="Give your audience a bonus as an incentive to give you feedback!"
                  headingNote="(optional)">
                  <OfferUploadFieldset>
                    {!offerFile && !fileName ? (
                      <TextField
                        required
                        variant="standard"
                        placeholder="Paste a website link or upload a file"
                        value={offerLink}
                        name="link"
                        error={!!linkError}
                        helperText={
                          linkError ? (
                            <FormFieldError
                              errorMsg={linkError}
                              allowClose={false}
                            />
                          ) : null
                        }
                        fullWidth
                        onChange={(e) => {
                          setGenericInlineError(null)
                          setLinkError(null)
                          setOfferLink(e.target.value)
                        }}
                        onBlur={(e) => handleBlur(e)}
                      />
                    ) : (
                      <Typography variant="body1">
                        {offerFile ? offerFile.name : fileName}
                      </Typography>
                    )}
                    {!offerFile && !fileName ? (
                      <label htmlFor="btn-upload">
                        <input
                          id="btn-upload"
                          name="btn-upload"
                          style={{ display: 'none' }}
                          type="file"
                          onChange={(e) => {
                            setGenericInlineError(null)
                            setLinkError(null)
                            setOfferFile(e.target.files[0])
                            setPendingSubmission(true)
                          }}
                        />
                        <FileUploadIcon />
                      </label>
                    ) : (
                      <DeleteFileIcon onClick={handleDeleteFile} />
                    )}
                  </OfferUploadFieldset>
                </FormSection>
                <FormSectionDescription>
                  <FormSectionDescriptionParagraph>
                    Optional, leave blank to skip.
                  </FormSectionDescriptionParagraph>
                  <FormSectionDescriptionLink
                    onClick={() => handleOpenVideoModal('D92PLs3Wxdk')}>
                    What makes a great bonus offer?
                  </FormSectionDescriptionLink>
                </FormSectionDescription>
              </FormSectionWrapper>
            </InnerFormContainer>
          </FormContainer>
        </FormDialogContent>
        <FormButtonsContainer>
          <SurveyOfferActions />
        </FormButtonsContainer>
      </>
    )
  }

  const emojiQuestionText = () => {
    if (selectedGroup) {
      return "Was this speaker's session "
    }

    return `Was ${user?.first_name}'s talk `
  }

  const renderAdvancedSettings = () => (
    <>
      <DialogTitle>
        <Heading variant="h4" component={'div'}>
          Advanced Settings (Pro)
        </Heading>
        {!canAddCustomQuestion() && (
          <UpgradeText
            onClick={() =>
              toggleUpgradeModal(
                true,
                'Upgrade now to unlock custom configurations!',
                CUSTOMIZATIONS
              )
            }>
            Upgrade your Talkadot account to access these features!
          </UpgradeText>
        )}
      </DialogTitle>
      {genericInlineError && (
        <Alert severity="error">{genericInlineError}</Alert>
      )}
      <FormDialogContent>
        <OptionalSettingsContainer>
          <PaddedSection>
            <InnerFormContainer>
              <FormSectionWrapper>
                <FormSection
                  stepNumber="4"
                  heading="Change your feedback questions"
                  showProFeatureIcon={!hasAccessCustomQuestion}
                  permissionType={CUSTOMIZATIONS}>
                  <EmojiKeywordChipContainer>
                    {offerEmojiKeywords
                      ?.sort(
                        (a, b) =>
                          parseInt(a.emoji_template_identifier?.slice(-1)) -
                          parseInt(b.emoji_template_identifier?.slice(-1))
                      )
                      .map((emojiKeyword, i) => {
                        return (
                          emojiKeyword?.emoji_template_identifier && (
                            <EmojiKeywordChipRow key={i}>
                              <EmojiKeywordRowText>
                                {emojiQuestionText()}
                              </EmojiKeywordRowText>
                              <EmojiKeywordSelect
                                currentEmojiKeyword={emojiKeyword}
                                offerEmojiKeywords={offerEmojiKeywords}
                                defaultEmojiKeywords={defaultEmojiKeywords}
                                canAddCustomQuestion={hasAccessCustomQuestion}
                                setOfferEmojiKeywords={setOfferEmojiKeywords}
                                setPendingSubmission={setPendingSubmission}
                              />
                            </EmojiKeywordChipRow>
                          )
                        )
                      })}
                  </EmojiKeywordChipContainer>
                  {usingCustomEmoji() && (
                    <TextButton onClick={() => handleResetEmojiKeywords()}>
                      Reset to recommended defaults.
                    </TextButton>
                  )}
                </FormSection>
                <FormSectionDescription>
                  <FormSectionDescriptionParagraph>
                    Use our defaults, or choose the attributes you want feedback
                    on related to your talk.
                  </FormSectionDescriptionParagraph>
                </FormSectionDescription>
              </FormSectionWrapper>
              <FormSectionWrapper>
                <FormSection
                  stepNumber="5"
                  heading="Add a custom question to the end of your feedback form"
                  showProFeatureIcon={!hasAccessCustomQuestion}
                  permissionType={CUSTOMIZATIONS}>
                  <TextField
                    required
                    variant="standard"
                    placeholder="Your question (75 characters max)"
                    fullWidth
                    value={offerCustomQuestion}
                    name="customQuestion"
                    disabled={!hasAccessCustomQuestion}
                    inputProps={{ maxLength: 75 }}
                    onChange={(e) => {
                      setCharactersRemaining(75 - e.target.value.length)
                      setGenericInlineError(null)
                      setCodeError(null)
                      setOfferCustomQuestion(e.target.value)
                    }}
                    onBlur={(e) => handleBlur(e)}
                  />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 300, marginTop: '5px' }}>
                    <i>(characters remaining: {charactersRemaining})</i>
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Custom question is visible on public report"
                    checked={offerIsCustomQuestionPublic}
                    disabled={!offerCustomQuestion || !hasAccessCustomQuestion}
                    onChange={(e) => handleUpdateCustomQuestionVisibility(e)}
                  />
                </FormSection>
                <FormSectionDescription>
                  <FormSectionDescriptionParagraph>
                    Optional, leave blank to skip.
                  </FormSectionDescriptionParagraph>
                </FormSectionDescription>
              </FormSectionWrapper>
            </InnerFormContainer>
          </PaddedSection>
        </OptionalSettingsContainer>
      </FormDialogContent>
      <FormButtonsContainer>
        <SurveyOfferActions />
      </FormButtonsContainer>
    </>
  )

  const renderPreview = () => (
    <>
      <DialogTitle>
        <Heading variant="h4" component={'div'}>
          {isMobileOnly || window.innerWidth < 960
            ? 'Preview Feedback'
            : 'Preview your Talkadot Feedback'}
        </Heading>
      </DialogTitle>
      <FormDialogContent>
        <PreviewSectionWrapper>
          <IframeContainer>
            <StyledIframe
              src={`${surveyLinkSrc}&mode=test`}
              title="Survey Preview"
            />
          </IframeContainer>
          <PreviewDescriptionWrapper>
            <FormSectionDescription marginTop="0" width="360px">
              <FormSectionDescriptionParagraph>
                This is what your audience will experience. Make sure it's what
                you expect!
                <br />
                <br />
                <strong>Note:</strong> If you use the Talkadot generated slide,
                the code will automatically be filled.
              </FormSectionDescriptionParagraph>
            </FormSectionDescription>
            <FormSectionDescription marginTop="2rem" width="360px">
              <CopyLinkContainer>
                <CopyLinkText>
                  Use this link to prefill your code for virtual presentations.
                </CopyLinkText>
                <CopyableLink
                  onClick={handleCopy}
                  aria-controls="simple-menu"
                  aria-haspopup="true">
                  {surveyLinkSrc}
                </CopyableLink>
              </CopyLinkContainer>
            </FormSectionDescription>
          </PreviewDescriptionWrapper>
        </PreviewSectionWrapper>
      </FormDialogContent>
      <FormButtonsContainer>
        <SurveyOfferActions />
      </FormButtonsContainer>
    </>
  )

  const renderForm = () => {
    switch (formStep) {
      case 1:
        return renderBasicSettings()
      case 2:
        return renderAdvancedSettings()
      case 3:
        return renderPreview()
      default:
        return <></>
    }
  }

  const handleCodeEditCancel = () => {
    setCodeEditDialogOpen(false)
    setOfferCode(code)
  }

  return showSlide ? (
    <div>
      <CloseIcon
        className="modal-close-icon"
        sx={{ fill: 'white' }}
        onClick={() => handleClose()}
      />
      <SurveyOffersSlide code={offerCode} />
    </div>
  ) : (
    <>
      {renderNavBanner()}
      {renderForm()}
      <ConfirmDialog
        title="Edit Talkadot Code"
        open={codeEditDialogOpen}
        setOpen={setCodeEditDialogOpen}
        onConfirm={handleSubmit}
        onCancel={handleCodeEditCancel}
        hideDangerMessage={true}>
        <span>
          Are you sure you want to change your code to <b>{offerCode}</b>?
        </span>
        <p>
          <b>
            If you proceed with this change, please ensure to re-download your
            slide / QR code and update your presentation!
          </b>
        </p>
      </ConfirmDialog>
    </>
  )
}

export default SurveyOffersForm
