// import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import { FaUsers } from 'react-icons/fa'
// import { ReactComponent as AttendeesIcon } from '../../icons/attendees.svg'

// const StatWidgetSection = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   marginBottom: theme.spacing(3.75),
// }))

const TotalAttendees = ({ totalResponses, isLoading }) => (
  <div className="stat-widget-section" id="total-responses">
    {/* <StatWidgetSection> */}
    {isLoading ? (
      <Skeleton width={200} height={30} />
    ) : (
      <Typography variant="h6" component="div" className="widget-title">
        <span className="stat-percentage">
          <b>{totalResponses}</b>
        </span>{' '}
        responses
      </Typography>
    )}
    {isLoading ? (
      <Skeleton width={200} height={200} variant="rectangular" />
    ) : (
      <div className="stat-widget-section__widgets">
        <div className="talk-responses">
          <FaUsers />
        </div>
      </div>
    )}
    {/* </StatWidgetSection> */}
  </div>
)

export default TotalAttendees
