import { formatDateWithTimeZone } from '../common/helpers'
import { getComparator } from '../../utils/sort'
import moment from 'moment'

const compareDates = (leadA, leadB, granularity = 'second') => {
  const dateA = moment(leadA.createdAt)
  const dateB = moment(leadB.createdAt)

  if (dateA.isBefore(dateB, granularity)) {
    return 1
  }
  if (dateA.isAfter(dateB, granularity)) {
    return -1
  }
  return 0
}

const compareWarmth = (leadA, leadB) => {
  const warmthOrder = ['hot', null, undefined]

  const warmthA = leadA?.warmth?.toLowerCase()
  const warmthB = leadB?.warmth?.toLowerCase()

  if (warmthA === warmthB) return 0

  const indexA = warmthOrder.indexOf(warmthA)
  const indexB = warmthOrder.indexOf(warmthB)

  return indexA < indexB ? -1 : 1
}

/**
 * Comparator function for sorting leads by createdAt date.
 * @param {Object} a - First lead to compare.
 * @param {Object} b - Second lead to compare.
 * @param {string} order - Sorting order: 'asc' or 'desc'.
 * @returns {number} - Comparison result: Sorts by date first, then by warmth, then by date and time.
 */
const compareLeads = (leadA, leadB, order) => {
  const dateA = leadA?.createdAt
  const dateB = leadB?.createdAt

  // Handle null dates
  if (dateA === null && dateB === null) return 0
  if (dateA === null) return 1
  if (dateB === null) return -1

  const sortMultiplier = order === 'desc' ? 1 : -1

  // Compare dates by day
  const dayComparison = compareDates(leadA, leadB, 'day')
  if (dayComparison !== 0) return dayComparison * sortMultiplier

  // Compare warmth
  const warmthComparison = compareWarmth(leadA, leadB)
  if (warmthComparison !== 0) return warmthComparison

  // Compare dates by second
  return compareDates(leadA, leadB) * sortMultiplier
}

/**
 * Returns a comparator function based on the order and key to sort by.
 * Does customized sorting for leads when sorting by createdAt date
 * @param {string} order - Sorting order: 'asc' or 'desc'.
 * @param {string} orderBy - Key to sort by.
 * @returns {function} - Comparator function.
 */
export const getLeadsComparator = (order, orderBy) => {
  if (orderBy.toLowerCase() !== 'createdat')
    return getComparator(order, orderBy)

  return (a, b) => compareLeads(a, b, order)
}

// Note:
// This is taken from an answer on stack overflow
// https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export const isBookingLead = (leadTypes) => {
  return leadTypes?.join('').includes('BOOK')
}

export const isReferLead = (leadTypes) => {
  return leadTypes?.join('').includes('REFER')
}

export const isOptIn = (leadTypes) => {
  return leadTypes?.join('').includes('OPT_IN')
}

// A hot lead is someone who answered "hot" to the classification questions,
// and filled out ALL of their contact info
export const isHotLead = (lead) => {
  return lead?.warmth === 'HOT'
}

export const allLeads = (leads) => {
  return leads.filter((lead) => !lead.archived)
}

export const referLeads = (leads) => {
  return leads.filter(
    (lead) => lead?.leadTypes.join('').includes('REFER') && !lead.archived
  )
}

export const bookingLeads = (leads) => {
  return leads.filter(
    (lead) => lead?.leadTypes.join('').includes('BOOK') && !lead.archived
  )
}

export const optInLeads = (leads) => {
  return leads.filter(
    (lead) => lead?.leadTypes.join('').includes('OPT_IN') && !lead.archived
  )
}

export const feedbackLeads = (leads) => {
  return leads.filter(
    (lead) => lead?.leadTypes.join('').includes('FEEDBACK') && !lead.archived
  )
}

export const archivedLeads = (leads) => {
  return leads.filter((lead) => lead.archived)
}

export const allLeadsSelected = (selectedLeads, leads) => {
  return (
    leads.length > 0 &&
    ungatedLeads(leads)
      .map((lead) => lead.id)
      .every((leadId) => selectedLeads.includes(leadId))
  )
}

export const ungatedLeads = (leads) => {
  return leads.filter((lead) => !lead.isGated)
}

export const humanizedFollowupFailure = (
  { followupSentAt, followupFailure },
  expandedMsg = false
) => {
  let message

  switch (followupFailure) {
    case 'lead invalid state: (lead.contacted || lead.deleted_at || lead.archived)':
      message = 'Skipped - lead was marked as contacted or archived'
      break
    case 'No bonus':
      message = 'Skipped - survey did not have a bonus'
      break
    case 'at subscription limit':
      message = 'Skipped - subscription limit has been reached'
      break
    case 'invalid email':
      message = 'Skipped - email address is invalid'
      break
    default:
      message = followupSentAt
        ? `Post event attempt on ${formatDateWithTimeZone(
          followupSentAt
        )} failed.  Email was sent but could not be delivered.  `
        : 'Email was sent but could not be delivered.  '
  }

  if (expandedMsg && !message.includes('could not be delivered')) {
    message = `Post event email ${message.toLowerCase()}`
  }

  return message
}
