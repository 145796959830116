import { styled } from '@mui/system'

import SurveyOffersOnboardingCta from './SurveyOffersOnboardingCta'

const WrapperContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const GraphicContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  backgroundImage:
    'url(https://d3qvkz8495pjoq.cloudfront.net/onboarding-text-bubble.png)',
  aspectRatio: '730 / 430',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  fontFamily: 'Figtree, sans-serif',
  padding: '50px 140px 100px 140px',
  position: 'relative',
  '@media (max-width: 1132px)': {
    padding: '50px 160px 100px 160px',
  },
  '@media (max-width: 850px)': {
    padding: '40px 150px 80px 150px',
  },
})

const Header = styled('h1')(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginTop: '0',
}))

const OrderedList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const ListItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
  fontSize: '1.125rem',
})

const ListItemNumber = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
  width: '28px',
  height: '28px',
  marginRight: '0.75rem',
  color: theme.palette.base.white,
  backgroundColor: theme.palette.primary.main,
}))

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '1.75rem',
  '@media (max-width: 1132px)': {
    marginTop: '1rem',
  },
  '@media (max-width: 850px)': {
    button: {
      fontSize: '1.25rem',
    },
  },
})

const UnicornContainer = styled('div')({
  position: 'absolute',
  bottom: '0',
  left: '-120px',
  img: {
    maxHeight: '292px',
  },
  '@media (max-width: 1132px)': {
    left: '-80px',
    img: {
      maxHeight: '240px',
    },
  },
  '@media (max-width: 850px)': {
    left: '-50px',
    img: {
      maxHeight: '200px',
    },
  },
})

const PostScriptum = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '10px',
  right: '48px',
  fontSize: '1rem',
  color: theme.palette.base.white,
  width: '54%',
  fontFamily: 'Figtree, sans-serif',
  '@media (max-width: 1132px)': {
    fontSize: '0.8rem',
  },
}))

const SurveyOffersOnboardingStepNine = ({ user, advanceLastStep, loading }) => {
  const buttonContent = () =>
    window.innerWidth < 1132
      ? 'Continue to Dashboard'
      : 'Continue to my Talkadot Dashboard'

  return (
    <>
      <WrapperContainer>
        <GraphicContainer>
          <Header>You are now set up to:</Header>
          <OrderedList>
            <ListItem>
              <ListItemNumber>1</ListItemNumber>
              Collect leads every time you speak
            </ListItem>
            <ListItem>
              <ListItemNumber>2</ListItemNumber>
              Get shareable testimonials and data
            </ListItem>
            <ListItem>
              <ListItemNumber>3</ListItemNumber>
              Automatically build your email list
            </ListItem>
          </OrderedList>
          <ButtonContainer>
            <SurveyOffersOnboardingCta
              buttonText={buttonContent()}
              handleClick={() => advanceLastStep()}
              loading={loading}
            />
          </ButtonContainer>
        </GraphicContainer>
        <UnicornContainer>
          <img
            src="https://d3qvkz8495pjoq.cloudfront.net/unicorn_mic_drop_cropped.png"
            alt="Talkadot Unicorn Mic Drop"
          />
        </UnicornContainer>
        <PostScriptum>
          p.s. we took you through a basic setup. There are more settings to
          discover from your dashboard.
        </PostScriptum>
      </WrapperContainer>
    </>
  )
}

export default SurveyOffersOnboardingStepNine
