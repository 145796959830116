import { useContext, useEffect, useState } from 'react'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from './groupContext'

import GroupEventsTable from './GroupEventsTable'

const GuestSpeakerEventTable = () => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const { setNotification } = useContext(
    AuthenticationContext
  )
  const {
    groupState: { selectedGroup }
  } = useContext(GroupContext)

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true)
        const res = await Api.loadGroupEvents(
          { 'group_id': selectedGroup.id }
        )

        if (!res.errors) {
          setLoading(false)
          setEvents(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        setLoading(false)
        return setNotification(err, 'error')
      }
    }

    fetchEvents()
  }, [])


  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          <GroupEventsTable
            groupEvents={events}
            groupEventsLoading={loading}
            selectedGroup={selectedGroup}
          />
        </>
      )}
    </>
  )
}

export default GuestSpeakerEventTable
