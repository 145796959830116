import { useState, useEffect, useContext } from 'react'
import { styled } from '@mui/system'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Skeleton,
  Dialog,
  DialogContent,
  Paper,
  Box,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { IoIosInformationCircleOutline } from 'react-icons/io'

import { AuthenticationContext } from '../authentication/authenticationContext'
import {
  usePermissionHelper,
  EMAIL_CAMPAIGNS,
} from '../../utils/permission_helper'
import { StyledTableCell } from '../groups/GroupsList'
import LeadsEmailCampaignRow from './LeadsEmailCampaignRow'
import InputText from '../common/InputText'

import Api from '../../services/api'
import isEmail from 'validator/lib/isEmail'

const SettingsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const SettingSubHeader = styled('div')({
  display: 'flex',
  fontSize: '1.25rem',
  fontFamily: 'Figtree, sans-serif',
  fontWeight: '500',
})

const StyledTableContainer = styled(TableContainer)({
  margin: '20px 0 40px',
})

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  th: {
    fontWeight: 'bold',
    color: theme.palette.white.main,
  },
}))

const EmailPreviewContainer = styled(DialogContent)(({ theme }) => ({
  background: theme.palette.greyExtraLight.main,
  padding: '25px',
  borderRadius: '10px',
}))

const EmailPreviewSubjectLine = styled('div')({
  marginBottom: '25px',
})

const EmailPreviewBlock = styled('div')(({ theme }) => ({
  background: theme.palette.white.main,
  padding: '15px',
}))

const ReplyToEmailContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '10px',
  marginTop: '5px',
  paddingTop: '20px',
})

const ReplyToEmailLabel = styled('div')({
  fontSize: '1rem',
  fontFamily: 'Figtree, sans-serif',
  fontWeight: '500',
  textAlign: 'left',
})

const LabelText = styled('span')({
  paddingRight: '5px',
})

const LabelIcon = styled('span')({
  fontSize: '1rem',
})

const LeadsEmailCampaignParent = () => {
  const [emailCampaigns, setEmailCampaigns] = useState([])
  const [replyToEmail, setReplyToEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [dialogContent, setDialogContent] = useState(null)
  const [replyToEmailError, setReplyToEmailError] = useState('')

  const { canUseEmailCampaigns } = usePermissionHelper()
  // uncomment when we want to show their limit and consumption for their billing interval
  // const { findPermission } = usePermissionHelper()
  // const permission = findPermission(EMAIL_CAMPAIGNS)
  // const planLimit = permission.limit
  // const consumed = permission.consumed
  const { toggleUpgradeModal, authData, setNotification, updatePermission } =
    useContext(AuthenticationContext)
  const { user } = authData

  useEffect(() => {
    const getEmailCampaigns = async () => {
      try {
        const res = await Api.fetchEmailCampaigns()

        if (!res.errors) {
          setEmailCampaigns(res.campaigns)
          setReplyToEmail(res.replyToEmail)

          // Update permission values for upgrade widget
          const emailCampaignsPermission = res.emailCampaignsPermission
          updatePermission(emailCampaignsPermission)

          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
      }
    }

    getEmailCampaigns()
  }, [])

  const toggleCampaignActive = async (campaignToUpdateId, toggleValue) => {
    if (!canUseEmailCampaigns()) {
      return toggleUpgradeModal(
        true,
        'Upgrade now to use our automated email campaigns!',
        EMAIL_CAMPAIGNS
      )
    }

    try {
      const res = await Api.updateEmailCampaign(campaignToUpdateId, {
        is_active: toggleValue,
      })

      if (res.errors) {
        throw res.errors
      } else {
        setEmailCampaigns(
          emailCampaigns.map((emailCampaign) =>
            emailCampaign.id === res.id
              ? { ...emailCampaign, isActive: res.isActive }
              : { ...emailCampaign }
          )
        )
      }
    } catch (err) {
      // handle error here
    }
  }

  const handlePreviewOpen = (campaign) => {
    setSelectedCampaign(campaign)
    setDialogContent({
      subject: campaign.emailSubject,
      replyTo: replyToEmail || user.email,
      content: campaign.emailContent,
    })
  }

  const renderEmailCampaignList = () => {
    return (
      <TableBody>
        {emailCampaigns.map((campaign) => {
          return (
            <LeadsEmailCampaignRow
              key={campaign.id}
              campaign={campaign}
              toggleCampaignActive={toggleCampaignActive}
              handlePreviewOpen={handlePreviewOpen}
            />
          )
        })}
      </TableBody>
    )
  }

  const updateReplyToEmail = async (newReplyToEmail) => {
    if (!canUseEmailCampaigns()) {
      return toggleUpgradeModal(
        true,
        'Upgrade now to use our automated email campaigns!'
      )
    }

    if (!isEmail(newReplyToEmail)) {
      setReplyToEmailError('Please enter a valid email address')
      return
    } else if (isEmail(newReplyToEmail) && replyToEmailError) {
      setReplyToEmailError('')
    }

    if (newReplyToEmail === replyToEmail) {
      return
    }

    try {
      const params = {
        account_setting: {
          reply_to_email: newReplyToEmail,
        },
      }

      const res = await Api.updateAccountSettings(params)

      if (res.errors) {
        throw res.errors
      } else {
        setReplyToEmail(res.replyToEmail)
        setNotification('Reply-To Email updated successfully', 'success')
      }
    } catch (err) {
      // handle error here
      console.log({ err })
    }
  }

  return (
    <>
      <SettingsContainer>
        <SettingSubHeader>Automated Email Triggers</SettingSubHeader>
        <StyledTableContainer component={Paper}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell component="th" scope="row" mobile lg>
                  Name
                </StyledTableCell>
                <StyledTableCell size="small" align="center" mobile>
                  Activated?
                </StyledTableCell>
                <StyledTableCell size="small" align="center">
                  Emails Sent
                </StyledTableCell>
                <StyledTableCell size="small" align="center" mobile>
                  Actions
                </StyledTableCell>
              </TableRow>
            </StyledTableHead>
            {renderEmailCampaignList()}
          </Table>
          {isLoading && <Skeleton variant="rectangular" height={400} />}
        </StyledTableContainer>
        <SettingSubHeader>Email Settings</SettingSubHeader>
        <ReplyToEmailContainer>
          <ReplyToEmailLabel>
            <LabelText>Reply-To Email</LabelText>
            <Tooltip
              title="This is the email address that will be used as the reply-to address for all emails sent by Talkadot."
              arrow>
              <LabelIcon>
                <IoIosInformationCircleOutline />
              </LabelIcon>
            </Tooltip>
          </ReplyToEmailLabel>
          <InputText
            value={replyToEmail}
            textAlign="right"
            placeholder="Edit reply to email..."
            error={replyToEmailError}
            handleOnBlur={updateReplyToEmail}
          />
        </ReplyToEmailContainer>
      </SettingsContainer>
      <Dialog
        open={!!selectedCampaign}
        fullWidth
        maxWidth="md"
        onClose={() => setSelectedCampaign(null)}>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => setSelectedCampaign(null)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {dialogContent && (
          <Typography>
            <EmailPreviewContainer>
              <EmailPreviewSubjectLine>
                <EmailPreviewBlock>
                  <div>
                    <b>Subject:</b> {dialogContent.subject}
                  </div>
                  <div>
                    <b>Reply-To:</b> {dialogContent.replyTo}
                  </div>
                </EmailPreviewBlock>
              </EmailPreviewSubjectLine>
              <EmailPreviewBlock
                dangerouslySetInnerHTML={{
                  __html: dialogContent.content,
                }}
              />
            </EmailPreviewContainer>
          </Typography>
        )}
      </Dialog>
    </>
  )
}

export default LeadsEmailCampaignParent
