import { useState, useEffect, forwardRef } from 'react'
import { styled } from '@mui/system'

const InputContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'width' && prop !== 'error',
})(({ theme, color, width, error }) => ({
  width: width || '100%',
  position: 'relative',
  textOverflow: 'ellipsis',
  '.underline': {
    borderBottom: `2px solid ${
      error ? theme.palette.error.main : color || theme.palette.primary.main
    }`,
    transform: error ? 'scale(1)' : 'scale(0, 1)',
    transition: 'all 200ms linear',
  },
  '.error': {
    display: error ? 'block' : 'none',
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    marginTop: '5px',
    textAlign: 'right',
  },
}))

const Input = styled('input', {
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'textAlign' && prop !== 'error',
})(({ theme, color, textAlign, error }) => ({
  width: '100%',
  transition: '300ms all ease-in-out',
  fontFamily: 'Figtree, sans-serif',
  fontWeight: '400',
  fontSize: '0.875rem',
  border: 'none',
  position: 'relative',
  backgroundColor: 'transparent',
  color: error
    ? theme.palette.error.main
    : color || theme.palette.tertiary.main,
  textOverflow: 'ellipsis',
  textAlign: textAlign,
  '&:focus': {
    color: color || theme.palette.primary.main,
    outline: 'none',
  },
  '&:focus + .underline': {
    transform: 'scale(1)',
  },
  '&::placeholder': {
    color: color || theme.palette.tertiary.light,
    opacity: '50%',
  },
}))

const InputText = forwardRef((props, ref) => {
  // Note: forwardRef only accepts props and ref as arguments
  const {
    value,
    handleOnBlur,
    width,
    inputStyle,
    placeholder,
    color,
    onChange,
    textAlign,
    error,
  } = props
  const [formValue, setFormValue] = useState('')

  useEffect(() => {
    if (value) {
      setFormValue(value)
    }
  }, [value])

  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value)
    }
    setFormValue(value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      e.target.blur()
    }
  }

  return (
    <InputContainer width={width} color={color} error={error}>
      <Input
        value={formValue}
        onChange={(e) => handleOnChange(e.currentTarget.value)}
        onBlur={(e) => handleOnBlur(e.currentTarget.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder || 'Edit value...'}
        style={inputStyle || {}}
        color={color}
        textAlign={textAlign || 'left'}
        error={error}
        ref={ref}
      />
      <div className="underline" />
      <div className="error">{error}</div>
    </InputContainer>
  )
})

export default InputText
