import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const NotificationsHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.navText,
  marginBottom: theme.spacing(2.5),
}))

const DashboardSubheader = ({ isNewUser }) => {
  let content = ''

  if (isNewUser) {
    content = 'Welcome to the community of evidence-based unicorn speakers!'
  }
  // TODO: uncomment this once we actually have something to show on the dashboard
  // else {
  //   content =
  //     "Here's what's been going on with your account. Let's keep your speaking game strong!"
  // }

  return <NotificationsHeader variant="body2">{content}</NotificationsHeader>
}

export default DashboardSubheader
