import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

import { ReactComponent as UpgradeIcon } from '../../icons/upgradeIcon.svg'

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'showText',
})(({ theme, showText }) => ({
  color: theme.palette.base.white,
  textWrap: 'nowrap',
  minWidth: showText ? '140px' : '50px',
  width: showText ? 'auto' : '50px',
  height: showText ? 'auto' : '50px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  svg: {
    marginRight: showText ? theme.spacing(1) : 0,
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '50px',
    height: '50px',
    svg: {
      marginRight: 0,
    },
    '& .button-text': {
      display: 'none',
    },
  },
}))

const UpgradeButton = ({ showText = true, status }) => {
  const history = useHistory()

  return (
    <StyledButton
      variant="contained"
      color={status === 'urgent' ? 'primary' : 'tertiary'}
      showText={showText}
      onClick={() => history.push('/account/billing')}>
      <UpgradeIcon />
      {showText && <span className="button-text">Upgrade Plan</span>}
    </StyledButton>
  )
}

export default UpgradeButton
