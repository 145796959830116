import { useContext } from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/system'

import {
  AccountSettingsCard,
  AccountSettingsCardInner,
  AccountSettingsCardTitle,
} from './AccountSettingsParent'
import BetaChip from '../common/BetaChip'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { usePermissionHelper } from '../../utils/permission_helper'

const ZapierEmbedContainer = styled('div')({
  width: '100%',
})

const ZapierSettings = ({ user }) => {
  const { toggleUpgradeModal } = useContext(AuthenticationContext)
  const { canUseIntegrations } = usePermissionHelper()

  return (
    <AccountSettingsCard>
      <AccountSettingsCardTitle variant="h5">
        Zapier Integration
        <BetaChip />
      </AccountSettingsCardTitle>
      <AccountSettingsCardInner>
        {canUseIntegrations() ? (
          <ZapierEmbedContainer>
            <zapier-full-experience
              sign-up-email={user.email}
              sign-up-first-name={user.first_name}
              sign-up-last-name={user.last_name}
              client-id="bNelOALhrq25MOMbF0wxa8Cov7Q1dUtUslpIA7wC"
              theme="light"
              app-search-bar-display="show"
              intro-copy-display="hide"
              app-selection-display="hide"
              zap-limit="0"
              zap-create-from-scratch-display="hide"
            />
          </ZapierEmbedContainer>
        ) : (
          <Button
            onClick={() =>
              toggleUpgradeModal(
                true,
                'Upgrade to sync your leads to your CRM of choice with Zapier!'
              )
            }
            variant="contained"
            color="primary">
            Upgrade To Use Zapier
          </Button>
        )}
      </AccountSettingsCardInner>
    </AccountSettingsCard>
  )
}

export default ZapierSettings
