export const ReattendSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.613"
      height="17.028"
      viewBox="0 0 20.613 17.028">
      <g id="fitness-outline" transform="translate(-47 -79)">
        <path
          id="Path_260"
          data-name="Path 260"
          d="M76.826,80a5.259,5.259,0,0,0-4.306,2.727A5.259,5.259,0,0,0,68.214,80a4.206,4.206,0,0,0-4.224,4.125c-.049,4.659,3.853,7.972,8.13,10.756a.736.736,0,0,0,.8,0c4.276-2.784,8.178-6.1,8.13-10.756A4.206,4.206,0,0,0,76.826,80Z"
          transform="translate(-15.213)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_261"
          data-name="Path 261"
          d="M48,164.662h5.011L55.159,160l2.148,7.77,2.148-4.662,1.432,3.108h5.727"
          transform="translate(0 -76.987)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
