import { useState } from 'react'
import { styled } from '@mui/system'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material'

import history from '../../routes/history'

import GroupsListRow from './GroupsListRow'
import ConfirmDialog from '../common/ConfirmDialog'
import { device } from '../../responsive'

export const StyledTableContainer = styled(TableContainer)({
  marginTop: '20px',
})

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  th: {
    fontWeight: 'bold',
  },
}))

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'lg' && prop !== 'mobile',
})(({ lg, mobile }) => ({
  width: lg ? '40%' : '10%',
  fontFamily: 'Figtree, sans-serif',
  [`@media ${device.mobile}`]: {
    width: lg ? '80%' : '10%',
    display: mobile ? 'table-cell' : 'none',
  },
}))

const initialConfirmProps = {
  title: '',
  message: '',
  onConfirm: () => {},
}

const GroupsList = ({
  groups,
  groupsLoading,
  setGroup,
  setOpen,
  setSettingsOpen,
  handleDeleteGroup,
  handleRemoveFromGroup,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState(initialConfirmProps)

  const handleLeaveGroupClick = (groupId) => {
    setConfirmProps({
      title: 'Leave Chapter?',
      message: 'Are you sure you want to leave this Chapter?',
      onConfirm: () => handleRemoveFromGroup(groupId),
    })

    setConfirmOpen(true)
  }

  const handleDeleteGroupClick = (groupId) => {
    setConfirmProps({
      title: 'Delete Chapter?',
      message: 'Are you sure you want to delete this Chapter?',
      onConfirm: () => handleDeleteGroup(groupId),
    })

    setConfirmOpen(true)
  }

  const handleEditGroup = (group) => {
    setGroup(group)
    setOpen(true)
  }

  const handleViewGroup = (groupId) => {
    const group = groups.find((group) => group.id === groupId)

    return history.push(`/groups/${groupId}/events`)
  }

  const handleOpenGroupSettings = (group) => {
    setGroup(group)
    setSettingsOpen(true)
  }

  const renderTableRows = () => {
    if (!groups || groups.length === 0) {
      return (
        <TableRow>
          <TableCell width="100%">No chapters found</TableCell>
        </TableRow>
      )
    }

    return groups.map((group) => {
      return (
        <GroupsListRow
          key={group.id}
          group={group}
          handleViewGroup={handleViewGroup}
          handleEditGroup={handleEditGroup}
          handleDeleteGroup={handleDeleteGroupClick}
          handleLeaveGroup={handleLeaveGroupClick}
          handleGroupSettings={handleOpenGroupSettings}
        />
      )
    })
  }

  if (groupsLoading) {
    return <div>Loading...</div>
  }

  return (
    <StyledTableContainer component={Paper}>
      <Table>
        <StyledTableHead>
          <TableRow>
            <StyledTableCell lg mobile>
              Name
            </StyledTableCell>
            <StyledTableCell lg>Description</StyledTableCell>
            <StyledTableCell mobile>Role</StyledTableCell>
            <StyledTableCell align="center" mobile>
              Actions
            </StyledTableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>{renderTableRows()}</TableBody>
      </Table>
      <ConfirmDialog
        title={confirmProps.title}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmProps.onConfirm}>
        {confirmProps.message}
      </ConfirmDialog>
    </StyledTableContainer>
  )
}

export default GroupsList
