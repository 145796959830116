/**
 * Retrieves the value from an object based on a nested path.
 * @param {Object} obj - The object to retrieve the value from.
 * @param {string} path - The nested path (e.g., 'event.talkTitle').
 * @returns {*} - The value at the specified path.
 */
const getValueByPath = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

/**
 * Comparator function for descending order.
 * @param {Object} a - First item to compare.
 * @param {Object} b - Second item to compare.
 * @param {string} orderBy - Key to compare by.
 * @returns {number} - Comparison result: negative if a < b, positive if a > b, 0 if equal.
 */
const descendingComparator = (a, b, orderBy) => {
  let valueA = getValueByPath(a, orderBy)
  let valueB = getValueByPath(b, orderBy)

  // Handle null values
  if (valueA === null && valueB === null) {
    return 0
  }
  if (valueA === null) {
    return 1
  }
  if (valueB === null) {
    return -1
  }

  // Convert strings to lowercase for case-insensitive comparison
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    valueA = valueA.toLowerCase()
    valueB = valueB.toLowerCase()
  }

  // Perform comparison
  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

/**
 * Returns a comparator function based on the order and key to sort by.
 * @param {string} order - Sorting order: 'asc' or 'desc'.
 * @param {string} orderBy - Key to sort by.
 * @returns {function} - Comparator function.
 */
export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
