import { useContext } from 'react'

import { AuthenticationContext } from '../authentication/authenticationContext'

import IntegrationSettings from '../accountSettings/IntegrationSettings'
import ZapierSettings from '../accountSettings/ZapierSettings'
import PageContainer from '../common/PageContainer'

const LeadsIntegrationsParent = () => {
  const { authData } = useContext(AuthenticationContext)
  const { user } = authData

  return (
    <PageContainer>
      <IntegrationSettings />
      <ZapierSettings user={user} />
    </PageContainer>
  )
}

export default LeadsIntegrationsParent
