import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthenticationContext } from './authenticationContext'
import { LinkedInLoginButton } from 'react-social-login-buttons'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import envConfig from '../../envConfig'
import { styled } from '@mui/system'
import { AUTHENTICATION_TYPES } from './OAuthButtons'
import { red } from '@mui/material/colors'

const LinkedinButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  span: {
    fontWeight: '200',
    fontSize: '19px',
    alignItems: 'center',
  },
})

const LinkedinOAuthButton = ({
  authenticationType,
  membershipPlan,
  registrationToken,
  redirect,
}) => {
  const { authenticateUserByLinkedin, registerUserByLinkedin } = useContext(
    AuthenticationContext
  )

  const location = useLocation()
  const registrationPage = location.pathname.includes('register')

  const handleClick = async () => {
    return linkedInLogin()
  }

  const handleLinkedinSuccess = async (code) => {
    if (authenticationType === AUTHENTICATION_TYPES.REGISTRATION) {
      await registerUserByLinkedin(
        code,
        membershipPlan?.token,
        registrationToken
      )
    } else {
      await authenticateUserByLinkedin(code, redirect)
    }
  }

  const { linkedInLogin } = useLinkedIn({
    clientId: envConfig.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: `${envConfig.REACT_APP_CLIENT_ENDPOINT}/linkedin`,
    scope: 'r_emailaddress r_liteprofile',
    onSuccess: async (code) => {
      await handleLinkedinSuccess(code)
    },
    onError: (error) => {
      // alert(JSON.stringify(error))
    },
  })

  return (
    <LinkedinButtonContainer>
      <LinkedInLoginButton onClick={handleClick}>
        <span>
          {registrationPage
            ? 'Register With LinkedIn'
            : 'Sign In With LinkedIn'}
        </span>
      </LinkedInLoginButton>
    </LinkedinButtonContainer>
  )
}

export default LinkedinOAuthButton
