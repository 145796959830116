import { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Select,
  FormControl,
} from '@mui/material'
import { ClickAwayListener } from '@mui/base'

import {
  IoEllipsisHorizontal,
  IoPersonRemoveOutline,
  IoEyeOutline,
  IoPencilOutline,
} from 'react-icons/io5'

import envConfig from '../../envConfig'
import { device } from '../../responsive'

export const GroupTableRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '3fr repeat(4, 2fr) 100px 60px',
  textAlign: 'left',
  padding: '0 1.5rem',
  [`@media ${device.mobile}`]: {
    gridTemplateColumns: '1fr auto',
  },
})

export const GroupTableCell = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'speakerCell' && prop !== 'centered' && prop !== 'mobile',
})(({ speakerCell, theme, centered, mobile }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: centered ? 'center' : 'flex-start',
  padding: '1.5rem 0',
  fontWeight: speakerCell ? 'bold' : 'inherit',
  color: speakerCell ? theme.palette.secondary.main : 'black',
  [`@media ${device.mobile}`]: {
    display: mobile ? 'flex' : 'none',
  },
}))

const UserAvatar = styled(Avatar)({
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  img: {
    width: '102%',
    height: '102%',
  },
  marginRight: '1rem',
})

const ClickableText = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
})

const EditIcon = styled(IoPencilOutline)({
  marginLeft: '0.5rem',
})

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: theme.palette.tertiary.extraLight,
  },
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.secondary.extraLight} !important`,
  },
}))

const GroupUsersRow = ({
  user,
  handleViewUser,
  handleRemoveUserClick,
  handleUpdateUserMembership,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [editingRole, setEditingRole] = useState(false)
  const [role, setRole] = useState(user.role)
  const [selectOpen, setSelectOpen] = useState(false)

  useEffect(() => {
    setRole(user.role)
  }, [user.role])

  const handleOpenDropdown = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  const handleClickAway = (e) => {
    if (!selectOpen) {
      setEditingRole(false)
    }
  }

  const renderMenuItems = (user) => {
    return (
      <div>
        {/* TODO: How should we handle navigating back once on user's profile page? Currently setup to open in a new tab. */}
        {/* TODO: How should we handle viewing profile pages that are not public? Probably ignore public setting when in group. */}
        {/*  How do we distinguish the request came from the Group page? */}
        <MenuItem
          component="a"
          href={`${envConfig.REACT_APP_PUBLIC_PAGE_ENDPOINT}/s/${user?.shortlink}`}
          target="_blank"
          rel="noreferrer">
          <ListItemIcon>
            <IoEyeOutline />
          </ListItemIcon>
          <ListItemText primary="View" />
        </MenuItem>
      </div>
    )
  }

  const avgRating =
    parseInt(
      (user.events_aggregate?.totalResponses?.positives /
        user.events_aggregate?.totalResponses?.total) *
        100
    ) || 0

  const reattendRate =
    parseInt(
      ((user.events_aggregate?.reattendBreakdown?.d_yes +
        user.events_aggregate?.reattendBreakdown?.p_yes) /
        user.events_aggregate?.reattendBreakdown?.total_reattend_answers) *
        100
    ) || 0

  return (
    <GroupTableRow key={user.id}>
      <GroupTableCell speakerCell mobile>
        <ClickableText onClick={() => handleViewUser(user)}>
          <UserAvatar
            sx={{ width: 50, height: 50 }}
            src={user.avatar}
            alt={user.first_name}
          />
          {user.first_name} {user.last_name}
        </ClickableText>
      </GroupTableCell>
      <GroupTableCell centered>
        {user.events_aggregate.totalEvents}
      </GroupTableCell>
      <GroupTableCell centered>{`${avgRating}%`}</GroupTableCell>
      <GroupTableCell centered>{`${reattendRate}%`}</GroupTableCell>
      <GroupTableCell centered>
        {user.events_aggregate.totalResponses.total}
      </GroupTableCell>
      {/* TODO: Add leads column. How do we want to define a lead for this scenario? */}
      {/* <GroupTableCell centered>leads</GroupTableCell> */}
      <GroupTableCell centered mobile>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenDropdown}
          aria-label="Open to show more"
          title="Open to show more">
          <IoEllipsisHorizontal />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          {renderMenuItems(user)}
        </Menu>
      </GroupTableCell>
    </GroupTableRow>
  )
}

export default GroupUsersRow
