import { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { AuthenticationContext } from './authenticationContext'

import OAuthProviderAuthenticationForm from './OAuthProviderAuthenticationForm'
import LoginForm from './LoginForm'
import PageContainer from '../common/PageContainer'

const OAuthProviderAuthenticationParent = () => {
  const { authData } = useContext(AuthenticationContext)
  const { user } = authData

  return (
    <PageContainer noNavPage={true}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {user?.id ? (
        <OAuthProviderAuthenticationForm />
      ) : (
        <LoginForm redirect={false} />
      )}
    </PageContainer>
  )
}

export default OAuthProviderAuthenticationParent
