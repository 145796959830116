import { IoTrashOutline } from 'react-icons/io5'
import { IconButton } from '@mui/material'
import { styled } from '@mui/system'
import { device } from '../../responsive'
import { truncText } from '../common/StringFormatters'
import { isMobileOnly } from 'react-device-detect'

export const TeamMemberTableRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '5fr 1fr',
  textAlign: 'left',
  padding: '0 1.5rem',
  borderBottom: '1px solid #e0e0e0',
  [`@media ${device.mobile}`]: {
    gridTemplateColumns: '1fr auto',
    padding: '0 1rem',
  },
})
export const TeamTableCell = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'centered'
})(({ theme, centered }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: centered ? 'center' : 'flex-start',
  padding: '1.5rem 0',
  color: 'black',
  [`@media ${device.mobile}`]: {
    padding: '1rem 0',
  },
}))

const TeamMemberRow = ({
  associate,
  deleteAssociate
}) => {
  const handleDelete = async (associate) => {
    await deleteAssociate(associate)
  }

  const renderEmailAddress = () => {
    const maxChars = isMobileOnly ? 22 : 60

    return truncText(associate.email, maxChars)
  }

  return (
    <TeamMemberTableRow key={associate.id}>
      <TeamTableCell>
        {renderEmailAddress()}
      </TeamTableCell>
      <TeamTableCell centered>
        <IconButton onClick={() => handleDelete(associate)}>
          <IoTrashOutline />
        </IconButton>
      </TeamTableCell>
    </TeamMemberTableRow>
  )
}
export default TeamMemberRow
