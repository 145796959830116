import { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { useMediaQuery, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import SpeakerSlide from '../../common/SpeakerSlide'

const ORIGINAL_SLIDE_WIDTH = 960
const ORIGINAL_SLIDE_HEIGHT = 540

const SpeakerslideContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'scale',
})(({ theme, scale }) => ({
  width: `calc(${ORIGINAL_SLIDE_WIDTH * scale}px)`,
  height: `calc(${ORIGINAL_SLIDE_HEIGHT * scale}px)`,
  overflow: 'hidden',
  position: 'relative',
  boxShadow: theme.shape.boxShadow.xs,
}))

const ScaleWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'scale',
})(({ scale }) => ({
  transform: `scale(${scale})`,
  transformOrigin: 'left top',
  top: '0',
  left: '0',
  width: `calc(${ORIGINAL_SLIDE_WIDTH * scale}px)`,
  height: `calc(${ORIGINAL_SLIDE_HEIGHT * scale}px)`,
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  a: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    fontWeight: theme.fontWeights.semiBold,
  },
}))

const CodeBuilderSettingsDownloadSlide = ({ user, code, slideRef }) => {
  const [scale, setScale] = useState(0.625)

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    // if (isSmallScreen) {
    //   setScale(0.2)
    if (isMediumScreen) {
      setScale(0.3)
    } else if (isLargeScreen) {
      setScale(0.625)
    }
  }, [isLargeScreen, isMediumScreen, isSmallScreen])

  return (
    <>
      <SpeakerslideContainer scale={scale}>
        <ScaleWrapper scale={scale}>
          <SpeakerSlide
            componentRef={slideRef}
            avatar={user?.avatar}
            surveyLink={user?.shortlink}
            socialLinks={user?.socialLinks}
            email={user?.email}
            name={user?.first_name}
            size={'lg'}
            code={code}
          />
        </ScaleWrapper>
      </SpeakerslideContainer>
      <StyledTypography variant="body2">
        Need changes to your profile picture?{' '}
        <Link to="/account/settings">Update it in your profile.</Link>
      </StyledTypography>
    </>
  )
}

export default CodeBuilderSettingsDownloadSlide
