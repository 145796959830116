import { useState, useEffect, useContext } from 'react'
import { styled } from '@mui/system'
import { IconButton, Tooltip } from '@mui/material'

import { AuthenticationContext } from '../../authentication/authenticationContext'

import { ReactComponent as EyeIcon } from '../../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EyeOffIcon } from '../../../icons/eyeOffIcon_16x16.svg'

const ToggleQuestionButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'enabled' && prop !== 'iconColor',
})(({ theme, enabled, iconColor }) => ({
  svg: {
    path: {
      stroke: enabled
        ? iconColor || theme.palette.neutral.veryDark
        : theme.palette.error.semiDark,
    },
  },
}))

const UPGRADE_HELPER_TEXT =
  'Upgrade your plan to customize question visibility and more!'

const CodeBuilderToggleQuestionButton = ({
  handleToggleQuestion,
  enabled,
  iconColor,
  canToggleVisibility,
  enabledTooltip = 'Question is enabled. Toggle to disable question.',
  disabledTooltip = 'Question is disabled, it will not be shown when you use this code. Toggle to enable question.',
}) => {
  const [open, setOpen] = useState(false)
  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  useEffect(() => {
    return () => {
      setOpen(false)
    }
  }, [enabled])

  const makeTooltipTitle = () => {
    if (!canToggleVisibility) {
      return 'You need to upgrade your plan to toggle the visibility of this question.'
    }

    return enabled ? enabledTooltip : disabledTooltip
  }

  const handleToggleClick = async () => {
    if (!canToggleVisibility) {
      return toggleUpgradeModal(true, UPGRADE_HELPER_TEXT, 'CUSTOMIZATIONS')
    }

    await handleToggleQuestion()
  }

  return (
    <Tooltip
      title={makeTooltipTitle()}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disableInteractive
      arrow>
      <ToggleQuestionButton
        onClick={handleToggleClick}
        enabled={enabled}
        iconColor={iconColor}>
        {enabled ? <EyeIcon /> : <EyeOffIcon />}
      </ToggleQuestionButton>
    </Tooltip>
  )
}

export default CodeBuilderToggleQuestionButton
