import React from 'react'
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
} from '@mui/material'
import { styled } from '@mui/system'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    fontSize: '20px',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
}))

const OtherOptionContent = styled('div')({
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
})

const OtherOptionTextfield = styled(TextField)({
  input: {
    fontSize: '20px',
    fontWeight: '300',
  },
  marginTop: '8px',
})

const ReferralQuestionParent = ({
  setSelectedReferrerOption,
  selectedReferrerOption,
  referrerOptions,
  setAdditionalReferrerContext,
  additionalReferrerContext,
}) => {
  return (
    <FormControl fullWidth={true}>
      <br />
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        onChange={(e) => setSelectedReferrerOption(e.target.value)}>
        {referrerOptions?.map((referrerOption) => {
          return (
            <StyledFormControlLabel
              key={referrerOption.id}
              value={referrerOption.text}
              control={
                <Radio
                  checked={selectedReferrerOption === referrerOption.text}
                />
              }
              label={
                !referrerOption.additionalContextRequired ? (
                  referrerOption.text
                ) : (
                  <OtherOptionContent>
                    <span>Other</span>
                    <OtherOptionTextfield
                      variant="standard"
                      type="text"
                      margin="normal"
                      label={null}
                      placeholder="Please specify"
                      fullWidth={true}
                      value={additionalReferrerContext}
                      onChange={(e) => {
                        setSelectedReferrerOption(referrerOption.text)
                        setAdditionalReferrerContext(e.target.value)
                      }}
                    />
                  </OtherOptionContent>
                )
              }
            />
          )
        })}
      </RadioGroup>{' '}
    </FormControl>
  )
}

export default ReferralQuestionParent
