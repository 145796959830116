import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '@mui/material'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'

const LinkButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'baseColor',
})(({ baseColor }) => ({
  position: 'absolute',
  marginTop: '0px',
  top: '10px',
  right: '10px',
  maxWidth: '200px',
  border: `1px solid ${baseColor}`,
  color: baseColor,
  '&:hover': {
    border: `1px solid ${baseColor}`,
    color: baseColor,
  },
  textTransform: 'capitalize',
}))

const ButtonParent = ({ baseUrl, content, baseColor }) => {
  const location = useLocation()
  const searchParams = location.search

  return (
    <Link to={`/${baseUrl}${searchParams}`}>
      <LinkButton variant="outlined" baseColor={baseColor}>
        {content}
      </LinkButton>
    </Link>
  )
}

export const GoToRegistrationPageButton = () => (
  <ButtonParent
    baseUrl="register"
    content="Create Account"
    baseColor={useTheme().palette.secondary.main}
  />
)

export const GoToLoginPageButton = () => (
  <ButtonParent
    baseUrl="login"
    content="Sign In"
    baseColor={useTheme().palette.primary.main}
  />
)
