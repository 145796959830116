import { useState } from 'react'
import { styled } from '@mui/system'
import { Typography, Tooltip } from '@mui/material'
import { IconButton, Menu, MenuItem, CircularProgress } from '@material-ui/core'
import { motion } from 'framer-motion/dist/framer-motion'

import { IoEllipsisHorizontal } from 'react-icons/io5'
import { FaStar } from 'react-icons/fa'
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri'

import { addAlpha } from '../../utils/color'

import { ReactComponent as ChatBubble } from '../../icons/chatBubble.svg'

const TestimonialContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'showFavouriteButton' && prop !== 'testimonialType',
})(({ showFavouriteButton, testimonialType }) => ({
  display: 'flex',
  width: '100%',
  minHeight: '50px',
  justifyContent: 'space-between',
  padding: showFavouriteButton ? '18px 18px 18px 0' : '18px',
  backgroundColor: testimonialType === 'improvement' && '#F8F8F8',
  borderRadius: testimonialType === 'improvement' && '10px',
}))

const FavButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: '-18px',
  marginRight: '15px',
})

const FavButtonWrapper = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== 'favourited',
})(({ theme, favourited }) => ({
  // TODO: change to palette color
  background: favourited
    ? theme.palette.secondary.main
    : addAlpha(theme.palette.tertiary.main, 0.2),
  borderRadius: '10px 0',
  padding: '2px',
}))

const FavButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'favourited',
})(({ favourited }) => ({
  fontSize: '18px',
  padding: '4px',
  svg: {
    stroke: favourited ? 'white' : addAlpha('#ffffff', 0.8),
    fill: favourited ? 'white' : addAlpha('#ffffff', 0.8),
  },
}))

const FeedbackActions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: '-5px',
  right: '0',
  cursor: 'pointer',
})

const DropDownButton = styled(IconButton)({
  padding: '4px',
  fontSize: '32px',
})

const TestimonialText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'publicShare',
})(({ theme, publicShare }) => ({
  flex: '1',
  padding: '10px 10px',
  hyphens: 'auto',
  wordBreak: 'break-word',
  color: publicShare ? 'inherit' : theme.palette.tertiary.disabledText,
}))

const OpenQuoteMark = styled(RiDoubleQuotesL)({
  fontSize: '24px',
})

const CloseQuoteMark = styled(RiDoubleQuotesR)({
  fontSize: '24px',
  alignSelf: 'flex-end',
})

const ImprovementIcon = styled(ChatBubble)({
  width: '30px',
  height: '30px',
})

export const Testimonial = ({
  feedbackType,
  feedback,
  canShareReport,
  canUseFeedbackActions,
  canFavourite,
  handleViewTestimonialData,
  handleDownloadTestimonial,
  handleToggleFavourite,
  favLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenDropdown = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  const handleViewDataClick = (feedback) => {
    setAnchorEl(null)
    handleViewTestimonialData(feedback)
  }

  const handleDownloadClick = (feedback) => {
    setAnchorEl(null)
    handleDownloadTestimonial(feedback)
  }

  const renderFavIcon = () => {
    if (favLoading) {
      return <CircularProgress size={15} />
    }

    return <FaStar />
  }

  const menuItems = () => {
    if (feedbackType === 'improvement' || !canShareReport) {
      return [
        {
          title: 'View Testimonial Data',
          onClick: (feedback) => handleViewDataClick(feedback),
        },
      ]
    } else {
      return [
        {
          title: 'View Testimonial Data',
          onClick: (feedback) => handleViewDataClick(feedback),
        },
        {
          title: feedback.public_share
            ? 'Download Shareable Testimonial'
            : 'Sharing Permission Not Granted',
          onClick: feedback.public_share
            ? (feedback) => handleDownloadClick(feedback)
            : null,
        },
      ]
    }
  }

  const showFavouriteButton =
    canUseFeedbackActions && canFavourite && feedback.public_share

  if (feedbackType !== 'improvement') {
    return (
      <TestimonialContainer showFavouriteButton={showFavouriteButton}>
        {canUseFeedbackActions && (
          <FeedbackActions>
            <DropDownButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenDropdown}
              aria-label="Open to show more"
              title="Open to show more">
              <IoEllipsisHorizontal />
            </DropDownButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDropdown}>
              {menuItems().map((item, i) => (
                <MenuItem
                  onClick={() => item.onClick(feedback)}
                  key={item.title}
                  value={item.title}
                  disabled={!feedback.public_share && i === 1}>
                  {item.title}
                </MenuItem>
              ))}
            </Menu>
          </FeedbackActions>
        )}
        {showFavouriteButton && (
          <FavButtonContainer>
            <FavButtonWrapper
              favourited={feedback.favourite}
              whileHover={{ scale: 1.05, transition: { duration: 0.05 } }}
              whileTap={{ scale: 0.95 }}>
              <Tooltip
                placement="top-start"
                title="Star this feedback to move it to the top of your report">
                <FavButton
                  onClick={() => handleToggleFavourite(feedback)}
                  aria-label="Favorite"
                  title="Favorite"
                  disabled={favLoading}
                  favourited={feedback.favourite}>
                  {renderFavIcon()}
                </FavButton>
              </Tooltip>
            </FavButtonWrapper>
          </FavButtonContainer>
        )}
        <OpenQuoteMark />
        <TestimonialText
          variant="body1"
          component="div"
          publicShare={feedback.public_share}>
          {feedback.text}
        </TestimonialText>
        <CloseQuoteMark />
      </TestimonialContainer>
    )
  } else {
    return (
      <TestimonialContainer testimonialType={'improvement'}>
        {canUseFeedbackActions && (
          <FeedbackActions>
            <DropDownButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenDropdown}
              aria-label="Open to show more"
              title="Open to show more">
              <IoEllipsisHorizontal />
            </DropDownButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDropdown}>
              {menuItems().map((item) => (
                <MenuItem
                  onClick={() => item.onClick(feedback)}
                  key={item.title}
                  value={item.title}>
                  {item.title}
                </MenuItem>
              ))}
            </Menu>
          </FeedbackActions>
        )}
        <ImprovementIcon />
        <Typography
          variant="body2"
          component="div"
          style={{
            flex: '1',
            padding: '0px 15px',
          }}>
          {feedback.text}
        </Typography>
      </TestimonialContainer>
    )
  }
}
