import { styled } from '@mui/system'

import Api from '../../services/api'

import EditAvatar from '../accountSettings/EditAvatar'

const SettingsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(1),
  marginBottom: '25px',
}))

const AvatarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(3),
}))

export const SettingsCardColumn = styled('div')(({ theme }) => ({
  textAlign: 'left',
  width: '100%',
  padding: theme.spacing(3),
}))

const GroupEventSettings = ({
  selectedGroup,
  updateGroup,
  updateGroups,
  setNotification,
}) => {
  const updateAvatar = async (storageUrl) => {
    const params = {
      group_metadatum: {
        avatar: storageUrl,
      },
      group_id: selectedGroup.id,
    }

    try {
      const res = await Api.updateGroupMetadatum(params)

      if (!res.errors) {
        updateGroup({ avatar: storageUrl })
        updateGroups({ avatar: storageUrl })
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  return (
    <SettingsContainer>
      <AvatarContainer>
        <EditAvatar
          currentAvatar={selectedGroup.avatar}
          submitOverrideFunction={updateAvatar}
          isGroupAvatar={true}
          size={300}
        />
      </AvatarContainer>
    </SettingsContainer>
  )
}

export default GroupEventSettings
