import { styled } from '@mui/system'

import envConfig from '../../envConfig'

import GroupUsersRow from './GroupUsersRow'
import { GroupTableRow, GroupTableCell } from './GroupUsersRow'

import { device } from '../../responsive'

const GroupContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '0.875rem',
})

const GroupTable = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  borderRadius: '4px',
  minWidth: '312px',
})

const GroupTableHeader = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '3fr repeat(4, 2fr) 120px 60px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.secondary.light,
  textAlign: 'left',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  padding: '0 1.5rem',
  [`@media ${device.mobile}`]: {
    gridTemplateColumns: '1fr auto',
  },
}))

const GroupTableBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& > div:nth-of-type(even)': {
    background: theme.palette.tertiary.extraLight,
  },
  '& > div:last-child': {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}))

const GroupUsersTable = ({ groupUsers, groupUsersLoading }) => {
  const handleViewUser = (user) => {
    window
      .open(
        `${envConfig.REACT_APP_PUBLIC_PAGE_ENDPOINT}/s/${user?.shortlink}`,
        '_blank'
      )
      .focus()
  }

  const renderGroupUsers = () => {
    if (groupUsersLoading) {
      return (
        <GroupTableBody>
          <GroupTableRow>
            <GroupTableCell>Loading...</GroupTableCell>
          </GroupTableRow>
        </GroupTableBody>
      )
    }

    if (!groupUsers?.length) {
      return (
        <GroupTableBody>
          <GroupTableRow>
            <GroupTableCell>
              Group does not have any members yet.
            </GroupTableCell>
          </GroupTableRow>
        </GroupTableBody>
      )
    }

    return (
      <GroupTableBody>
        {groupUsers?.map((user) => (
          <GroupUsersRow
            key={user.id}
            user={user}
            handleViewUser={handleViewUser}
          />
        ))}
      </GroupTableBody>
    )
  }

  return (
    <GroupContainer>
      <GroupTable>
        <GroupTableHeader>
          <GroupTableCell mobile>Speaker</GroupTableCell>
          <GroupTableCell centered>Talks Given</GroupTableCell>
          <GroupTableCell centered>Avg Rating</GroupTableCell>
          <GroupTableCell centered>Reattend %</GroupTableCell>
          <GroupTableCell centered>Responses</GroupTableCell>
          {/* <GroupTableCell centered>Leads</GroupTableCell> */}
          {/* <GroupTableCell centered></GroupTableCell> */}
          <GroupTableCell centered mobile>
            Actions
          </GroupTableCell>
        </GroupTableHeader>
        {renderGroupUsers()}
      </GroupTable>
    </GroupContainer>
  )
}

export default GroupUsersTable
