import { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/system'

import { ReactComponent as UsersIcon } from '../../icons/usersIcon_24x24.svg'
import { ReactComponent as UpgradeIcon } from '../../icons/zapIcon_24x24.svg'

import { AuthenticationContext } from '../authentication/authenticationContext'
import Api from '../../services/api'

import { makeReferralLink, processWithFallback } from '../common/helpers'

import {
  Card,
  CardInnerReferral,
} from '../dashboard/v2/DashboardCardComponents'
import ChipList from './ChipList'
import ShareParent from './ShareParent'
import QrParent from './QrParent'

const RightColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '250px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    alignItems: 'center',
  },
}))

const ReferralCountWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const ReferralParent = () => {
  const [signups, setSignups] = useState(0)
  const [upgrades, setUpgrades] = useState(0)
  const {
    authData: {
      user: { shortlink },
    },
    setNotification,
  } = useContext(AuthenticationContext)

  useEffect(() => {
    const fetchReferralData = async () => {
      try {
        const res = await Api.getReferralData()
        if (!res.errors) {
          setSignups(res.signups)
          setUpgrades(res.upgrades)
        } else {
          throw res.errors
        }
      } catch (error) {
        setNotification(error, 'error')
      }
    }

    fetchReferralData()
  }, [])

  const referralLink = makeReferralLink(shortlink)

  if (!shortlink) {
    return null
  }

  const chipItems = [
    {
      label: 'signups',
      value: processWithFallback(signups),
      icon: <UsersIcon />,
    },
    {
      label: 'upgrades',
      value: processWithFallback(upgrades),
      icon: <UpgradeIcon />,
    },
  ]

  return (
    <Card>
      <CardInnerReferral>
        <ShareParent referralLink={referralLink} />
        <ReferralCountWrapper>
          <ChipList items={chipItems}></ChipList>
        </ReferralCountWrapper>
        <RightColumn>
          <QrParent referralLink={referralLink} />
        </RightColumn>
      </CardInnerReferral>
    </Card>
  )
}

export default ReferralParent
