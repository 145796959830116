import TableHeaderSortable from '../common/TableHeaderSortable'

const TalksTableHeader = ({ order, orderBy, onRequestSort }) => {
  const headCells = [
    {
      id: 'checkbox',
      label: '',
      // displayBreakpoint: 'sm'
    },
    { id: 'date', label: 'Date' },
    { id: 'eventOrganization', label: 'Organization', displayBreakpoint: 'md' },
    { id: 'talkTitle', label: 'Talk Title' },
    {
      id: 'compensation',
      label: 'Rate',
      align: 'center',
      displayBreakpoint: 'lg',
    },
    {
      id: 'rating',
      label: 'Valuable',
      align: 'center',
      tooltip: 'Percent who answered yes to if they found your talk valuable.',
      displayBreakpoint: 'lg',
    },
    {
      id: 'responses',
      label: 'Responses',
      align: 'center',
      tooltip:
        'Total number of attendees who answered at least 1 question in the review flow.',
      displayBreakpoint: 'sm',
    },
    {
      id: 'leads',
      label: 'Leads',
      align: 'center',
      tooltip:
        'Total number of attendees who want to either book or refer you. Email opt-in only contacts are not included in this total.',
      displayBreakpoint: 'sm',
    },
    {
      id: 'actions',
      label: 'Actions',
      align: 'center',
    },
  ]

  return (
    <TableHeaderSortable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  )
}

export default TalksTableHeader
