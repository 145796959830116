import React, { useState, useContext } from 'react'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'
import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'

const LIGHT_RED = '#ff7f7f'
const LIGHT_YELLOW = '#ffff99'

const MainContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'left',
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': { border: 0 },
}))

const NotificationCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== 'processable' && prop !== 'halt_process',
})(({ theme, processable, halt_process }) => ({
  backgroundColor: processable
    ? 'none'
    : halt_process
    ? LIGHT_RED
    : LIGHT_YELLOW,
}))

const AdminChapterUploadParent = ({ user }) => {
  const [isLoading, setIsLoading] = useState(null)
  const [parsedCsvDetails, setParsedCsvDetails] = useState(null)
  const [file, setFile] = useState(null)

  const { setNotification } = useContext(AuthenticationContext)

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
    setParsedCsvDetails(null)
  }

  const handleUpload = async () => {
    if (file) {
      try {
        setIsLoading(true)

        const res = await Api.uploadChapterCsv({ file: file })

        if (!res.errors) {
          setParsedCsvDetails(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        setNotification(err)
      }
      setIsLoading(false)
    }
  }

  const handleUpdateChapters = async () => {
    if (window.confirm('Are you sure? These changes are permanent.')) {
      try {
        setIsLoading(true)

        const res = await Api.updateChapters({
          data_to_process: parsedCsvDetails.data_to_process,
        })
        if (!res.errors) {
          setNotification('Chapters Data Successfully Processed', 'success')
          setParsedCsvDetails(null)
          setFile(null)
        } else {
          throw res.errors
        }
      } catch (err) {
        setNotification(err)
      }
      setIsLoading(false)
    }
  }

  const renderUnprocessableError = () => {
    if (parsedCsvDetails.block_processing) {
      return (
        <StyledTypography variant="h6" component="div" color={LIGHT_RED}>
          This File cannot be processed. Please fix the errors in RED and try
          again
        </StyledTypography>
      )
    }

    if (parsedCsvDetails.data_to_process.length === 0) {
      return (
        <StyledTypography variant="h6" component="div" color={LIGHT_RED}>
          This File does not contain any records to process
        </StyledTypography>
      )
    }
  }

  const renderUserDetails = () => {
    if (
      parsedCsvDetails.block_processing ||
      parsedCsvDetails.users_to_create.length === 0
    ) {
      return
    }

    return (
      <StyledBox>
        <StyledTypography variant="h6" component="div">
          Summary of Talkadot accounts that will be created
        </StyledTypography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: 'lightgrey' }}>
                <TableCell>Email</TableCell>
                <TableCell>Short Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsedCsvDetails.users_to_create.map((row) => (
                <TableRow key={row.shortlink}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.shortlink}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledBox>
    )
  }

  const renderChapterDetails = () => {
    if (
      parsedCsvDetails.block_processing ||
      parsedCsvDetails.data_to_process.length === 0
    ) {
      return
    }

    return (
      <StyledBox>
        <StyledTypography variant="h6" component="div">
          Chapter Details (these will be linked to the account of{' '}
          {parsedCsvDetails.primary_email})
        </StyledTypography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: 'lightgrey' }}>
                <TableCell>Name</TableCell>
                <TableCell>Short Code</TableCell>
                <TableCell>Creating New Record</TableCell>
                <TableCell>Number of Members added</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsedCsvDetails.data_to_process.map((row) => (
                <TableRow key={row.shortlink}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.shortlink}</TableCell>
                  <TableCell>{row.id === null ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{row.users.length}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledBox>
    )
  }

  const renderRowDetails = () => (
    <StyledBox>
      <StyledTypography variant="h6" component="div">
        Row Details
      </StyledTypography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: 'lightgrey' }}>
              <TableCell>CSV Row Reference</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Will be processed</TableCell>
              <TableCell>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parsedCsvDetails.row_info.map((row) => (
              <StyledTableRow key={row.row_number}>
                <TableCell component="th" scope="row">
                  {row.row_number}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.processable ? 'Yes' : 'No'}</TableCell>
                <NotificationCell
                  processable={row.processable}
                  halt_process={row.halt_process}>
                  {row.msg}
                </NotificationCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledBox>
  )

  const renderUploadConfirmationDetails = () => (
    <>
      {renderUnprocessableError()}
      {renderUserDetails()}
      {renderChapterDetails()}
      {renderRowDetails()}
    </>
  )

  const renderSubmitActions = () => {
    if (parsedCsvDetails) {
      return (
        <>
          <StyledBox>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setParsedCsvDetails(null)
                setFile(null)
              }}>
              Reset
            </Button>
          </StyledBox>
          {!parsedCsvDetails.block_processing &&
            parsedCsvDetails.data_to_process.length > 0 && (
              <StyledBox>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateChapters}>
                  Confirm Database Changes
                </Button>
              </StyledBox>
            )}
        </>
      )
    }

    return (
      <StyledBox>
        <input type="file" accept=".csv" onChange={handleFileChange} />
        {file && (
          <Button variant="contained" color="primary" onClick={handleUpload}>
            Upload
          </Button>
        )}
      </StyledBox>
    )
  }

  return (
    <MainContainer>
      <StyledTypography variant="h4" component="div">
        Chapter CSV uploader
      </StyledTypography>
      {isLoading ? (
        <p>Please Wait. This may take a couple seconds...</p>
      ) : (
        <>
          {renderSubmitActions()}
          {parsedCsvDetails && renderUploadConfirmationDetails()}
        </>
      )}
    </MainContainer>
  )
}

export default AdminChapterUploadParent
