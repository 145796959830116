// import { styled } from '@mui/system'
import { Typography, Skeleton } from '@mui/material'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useTheme } from '@mui/material/styles'

ChartJS.register(ArcElement, Tooltip)

// const StatContainer = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   marginBottom: theme.spacing(3.75),
// }))

const TalkValue = ({
  ratingsBreakdown,
  isLoading,
  eventType,
  isProfile = false,
  speaker,
}) => {
  const theme = useTheme()
  const { positives, negatives, total } = ratingsBreakdown
  const rating = parseInt((positives / total) * 100) || 0

  const chartData = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [positives, negatives],
        backgroundColor: [
          theme.palette.eventReport.circleChartBlue,
          theme.palette.eventReport.circleChartGrey,
        ],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const renderHeaderContent = () => {
    if (!eventType) {
      return ` found ${isProfile ? speaker?.name + "'s" : 'this'} session${
        isProfile ? 's' : ''
      } valuable`
    }

    if (eventType.toUpperCase() === 'GLOBAL') {
      return ' found this event valuable'
    }

    if (eventType.toUpperCase() === 'TOTAL_STATISTICS') {
      return ' found the sessions valuable'
    }

    return ' found this session valuable'
  }

  return (
    <div className="stat-widget-section">
      {/* <StatContainer> */}
      {isLoading ? (
        <Skeleton width={200} height={30} />
      ) : (
        <Typography variant="h6" component="div" className="widget-title">
          <span className="stat-percentage">
            <b>{rating}%</b>
          </span>
          {renderHeaderContent()}
        </Typography>
      )}
      {isLoading ? (
        <Skeleton width={300} height={200} variant="rectangular" />
      ) : (
        <div className="stat-widget-section__widgets" id="talk-value-widget">
          <div className="stat-widget-section__donut-chart">
            <Doughnut data={chartData} options={options} />
          </div>
          <div className="stat-widget-section__progress-bar-section">
            <div className="stat-widget-section__progress-bar-container">
              <Typography
                variant="body1"
                component="div"
                className="progress-stat-label">
                Valuable
              </Typography>
              <CircularProgressbar
                value={positives}
                maxValue={total}
                text={positives}
                strokeWidth={10}
                className="stat-widget-section__progress-bar"
                styles={buildStyles({
                  textSize: '25px',
                  textColor: 'black',
                  pathColor: theme.palette.eventReport.circleChartBlue,
                })}
              />
            </div>
            <div className="stat-widget-section__progress-bar-container">
              <Typography
                variant="body1"
                component="div"
                className="progress-stat-label">
                Not Valuable
              </Typography>
              <CircularProgressbar
                value={negatives}
                maxValue={total}
                text={negatives}
                counterClockwise={true}
                strokeWidth={10}
                className="stat-widget-section__progress-bar"
                styles={buildStyles({
                  textColor: 'black',
                  pathColor: theme.palette.eventReport.circleChartGrey,
                  textSize: '25px',
                })}
              />
            </div>
          </div>
        </div>
      )}
      {/* </StatContainer> */}
    </div>
  )
}

export default TalkValue
