import { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

import SurveyOffersOnboardingHeader from './SurveyOffersOnboardingHeader'
import SpeakerSlide from '../../common/SpeakerSlideResponsive'
import AlertCard from '../../common/AlertCard'
import AlertCardCta from '../../common/AlertCardCta'
import { ReactComponent as Arrow } from '../../../icons/arrow.svg'

const AlertContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'windowWidth',
})(({ windowWidth }) => ({
  position: 'absolute',
  top: '18%',
  right: `calc(-180px + ${
    windowWidth > 960 + 540
      ? '0px'
      : `${Math.min(160, 960 + 540 - windowWidth)}px`
  })`,
  '@media (max-width: 1132px)': {
    top: '7%',
    right: `calc(-180px + ${
      windowWidth > 640 + 540
        ? '0px'
        : `${Math.min(160, 640 + 540 - windowWidth)}px`
    })`,
  },
  '@media (max-width: 850px)': {
    top: '75%',
    left: `calc(-180px + ${
      windowWidth > 480 + 540
        ? '0px'
        : `${Math.min(160, 480 + 540 - windowWidth)}px`
    })`,
  },
}))

const ArrowContainer = styled('div')({
  position: 'absolute',
  bottom: '200px',
  right: '70px',
  transform: 'rotate(180deg)',
  '@media (max-width: 1132px)': {
    bottom: '120px',
  },
  '@media (max-width: 850px)': {
    right: '250px',
    bottom: '40px',
    transform: 'rotate(0deg)',
  },
})

const AlertContent = styled('div')(({ theme }) => ({
  color: theme.palette.base.black,
}))

const CtaContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '1rem 0',
})

const SkipButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const SkipButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Figtree, sans-serif',
  color: theme.palette.secondary.main,
  textTransform: 'none',
}))

const FlexWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const SurveyOffersOnboardingStepThree = ({
  user,
  advanceLastStep,
  loading,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <SurveyOffersOnboardingHeader>
        This is a Talkadot Slide. Use the QR code or your unique link to collect
        feedback.
        <br />
        Let's start by setting up your first code.
      </SurveyOffersOnboardingHeader>
      <FlexWrapper>
        <SpeakerSlide
          avatar={user.avatar}
          surveyLink={user.shortlink}
          socialLinks={user.socialLinks}
          email={user.email}
          name={user.first_name}
          size={'lg'}
          onboardingPlaceholder={true}
        />
      </FlexWrapper>
      <AlertContainer windowWidth={windowWidth}>
        <AlertCard title={'This is your Talkadot Code'}>
          <AlertContent>
            This code tells our system what bonus to give your audience.
            <CtaContainer>
              <AlertCardCta
                buttonText="Make a code"
                handleClick={() => advanceLastStep()}
                loading={loading}
              />
            </CtaContainer>
            <SkipButtonContainer>
              <SkipButton variant="text" onClick={() => advanceLastStep(2)}>
                I want to do this later.
              </SkipButton>
            </SkipButtonContainer>
          </AlertContent>
        </AlertCard>
      </AlertContainer>
      <ArrowContainer>
        <Arrow />
      </ArrowContainer>
    </>
  )
}

export default SurveyOffersOnboardingStepThree
