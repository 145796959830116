import React from 'react'
import Skeleton from '@mui/material/Skeleton'

import './Card.scss'

const Card = ({ title, isLoading, children, cx }) => {
  return (
    <div className={`card ${cx}`}>
      <div className="card__inner">
        <div className="card__title">
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{ width: '100%', height: '10px', marginBottom: '15px' }}
            />
          ) : (
            title
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default Card
