import React, { useState } from 'react'

import {
  TableRow,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from '@mui/material'
import { StyledTableCell } from '../groups/GroupsList'

import { SwitchWithLabel } from '../common/SwitchWithLabel'
import { IoEyeOutline } from 'react-icons/io5'

import { IoEllipsisHorizontal } from 'react-icons/io5'

const LeadsEmailCampaignRow = ({
  campaign,
  toggleCampaignActive,
  handlePreviewOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenDropdown = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  return (
    <TableRow key={campaign.id}>
      <StyledTableCell component="th" scope="row" mobile lg>
        {campaign.name}
      </StyledTableCell>
      <StyledTableCell size="small" align="center" mobile>
        <SwitchWithLabel
          checked={campaign.isActive || false}
          onChange={(e) => toggleCampaignActive(campaign.id, e.target.checked)}
        />
      </StyledTableCell>
      <StyledTableCell size="small" align="center">
        {campaign.emailsSent}
      </StyledTableCell>
      <StyledTableCell size="small" align="center" mobile>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenDropdown}
          aria-label="Open to show more"
          title="Open to show more">
          <IoEllipsisHorizontal />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <MenuItem
            component="div"
            onClick={() => {
              handlePreviewOpen(campaign)
              handleCloseDropdown()
            }}>
            <ListItemIcon>
              <IoEyeOutline />
            </ListItemIcon>
            <ListItemText primary="View Email Template" />
          </MenuItem>
        </Menu>
      </StyledTableCell>
    </TableRow>
  )
}

export default LeadsEmailCampaignRow
