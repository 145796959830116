import { useState } from 'react'
import { styled } from '@mui/system'
import {
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import ManageGroupMembershipsParent from './ManageGroupMembershipsParent'

import {
  IoEllipsisHorizontal,
  IoTrashOutline,
  IoLogOutOutline,
  IoPencilOutline,
  IoEyeOutline,
  IoPeopleCircleOutline,
} from 'react-icons/io5'

import { StyledTableCell } from './GroupsList'

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'role',
})(({ theme, role }) => ({
  cursor: role === 'member' ? 'default' : 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.tertiary.extraLight,
  },
}))

const StyledListItemText = styled(ListItemText)({
  span: {
    fontFamily: 'Figtree, sans-serif',
  },
})

const GroupsListRow = ({
  group,
  handleViewGroup,
  handleEditGroup,
  handleDeleteGroup,
  handleLeaveGroup,
  handleGroupSettings,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [manageMembersOpen, setManageMembersOpen] = useState(false)

  const handleOpenDropdown = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }
  const renderMenuItems = (group) => {
    switch (group.role) {
      case 'owner':
        return (
          <div>
            <MenuItem key="0" onClick={() => handleViewGroup(group.id)}>
              <ListItemIcon>
                <IoEyeOutline />
              </ListItemIcon>
              <StyledListItemText>View Chapter</StyledListItemText>
            </MenuItem>
            <MenuItem key="1" onClick={() => handleEditGroup(group)}>
              <ListItemIcon>
                <IoPencilOutline />
              </ListItemIcon>
              <StyledListItemText>Edit Chapter</StyledListItemText>
            </MenuItem>
            <MenuItem key="2" onClick={() => setManageMembersOpen(true)}>
              <ListItemIcon>
                <IoPeopleCircleOutline />
              </ListItemIcon>
              <StyledListItemText>Manage Members</StyledListItemText>
            </MenuItem>
            <MenuItem key="3" onClick={() => handleDeleteGroup(group.id)}>
              <ListItemIcon>
                <IoTrashOutline />
              </ListItemIcon>
              <StyledListItemText>Delete Chapter</StyledListItemText>
            </MenuItem>
          </div>
        )
      case 'manager':
        return (
          <div>
            <MenuItem key="0" onClick={() => handleViewGroup(group.id)}>
              <ListItemIcon>
                <IoEyeOutline />
              </ListItemIcon>
              <StyledListItemText>View Chapter</StyledListItemText>
            </MenuItem>
            <MenuItem key="2" onClick={() => setManageMembersOpen(true)}>
              <ListItemIcon>
                <IoPeopleCircleOutline />
              </ListItemIcon>
              <StyledListItemText>Manage Members</StyledListItemText>
            </MenuItem>
            <MenuItem key="3" onClick={() => handleLeaveGroup(group.id)}>
              <ListItemIcon>
                <IoLogOutOutline />
              </ListItemIcon>
              <StyledListItemText>Leave Chapter</StyledListItemText>
            </MenuItem>
          </div>
        )
      case 'member':
        return (
          <div>
            <MenuItem key="0" onClick={() => handleViewGroup(group.id)}>
              <ListItemIcon>
                <IoEyeOutline />
              </ListItemIcon>
              <StyledListItemText>View Chapter</StyledListItemText>
            </MenuItem>
            <MenuItem key="1" onClick={() => handleLeaveGroup(group.id)}>
              <ListItemIcon>
                <IoLogOutOutline />
              </ListItemIcon>
              <StyledListItemText>Leave Chapter</StyledListItemText>
            </MenuItem>
          </div>
        )
      default:
        return null
    }
  }
  return (
    <StyledTableRow key={group.id} role={group.role}>
      <StyledTableCell lg mobile onClick={() => handleViewGroup(group.id)}>
        {group.name}
      </StyledTableCell>
      <StyledTableCell lg onClick={() => handleViewGroup(group.id)}>
        {group.description}
      </StyledTableCell>
      <StyledTableCell mobile onClick={() => handleViewGroup(group.id)}>
        {group.role}
      </StyledTableCell>
      <StyledTableCell mobile align="center">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenDropdown}
          aria-label="Open to show more"
          title="Open to show more">
          <IoEllipsisHorizontal />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          {renderMenuItems(group)}
        </Menu>
      </StyledTableCell>
      <Dialog
        open={manageMembersOpen}
        fullWidth
        maxWidth="md"
        onClose={() => setManageMembersOpen(false)}>
        <DialogTitle>
          <Typography variant="h5">
            Add New Members For <b>{group?.name}</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <ManageGroupMembershipsParent group={group} />
        </DialogContent>
      </Dialog>
    </StyledTableRow>
  )
}

export default GroupsListRow
