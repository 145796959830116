import { useContext } from 'react'
import {
  Avatar,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  // StepConnector,
} from '@mui/material'
// import { stepConnectorClasses } from '@mui/material/StepConnector'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as GiftIcon } from '../../../icons/giftIcon.svg'
import { ReactComponent as BellIcon } from '../../../icons/bellIcon.svg'
import { ReactComponent as StarIcon } from '../../../icons/starIcon.svg'

import { Card, CardInnerTrial } from './DashboardCardComponents'
import { AuthenticationContext } from '../../authentication/authenticationContext'

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'isDefault',
})(({ theme, isDefault }) => ({
  width: '250px',
  height: '250px',
  ...(isDefault && {
    img: {
      marginLeft: '16px', // Center the image within avatar container
      width: '110%',
      height: '110%',
    },
  }),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
}))

const TrialDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))

const ModalTriggerLink = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
  cursor: 'pointer',
}))

const StepLabelTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.neutral.veryDark,
  paddingLeft: theme.spacing(2),
}))

const StyledEllipse = styled('div')(({ theme, active }) => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.success.semiDark : 'transparent',
  border: active ? 'none' : `1px solid ${theme.palette.neutral.veryLight}`,
  svg: {
    stroke: active ? theme.palette.base.white : theme.palette.neutral.veryLight,
    width: '24px',
    height: '24px',
  },
}))

// NOTE: This is kinda janky but couldn't get the connector to position correctly
const StyledStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepConnector-root': {
    marginLeft: theme.spacing(2.5),
  },
}))

// NOTE: This is kinda janky but couldn't get the content to position correctly using MUI stepper
const StyledStepContent = styled(StepContent)(({ theme }) => ({
  '&.MuiStepContent-root': {
    marginLeft: theme.spacing(2.5),
    paddingLeft: theme.spacing(5.5),
    '& .MuiTypography-root': {
      marginTop: `-${theme.spacing(2)}`,
    },
  },
}))

const StepIcon = ({ children, active }) => (
  <StyledEllipse active={active}>{children}</StyledEllipse>
)

const TrialProgressParent = ({
  planName,
  discountedPrice,
  retailPrice,
  planTrialDays,
  startDate,
  reminderDate,
  endDate,
  today,
  // percentageOfTrialComplete,
  referrerName,
  referrerAvatar,
  currentStep,
}) => {
  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  const theme = useTheme()
  const veryDark = theme.palette.neutral.veryDark

  const steps = [
    {
      stepNum: 1,
      icon: <GiftIcon />,
      label: `${
        today === startDate ? 'Today' : startDate
      } - Free Trial of ${planName}`,
      description: discountedPrice && (
        <>
          <ModalTriggerLink onClick={() => toggleUpgradeModal(true, 'Upgrade')}>
            Upgrade
          </ModalTriggerLink>{' '}
          <span>
            in your first {planTrialDays} days and get Talkadot for $
            {discountedPrice} instead of ${retailPrice} per year
          </span>
        </>
      ),
    },
    {
      stepNum: 2,
      icon: <BellIcon />,
      label: today === reminderDate ? 'Today' : reminderDate,
      description: (
        <span>
          You’ll get a reminder that you’re halfway through your free trial
        </span>
      ),
    },
    {
      stepNum: 3,
      icon: <StarIcon />,
      label: today === endDate ? 'Today' : endDate,
      description: (
        <span>
          This is the last free trial day.
          <br /> If you don’t upgrade, you’ll automatically revert back to the
          free Talkadot Lite Plan.
        </span>
      ),
    },
  ]

  const renderTitle = () => {
    if (referrerName) {
      return `You’re on a ${planTrialDays} day free ${planName} trial thanks to ${referrerName}!`
    }

    return `You’re on a ${planTrialDays} day free ${planName} trial!`
  }

  return (
    <Card>
      <CardInnerTrial>
        <StyledAvatar
          src={
            referrerAvatar ||
            'https://d3qvkz8495pjoq.cloudfront.net/unicorn-headshot.jpg'
          }
          isDefault={!referrerAvatar}
          alt="referrerAvatar"
        />

        <TrialDetailsContainer>
          <StyledHeader variant="h5">{renderTitle()}</StyledHeader>
          <StyledStepper
            orientation="vertical"
            activeStep={currentStep}
            // connector={<CustomStepConnector />} // No change in appearance with this commented out??
          >
            {steps.map((step, index) => (
              <Step key={index} active>
                <StepLabel
                  StepIconComponent={() => (
                    <StepIcon active={step.stepNum <= currentStep}>
                      {step.icon}
                    </StepIcon>
                  )}>
                  <StepLabelTypography variant="body2">
                    {step.label}
                  </StepLabelTypography>
                </StepLabel>
                <StyledStepContent>
                  <Typography sx={{ color: veryDark }} variant="body1">
                    {step.description}
                  </Typography>
                </StyledStepContent>
              </Step>
            ))}
          </StyledStepper>
        </TrialDetailsContainer>
      </CardInnerTrial>
    </Card>
  )
}

export default TrialProgressParent
