import { useEffect, useContext } from 'react'

import { useLocation, useParams, useHistory } from 'react-router-dom'

import { GroupContext } from './groupContext'

import GroupMembersParent from './GroupMembersParent'
import GuestSpeakerDashboard from './GuestSpeakerDashboard'
import GuestSpeakerEventTable from './GuestSpeakerEventTable'
import PageContainer from '../common/PageContainer'

const GroupParent = () => {
  const { id } = useParams()
  const location = useLocation()
  const history = useHistory()
  const {
    groupState: { selectedGroup, groups },
    getGroupById,
    setSelectedGroup,
  } = useContext(GroupContext)
  const viewOnly = selectedGroup?.access?.viewOnly

  useEffect(() => {
    if (groups === null) {
      // wait till groups exists on refresh
      return
    }

    const group = getGroupById(Number(id))

    if (group) {
      setSelectedGroup(group)
    } else {
      history.push('/dashboard')
    }
  }, [id, groups])

  useEffect(() => {
    // Cleanup selectedGroup when GroupParent unmounts so
    // events and survey offers no longer reference a group
    return () => {
      setSelectedGroup(null)
    }
  }, [])

  const renderChildComponent = () => {
    const path = location.pathname

    if (path.includes('/members')) {
      return <GroupMembersParent isLoading={false} />
    } else if (path.includes('/events')) {
      if (viewOnly) {
        return <GuestSpeakerEventTable />
      }

      return <GuestSpeakerDashboard />
    }
  }

  return selectedGroup ? (
    <PageContainer>{renderChildComponent()}</PageContainer>
  ) : (
    <>Loading...</>
  )
}

export default GroupParent
