export const ResponsesSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.636"
      height="22.018"
      viewBox="0 0 28.636 22.018">
      <path
        id="barcode-outline"
        d="M86.2,132l2.437-.023a2.012,2.012,0,0,0,2-2V114a2.012,2.012,0,0,0-2-2l-2.437.023M68.439,112l-2.553.023a1.913,1.913,0,0,0-1.887,2V130a1.912,1.912,0,0,0,1.887,2l2.553-.023M86.2,117.549v8.879m-4.439-11.1v13.318m-4.439-12.208v11.1M72.879,115.33v13.318m-4.439-11.1v8.879"
        transform="translate(-63 -110.991)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}
