import { styled } from '@mui/system'
import { TablePagination, Button, Stack, Typography } from '@mui/material'
import { ReactComponent as ArrowLeft } from '../../icons/arrowLeft.svg'

const PaginationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  button: {
    backgroundColor: theme.palette.base.white,
  },
}))

const BackButton = styled(Button)(({ theme, disabled }) => ({
  svg: {
    marginRight: theme.spacing(1),
    path: {
      stroke: disabled ? theme.palette.action.disabled : '',
    },
  },
  [theme.breakpoints.down('md')]: {
    svg: {
      marginRight: 0,
    },
    '& .button-text': {
      display: 'none',
    },
  },
}))

const NextButton = styled(Button)(({ theme, disabled }) => ({
  svg: {
    transform: 'rotate(180deg)',
    marginLeft: theme.spacing(1),
    path: {
      stroke: disabled ? theme.palette.action.disabled : '',
    },
  },
  [theme.breakpoints.down('md')]: {
    svg: {
      marginLeft: 0,
    },
    '& .button-text': {
      display: 'none',
    },
  },
}))

const PageCounter = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.navText,
}))

const TablePaginationFooter = ({
  rows,
  rowsPerPage,
  page,
  handleChangePage,
}) => {
  const handlePrevButtonClick = () => {
    handleChangePage(page - 1)
    window.scrollTo(0, 0)
  }

  const handleNextButtonClick = () => {
    handleChangePage(page + 1)
    window.scrollTo(0, 0)
  }

  return (
    <TablePagination
      // NOTE: These are the default props for TablePagination
      // rowsPerPageOptions={[5, 10, 25]}
      // component="div"
      // count={codes.length}
      // rowsPerPage={rowsPerPage}
      // page={page}
      // onPageChange={handleChangePage}
      // onRowsPerPageChange={handleChangeRowsPerPage}

      component="div"
      count={rows.length}
      page={page}
      rowsPerPage={rowsPerPage}
      onChangePage={handleChangePage}
      rowsPerPageOptions={[]}
      labelDisplayedRows={() => null}
      ActionsComponent={() => (
        <PaginationContainer>
          <BackButton
            variant="outlined"
            color="tertiary"
            onClick={handlePrevButtonClick}
            disabled={page === 0}>
            <ArrowLeft />
            <span className="button-text">Previous</span>
          </BackButton>
          <Stack direction="row" spacing={1}>
            <PageCounter variant="body2">{`Page ${page + 1} of ${
              rows.length > 0 ? Math.ceil(rows.length / rowsPerPage) : 1
            }`}</PageCounter>
          </Stack>
          <NextButton
            variant="outlined"
            color="tertiary"
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(rows.length / rowsPerPage) - 1}>
            <span className="button-text">Next</span>
            <ArrowLeft />
          </NextButton>
        </PaginationContainer>
      )}
    />
  )
}

export default TablePaginationFooter
