import { styled } from '@mui/system'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'

import { ProfileSectionHeader } from './ProfileSectionHeader'

const AttributesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '340px',
  marginTop: '18px',
  marginBottom: '40px',
})

const AttributeWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '92px 1fr 50px',
  alignItems: 'center',
  marginBottom: '1rem',
})

const AttributeLabel = styled('div')({
  textAlign: 'left',
  fontSize: '0.9375rem',
  textTransform: 'capitalize',
})

const AttributeProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: '6px',
  borderRadius: '10px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.tertiary.light,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '10px',
    backgroundColor: theme.palette.profileWidget.linearProgressGray,
  },
  marginRight: '10px',
}))

const AttributePercentage = styled('div')({
  textAlign: 'left',
  fontSize: '0.9375rem',
})

const PlaceHolder = styled('div')({
  fontSize: '1.2rem',
  marginTop: '20px',
  marginBottom: '40px',
  fontFamily: 'Sora, sans-serif',
  textAlign: 'left',
})

export const ProfileAttributes = ({ speaker, engagementBreakdown }) => {
  const ACTIONABLE = 'actionable'
  const ENGAGING = 'engaging'
  const INSPIRING = 'inspiring'
  const RELEVANT = 'relevant'
  const INTERACTIVE = 'interactive'

  const headerText = `${speaker?.name}'s Top Attributes`

  const isValidLabel = (label) => {
    return !label?.includes('{{') && label?.trim().split(/\s+/).length <= 2
  }

  const formatLabelFromLegacyKeywords = (content) => {
    if (content.includes(ACTIONABLE)) {
      return ACTIONABLE
    }

    if (content.includes(ENGAGING)) {
      return ENGAGING
    }

    if (content.includes(INSPIRING)) {
      return INSPIRING
    }

    if (content.includes(RELEVANT)) {
      return RELEVANT
    }

    if (content.includes(INTERACTIVE)) {
      return INTERACTIVE
    }

    return
  }

  const formatLabel = (label) => {
    let formattedLabel = formatLabelFromLegacyKeywords(label)

    if (!formattedLabel) {
      return label
    }

    return formattedLabel
  }

  const sortedEngagementBreakdown = engagementBreakdown
    .map((engagementStat, i) => {
      if (engagementStat.label) {
        return {
          ...engagementStat,
          label: formatLabel(engagementStat.label),
        }
      } else {
        return null
      }
    })
    .filter((engagementStat) =>
      isValidLabel(engagementStat.label) ? { ...engagementStat } : null
    )
    .sort((a, b) => {
      const scoreA = a.answer_count * a.avg_value
      const scoreB = b.answer_count * b.avg_value

      return scoreB - scoreA // Sort in descending order based on the multiplied score
    })

  const makeBarValue = (engagementStatValue) => {
    if (!engagementStatValue) {
      return
    } else {
      // All the numbers are on a scale of 1-5, so need to adjust this for a scale of 100%
      return Number(parseFloat(engagementStatValue * 20).toFixed(1))
    }
  }

  const renderEngagementRow = (engagementType, index) => {
    const barValue = makeBarValue(engagementType.avg_value)
    return (
      <AttributeWrapper key={index}>
        <AttributeLabel>{engagementType.label}</AttributeLabel>
        <AttributeProgressBar variant="determinate" value={barValue} />
        <AttributePercentage>{Math.round(barValue)}%</AttributePercentage>
      </AttributeWrapper>
    )
  }

  const renderPlaceholder = () => {
    return (
      <PlaceHolder>
        We're still gathering feedback for this profile!
      </PlaceHolder>
    )
  }

  return (
    <>
      <ProfileSectionHeader headerText={headerText} />
      {sortedEngagementBreakdown?.length > 0 ? (
        <AttributesContainer>
          {sortedEngagementBreakdown?.slice(0, 5)?.map((engagementType, i) => {
            return renderEngagementRow(engagementType, i)
          })}
        </AttributesContainer>
      ) : (
        renderPlaceholder()
      )}
    </>
  )
}
