import { styled } from '@mui/system'

export const drawerWidth = 272
export const drawerWidthCollapsed = 88
export const drawerWidthMobile = 0
export const upperNavHeight = 140
export const upperNavHeightMobile = 80

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  height: upperNavHeight,
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('sm')]: {
    height: upperNavHeightMobile,
  },
}))
