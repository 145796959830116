import { styled } from '@mui/system'

const HeaderWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: width ? width : 'fit-content',
}))

const HeaderText = styled('div')(({ theme }) => ({
  fontFamily: 'Sora, sans-serif',
  fontSize: '1.5625rem',
  fontWeight: 500,
  paddingRight: '26px',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
}))

const UnderLine = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '5px',
  background: theme.palette.secondary.main,
}))

export const ProfileSectionHeader = ({ headerText, width }) => {
  return (
    <HeaderWrapper width={width}>
      <HeaderText>{headerText}</HeaderText>
      <UnderLine />
    </HeaderWrapper>
  )
}
