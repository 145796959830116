import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const RatingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const EmojiWrapper = styled('span')(({ emojiFontSize }) => ({
  fontSize: emojiFontSize,
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

const YesNoRating = ({
  answerValue,
  answerContent,
  emojiFontSize,
  typographyVariant,
}) => {
  return (
    <RatingContainer>
      {answerValue?.toLowerCase().includes('yes') ? (
        <EmojiWrapper emojiFontSize={emojiFontSize}>👍</EmojiWrapper>
      ) : (
        <EmojiWrapper emojiFontSize={emojiFontSize}>👎</EmojiWrapper>
      )}
      <StyledTypography variant={typographyVariant}>
        {answerContent}
      </StyledTypography>
    </RatingContainer>
  )
}

export default YesNoRating
