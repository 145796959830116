import { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { ReactComponent as CheckMarkIcon } from '../../icons/checkMarkIcon_16x16.svg'
import { ReactComponent as TrashIcon } from '../../icons/trash2Icon_16x16.svg'
import { ReactComponent as ArchiveIcon } from '../../icons/archiveIcon_16x16.svg'
import { ReactComponent as AISparklesIcon } from '../../icons/aiSparklesIcon_16x16.svg'

import * as Lead from './helpers'
import { formatDateWithTimeZone } from '../common/helpers'

import LeadTypeIcon from './LeadTypeIcon'
import YesNoRating from '../common/YesNoRating'
import EmojiRating from '../common/EmojiRating'
import LoadingSpinner from '../common/LoadingSpinner'
import SplitButton from '../common/SplitButton'
import InlineIconWrapper from '../common/InlineIconWrapper'

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'loading',
})(({ loading }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: loading ? 'center' : '',
}))

const ColumnWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const ContactInfoRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const LeadContactInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}))

const LeadName = styled(Typography)({
  fontWeight: '600',
})

const ResponsesRow = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: theme.spacing(6.5),
  gridTemplateAreas: `
    "ratings engagement feedback"
  `,
  marginTop: theme.spacing(3.5),
  marginBottom: theme.spacing(1),
  '& > div:nth-child(1)': {
    gridArea: 'ratings',
  },
  '& > div:nth-child(2)': {
    gridArea: 'engagement',
  },
  '& > div:nth-child(3)': {
    gridArea: 'feedback',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      "ratings engagement"
      "feedback feedback"
    `,
    gap: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
      "ratings"
      "engagement"
      "feedback"
    `,
  },
}))

const LeadRatingsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const LeadTypeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const StyledList = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(0),
  margin: 0,
  listStyleType: 'none',
  li: {
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: '1em',
  },
  'li::before': {
    content: '"•"',
    fontSize: '0.875rem',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

const ColoredLi = styled('li', {
  shouldForwardProp: (prop) => prop !== 'alert',
})(({ alert, theme }) => ({
  color: alert ? theme.palette.error.semiDark : theme.palette.neutral.main,
  '&::before': {
    color: alert ? theme.palette.error.semiDark : theme.palette.neutral.main,
  },
}))

const FeedbackAnswerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))

const FeedbackAnswerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const FeedbackAnswerTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
}))

const FeedbackAnswer = styled(Typography)(({ theme, variant }) => ({
  color:
    variant === 'secondaryBody1'
      ? theme.palette.secondary.main
      : theme.palette.neutral.main,
}))

const ActionsRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
}))

const StyledButton = styled(LoadingButton)(({ theme, variant, color }) => ({
  color: variant === 'contained' ? theme.palette.base.white : '',
  svg: {
    marginRight: theme.spacing(1),
    minWidth: '16px',
    path: {
      stroke:
        variant === 'contained'
          ? theme.palette.base.white
          : color === 'primary'
            ? theme.palette.primary.main
            : '',
      // strokeWidth: 1,
    },
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const SplitButtonContainer = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}))

const IntegrationTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginBottom: theme.spacing(0.5),
}))

const IntegrationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(3),
  },
}))

const LeadsTableRowExpand = ({
  lead,
  leadSurveyData,
  leadLoading,
  leadUpdating,
  handleArchiveLead,
  handleMarkContacted,
  handleDeleteLead,
}) => {
  const [integrationItems, setIntegrationItems] = useState([])

  useEffect(() => {
    setIntegrationItems(generateIntegrationItems(lead))
  }, [lead])

  const generateIntegrationItems = (currentLead) => {
    const {
      followupSentAt,
      followupFailure,
      speakerflowSyncedAt,
      zapierSyncedAt,
      upcomingSpeakerflowSyncTime,
      upcomingFollowupSendTime,
    } = currentLead

    const items = []

    if (followupFailure) {
      items.push({
        message: Lead.humanizedFollowupFailure(
          { followupSentAt, followupFailure },
          true
        ),
        alert: true,
      })
    }

    if (!followupFailure && followupSentAt) {
      items.push({
        message: `Post event email sent out on ${formatDateWithTimeZone(
          followupSentAt
        )}`,
      })
    }

    if (speakerflowSyncedAt) {
      items.push({
        message: `Pushed to Speakerflow as a lead on ${formatDateWithTimeZone(
          speakerflowSyncedAt
        )}`,
      })
    }

    if (zapierSyncedAt) {
      items.push({
        message: `Pushed to Zapier as a lead on ${formatDateWithTimeZone(
          zapierSyncedAt
        )}`,
      })
    }

    if (upcomingFollowupSendTime) {
      items.push({
        message: `Post event email scheduled for ${upcomingFollowupSendTime}`,
      })
    }

    if (upcomingSpeakerflowSyncTime) {
      items.push({
        message: `This contact is scheduled to be pushed to Speakerflow on ${upcomingSpeakerflowSyncTime}`,
      })
    }

    return items
  }

  const talkValue = leadSurveyData?.find((l) =>
    l.question_type.includes('RATING')
  )
  const talkReattend = leadSurveyData?.find((l) =>
    l.question_type.includes('REATTEND')
  )
  const talkEngagement = leadSurveyData?.filter((l) =>
    l.question_type.includes('ENGAGEMENT')
  )
  const talkTakeaways = leadSurveyData?.find((l) =>
    l.question_type.includes('TAKEAWAYS')
  )
  // Note: I ran into a case where there were multiple records with the
  // PUBLIC_FEEDBACK question_type but only had a boolean value
  const otherFeedback = leadSurveyData?.find(
    (l) => l.question_type.includes('PUBLIC_FEEDBACK') && l.text_value !== null
  )
  const improvementFeedback = leadSurveyData?.find((l) =>
    l.question_type.includes('IMPROVEMENT')
  )

  const renderLeadType = () => {
    const leadTypes = lead.leadTypes || []

    let listItems = []

    const isBookingLead = Lead.isBookingLead(leadTypes)
    const isReferLead = Lead.isReferLead(leadTypes)
    const isOptIn = Lead.isOptIn(leadTypes)

    if (isBookingLead) {
      listItems.push({ message: 'Books speakers' })
    }
    if (isReferLead) {
      listItems.push({ message: 'Knows someone who books speakers' })
    }

    if (Lead.isHotLead(lead)) {
      listItems.push({ message: 'Has an event in mind' })
      listItems.push({ message: 'Provided all contact info' })
    }
    if (isOptIn) {
      listItems.push({ message: 'Opted in to receive emails' })
    } else {
      listItems.push({ message: 'No to email opt-in', alert: true })
    }
    if (lead.opportunityDetails) {
      listItems.push({ message: 'Added details about the opportunity' })
    }

    return (
      <>
        <LeadTypeIcon lead={lead} expanded={true} typographyVariant="body2" />
        <StyledList>
          {listItems.map((item, index) => (
            <ColoredLi alert={item.alert} key={index}>
              {item.message}
            </ColoredLi>
          ))}
        </StyledList>
      </>
    )
  }

  const renderFeedback = () => {
    return (
      <>
        {lead.opportunityDetails && (
          <FeedbackAnswerWrapper>
            <FeedbackAnswerTitle variant="body1">
              Can you share more information about this opportunity?
            </FeedbackAnswerTitle>
            <FeedbackAnswer variant="body1">
              {lead.opportunityDetails}
            </FeedbackAnswer>

            {lead?.aiVerifiedOpportunityDetails && (
              <FeedbackAnswer
                sx={{ paddingTop: '5px' }}
                variant="secondaryBody1"
              >
                <InlineIconWrapper>
                  <AISparklesIcon />
                </InlineIconWrapper>{' '}
                AI Verified
              </FeedbackAnswer>
            )}
          </FeedbackAnswerWrapper>
        )}
        {talkTakeaways?.text_value && (
          <FeedbackAnswerWrapper>
            <FeedbackAnswerTitle variant="body1">
              How would you describe this talk to a friend or colleague?
            </FeedbackAnswerTitle>
            <FeedbackAnswer variant="body1">
              {talkTakeaways?.text_value}
            </FeedbackAnswer>
          </FeedbackAnswerWrapper>
        )}
        {otherFeedback?.text_value && (
          <FeedbackAnswerWrapper>
            <FeedbackAnswerTitle variant="body1">
              Anything else to add?
            </FeedbackAnswerTitle>
            <FeedbackAnswer variant="body1">
              {otherFeedback?.text_value}
            </FeedbackAnswer>
          </FeedbackAnswerWrapper>
        )}
        {improvementFeedback?.text_value && (
          <FeedbackAnswerWrapper>
            <FeedbackAnswerTitle variant="body1">
              Areas of improvement
            </FeedbackAnswerTitle>
            <FeedbackAnswer variant="body1">
              {improvementFeedback?.text_value}
            </FeedbackAnswer>
          </FeedbackAnswerWrapper>
        )}
      </>
    )
  }

  const renderIntegrationDetails = () => {
    if (integrationItems.length === 0) return

    return (
      <IntegrationContainer>
        <IntegrationTitle variant="body2">Integration Details</IntegrationTitle>
        <StyledList>
          {integrationItems.map((item, index) => (
            <ColoredLi key={index} alert={item.alert}>
              {item.message}
            </ColoredLi>
          ))}
        </StyledList>
      </IntegrationContainer>
    )
  }

  const splitButtonOptions = [
    {
      text: `Mark as ${lead.contacted ? 'Uncontacted' : 'Contacted'}`,
      icon: <CheckMarkIcon />,
      onClick: () => handleMarkContacted(lead.id),
    },
    {
      text: lead.archived ? 'Unarchive' : 'Archive',
      icon: <ArchiveIcon />,
      onClick: () => handleArchiveLead(lead.id),
    },
    {
      text: 'Delete',
      icon: <TrashIcon />,
      onClick: () => handleDeleteLead(lead.id),
    },
  ]

  const renderContent = () => {
    if (leadLoading) {
      return <LoadingSpinner size={32} />
    }

    return (
      <>
        <ContactInfoRow>
          <LeadContactInfo>
            {lead.name && <LeadName variant="body2">{lead.name}</LeadName>}
            {lead.title && (
              <Typography variant="body1">{lead.title}</Typography>
            )}
            {lead.organization && (
              <Typography variant="body1">{lead.organization}</Typography>
            )}
            {lead.email && (
              <Typography variant="body1">{lead.email}</Typography>
            )}
            {lead.phone && (
              <Typography variant="body1">{lead.phone}</Typography>
            )}
          </LeadContactInfo>
          <LeadTypeContainer>{renderLeadType()}</LeadTypeContainer>
        </ContactInfoRow>
        <ResponsesRow>
          <LeadRatingsContainer>
            {talkValue?.text_value && (
              <YesNoRating
                answerValue={talkValue?.text_value}
                answerContent="Found talk valuable"
                emojiFontSize="0.875rem"
                typographyVariant="body1"
              />
            )}
            {talkReattend?.text_value && (
              <YesNoRating
                answerValue={talkReattend?.text_value}
                answerContent="Would attend future talks"
                emojiFontSize="0.875rem"
                typographyVariant="body1"
              />
            )}
          </LeadRatingsContainer>
          <ColumnWrapper>
            {talkEngagement?.length > 0 &&
              talkEngagement.map((engagement, i) => {
                return (
                  <EmojiRating
                    key={i}
                    answerText={engagement.label || engagement.text_value}
                    answerValue={engagement.range_value}
                    emojiSize="25px"
                  />
                )
              })}
          </ColumnWrapper>
          <FeedbackAnswerContainer>{renderFeedback()}</FeedbackAnswerContainer>
        </ResponsesRow>
        {renderIntegrationDetails()}
        <ActionsRow>
          <StyledButton
            variant={lead.contacted ? 'outlined' : 'contained'}
            color="primary"
            disabled={leadUpdating}
            loading={leadUpdating}
            onClick={() => handleMarkContacted(lead.id)}
          >
            <CheckMarkIcon />
            Mark as {lead.contacted ? 'Uncontacted' : 'Contacted'}
          </StyledButton>
          <StyledButton
            variant="outlined"
            color="tertiary"
            disabled={leadUpdating}
            loading={leadUpdating}
            onClick={() => handleArchiveLead(lead.id)}
          >
            <ArchiveIcon />
            {lead.archived ? 'Unarchive' : 'Archive'}
          </StyledButton>
          <StyledButton
            variant="outlined"
            color="tertiary"
            disabled={leadUpdating}
            loading={leadUpdating}
            onClick={() => handleDeleteLead(lead.id)}
          >
            <TrashIcon />
            Delete
          </StyledButton>
          <SplitButtonContainer>
            <SplitButton
              options={splitButtonOptions}
              variant="outlined"
              color="tertiary"
            />
          </SplitButtonContainer>
        </ActionsRow>
      </>
    )
  }

  return <Container loading={leadLoading}>{renderContent()}</Container>
}

export default LeadsTableRowExpand
