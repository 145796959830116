/**
 * Validates the format of a given shortlink string.
 *
 * @param {string} string - The shortlink string to be validated.
 * @returns {Object} - An object with properties:
 *                     - `valid`: A boolean indicating whether the string is valid.
 *                     - `message`: A string providing an error message if invalid, or null if valid.
 */
export const validateShortlinkFormat = (string) => {
  const shortlinkRegex = /^[a-z0-9-]+$/i

  if (!string || string.length < 2) {
    return {
      valid: false,
      message: 'shortlinks must be at least 2 characters in length',
    }
  }

  if (string?.length > 19) {
    return { valid: false, message: 'shortlinks must be 19 characters or less' }
  }

  if (!shortlinkRegex.test(string)) {
    return {
      valid: false,
      message:
        'shortlink can only be letters and numbers with no special characters',
    }
  }

  return { valid: true, message: null }
}

export const validateEmailFormat = (email) => {
  // test cases @, @@, @@@
  if (email === '') {
    return { valid: false, message: 'Please enter a Value' }
  }

  if (!/^([^@]*@[^@]*)$/.test(email)) {
    return { valid: false, message: 'An email address must contain a single @' }
  }

  const [username, domain] = email.split('@')

  if (username.length < 1) {
    return {
      valid: false,
      message:
        'The username portion of the email address is missing (the portion before the @:)',
    }
  }

  const username_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+$/

  if (!username_regex.test(username)) {
    return {
      valid: false,
      message:
        'The username portion of the email address contains an invalid character (the portion before the @:)',
    }
  }

  if (domain.length < 1) {
    return {
      valid: false,
      message:
        'The domain portion of the email address is missing (the portion after the @:)',
    }
  }

  const domainRegex =
    /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/

  if (!domainRegex.test(domain)) {
    return {
      valid: false,
      message:
        'The domain portion of the email address is invalid (the portion after the @)',
    }
  }

  return { valid: true, message: null }
}

export const validateNameFormat = (name) => {
  if (name.length > 25) {
    return { valid: false, message: 'Name must be less than 25 characters' }
  }

  return { valid: true, message: null }
}

export const validateUrlFormat = (url) => {
  try {
    new URL(url)
    return { valid: true, message: null }
  } catch (error) {
    return { valid: false, message: 'Invalid URL' }
  }
}
