import { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined'

import { AuthenticationContext } from '../authentication/authenticationContext'

const FormSectionWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'lastSection',
})(({ lastSection }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: '15px',
  marginBottom: lastSection ? '10px' : '35px',
  width: '100%',
}))

const StepNumber = styled('div')(({ theme }) => ({
  padding: '5px 12px',
  marginRight: '15px',
  background: theme.palette.primary.main,
  color: theme.palette.base.white,
  fontWeight: 'bolder',
  fontSize: '18px',
}))

const StepContent = styled('div')({
  width: '80%',
})

const StepContentDescription = styled(Typography)({
  marginBottom: '10px',
  fontSize: '1.125rem',
  fontWeight: '400',
})

const HeaderSection = styled(Typography)({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '18px',
  fontWeight: 'bold',
})

const SubheaderSection = styled(Typography)({
  fontSize: '16px',
  fontWeight: '200',
})

const HeadingNote = styled('span')({
  fontSize: '1rem',
  fontWeight: '400',
  marginLeft: '6px',
})

const HeaderTextWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const ProFeatureIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginLeft: '6px',
  color: theme.palette.secondary.main,
  div: {
    fontSize: '0.75rem',
  },
  cursor: 'pointer',
}))

const FormSection = ({
  stepNumber,
  description,
  heading,
  headerComponent = 'body1',
  headingNote,
  subheading,
  showProFeatureIcon,
  children,
  permissionType = null,
  lastSection = false,
}) => {
  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  return (
    <FormSectionWrapper lastSection={lastSection}>
      {stepNumber && <StepNumber>{stepNumber}</StepNumber>}
      <StepContent>
        {heading && (
          <HeaderSection variant={headerComponent}>
            <HeaderTextWrapper>
              {heading}
              {headingNote && <HeadingNote>{headingNote}</HeadingNote>}
            </HeaderTextWrapper>
            {showProFeatureIcon && (
              <ProFeatureIconWrapper
                onClick={() =>
                  toggleUpgradeModal(
                    true,
                    'Upgrade now to unlock custom configurations!',
                    permissionType
                  )
                }>
                <DiamondOutlinedIcon />
                <div>
                  <b>PRO</b>
                  <br />
                  <b>Feature</b>
                </div>
              </ProFeatureIconWrapper>
            )}
          </HeaderSection>
        )}
        {subheading && (
          <SubheaderSection variant="body1">{subheading}</SubheaderSection>
        )}
        <StepContentDescription component="div" variant="body1">
          <span dangerouslySetInnerHTML={{ __html: description }}></span>
        </StepContentDescription>
        {children}
      </StepContent>
    </FormSectionWrapper>
  )
}

export default FormSection
