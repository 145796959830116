import { useState } from 'react'
import { styled } from '@mui/system'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  TextField,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import { device } from '../../responsive'

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})(({ theme, backgroundColor }) => ({
  '& .MuiPaper-root': {
    backgroundColor: backgroundColor,
    width: '626px',
    fontFamily: 'Figtree, sans-serif',
    borderRadius: theme.shape.borderRadius.sm,
  },
}))

const Title = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
  padding: '2.125rem 1.1875rem 0.5rem 1.1875rem',
  p: {
    fontSize: '1.25rem',
  },
})

const TitleText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  marginLeft: '1.3125rem',
  fontWeight: 'bold',
  color: color,
  fontFamily: 'Figtree, sans-serif',
}))

const StyledDialogContent = styled(DialogContent)({
  padding: '0 4rem 1.25rem 4rem',
  fontSize: '0.875rem',
})

const DangerMessage = styled('div')(({ theme }) => ({
  color: theme.palette.red.main,
  fontWeight: 'bold',
  marginTop: '1rem',
}))

const ManualConfirmContainer = styled('div')({
  marginTop: '1.4rem',
})

const ConfirmLabel = styled('div')(({ theme }) => ({
  color: theme.palette.base.black,
}))

const ConfirmTextField = styled(TextField)(({ theme }) => ({
  marginTop: '1rem',
  width: '250px',
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.base.white,
    borderRadius: '0',
    '& fieldset': {
      borderColor: theme.palette.base.black + ' !important', // don't judge me
    },
  },
  [`@media ${device.mobile}`]: {
    width: '100%',
  },
}))

const StyledDialogActions = styled(DialogActions, {
  shouldForwardProp: (prop) =>
    prop !== 'columnLayout' && prop !== 'buttonOrder',
})(({ columnLayout, buttonOrder }) => ({
  paddingBottom: '2.125rem',
  paddingRight: '4rem',
  paddingLeft: '4rem',
  button: {
    fontWeight: '600',
    textTransform: 'none',
    padding: '0.875rem 1rem',
    borderRadius: '10px',
  },
  flexDirection: columnLayout
    ? 'column-reverse'
    : buttonOrder === 'confirmFirst'
    ? 'row-reverse'
    : 'row',
  alignItems: columnLayout ? 'flex-start' : 'inherit',
}))

const CancelButton = styled(Button)(({ theme, variant }) => ({
  border: variant === 'text' ? 'none' : `1px solid ${theme.palette.black.main}`,
  fontFamily: 'Figtree, sans-serif',
}))

const ConfirmButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'textColor' &&
    prop !== 'columnLayout' &&
    prop !== 'manualConfirm' &&
    prop !== 'buttonOrder',
})(({ textColor, columnLayout, buttonOrder }) => ({
  color: textColor || 'auto',
  marginLeft:
    columnLayout || buttonOrder === 'confirmFirst' ? '0 !important' : 'auto',
  marginRight: buttonOrder === 'confirmFirst' ? '1rem' : '0',
  marginBottom: columnLayout ? '2.25rem' : '0',
  fontFamily: 'Figtree, sans-serif',
  [`@media ${device.mobile}`]: {
    flex: '1',
  },
}))

const ConfirmDialog = ({
  title,
  children,
  open,
  setOpen,
  onConfirm,
  onCancel,
  buttonColor,
  textColor,
  buttonText,
  cancelButtonText,
  cancelButtonTextColor,
  cancelButtonVariant,
  manualConfirm = false,
  confirmText = '',
  columnLayout = false,
  buttonOrder = 'confirmLast',
  variant = 'danger',
  disableOutsideClick = false,
  showCancel = true,
  hideDangerMessage,
}) => {
  const [confirmValue, setConfirmValue] = useState('')

  const theme = useTheme()

  const backgroundColor = () => {
    switch (variant) {
      case 'danger':
        return theme.palette.red.light
      case 'warning':
        // TODO: this is placeholder
        return theme.palette.primary.light
      case 'info':
        return theme.palette.base.white
      default:
        return theme.palette.red.light
    }
  }

  const titleColor = () => {
    switch (variant) {
      case 'danger':
        return theme.palette.error.main
      case 'warning':
        return theme.palette.primary.main
      case 'info':
        return theme.palette.primary.main
      default:
        return theme.palette.error.main
    }
  }

  const handleClose = (reason) => {
    if (disableOutsideClick && reason === 'backdropClick') {
      return
    }

    if (onCancel) {
      onCancel()
    }
    setOpen(false)
    setConfirmValue('')
  }

  const handleConfirm = () => {
    if (manualConfirm && !confirmTextMatch) {
      return
    }
    setOpen(false)
    setConfirmValue('')
    onConfirm()
  }

  const renderIcon = () => {
    switch (variant) {
      case 'danger':
        return <ErrorIcon color="error" />
      case 'warning':
        return <ErrorIcon color="warning" />
      case 'info':
        return <ErrorIcon color="primary" />
      default:
        return <ErrorIcon color="error" />
    }
  }

  const showDangerMessage = !hideDangerMessage && variant === 'danger'

  const confirmTextMatch = confirmValue === confirmText

  return (
    <StyledDialog
      open={open}
      maxWidth="100%"
      fullWidth
      onClose={(e, reason) => handleClose(reason)}
      aria-labelledby="confirm-dialog"
      backgroundColor={backgroundColor()}>
      <Title>
        {renderIcon()}
        <TitleText color={titleColor()}>{title}</TitleText>
      </Title>
      <Box position="absolute" top={10} right={10}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <StyledDialogContent>
        {children}
        {showDangerMessage && (
          <DangerMessage>This action cannot be undone.</DangerMessage>
        )}
        {manualConfirm && (
          <ManualConfirmContainer>
            <ConfirmLabel>
              To continue, type <b>{confirmText}</b> to confirm
            </ConfirmLabel>
            <ConfirmTextField
              variant="outlined"
              value={confirmValue}
              onChange={(e) => setConfirmValue(e.target.value.toUpperCase())}
              error={!confirmTextMatch}
              helperText={
                !confirmTextMatch &&
                confirmValue.length > 0 &&
                `Type ${confirmText} to confirm`
              }
            />
          </ManualConfirmContainer>
        )}
      </StyledDialogContent>
      <StyledDialogActions
        columnLayout={columnLayout}
        buttonOrder={buttonOrder}>
        {showCancel && (
          <CancelButton
            color={cancelButtonTextColor || 'white'}
            variant={cancelButtonVariant || 'contained'}
            onClick={handleClose}>
            {cancelButtonText || 'Cancel'}
          </CancelButton>
        )}
        <ConfirmButton
          color={buttonColor || 'error'}
          textColor={textColor}
          variant="contained"
          disabled={manualConfirm && !confirmTextMatch}
          onClick={handleConfirm}
          manualConfirm={manualConfirm}
          columnLayout={columnLayout}
          buttonOrder={buttonOrder}>
          {buttonText || 'Confirm'}
        </ConfirmButton>
      </StyledDialogActions>
    </StyledDialog>
  )
}

export default ConfirmDialog
