import { Switch } from '@mui/material'
import { styled } from '@mui/system'

const StyledSwitch = styled(Switch, {
  shouldForwardProp: (prop) =>
    prop !== 'enabledText' && prop !== 'disabledText',
})(({ enabledText, disabledText }) => ({
  width: 77,
  height: 31,
  padding: 0,
  border: '1px solid #999999',
  borderRadius: '15px',
  backgroundColor: 'white',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    color: '#999999',
    '&.Mui-checked': {
      transform: 'translateX(27px)',
      color: 'orange',
      '& .MuiSwitch-thumb:before': {
        content: enabledText ? `'${enabledText}'` : "'ON'",
        color: 'white',
        textAlign: 'center',
        top: 4,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: 'white',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: 'white',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: '44px',
    height: '24px',
    borderRadius: '15px',
    '&:before': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 4,
      content: disabledText ? `'${disabledText}'` : "'OFF'",
      color: 'white',
      textAlign: 'center',
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'white',
  },
}))

export const SwitchWithLabel = ({ enabledText, disabledText, ...props }) => {
  return (
    <StyledSwitch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      enabledText={enabledText}
      disabledText={disabledText}
      {...props}
    />
  )
}
