import YouTube from 'react-youtube'
import { styled } from '@mui/system'

const VideoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  div: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
})

const StyledYouTube = styled(YouTube)({
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})

const VideoPlayerComponent = ({ videoId, autoplay, listId }) => {
  const videoOpts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: autoplay,
      listType: listId ? 'playlist' : 'single',
      list: listId,
    },
  }

  return (
    <VideoContainer>
      <StyledYouTube videoId={videoId} opts={videoOpts} />
    </VideoContainer>
  )
}

export default VideoPlayerComponent
