import { styled } from '@mui/system'

import SpeakerSlide from '../../common/SpeakerSlide'
import SurveyOffersOnboardingCta from './SurveyOffersOnboardingCta'

import { ReactComponent as ArrowOrange } from '../../../icons/arrow_orange.svg'

const WrapperContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const GraphicContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage:
    'url(https://d3qvkz8495pjoq.cloudfront.net/onboarding-text-bubble.png)',
  aspectRatio: '730 / 430',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  fontFamily: 'Figtree, sans-serif',
  padding: '50px 140px 100px 140px',
  position: 'relative',
  '@media (max-width: 1132px)': {
    padding: '50px 160px 100px 160px',
  },
  '@media (max-width: 850px)': {
    padding: '40px 150px 80px 150px',
  },
})

const Header = styled('h1')({
  fontSize: '1.625rem',
  fontWeight: 'normal',
  marginTop: '0',
  marginBottom: '1.5rem',
  '@media (max-width: 1132px)': {
    fontSize: '1.5rem',
    marginBottom: '1.5rem',
  },
  '@media (max-width: 850px)': {
    fontSize: '1.125rem',
  },
})

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '2rem',
  '@media (max-width: 1132px)': {
    marginTop: '1.5rem',
  },
  '@media (max-width: 850px)': {},
})

const ContentContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const SlidedeckContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '4rem',
  position: 'relative',
})

const SlideContainer = styled('div')({
  display: 'flex',
})

const SlideNumber = styled('div')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  fontFamily: 'Figtree, sans-serif',
  color: theme.palette.tertiary.main,
  width: '12px',
}))

const Slide = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '160px',
  height: '100px',
  marginLeft: '0.5rem',
  border: `1px solid ${theme.palette.neutral.light}`,
  borderRadius: '10px',
  fontSize: '0.9rem',
  padding: '0.5rem',
  textAlign: 'center',
  '@media (max-width: 1132px)': {
    width: '140px',
    height: '80px',
  },
  '@media (max-width: 850px)': {
    width: '100px',
    height: '60px',
    fontSize: '0.7rem',
  },
}))

const Divider = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-end',
  width: '160px',
  height: '2px',
  backgroundColor: theme.palette.secondary.main,
  margin: '0.5rem 0',
  '@media (max-width: 1132px)': {
    width: '140px',
  },
  '@media (max-width: 850px)': {
    width: '100px',
  },
}))

const FlexWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const SpeakerslideContainer = styled('div')({
  width: '384px',
  height: '216px',
  overflow: 'hidden',
  position: 'relative',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  '@media (max-width: 1132px)': {
    width: '288px',
    height: '162px',
  },
  '@media (max-width: 850px)': {
    width: '192px',
    height: '108px',
  },
})

const ScaleWrapper = styled('div')({
  position: 'absolute',
  transform: 'scale(0.4)',
  transformOrigin: 'left top',
  top: '0',
  left: '0',
  width: '384px',
  height: '216px',
  '@media (max-width: 1132px)': {
    width: '288px',
    height: '162px',
    transform: 'scale(0.3)',
  },
  '@media (max-width: 850px)': {
    width: '192px',
    height: '108px',
    transform: 'scale(0.2)',
  },
})

const ArrowContainer = styled('div')({
  position: 'absolute',
  bottom: '41%',
  right: '-50px',
  animation: 'slide 0.6s ease-out 0s infinite alternate',
  '@keyframes slide': {
    from: {
      right: '-50px',
      opacity: 1,
    },
    to: {
      right: '-58px',
      opacity: 0.6,
    },
  },
  '@media (max-width: 1132px)': {
    bottom: '39%',
  },
  '@media (max-width: 850px)': {
    bottom: '36%',
  },
})

const UnicornContainer = styled('div')({
  position: 'absolute',
  bottom: '0',
  left: '-20px',
  img: {
    maxHeight: '292px',
  },
  '@media (max-width: 1132px)': {
    left: '0',
    img: {
      maxHeight: '240px',
    },
  },
  '@media (max-width: 850px)': {
    img: {
      maxHeight: '200px',
    },
  },
})

const SurveyOffersOnboardingStepEight = ({
  advanceLastStep,
  loading,
  user,
  code,
}) => {
  return (
    <>
      <WrapperContainer>
        <GraphicContainer>
          <Header>
            Wondering where to put your slide?
            <br />
            The best place is right before your big close.
          </Header>
          <ContentContainer>
            <SlidedeckContainer>
              <SlideContainer>
                <SlideNumber>1</SlideNumber>
                <Slide>Your Deck</Slide>
              </SlideContainer>
              <Divider />
              <SlideContainer>
                <SlideNumber>2</SlideNumber>
                <Slide>Your Final Thought</Slide>
              </SlideContainer>
              <ArrowContainer>
                <ArrowOrange />
              </ArrowContainer>
            </SlidedeckContainer>
            <FlexWrapper>
              <SpeakerslideContainer>
                <ScaleWrapper>
                  <SpeakerSlide
                    avatar={user.avatar}
                    surveyLink={user.shortlink}
                    socialLinks={user.socialLinks}
                    email={user.email}
                    name={user.first_name}
                    size={'lg'}
                    code={code}
                  />
                </ScaleWrapper>
              </SpeakerslideContainer>
            </FlexWrapper>
          </ContentContainer>
          <ButtonContainer>
            <SurveyOffersOnboardingCta
              buttonText="Continue"
              handleClick={() => advanceLastStep()}
              loading={loading}
              fontSize="1.125rem"
            />
          </ButtonContainer>
        </GraphicContainer>
        <UnicornContainer>
          <img
            src="https://d3qvkz8495pjoq.cloudfront.net/unicorn_with_mic_cropped.png"
            alt="Talkadot Unicorn with Mic"
          />
        </UnicornContainer>
      </WrapperContainer>
    </>
  )
}

export default SurveyOffersOnboardingStepEight
