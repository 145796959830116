import { Typography } from '@mui/material'

import {
  StyledHeading,
  StyledPaper,
  StyledSubheading,
  StyledCodeBlock,
} from './ApiDocumentationParent'

const WebhooksDocumentation = () => {
  return (
    <StyledPaper>
      <StyledHeading variant="h4">Webhook Subscriptions API</StyledHeading>

      <Typography variant="body1" paragraph>
        This API allows users to manage webhook subscriptions for receiving
        updates.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Base URL:</strong> <code>/api/v1/hooks</code>
      </Typography>

      <StyledSubheading variant="h6">Endpoints</StyledSubheading>

      {/* Repeat this pattern for each section and endpoint */}
      <StyledSubheading variant="subtitle1">
        1. Subscribe to Webhook
      </StyledSubheading>

      <Typography variant="body1" paragraph>
        <strong>POST /subscribe</strong>
      </Typography>

      <Typography variant="body1" paragraph>
        Subscribe to a new webhook for receiving updates.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Parameters:</strong>
        <ul>
          <li>
            <code>hookUrl</code> (string, required): The URL to receive webhook
            notifications.
          </li>
          <li>
            <code>name</code> (string, required): The name of the webhook
            subscription.
          </li>
          <li>
            <code>client_id</code> (string, required): The client ID associated
            with the webhook subscription.
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Request:</strong>
        <StyledCodeBlock>
          {`{
  "hookUrl": "https://hooks.zapier.com/hooks/standard/12727857/caa22fd3c9ab4e6b9430c197529e46e0/",
  "name": "new_lead",
  "client_id": "12345"
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Response:</strong>
        <StyledCodeBlock>
          {`{
  "id": "1",
}`}
        </StyledCodeBlock>
      </Typography>

      <StyledSubheading variant="subtitle1">
        2. Unsubscribe from Webhook
      </StyledSubheading>

      <Typography variant="body1" paragraph>
        <strong>DELETE /unsubscribe</strong>
      </Typography>

      <Typography variant="body1" paragraph>
        Unsubscribe from an existing webhook.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Parameters:</strong>
        <ul>
          <li>
            <code>id</code> (string, required): The ID of the webhook
            subscription to unsubscribe from.
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Request:</strong>
        <StyledCodeBlock>
          {`{
  "id": "1"
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Response:</strong>
        <StyledCodeBlock>
          {`{
  "message": "success"
}`}
        </StyledCodeBlock>
      </Typography>

      <StyledSubheading variant="subtitle1">
        3. List of Test Data
      </StyledSubheading>

      <Typography variant="body1" paragraph>
        <strong>GET /perform_list</strong>
      </Typography>

      <Typography variant="body1" paragraph>
        Get a list of test data for webhook subscriptions.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Example Response:</strong>
        <StyledCodeBlock>
          {`{
  "[
    {
      "createdAt": "2024-02-08T12:00:00Z",
      "firstName": "John",
      "lastName": "Doe",
      "talkTitle": "How to be a great speaker",
      "talkOrganization": "Best Speakers Conference",
      "phone": "123-456-7890",
      "email": "john@doe.com",
      "organization": "Event Planner Inc.",
      "title": "CEO",
      "contacted": "",
      "warmth": "HOT",
      "bookingLead": "yes",
      "referLead": "",
      "optInLead": "yes"
    }
  ]"
}`}
        </StyledCodeBlock>
      </Typography>

      {/* Repeat the above pattern for other endpoints */}

      {/* Add error responses section */}
      <StyledSubheading variant="h6">Error Responses</StyledSubheading>

      <Typography variant="body1" paragraph>
        <strong>400 Bad Request:</strong> Missing or invalid parameters.
        <StyledCodeBlock>
          {`{
  "error": "invalid_request",
  "error_description": "Missing required parameters"
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>404 Not Found:</strong> Webhook subscription not found.
        <StyledCodeBlock>
          {`{
  "error": "not_found",
  "error_description": "Webhook subscription not found"
}`}
        </StyledCodeBlock>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>500 Internal Server Error:</strong> Something went wrong on the
        server.
        <StyledCodeBlock>
          {`{
  "error": "server_error",
  "error_description": "Something went wrong"
}`}
        </StyledCodeBlock>
      </Typography>

      {/* Repeat the above pattern for other error responses */}
    </StyledPaper>
  )
}

export default WebhooksDocumentation
