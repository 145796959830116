import { useContext, useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { styled } from '@mui/system'
import { Button, Typography, CircularProgress } from '@mui/material'

import Api from '../../services/api'
import envConfig from '../../envConfig'
import { AuthenticationContext } from '../authentication/authenticationContext'

import { ReactComponent as ArrowIcon } from '../../icons/arrowLeft.svg'
import { ReactComponent as UpgradeIcon } from '../../icons/upgradeIcon.svg'

import {
  AccountSettingsCard,
  AccountSettingsCardInner,
  AccountSettingsCardColumn,
} from '../accountSettings/AccountSettingsParent'

import CheckoutForm from './CheckoutForm'
import PricingGrid from './PricingGrid'
import PlanDetails from './PlanDetails'
import { trackViewedUpgrades } from '../../services/metrics'

import './Payments.scss'

const UpgradeContainerPlans = styled('div')({
  display: 'grid',
})

const PricingGridContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 320px))',
  gap: theme.spacing(4.5),
  justifyContent: 'center',
}))

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const UpgradeContainerCheckout = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(240px, 472px) 1fr',
  gap: theme.spacing(4.5),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}))

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}))

const CheckoutFormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.base.white,
  maxWidth: '472px',
  minWidth: '288px',
}))

const BackButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(5),
  color: theme.palette.neutral.semiLight,
  border: `1px solid ${theme.palette.border.main}`,
  svg: {
    marginRight: theme.spacing(1),
    path: {
      stroke: theme.palette.neutral.semiLight,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.base.white,
  },
}))

const FormHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const UpgradeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.base.white,
  fontSize: '1rem',
  marginBottom: theme.spacing(1),
  svg: {
    marginRight: theme.spacing(1),
  },
}))

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

const stripePromise = loadStripe(envConfig.REACT_APP_STRIPE_P_KEY)

const UpgradeParent = () => {
  const [plansLoading, setPlansLoading] = useState(false)
  const [plans, setPlans] = useState([])
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [upgradingPaidPlan, setUpgradingPaidPlan] = useState(false)
  const [currentPlan, setCurrentPlan] = useState({})
  const [selectedPlan, setSelectedPlan] = useState()
  const {
    toggleUpgradeModal,
    setNotification,
    showFullPageLoader,
    hideFullPageLoader,
    authData,
  } = useContext(AuthenticationContext)
  const {
    user,
    user: {
      membership: {
        isTrialing,
        subscribedWhileTrialing,
        trialDaysRemaining,
        nextBillingDate,
      },
    },
    upgradePermissiontype,
  } = authData

  useEffect(() => {
    const getUpgrades = async () => {
      try {
        setPlansLoading(true)
        const res = await Api.getAvailableUpgrades({
          required_permission: upgradePermissiontype,
        })

        if (!res.errors) {
          setPlans(res.availablePlans)
          setCurrentPlan(res.currentPlan)
          setUpgradingPaidPlan(res.upgradingWhileSubscribed)
          setPlansLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setPlansLoading(false)
        setNotification(err)
      }
    }

    trackViewedUpgrades()
    getUpgrades()
  }, [])

  const renderUpgradePlans = () => {
    return plansLoading ? (
      <CircularProgress />
    ) : (
      <UpgradeContainerPlans>
        <AccountSettingsCard>
          <AccountSettingsCardInner>
            <AccountSettingsCardColumn>
              <PricingGridContainer>
                {plans
                  .sort(
                    (a, b) => parseFloat(b.planTier) - parseFloat(a.planTier)
                  )
                  .map((plan, i) => {
                    return (
                      <PricingGrid
                        key={i}
                        plan={plan}
                        user={user}
                        isTrialing={isTrialing}
                        setSelectedPlan={setSelectedPlan}
                        selectedPlan={selectedPlan}
                        upgradingPaidPlan={upgradingPaidPlan}
                        subscribedWhileTrialing={subscribedWhileTrialing}
                        trialDaysRemaining={trialDaysRemaining}
                        currentPlan={currentPlan}
                        isCurrentPlan={
                          !isTrialing && plan.planTier === currentPlan.planTier
                        }
                      />
                    )
                  })}
              </PricingGridContainer>
            </AccountSettingsCardColumn>
          </AccountSettingsCardInner>
        </AccountSettingsCard>
      </UpgradeContainerPlans>
    )
  }

  const handleSubmitUpgrade = async (e) => {
    e.preventDefault()

    // Dont allow double clicks
    if (checkoutLoading) {
      return false
    }

    setCheckoutLoading(true)
    showFullPageLoader('Hang Tight While We Upgrade Your Account!')

    try {
      const params = {
        membership_plan_token: selectedPlan?.token,
      }

      const res = await Api.upgradePaidPlan(params)

      if (!res.errors) {
        toggleUpgradeModal(false)
        setCheckoutLoading(false)
        hideFullPageLoader()

        // Reload window to make sure data gets refreshed wherever they are
        return window.location.reload()
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      setCheckoutLoading(false)
      return setNotification(err)
    }
  }

  const renderUpgradeCheckoutForm = () => {
    return (
      <>
        <UpgradeButton
          variant="contained"
          color="primary"
          onClick={(e) => handleSubmitUpgrade(e)}
          disabled={checkoutLoading}>
          <UpgradeIcon />
          Upgrade to {selectedPlan?.planType}!
        </UpgradeButton>
        <Typography variant="body1">
          Questions? Check out our{' '}
          <StyledLink
            href="https://www.talkadot.com/frequently-asked-questions"
            target="_blank"
            rel="noreferrer">
            FAQ
          </StyledLink>{' '}
          or reach out any time to{' '}
          <StyledLink href="mailto:hello@talkadot.com">
            hello@talkadot.com
          </StyledLink>
        </Typography>
      </>
    )
  }

  const renderCheckout = () => {
    return (
      <Container>
        <UpgradeContainerCheckout>
          <FlexContainer>
            <CheckoutFormContainer>
              <div>
                <BackButton
                  variant="contained"
                  color="white"
                  onClick={() => setSelectedPlan(null)}>
                  <ArrowIcon />
                  Back to all plans
                </BackButton>
              </div>
              <FormHeader variant="h5">
                Join the Fam: Upgrade to {selectedPlan?.planType}
              </FormHeader>
              {upgradingPaidPlan ? (
                renderUpgradeCheckoutForm()
              ) : (
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    selectedPlan={selectedPlan}
                    isTrialing={isTrialing}
                  />
                </Elements>
              )}
            </CheckoutFormContainer>
          </FlexContainer>
          <PlanDetails
            selectedPlan={selectedPlan}
            upgradingPaidPlan={upgradingPaidPlan}
            isTrialing={isTrialing}
            nextBillingDate={nextBillingDate}
          />
        </UpgradeContainerCheckout>
      </Container>
    )
  }

  return selectedPlan ? renderCheckout() : renderUpgradePlans()
}

export default UpgradeParent
