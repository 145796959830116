import React from 'react'
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton'

const EventPlannerEngagement = ({ isLoading, engagementBreakdown }) => {
  const sortedEngagementBreakdown = engagementBreakdown?.sort((a, b) => parseFloat(b.avg_value) - parseFloat(a.avg_value));
  const showSection = engagementBreakdown?.length > 0

  const makeBarValue = (engagementStatValue) => {
    if (!engagementStatValue) {
      return
    } else {
      // All the numbers are on a scale of 1-5, so need to adjust this for a scale of 100%
      return parseFloat(engagementStatValue*20).toFixed(1)
    }
  }

  const COLOR_CODES = ['red', 'green', 'orange', '#178ECB', 'red', 'green', 'orange', '#178ECB']

  const renderEngagementRow = (engagementStat, index) => {
    const barValue = makeBarValue(engagementStat.avg_value)

    return(
      <div className="engagement-progress__container" key={index}>
        <div>
          <Typography variant="body2" component="div" id="engagement-type-label" sx={{fontSize: '16px'}}>{engagementStat.label}</Typography>
        </div>
        <div className="engagement-progress__outer-bar">
          { 
            barValue ?
            <div className="engagement-progress__inner-bar" style={{backgroundColor: COLOR_CODES[index], width: `${barValue}%`}}>
              {barValue}%
            </div> : <div>N/A</div>
          }
        </div>
      </div>
    )
  }

  const renderLoadingState = () => (
    <Skeleton width={400} height={200} variant="rectangular" />
  )

  const renderEngagementStats = () => (
    <div className="stat-widget-section" id="talk-engagement-section" style={{visibility: !showSection && 'hidden'}} >
      <Typography variant="h6" component="div" className="stat-percentage widget-title" id="talk-engagement-title">
        <b>Attendees found this event</b>
      </Typography>
      <div className="stat-widget-section__widgets">
        <div className="engagement-progress__list">
          { sortedEngagementBreakdown.map((engagementStat, i) => {
              return renderEngagementRow(engagementStat, i)
          })}
        </div>
      </div>
    </div> 
  )

  return isLoading ? renderLoadingState() : renderEngagementStats()
}

export default EventPlannerEngagement