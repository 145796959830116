import React, { useContext } from 'react'
import LeadsEmailCampaignParent from '../leads/LeadsEmailCampaignParent'
import {
  AccountSettingsCard,
  AccountSettingsCardInner,
  AccountSettingsCardTitle,
} from './AccountSettingsParent'
import UpgradeModalLauncher from '../common/UpgradeModalLauncher'
import { Alert } from '@mui/material'
import BetaChip from '../common/BetaChip'

import {
  usePermissionHelper,
  EMAIL_CAMPAIGNS,
} from '../../utils/permission_helper'

import { styled } from '@mui/system'

const InfoAlert = styled(Alert)({
  margin: '15px 0px',
})

const EmailCampaignSettings = () => {
  const { canUseEmailCampaigns } = usePermissionHelper()

  return (
    <AccountSettingsCard id="campaign-settings">
      <AccountSettingsCardTitle variant="h5">
        Email Campaign Settings
        <BetaChip />
        <InfoAlert severity="info" icon={false}>
          Talkadot will automatically email your leads a follow up and your
          bonus (if applicable) for each event.{' '}
          <a
            href="https://talk.talkadot.com/c/resources/talkadot-automated-email-campaigns-help-article"
            target="_blank"
            rel="noreferrer">
            <b>Learn More</b>
          </a>
        </InfoAlert>
        {!canUseEmailCampaigns() && (
          <UpgradeModalLauncher
            content="Upgrade To Turn On"
            variant="outlined"
            upgradeHelperText="Upgrade To Use Our Automated Email Campaigns!"
            permissionType={EMAIL_CAMPAIGNS}
          />
        )}
      </AccountSettingsCardTitle>
      <AccountSettingsCardInner>
        <LeadsEmailCampaignParent />
      </AccountSettingsCardInner>
    </AccountSettingsCard>
  )
}

export default EmailCampaignSettings
