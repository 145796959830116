const NsaChapterList = [
  'BlackNSA - BNSA',
  'Florida Speakers Assc - NSAFSA',
  'NSA Arizona - NSAAZ',
  'NSA Austin - NSAAUS',
  'NSA Carolinas - NSACAS',
  'NSA Central Florida - NSACF',
  'NSA Colorado - NSACO',
  'NSA Connecticut - NSACT',
  'NSA DC - NSADC',
  'NSA Georgia - NSAGA',
  'NSA Houston - NSAHOU',
  'NSA Illinois - NSAIL',
  'NSA Kansas City - NSAKC',
  'NSA Kentucky - NSAKY',
  'NSA Las Vegas - NSANV',
  'NSA Los Angeles - NSALA',
  'NSA Michigan - NSAMI',
  'NSA Minnesota - NSAMN',
  'NSA Mountain West - NSAMW',
  'NSA New England - NSANE',
  'NSA New Mexico - NSANM',
  'NSA New Orleans - NSANOLA',
  'NSA New York - NSANY',
  'NSA North Texas - NSANT',
  'NSA Northern California - NSANC',
  'NSA Northwest - NSANW',
  'NSA Ohio - NSAOH',
  'NSA Oregon - NSAOR',
  'NSA Philadelphia - NSAPHL',
  'NSA Pittsburgh - NSAPITT',
  'NSA St. Louis - NSAMO',
  'NSA Virginia - NSAVA',
  'NSA Wisconsin - NSAWIS',
]

export const OrganizationsList = [
  {
    label: 'Innovation Women',
  },
  {
    label: 'National Speakers Association',
    chapterList: NsaChapterList,
  },
  {
    label: 'Next Level Speakers Academy',
  },
  {
    label: 'Maxwell Leadership',
  },
  {
    label: 'Toastmasters',
  },
  {
    label: 'Black Speakers Network',
  },
  {
    label: 'Speakerflow',
  },
  {
    label: 'APCA',
  },
  {
    label: 'CAPS',
  },
  {
    label: 'Greek University',
  },
  {
    label: 'Impact Elevent',
  },
  {
    label: 'NAMCA',
  },
  {
    label: 'Other - Not Listed Here',
  },
]

export const FullOrganizationsList = OrganizationsList.map(
  (listItem) => listItem.label
).concat(NsaChapterList)
