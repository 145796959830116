import { styled } from '@mui/system'

import { IconButton, Tooltip } from '@mui/material'

import { ReactComponent as TrashIcon } from '../../../icons/trash2Icon_16x16.svg'

const DeleteButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'iconColor',
})(({ theme, iconColor }) => ({
  svg: {
    path: {
      stroke: iconColor || theme.palette.neutral.veryDark,
    },
  },
}))

const CodeBuilderDeleteButton = ({
  handleDelete,
  iconColor,
  toolTip = 'Click to delete this question permanently.',
}) => {
  return (
    <Tooltip title={toolTip} disableInteractive arrow>
      <DeleteButton iconColor={iconColor} onClick={handleDelete}>
        {<TrashIcon />}
      </DeleteButton>
    </Tooltip>
  )
}

export default CodeBuilderDeleteButton
