import { useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Skeleton,
  Button,
  Alert,
  Typography,
  AlertTitle,
  CircularProgress,
} from '@mui/material'
import { SwitchWithLabel } from '../common/SwitchWithLabel'
import { styled } from '@mui/system'
import { AuthenticationContext } from '../authentication/authenticationContext'
import * as qs from 'qs'

import Api from '../../services/api'
import { humanizeError } from '../../utils/errorFormatter'

const AcceptanceContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
  width: '85%',
  margin: '0 auto',
  '.MuiAlert-message': {
    width: '100%',
  },
  backgroundColor: theme.palette.tertiary.extraLight,
  padding: '20px',
}))

const InnerAcceptanceContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  textAlign: 'left',
  h5: {
    textAlign: 'center',
  },
})

const AcceptanceSettingsContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const AcceptanceButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
  width: '100%',
  color: theme.palette.base.white,
  margin: '0 auto',
}))

const GroupInvitationAcceptancePArent = () => {
  const [group, setGroup] = useState({})
  const [groupLoading, setGroupLoading] = useState(false)
  const [acceptanceLoading, setAcceptanceLoading] = useState(false)
  const [autoAddEvents, toggleAutoAddEvents] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const location = useLocation()
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const groupInvitationToken = urlParams.git
  const history = useHistory()
  const { authData, removeGroupInvitation, setNotification } = useContext(
    AuthenticationContext
  )
  const { user } = authData

  useEffect(() => {
    const fetchGroupFromToken = async () => {
      const reqParams = {
        group_invitation: {
          invitation_token: groupInvitationToken,
        },
      }

      try {
        setGroupLoading(true)
        const res = await Api.getGroupInvitationDetails(reqParams)

        if (!res.errors) {
          // setGroup(res)
          removeGroupInvitation(res)
          setNotification('Invitation accepted successfully!', 'success')
          setGroupLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setGroupLoading(false)
        setNotification(err)
      }
    }
    // if (!user?.id) {
    //   // IF the user isn't signed in, send them to the registration page
    //   // with the groups token
    //   history.push(`/register?git=${groupInvitationToken}`)
    // } else {
    fetchGroupFromToken()
    return history.push(`/groups`)
    // }
  }, [groupInvitationToken, user])

  const handleJoinGroup = async () => {
    const reqParams = {
      group_membership: {
        auto_add_events: autoAddEvents,
        group_id: group.id,
      },
    }

    try {
      setAcceptanceLoading(true)
      setErrorMessage(null)
      const res = await Api.createGroupMembership(reqParams)

      if (!res.errors) {
        setAcceptanceLoading(false)
        removeGroupInvitation({ id: group.id })
        // TODO:
        // Once we adjust the permissions for group viewing,
        // send the user to that view instead
        return history.push(`/groups`)
      } else {
        throw res.errors
      }
    } catch (err) {
      setAcceptanceLoading(false)
      setErrorMessage(humanizeError(err))
    }
  }

  return groupLoading ? (
    <AcceptanceContainer>
      <Skeleton height={100} />
      <Skeleton height={250} />
    </AcceptanceContainer>
  ) : (
    <AcceptanceContainer>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {group?.id && group.already_group_member && (
        <InnerAcceptanceContainer>
          <Typography variant="h5">
            You're already a member of <b>{group.name}</b>!
          </Typography>
          <AcceptanceButton
            variant="contained"
            color="primary"
            onClick={() => {
              removeGroupInvitation({ id: group.id })
              history.push('/groups')
            }}>
            Go To Group Details
          </AcceptanceButton>
        </InnerAcceptanceContainer>
      )}
      {group?.id && !group.already_group_member && (
        <InnerAcceptanceContainer>
          <Typography variant="h5">
            You've been invited to join <b>{group.name}</b>
          </Typography>
          <Alert severity="info">
            <AlertTitle>
              <strong>Group Data Retention Settings:</strong>
            </AlertTitle>
            <Typography>
              If you leave this group,
              {group.retain_report_access
                ? 'the group will retain access to all of the speaking engagement reports you have shared with them.'
                : 'the group will no longer be able to access all of the speaking engagement reports you have shared with them.'}
            </Typography>
          </Alert>
          <Alert severity="info">
            <AlertTitle>
              <strong>Data Sharing Settings:</strong>
            </AlertTitle>
            <AcceptanceSettingsContainer>
              <Typography>
                Enable this setting to automatically make any newly created
                speaking engagements available to this Group. This does not
                affect speaking engagements that were created prior to toggling
                this setting on.
              </Typography>
              <SwitchWithLabel
                checked={autoAddEvents}
                onChange={(e) => toggleAutoAddEvents(e.target.checked)}
              />
            </AcceptanceSettingsContainer>
          </Alert>
          <AcceptanceButton
            variant="contained"
            color="primary"
            disabled={acceptanceLoading}
            onClick={handleJoinGroup}>
            {acceptanceLoading ? (
              <CircularProgress size="1.2rem" color="inherit" />
            ) : (
              'Join Group'
            )}
          </AcceptanceButton>
        </InnerAcceptanceContainer>
      )}
    </AcceptanceContainer>
  )
}

export default GroupInvitationAcceptancePArent
