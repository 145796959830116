import { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  Button,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material'
import {
  EventGroupFormFields,
  FormDialogContent,
  FormDialogActions,
} from './CreateEventGroupButton'
import EditIcon from '@mui/icons-material/Edit'
import Api from '../../services/api'
import { humanizeError } from '../../utils/errorFormatter'

const EditEventGroupButton = ({ eventGroup, setEventGroups, eventGroups }) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [editableEventGroup, setEditableEventGroup] = useState(eventGroup)

  const handleEditEventGroup = async () => {
    const params = {
      event_group: {
        name: editableEventGroup.name,
        start_date: editableEventGroup.startDate,
        end_date: editableEventGroup.endDate,
        in_person: editableEventGroup.inPerson,
        virtual: editableEventGroup.virtual,
        thank_you_message: editableEventGroup.thankYouMessage,
        thank_you_button_text: editableEventGroup.thankYouButtonText,
      },
    }

    try {
      setIsLoading(true)
      const res = await Api.editEventGroup(params, eventGroup.id)

      if (!res.errors) {
        setIsLoading(false)
        setOpen(false)
        setEventGroups(eventGroups.map((eg) => (eg.id === res.id ? res : eg)))
      } else {
        throw res.errors
      }
    } catch (err) {
      setOpen(false)
      setIsLoading(false)
      alert(humanizeError(err))
    }
  }

  return (
    <div>
      <MenuItem key="1" onClick={() => setOpen(true)}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>Edit Event</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}>
        <DialogTitle>Edit Event Details</DialogTitle>
        <FormDialogContent>
          <EventGroupFormFields
            setEventGroup={setEditableEventGroup}
            eventGroup={editableEventGroup}
          />
        </FormDialogContent>
        <FormDialogActions>
          {isLoading ? (
            <CircularProgress sx={{ color: 'orange' }} />
          ) : (
            <div>
              <Button
                style={{ color: 'orange' }}
                onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                className="orange-button"
                onClick={handleEditEventGroup}>
                Save Changes
              </Button>
            </div>
          )}
        </FormDialogActions>
      </Dialog>
    </div>
  )
}

export default EditEventGroupButton
