import { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { Chip, ListItemText, ListItemIcon, Menu, MenuItem } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const EmojiKeywordChipWrapper = styled('div')({
  flex: '1',
})

const EmojiKeywordChip = styled(Chip)({
  background: 'white',
  fontSize: '16px',
  padding: '5px',
  marginLeft: '5px',
  width: '100%',
  justifyContent: 'flex-start',
  fontWeight: 'bold',
  span: {
    flex: '1',
  },
})

const EmojiChipContent = styled('div')({
  display: 'flex',
  flex: '1',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const EmojiKeywordSelect = ({
  currentEmojiKeyword,
  offerEmojiKeywords,
  defaultEmojiKeywords,
  canAddCustomQuestion,
  setOfferEmojiKeywords,
  setPendingSubmission,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [availableEmojiKeywords, setAvailableEmojiKeywords] = useState([])

  useEffect(() => {
    const availableKeywords = defaultEmojiKeywords.map((keyword) => {
      const offerKeyword = offerEmojiKeywords.find(
        (offerKeyword) => offerKeyword.keyword === keyword.keyword
      )
      return {
        ...keyword,
        emoji_template_identifier: offerKeyword
          ? offerKeyword.emoji_template_identifier
          : null,
      }
    })

    setAvailableEmojiKeywords(availableKeywords)
  }, [offerEmojiKeywords, currentEmojiKeyword])

  const handleOpenDropdown = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  const replaceEmojiKeyword = (oldKeyword, newKeyword) => {
    return {
      ...oldKeyword,
      keyword: newKeyword.keyword,
    }
  }

  const handleSelectKeyword = (newEmojiKeyword) => {
    setOfferEmojiKeywords(
      // Swap the currentEmojiKeyword with newEmojiKeyword
      offerEmojiKeywords.map((keyword) => {
        if (keyword.keyword === currentEmojiKeyword.keyword) {
          // Replace the currentEmojiKeyword with newEmojiKeyword
          return replaceEmojiKeyword(currentEmojiKeyword, newEmojiKeyword)
        } else if (keyword.keyword === newEmojiKeyword.keyword) {
          // Replace the newEmojiKeyword in the existing list with currentEmojiKeyword.keyword
          return { ...keyword, keyword: currentEmojiKeyword.keyword }
        } else {
          // Keep other keywords unchanged
          return { ...keyword }
        }
      })
    )

    // NOTE: Don't strictly need this because we do it in the useEffect above
    // but if I don't set it here it seems to cause some flickering in the UI
    setAvailableEmojiKeywords(
      availableEmojiKeywords.map((keyword) =>
        keyword.keyword === newEmojiKeyword.keyword
          ? {
              ...keyword,
              emoji_template_identifier:
                currentEmojiKeyword.emoji_template_identifier,
            }
          : keyword.keyword === currentEmojiKeyword.keyword
          ? {
              ...keyword,
              emoji_template_identifier: null,
            }
          : { ...keyword }
      )
    )
    // trigger handleSubmit after offer state has been updated
    setPendingSubmission(true)
    handleCloseDropdown()
  }

  return (
    <EmojiKeywordChipWrapper>
      <EmojiKeywordChip
        key={currentEmojiKeyword.id}
        variant="outlined"
        onClick={handleOpenDropdown}
        label={
          <EmojiChipContent>
            <div>{currentEmojiKeyword.keyword}</div>
            <KeyboardArrowDownIcon />
          </EmojiChipContent>
        }
      />
      {/* TODO: this should probably be a Select component, not a Menu */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {availableEmojiKeywords.map((emojiKeyword, i) => {
          if (
            emojiKeyword.keyword === currentEmojiKeyword.keyword ||
            !emojiKeyword.emoji_template_identifier
          ) {
            return (
              <MenuItem
                key={i}
                disabled={!canAddCustomQuestion}
                onClick={() => handleSelectKeyword(emojiKeyword)}>
                <ListItemText primary={emojiKeyword?.keyword} />
                {emojiKeyword?.emoji_template_identifier && (
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                )}
              </MenuItem>
            )
          }
          return null
        })}
      </Menu>
    </EmojiKeywordChipWrapper>
  )
}

export default EmojiKeywordSelect
