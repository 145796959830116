import { styled } from '@mui/system'
import {
  TableCell,
  Checkbox,
  Tooltip,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Avatar,
} from '@mui/material'

import {
  ActionsTableCell,
  CheckboxTableCell,
  SessionFromEventIcon,
} from '../common/TableComponents'
import TableActionsDropdown from '../common/TableActionsDropdown'

import { formatDate } from '../common/helpers'
import { renderPercentage } from '../../utils/number'

const SpeakersListAvatar = styled(Avatar)({
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  img: {
    width: '102%',
    height: '102%',
  },
})

// TODO: Refactor this to use common styled table cell
const DateCell = styled(TableCell)({
  textWrap: 'nowrap',
})

const TalksTableRowGroup = ({
  event,
  isEventOwner,
  handleSelectEvent,
  menuItems,
  goToEventReport,
  handleOpenEventForm,
}) => {
  const handleCellClick = (value) => {
    // if cell does not contain a value AND user is event owner return,
    // let button handle click, otherwise open event report
    if (
      (value === '' || value === null || value === undefined) &&
      isEventOwner
    ) {
      return
    }
    goToEventReport(event)
  }

  const renderCellContent = ({ value, editButtonText = 'add' }) => {
    return (
      <>
        {value ? (
          <>{value}</>
        ) : (
          <Button
            variant="text"
            color="secondary"
            onClick={() => handleOpenEventForm(event)}>
            {editButtonText}
          </Button>
        )}
      </>
    )
  }

  const renderSpeakerListItem = (speaker) => (
    <ListItem key={speaker.id} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
      <ListItemIcon>
        <SpeakersListAvatar
          alt={speaker.full_name}
          src={speaker.avatar || speaker.full_name?.charAt(0)}
          sx={{ width: 38, height: 38 }}
        />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body1">{speaker.full_name}</Typography>
      </ListItemText>
    </ListItem>
  )

  const renderSpeakerList = () => {
    return (
      <List>
        {event.speakers.map((speaker) => {
          return renderSpeakerListItem(speaker)
        })}
      </List>
    )
  }

  return (
    <>
      <CheckboxTableCell>
        {!isEventOwner ? (
          <Tooltip
            placement="top"
            title="This session is from a talk you gave at an event that was organized by someone else.">
            <SessionFromEventIcon>E</SessionFromEventIcon>
          </Tooltip>
        ) : (
          <Checkbox
            color="secondary"
            checked={!!event.isSelected}
            onChange={handleSelectEvent}
            inputProps={{ 'aria-labelledby': event.id }}
          />
        )}
      </CheckboxTableCell>
      <DateCell onClick={() => handleCellClick(event.date)}>
        {renderCellContent({ value: formatDate(event.date, event.utcOffset) })}
      </DateCell>
      <TableCell
        onClick={() => handleCellClick(event.talkTitle)}
        padding={event.talkTitle ? 'normal' : 'none'}>
        {renderCellContent({ value: event.talkTitle })}
      </TableCell>
      <TableCell align="left" onClick={goToEventReport}>
        {renderSpeakerList()}
      </TableCell>
      <TableCell align="center" onClick={goToEventReport}>
        {renderPercentage(event.reattend)}
      </TableCell>
      <TableCell align="center" onClick={goToEventReport}>
        {renderPercentage(event.rating)}
      </TableCell>
      <TableCell align="center" onClick={goToEventReport}>
        {event.responses}
      </TableCell>
      <TableCell align="center" onClick={goToEventReport}>
        {event.eventCode?.toUpperCase()}
      </TableCell>
      <ActionsTableCell align="center">
        <TableActionsDropdown menuItems={menuItems} />
      </ActionsTableCell>
    </>
  )
}

export default TalksTableRowGroup
