import React, { useContext, createRef } from 'react'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { Typography, Skeleton, Tooltip } from '@mui/material'
import EventGroupList from './EventGroupList'
import { styled } from '@mui/material/styles'
import QrCodeIcon from '@mui/icons-material/QrCode'
import envConfig from '../../envConfig'
import * as Helpers from '../common/helpers'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import '../dashboard/Dashboard.scss'

var QRCode = require('qrcode.react')

const HeaderContainer = styled('div')({
  marginBottom: '30px',
  textAlign: 'left',
})

const Header = styled(Typography)({
  fontWeight: 'bold',
  textTransform: 'capitalize',
})

const EventPlannerDashboard = () => {
  const { authData, setNotification } = useContext(AuthenticationContext)
  const { user, fullPageLoader } = authData
  const fullSpeakerLink = `${envConfig.REACT_APP_SHORTLINK}/${user?.shortlink}`
  const speakerShortlink = user?.shortlink?.toUpperCase()
  const qrDownloadRef = createRef()

  const DownloadableQrCode = () => {
    return (
      <div
        ref={qrDownloadRef}
        style={{ height: '180px', width: '180px', marginBottom: '-180px' }}>
        <QRCode
          level={'M'}
          value={`https://${fullSpeakerLink}`}
          size={180}
          id="qr-code-dl"
          title="talkadot-review-link"
          style={{ visibility: 'hidden' }}
        />
      </div>
    )
  }

  const handleCopy = () => {
    Helpers.handleCopy(fullSpeakerLink)
    return setNotification('Copied!', 'success')
  }

  const SpeakerShortlink = () => (
    <Typography component="div" variant="h6" className="dashboard-link-summary">
      <div>
        <b>Your Talkadot handle:</b>
      </div>
      <div className="dashboard-link-summary__link-section">
        <span className="copyable-survey-link">
          <b>talk.ac/{user.shortlink}</b>
        </span>
        <Tooltip title="Copy Survey Link" placement="top">
          <ContentCopyIcon onClick={handleCopy} />
        </Tooltip>
      </div>
    </Typography>
  )

  const SpeakerLinkActions = () => {
    const exportQrCode = () => {
      return Helpers.downloadInvisibleComponent(
        qrDownloadRef,
        'qr-code-dl',
        `Talkadot-QR-${speakerShortlink}`
      )
    }

    return (
      <div className="speaker-link-share">
        <div
          className="speaker-link-share__share-type"
          onClick={() =>
            window.open(`http://${fullSpeakerLink}?mode=test`, '_blank')
          }>
          <div className="speaker-link-share__icon">
            <BuildOutlinedIcon />
          </div>
          <div>Test My Talkadot Flow</div>
        </div>
        <div className="speaker-link-share__share-type" onClick={exportQrCode}>
          <div className="speaker-link-share__icon">
            <QrCodeIcon />
          </div>
          <div>Download My QR Code</div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {fullPageLoader.isActive ? (
        <Skeleton variation="rectangular" width={'100%'} height={'50px'} />
      ) : (
        <HeaderContainer>
          <Header variant="h3" color="secondary">
            Welcome, {user.first_name}!
          </Header>
        </HeaderContainer>
      )}
      <DownloadableQrCode />
      <SpeakerShortlink />
      <SpeakerLinkActions />
      <EventGroupList />
    </div>
  )
}

export default EventPlannerDashboard
