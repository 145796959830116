import React, { useContext, useEffect, useState } from 'react'
import { AuthenticationContext } from '../authentication/authenticationContext'
import Snackbar from '@mui/material/Snackbar'

import { Notification } from './Notification'

const NotificationParent = () => {
  const { authData, closeNotification } = useContext(AuthenticationContext)
  const { notification } = authData
  const { showNotification, message, notificationType } = notification

  const [state, setState] = useState({
    open: true,
    vertical: 'top',
    horizontal: 'right',
  })

  const { vertical, horizontal, open } = state

  useEffect(() => {
    setState({ ...state, open: showNotification })
  }, [showNotification])

  const handleClose = () => {
    closeNotification()
    setState({ ...state, open: false })
  }

  const renderNotification = () => {
    let variant
    switch (notificationType) {
      case 'info':
        variant = 'whiteWarning'
        break
      case 'success':
        variant = 'success'
        break
      case 'warning':
        variant = 'orangeWarning'
        break
      case 'error':
        variant = 'error'
        break
      default:
        variant = 'error'
    }

    return (
      <Notification variant={variant} onClose={handleClose}>
        {message}
      </Notification>
    )
  }

  return (
    // <div className="my-alert showing">
    <Snackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={6000}
      onClose={handleClose}>
      {renderNotification()}
    </Snackbar>
    // </div>
  )
}

export default NotificationParent
