import { useContext, useState } from 'react'
import { styled } from '@mui/system'
import { TableRow, Tooltip, Typography } from '@mui/material'

import { ReactComponent as DownloadIcon } from '../../icons/downloadIcon_16x16.svg'
import { ReactComponent as DownloadQRCodeIcon } from '../../icons/qrCodeIcon_16x16.svg'
import { ReactComponent as EyeIcon } from '../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EditIcon } from '../../icons/editIcon_16x16.svg'
import { ReactComponent as CopyIcon } from '../../icons/copyIcon_16x16.svg'
import { ReactComponent as TrashIcon } from '../../icons/trash2Icon_16x16.svg'
import { ReactComponent as LockIcon } from '../../icons/lockIcon_16x16.svg'
import { ReactComponent as MailIcon } from '../../icons/mailIcon_16x16.svg'
import { ReactComponent as LeadsIcon } from '../../icons/leadsIcon_16x16.svg'
import { ReactComponent as BonusIcon } from '../../icons/bonusIcon_16x16.svg'
import { ReactComponent as WrenchIcon } from '../../icons/wrenchIcon_16x16.svg'
import { ReactComponent as CustomSettingsIcon } from '../../icons/customSettingsIcon_16x16.svg'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'
import history from '../../routes/history'

import { ActionsTableCell } from '../common/TableComponents'
import DisabledBadgeIconWrapper from '../common/DisabledBadgeIconWrapper'
import TableCellStyled from '../common/TableCellStyled'
import TableActionsDropdown from '../common/TableActionsDropdown'
import GateToolTip from '../common/GateTooltip'

import { handleCopy, makeSurveyLink, formatDate } from '../common/helpers'

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'isGated',
})(({ isGated }) => ({
  cursor: 'pointer',
  opacity: isGated ? 0.5 : 1,
}))

const CodeTypography = styled(Typography)({
  fontWeight: 600,
})

const SettingsIconsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
}))

const SettingsIconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const CodesTableRow = ({
  offer,
  handleDelete,
  handleEdit,
  handleOpenSlideDownload,
  handleDownloadQrCode,
  // TODO: remove this prop when we remove the old codes page
  customCodes,
}) => {
  const { authData, setNotification } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)

  const { user } = authData
  const shortlink = selectedGroup ? selectedGroup.shortlink : user?.shortlink

  const codeUrl = offer.downloadUrl ? offer.downloadUrl : offer.link
  const regex = /^((http|https):\/\/)/
  const url = !regex.test(codeUrl) ? '//' + codeUrl : codeUrl

  // We create this offer code for all users, and it can't be changed
  const { isGated } = offer
  const isDefaultOffer = offer.code === 'TALK'

  const menuItems = [
    {
      text: 'Download Slide',
      icon: <DownloadIcon />,
      onClick: () => handleOpenSlideDownload(offer),
      disabled: isGated,
    },
    {
      text: 'Download QR Code',
      icon: <DownloadQRCodeIcon />,
      onClick: () => handleDownloadQrCode(offer),
      disabled: isGated,
    },
    {
      text: 'View Bonus',
      icon: <EyeIcon />,
      onClick: () => openOffer(),
      visible: !isDefaultOffer,
      disabled: isGated || !codeUrl,
    },
    {
      text: 'Edit Code',
      icon: <EditIcon />,
      onClick: () => handleEdit(offer),
      visible: !isDefaultOffer,
      disabled: isGated,
    },
    {
      text: 'Test Code',
      icon: <WrenchIcon />,
      onClick: () =>
        window.open(
          makeSurveyLink({ shortlink, code: offer.code, test: true }),
          '_blank',
          'noreferrer'
        ),
      disabled: isGated,
    },
    {
      text: 'Copy Code Link',
      icon: <CopyIcon />,
      onClick: () =>
        handleCopyCodeLink(makeSurveyLink({ shortlink, code: offer.code })),
      visible: !isDefaultOffer,
      disabled: isGated,
    },
    {
      text: 'Delete Code',
      icon: <TrashIcon />,
      onClick: () => handleDelete(offer.id),
      visible: !isDefaultOffer,
    },
  ]

  const handleCopyCodeLink = (copyText) => {
    handleCopy(copyText)
    return setNotification('Code Link Copied!', 'success')
  }

  const openOffer = () => {
    return window.open(url, '_blank', 'noreferrer')
  }

  const renderSettingsIcons = () => {
    const { lockedEvent } = offer

    return (
      <SettingsIconsContainer>
        {!offer.downloadUrl && !offer.link && (
          <Tooltip
            title="No bonus attached to this offer"
            placement="top"
            disableHoverListener={isGated}>
            <SettingsIconWrapper>
              <DisabledBadgeIconWrapper>
                <BonusIcon />
              </DisabledBadgeIconWrapper>
            </SettingsIconWrapper>
          </Tooltip>
        )}
        {lockedEvent && (
          <Tooltip
            placement="top"
            title={`All responses that use this offer code will always go to the event ${
              lockedEvent.talkTitle ? ':' + lockedEvent.talkTitle : ''
            } from ${formatDate(lockedEvent.date)}`}
            disableHoverListener={isGated}>
            <SettingsIconWrapper>
              <LockIcon />
            </SettingsIconWrapper>
          </Tooltip>
        )}
        {offer.disableContactCollection && (
          <Tooltip
            title="Collect contact info is OFF for this offer"
            placement="top"
            disableHoverListener={isGated}>
            <SettingsIconWrapper>
              <DisabledBadgeIconWrapper>
                <MailIcon />
              </DisabledBadgeIconWrapper>
            </SettingsIconWrapper>
          </Tooltip>
        )}
        {offer.disableBookingFlow && (
          <Tooltip
            title="Booking info collection is OFF for this offer"
            placement="top"
            disableHoverListener={isGated}>
            <SettingsIconWrapper>
              <DisabledBadgeIconWrapper>
                <LeadsIcon />
              </DisabledBadgeIconWrapper>
            </SettingsIconWrapper>
          </Tooltip>
        )}
        {offer.disableLeadgen && (
          <>
            <Tooltip
              title="Booking info collection is OFF for this offer"
              placement="top"
              disableHoverListener={isGated}>
              <SettingsIconWrapper>
                <DisabledBadgeIconWrapper>
                  <LeadsIcon />
                </DisabledBadgeIconWrapper>
              </SettingsIconWrapper>
            </Tooltip>
            <Tooltip
              title="Email collection is OFF for this offer"
              placement="top">
              <SettingsIconWrapper>
                <DisabledBadgeIconWrapper>
                  <MailIcon />
                </DisabledBadgeIconWrapper>
              </SettingsIconWrapper>
            </Tooltip>
          </>
        )}
        {offer.customQuestion && (
          <Tooltip
            title="Custom question active on this offer"
            placement="top"
            disableHoverListener={isGated}>
            <SettingsIconWrapper>
              <CustomSettingsIcon />
            </SettingsIconWrapper>
          </Tooltip>
        )}
      </SettingsIconsContainer>
    )
  }

  const handleCellClick = () => {
    if (isDefaultOffer) return

    if (isGated) return

    // TODO: remove this check when we remove the old codes page
    if (customCodes) {
      history.push(`/codes/edit/${offer.id}`)
    } else {
      handleEdit(offer)
    }
  }

  const renderRow = (onChildOpen = null, onChildClose = null) => {
    return (
      <StyledTableRow key={`codes-table-row-${offer.id}`} isGated={isGated}>
        <TableCellStyled
          textWrap="nowrap"
          width="115px"
          paddingLeft={2}
          component="th"
          scope="row"
          onClick={handleCellClick}>
          {offer.createdAt
            ? formatDate(offer.createdAt)
            : formatDate(authData.user.signUpDate)}
        </TableCellStyled>
        <TableCellStyled minWidth="20%" onClick={handleCellClick}>
          <CodeTypography variant="body2" component="div">
            {offer.code}
          </CodeTypography>
        </TableCellStyled>
        <TableCellStyled
          width="60%"
          displayBreakpoint="md"
          onClick={handleCellClick}>
          {offer.name}
        </TableCellStyled>
        <TableCellStyled
          width="10%"
          displayBreakpoint="sm"
          onClick={handleCellClick}>
          {renderSettingsIcons()}
        </TableCellStyled>
        <ActionsTableCell align="center">
          <TableActionsDropdown
            menuItems={menuItems}
            onOpen={onChildOpen}
            onClose={onChildClose}
          />
        </ActionsTableCell>
      </StyledTableRow>
    )
  }

  if (isGated) {
    return (
      <GateToolTip
        title={`This code uses ${
          offer.tierRestriction === 20 ? 'Elite' : 'Pro'
        } features. Upgrade to edit`}>
        {({ onChildOpen, onChildClose }) =>
          renderRow(onChildOpen, onChildClose)
        }
      </GateToolTip>
    )
  }

  return renderRow()
}

export default CodesTableRow
