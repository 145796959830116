import { styled } from '@mui/system'
import { Button } from '@mui/material'

import SurveyOffersOnboardingCta from './SurveyOffersOnboardingCta'

const WrapperContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const GraphicContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage:
    'url(https://d3qvkz8495pjoq.cloudfront.net/onboarding-text-bubble.png)',
  aspectRatio: '730 / 430',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  fontFamily: 'Figtree, sans-serif',
  padding: '50px 140px 100px 140px',
  position: 'relative',
  '@media (max-width: 850px)': {
    padding: '20px 100px 50px 100px',
  },
})

const Header = styled('h1')({
  fontSize: '1.625rem',
  fontWeight: 'bold',
  marginTop: '0',
  marginBottom: '2rem',
  '@media (max-width: 850px)': {
    marginBottom: '1rem',
  },
})

const Content = styled('div')({
  fontSize: '1.625rem',
  '@media (max-width: 850px)': {
    fontSize: '1.125rem',
  },
  textAlign: 'center',
})

const UnicornContainer = styled('div')({
  position: 'absolute',
  bottom: '0',
  left: '-20px',
  img: {
    maxHeight: '292px',
  },
  '@media (max-width: 850px)': {
    img: {
      maxHeight: '240px',
    },
  },
})

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '2rem',
  '@media (max-width: 850px)': {
    marginTop: '1.5rem',
  },
})

const SkipButton = styled(Button)({
  fontFamily: 'Figtree, sans-serif',
  textTransform: 'none',
  marginTop: '1rem',
  '@media (max-width: 850px)': {
    marginTop: '0.5rem',
  },
})

const SurveyOffersOnboardingStepOne = ({
  user,
  skipOnboarding,
  advanceLastStep,
  loading,
}) => {
  const breakPoint = 850
  const isBelowBreakpoint = window.innerWidth < breakPoint
  const advanceStepIncrement = user.avatar ? 2 : 1

  return (
    <WrapperContainer>
      <GraphicContainer>
        <Header>Welcome {user.first_name}!</Header>
        <Content>
          Talkadot gets you immediate feedback and leads.
          <br />
          Let's show you how to do that.
        </Content>
        <ButtonContainer>
          <SurveyOffersOnboardingCta
            buttonText="Click here to get started"
            handleClick={() => advanceLastStep(advanceStepIncrement)}
            loading={loading}
            fontSize={isBelowBreakpoint ? '1.125rem' : '1.625rem'}
          />
          <SkipButton
            variant="text"
            color="secondary"
            onClick={skipOnboarding}
            disabled={loading}>
            {isBelowBreakpoint
              ? "No thanks, I'll do this later."
              : "No thanks, I'll do this later. I just want to look around right now."}
          </SkipButton>
        </ButtonContainer>
      </GraphicContainer>
      <UnicornContainer>
        <img
          src="https://d3qvkz8495pjoq.cloudfront.net/unicorn_with_mic_cropped.png"
          alt="Talkadot Unicorn with Mic"
        />
      </UnicornContainer>
    </WrapperContainer>
  )
}

export default SurveyOffersOnboardingStepOne
