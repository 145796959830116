import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/system'
import {
  Box,
  Link,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material'
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import Api from '../../services/api'
import * as qs from 'qs'

const MessageContainer = styled(Card)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  width: '358px',
  borderRadius: '0',
}))

const MessageDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}))

const FullHeightCenteringGrid = styled(Grid)(({ theme }) => ({
  minHeight: '100vh',
}))

const UnsubscribeParent = () => {
  const [unsubscribed, setUnsubscribed] = useState(false)
  const [resubscribed, setResubscribed] = useState(false)
  const [talkInfo, setTalkInfo] = useState(null)
  const [invalidRequest, setInvalidRequest] = useState(false)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    const unsubscribeLead = async () => {
      try {
        const res = await Api.unsubscribeLead({ token: urlParams.token })

        if (!res.errors) {
          setTalkInfo(res.talkInfo)

          if (res.isNewRecord) {
            setUnsubscribed(true)
          }
        } else {
          throw res.errors
        }
      } catch (err) {
        setInvalidRequest(true)
      }

      setLoading(false)
    }

    unsubscribeLead()
  }, [urlParams.token])

  const resubscribe = async () => {
    try {
      const res = await Api.resubscribeLead({ token: urlParams.token })

      if (!res.errors) {
        setResubscribed(true)
        setUnsubscribed(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setInvalidRequest(true)
    }
  }
  const renderContent = () => {
    if (invalidRequest) {
      return 'Please try again. If the problem persists, please contact support.'
    }
    if (resubscribed) {
      return `You've opted to receive future updates and bonuses from ${talkInfo.speaker}! We're glad to have you back!`
    }

    return `You'll no longer receive emails from ${
      talkInfo.speaker
    } related to ${talkInfo.title || 'this talk'}`
  }

  const renderHeadIcon = () => {
    if (resubscribed) {
      return <EmailOutlinedIcon fontSize="large" />
    }

    return <DraftsOutlinedIcon fontSize="large" />
  }

  const renderHeaderContent = () => {
    if (invalidRequest) {
      return 'Something went wrong.'
    }

    if (resubscribed) {
      return 'Welcome back!'
    }

    if (unsubscribed) {
      return "You're unsubscribed"
    } else {
      return "You're already unsubscribed"
    }
  }

  const renderResubscribeContent = () => {
    if (resubscribed || invalidRequest) {
      return
    }
    return (
      <>
        <MessageDivider />
        <div>Unsubscribed by accident?</div>
        <div>
          <Link onClick={resubscribe} style={{ cursor: 'pointer' }}>
            Subscribe again
          </Link>
        </div>
      </>
    )
  }

  return (
    <>
      {!loading && (
        <>
          <FullHeightCenteringGrid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Grid item xs={3}>
              <MessageContainer variant="outlined">
                <CardHeader
                  title={
                    <Box textAlign="center">
                      <>{renderHeadIcon()}</>
                      <div>{renderHeaderContent()}</div>
                    </Box>
                  }
                />
                <CardContent>
                  <Box>{renderContent()}</Box>
                  {renderResubscribeContent()}
                </CardContent>
              </MessageContainer>
            </Grid>
          </FullHeightCenteringGrid>
        </>
      )}
    </>
  )
}

export default UnsubscribeParent
