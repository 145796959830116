import { useState } from 'react'
import { ExportToCsv } from 'export-to-csv'
import Api from '../../services/api'
import * as Lead from '../leads/helpers'
import { IoFunnelOutline } from 'react-icons/io5'
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@mui/material'

const EventLeadsDownloadMenuItem = ({ event, group }) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const cleanAttributeForCsv = (attribute) => {
    if (!attribute) {
      return ''
    }

    return attribute
  }

  const exportTitle = `talkadot-leads-export-${
    event.talkTitle ? event.talkTitle : 'unnamed-event'
  }-${event.date}`

  const downloadEventLeads = async () => {
    const params = {
      event_leads: {
        event_id: event.id,
      },
    }

    try {
      setIsDownloading(true)
      let res

      if (group) {
        res = await Api.fetchGroupEventLeads({
          ...params,
          group_id: group.id,
        })
      } else {
        res = await Api.fetchEventLeads(params)
      }

      if (!res.errors) {
        if (res?.length <= 0) {
          setIsDownloading(false)
          return alert('There are no usable leads for this session')
        }

        const headers = [
          'Created At',
          'First Name',
          'Last Name',
          'Talk Title',
          'Talk Organization',
          'Phone',
          'Email',
          'Organization',
          'Job Title',
          'Contacted',
          'Warmth',
          'Booking Lead?',
          'Refer Lead?',
          'Email Opt In?',
        ]

        const rows = res.map((lead) => {
          return [
            lead.createdAt,
            cleanAttributeForCsv(lead.firstName),
            cleanAttributeForCsv(lead.lastName),
            cleanAttributeForCsv(lead.talkTitle),
            cleanAttributeForCsv(lead.talkOrganization),
            cleanAttributeForCsv(lead.phone),
            cleanAttributeForCsv(lead.email),
            cleanAttributeForCsv(lead.organization),
            cleanAttributeForCsv(lead.title),
            lead.contacted ? 'yes' : 'no',
            cleanAttributeForCsv(lead.warmth),
            Lead.isBookingLead(lead.leadTypes) ? 'yes' : '',
            Lead.isReferLead(lead.leadTypes) ? 'yes' : '',
            Lead.isOptIn(lead.leadTypes) ? 'yes' : '',
          ]
        })

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: false,
          title: exportTitle,
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
          headers: headers,
          filename: exportTitle,
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(rows)
        setIsDownloading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsDownloading(false)
      alert(err)
    }
  }

  return (
    <MenuItem key="3" onClick={downloadEventLeads} disabled={isDownloading}>
      <ListItemIcon>
        {isDownloading ? <CircularProgress size={25} /> : <IoFunnelOutline />}
      </ListItemIcon>
      <ListItemText>Download Leads</ListItemText>
    </MenuItem>
  )
}

export default EventLeadsDownloadMenuItem
