import { useEffect, useState } from 'react'
import { styled } from '@mui/system'

import SurveyOffersOnboardingHeader from './SurveyOffersOnboardingHeader'
import SpeakerSlide from '../../common/SpeakerSlideResponsive'
import AlertCard from '../../common/AlertCard'
import AlertCardCta from '../../common/AlertCardCta'
import { ReactComponent as Arrow } from '../../../icons/arrow.svg'

const AlertContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'windowWidth',
})(({ windowWidth }) => ({
  position: 'absolute',
  top: '0',
  right: `calc(-210px + ${
    windowWidth > 960 + 540
      ? '0px'
      : `${Math.min(190, 960 + 540 - windowWidth)}px`
  })`,
  '@media (max-width: 1132px)': {
    right: `calc(-210px + ${
      windowWidth > 640 + 540
        ? '0px'
        : `${Math.min(190, 640 + 540 - windowWidth)}px`
    })`,
    top: '280px',
  },
  '@media (max-width: 850px)': {
    right: `calc(-210px + ${
      windowWidth > 480 + 540
        ? '0px'
        : `${Math.min(190, 480 + 540 - windowWidth)}px`
    })`,
    top: '230px',
  },
}))

const ArrowContainer = styled('div')({
  position: 'absolute',
  top: '215px',
  right: '70px',
  transform: 'rotate(150deg)',
  '@media (max-width: 1132px)': {
    right: '50px',
    transform: 'rotate(40deg) scaleX(-1)',
  },
  '@media (max-width: 850px)': {
    top: '160px',
    right: '30px',
  },
})

const CtaContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '1.375rem',
})

const SurveyOffersOnboardingStepFive = ({
  user,
  advanceLastStep,
  loading,
  code,
  offers,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <SurveyOffersOnboardingHeader>
        {offers?.length > 1
          ? "Your slide has been created. Let's go through each section."
          : "Here is your default slide. Let's go through each section."}
      </SurveyOffersOnboardingHeader>
      <SpeakerSlide
        avatar={user.avatar}
        surveyLink={user.shortlink}
        socialLinks={user.socialLinks}
        email={user.email}
        name={user.first_name}
        size={'lg'}
        code={code}
      />
      <AlertContainer windowWidth={windowWidth}>
        <AlertCard title={'This is your personal Talkadot link'} width="300">
          It contains your Talkadot handle.
          <br />
          <br />
          Give this link to your audience whenever you are not using slides.
          <CtaContainer>
            <AlertCardCta
              buttonText="Continue"
              buttonWidth="160px"
              handleClick={() => advanceLastStep()}
              loading={loading}
            />
          </CtaContainer>
        </AlertCard>
      </AlertContainer>
      <ArrowContainer>
        <Arrow />
      </ArrowContainer>
    </>
  )
}

export default SurveyOffersOnboardingStepFive
