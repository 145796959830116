import moment from 'moment'

export const timeInUnix = (date) => {
  if (!date) {
    return null
  }

  return moment(date).unix()
}

export const trialEndDate = (trialDaysRemaining) => {
  const daysToAdd = trialDaysRemaining ? trialDaysRemaining : 0

  return moment().add(daysToAdd, 'days').format('MMMM D')
}
