import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import { Button } from '@mui/material'
import * as qs from 'qs'

import { AuthenticationContext } from './authenticationContext'

import Api from '../../services/api'

import envConfig from '../../envConfig'

import { ReactComponent as ZapierIcon } from '../../icons/zapierIcon.svg'
import { ReactComponent as CircleIcon } from '../../icons/circleIcon.svg'
import { IoCheckmarkCircle } from 'react-icons/io5'

const AuthenticationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px 30px',
  marginBottom: '50px',
  maxWidth: '400px',
}))

const LogosContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '30px',
})

const TalkadotLogo = styled('img')({
  width: '100px',
  marginRight: '20px',
})

const ClientIconWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '20px',
})

const DividerContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '30px 0',
})

const Divider = styled('div')({
  width: '20%',
  height: '3px',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
})

const AccessDetailsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
})

const AccessDescription = styled('div')({
  textAlign: 'left',
})

const AccessHeader = styled('div')({
  textAlign: 'left',
  marginBottom: '20px',
})

const AccessListItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
})

const CheckIcon = styled(IoCheckmarkCircle)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '20px',
  marginRight: '10px',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: '20px',
  textTransform: 'none',
  color: theme.palette.common.white,
  fontWeight: 'bold',
  fontSize: '1.1rem',
}))

const InvalidClientContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const OAuthProviderAuthenticationForm = () => {
  const [codeLoading, setCodeLoading] = useState(false)
  const { setNotification } = useContext(AuthenticationContext)

  const params = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  const { client_id, redirect_uri, state } = params

  const allowedClients = [
    envConfig.REACT_APP_ZAPIER_CLIENT_ID,
    envConfig.REACT_APP_CIRCLE_CLIENT_ID,
  ]

  let clientName = ''

  switch (client_id) {
    case envConfig.REACT_APP_ZAPIER_CLIENT_ID:
      clientName = 'Zapier'
      break
    case envConfig.REACT_APP_CIRCLE_CLIENT_ID:
      clientName = 'Circle'
      break
    default:
      clientName = ''
  }

  const createAuthCode = async () => {
    // make request to Talkadot API to get authorization code and create the OAuthClient record
    try {
      setCodeLoading(true)

      const params = {
        client_id: client_id,
      }

      const res = await Api.createAuthCode(params)

      if (!res.errors) {
        const { code } = res
        // NOTE: Not setting codeLoading to false here because we are redirecting to the redirect_uri
        // after getting the code, and we want the button to be disabled until the redirect happens
        return code
      } else {
        throw res.errors
      }
    } catch (err) {
      setCodeLoading(false)
      return setNotification(err, 'error')
    }
  }

  const handleClick = async () => {
    // make request to Talkadot API to get authorization code and create the OAuthClient record
    const code = await createAuthCode()

    if (!code) return

    const redirectUri = `${redirect_uri}?state=${state}&code=${code}`
    window.location.href = redirectUri
  }

  const renderClientIcon = () => {
    switch (client_id) {
      case envConfig.REACT_APP_ZAPIER_CLIENT_ID:
        return <ZapierIcon />
      case envConfig.REACT_APP_CIRCLE_CLIENT_ID:
        return <CircleIcon />
      default:
        return <></>
    }
  }

  const renderAccessDetails = () => {
    switch (client_id) {
      case envConfig.REACT_APP_ZAPIER_CLIENT_ID:
        return (
          <>
            <AccessListItem>
              <CheckIcon />
              <span>
                Access your leads <b>email</b>
              </span>
            </AccessListItem>
            <AccessListItem>
              <CheckIcon />
              <span>
                Access your leads <b>name</b>
              </span>
            </AccessListItem>
            <AccessListItem>
              <CheckIcon />
              <span>
                Access your leads <b>phone number</b>
              </span>
            </AccessListItem>
            <AccessListItem>
              <CheckIcon />
              <span>
                Access your <b>event title</b>
              </span>
            </AccessListItem>
            <AccessListItem>
              <CheckIcon />
              <span>
                Access your <b>event date</b>
              </span>
            </AccessListItem>
          </>
        )
      case envConfig.REACT_APP_CIRCLE_CLIENT_ID:
        return (
          <>
            <AccessListItem>
              <CheckIcon />
              <span>
                Access your <b>name</b>
              </span>
            </AccessListItem>
            <AccessListItem>
              <CheckIcon />
              <span>
                Access your <b>email</b>
              </span>
            </AccessListItem>
            <AccessListItem>
              <CheckIcon />
              <span>
                Access your <b>profile image</b>
              </span>
            </AccessListItem>
          </>
        )
      default:
        return <></>
    }
  }

  const renderAccessDescription = () => {
    switch (client_id) {
      case envConfig.REACT_APP_CIRCLE_CLIENT_ID:
        return (
          <AccessDescription>
            Talkadot is using <b>{clientName}</b> to power our Unicorn Speaker
            Community. Please connect to our community with your Talkadot
            account!
          </AccessDescription>
        )
      default:
        return (
          <AccessDescription>
            <b>{clientName}</b> wants access to your Talkadot account
          </AccessDescription>
        )
    }
  }

  const renderForm = () => (
    <AuthenticationContainer>
      <LogosContainer>
        <TalkadotLogo
          className="main-logo"
          src="https://d3qvkz8495pjoq.cloudfront.net/talkadot-logo-transparent.png"
          alt="Talkadot!"
        />
        <b>+</b>
        <ClientIconWrapper>{renderClientIcon()}</ClientIconWrapper>
      </LogosContainer>
      {renderAccessDescription()}
      <DividerContainer>
        <Divider />
      </DividerContainer>
      <AccessDetailsContainer>
        <AccessHeader>
          This will allow <b>{clientName}</b> to access the following
          information:
        </AccessHeader>
        {renderAccessDetails()}
      </AccessDetailsContainer>
      <DividerContainer>
        <Divider />
      </DividerContainer>
      <AccessHeader>
        <b>Allow {clientName}</b> to access your Talkadot account?
      </AccessHeader>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={codeLoading}>
        Grant Access
      </StyledButton>
    </AuthenticationContainer>
  )

  if (allowedClients.includes(client_id)) {
    return renderForm()
  }

  return (
    <InvalidClientContainer>
      <h1>Invalid OAuth Client</h1>
      <p>
        The OAuth client you are trying to authenticate with is not allowed to
        access this application.
      </p>
    </InvalidClientContainer>
  )
}

export default OAuthProviderAuthenticationForm
