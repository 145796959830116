import React, { useState, useContext } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'

const TestSpeakerflowButton = ({ disabled }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { setNotification } = useContext(AuthenticationContext)

  const handleTestSpeakerflowIntegration = async () => {
    try {
      setIsLoading(true)

      const res = await Api.testSpeakerflowIntegration()

      if (!res.errors) {
        setIsLoading(false)
        setNotification('Speakerflow is setup correctly!', 'success')
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsLoading(false)
      setNotification(
        'Oops - looks like there was something wrong with testing your speakerflow integration. Please try again. If the problem persists, contact us at hello@talkadot.com!'
      )
    }
  }

  return (
    <Button
      className="orange-button"
      disabled={disabled}
      onClick={handleTestSpeakerflowIntegration}>
      {isLoading ? <CircularProgress size={25} /> : 'Test'}
    </Button>
  )
}

export default TestSpeakerflowButton
