import { useContext, useState, useEffect } from 'react'

import { QuestionHeaderActionButton } from './CodeBuilderQuestionHeader'
import { ReactComponent as Preview } from '../../../icons/preview.svg'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { makeSurveyLink } from '../../common/helpers'
import CloseIcon from '@mui/icons-material/Close'

import { Tooltip, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { styled } from '@mui/system'

import { isMobileOnly } from 'react-device-detect'

const SURVEY_HEIGHT_THRESHOLD = 800

const IframeContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
})

const StyledIframe = styled('iframe')(({ overrideHeight }) => ({
  width: '100%',
  height: overrideHeight,
  border: 'none',
  transform: 'scale(0.9)',
}))

const StyledDialogContent = styled(DialogContent)({
  height: '100vh',
  padding: 0,
})

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'flex-end',
})

const CodeBuilderLaunchPreviewButton = ({ code }) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const { authData } = useContext(AuthenticationContext)
  const { shortlink } = authData?.user
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)

  const surveyLinkSrc = makeSurveyLink({
    shortlink: shortlink,
    code: code,
    test: true,
  })

  // -----------------------------------------
  // -----------------------------------------
  // Use Similar dynamic resizing to the survey parent so that the layout doesn't
  // completely break if someone is using an odd screen.  Although it's not perfect
  // since this is a modal and an iFrame.
  const surveyHeight =
    isMobileOnly || screenHeight < SURVEY_HEIGHT_THRESHOLD
      ? '100%'
      : SURVEY_HEIGHT_THRESHOLD

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------------
  // ------------------------------------------

  return (
    <>
      <Tooltip title="Preview this survey" disableInteractive arrow>
        <QuestionHeaderActionButton
          variant="outlined"
          startIcon={<Preview />}
          color="tertiary"
          onClick={() => setPreviewOpen(true)}>
          <span className="button-text">Preview</span>
        </QuestionHeaderActionButton>
      </Tooltip>
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        fullScreen
        maxWidth="lg"
        aria-labelledby="preview-mode-survey">
        <StyledDialogTitle>
          <QuestionHeaderActionButton
            variant="outlined"
            color="tertiary"
            startIcon={<CloseIcon />}
            onClick={() => setPreviewOpen(false)}>
            Close Preview
          </QuestionHeaderActionButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          <IframeContainer>
            <StyledIframe
              src={surveyLinkSrc}
              title="Survey Preview"
              overrideHeight={surveyHeight}
            />
          </IframeContainer>
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

export default CodeBuilderLaunchPreviewButton
