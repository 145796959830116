import React, { useEffect, useState, useContext } from 'react'
import { Typography } from '@mui/material'
import TotalAttendees from '../eventReportCards/TotalAttendees'
import TalkValue from '../eventReportCards/TalkValue'
import TalkReattend from '../eventReportCards/TalkReattend'
import EventPlannerEngagement from '../eventReportCards/EventPlannerEngagement'
import Banner from '../eventReportCards/Banner'
import Api from '../../services/api'
import { formatDate } from '../common/helpers'
import { AuthenticationContext } from '../authentication/authenticationContext'

const initialReportDataState = {
  totalResponses: null,
  reattendBreakdown: {},
  ratingsBreakdown: {},
  eventType: 'TOTAL_STATISTICS',
  speaker: {},
  talkTitle: null,
  organization: null,
  date: null,
  endDate: null,
  speakers: [],
  engagementBreakdown: [],
}

const EventGroupReport = ({ eventGroupId = null }) => {
  const [reportData, setReportData] = useState(initialReportDataState)
  const [isLoading, setIsLoading] = useState(false)
  const { setNotification } = useContext(AuthenticationContext)

  useEffect(() => {
    const loadReportData = async () => {
      try {
        setIsLoading(true)

        const res = await Api.fetchEventGroupOverallData(eventGroupId)

        if (!res.errors) {
          setReportData({ ...reportData, ...res })
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
        setNotification(err)
      }
    }

    loadReportData()
  }, [])

  const renderEmptyReport = () => (
    <div className="event-report-card__empty-report-text">
      <Typography variant="h4">
        <b>Your aggregate stats will appear here!</b>
      </Typography>
      <br />
      <Typography variant="h5">
        Once your event starts and you collect submissions, they'll appear here
        in real time!
      </Typography>
    </div>
  )

  const renderDataSummary = () => (
    <div className="event-report-card__data-summary">
      <TotalAttendees
        totalResponses={reportData.totalResponses}
        isLoading={isLoading}
      />
      <TalkValue
        ratingsBreakdown={reportData.ratingsBreakdown}
        isLoading={isLoading}
        eventType={reportData.eventType}
      />
      <TalkReattend
        reattendBreakdown={reportData.reattendBreakdown}
        isLoading={isLoading}
        speaker={reportData.speaker}
        eventType={reportData.eventType}
      />
      <EventPlannerEngagement
        isLoading={isLoading}
        engagementBreakdown={reportData.engagementBreakdown}
      />
    </div>
  )

  const makeBannerDate = () => {
    if (reportData.date && reportData.endDate) {
      return `${formatDate(
        reportData.date,
        reportData.utcOffset
      )} - ${formatDate(reportData.endDate, reportData.utcOffset)}`
    }

    if (reportData.date) {
      return formatDate(reportData.date, reportData.utcOffset)
    }

    return null
  }

  return (
    <div className="event-report-card" style={{ width: '100%' }}>
      <Banner
        speaker={reportData.speaker}
        talkTitle={reportData.talkTitle}
        organization={reportData.organization}
        date={makeBannerDate()}
        speakers={reportData.speakers}
      />
      {!isLoading && !reportData.totalResponses
        ? renderEmptyReport()
        : renderDataSummary()}
    </div>
  )
}

export default EventGroupReport
