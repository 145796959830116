import React, { useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthenticationContext } from './authenticationContext'
import Api from '../../services/api'
import * as qs from 'qs'
import { styled } from '@mui/system'
import RegistrationCheckout from './RegistrationCheckout'
import UnpaidRegistrationCheckout from './UnpaidRegistrationCheckout'
import { Button } from '@material-ui/core'
import { getGaCookies, getUtmCookies } from '../../services/cookies'

export const RegistrationContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(200px, 500px) 1fr',
  position: 'absolute',
  top: '0',
  left: '0',
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.palette.base.white,
  [theme.breakpoints.down(1028)]: {
    gridTemplateColumns: '1fr',
  },
}))

export const AuthButton = styled(Button)({
  textTransform: 'capitalize',
  color: 'white',
  fontSize: '19px',
  marginTop: '20px',
})

export const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  [theme.breakpoints.up(1028)]: {
    alignItems: 'center',
  },
}))

export const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: '425px',
  padding: '60px 40px 40px',
  [theme.breakpoints.up(1028)]: {
    width: '425px',
    padding: '20px',
  },
}))

export const CheckoutForm = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

export const Divider = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  fontWeight: '200',
  margin: '20px 0px',
  flexGrow: '0.2',
})

export const InnerDivider = styled('div')({
  flexGrow: '0.4',
  alignItems: 'center',
  border: '1px solid lightgray',
})

const RegistrationParent = () => {
  const [membershipPlan, setMembershipPlan] = useState(null)
  const [groupName, setGroupName] = useState(null)

  const {
    showFullPageLoader,
    hideFullPageLoader,
    setNotification,
    setGroupInvitations,
    authData,
  } = useContext(AuthenticationContext)

  const location = useLocation()
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const referrer = urlParams.via
  const planToken = urlParams.pt
  const registrationToken = urlParams.rt
  const groupInvitationToken = urlParams.git
  const groupInvitation = authData?.user?.groupInvitations[0]
  const showPaidCheckout =
    membershipPlan?.price > 0 && !membershipPlan?.trialPeriodDays

  useEffect(() => {
    const fetchMembershipPlanDetails = async () => {
      try {
        showFullPageLoader()
        const res = await Api.getMembershipPlanDetails({
          plan_token: planToken,
          registration_token: registrationToken,
          is_direct_pay_link: planToken ? true : null,
        })

        if (!res.errors) {
          hideFullPageLoader()
          setMembershipPlan(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        hideFullPageLoader()
        setNotification(
          "Oops - looks like you're trying to sign up for a plan that doesn't exist. Please contact support at hello@talkadot.com"
        )
      }
    }

    if (planToken || registrationToken) {
      fetchMembershipPlanDetails()
    }
  }, [planToken, registrationToken])

  useEffect(() => {
    const fetchGroupDetails = async () => {
      const reqParams = {
        group_invitation: {
          invitation_token: groupInvitationToken,
        },
      }

      try {
        showFullPageLoader()
        const res = await Api.getUnauthGroupInvitationDetails(reqParams)

        if (!res.errors) {
          hideFullPageLoader()
          setGroupName(res.groupName)
          setMembershipPlan(res.membershipPlan)
          setGroupInvitations({
            groupName: res.groupName,
            id: res.id,
            invitationToken: groupInvitationToken,
          })
        } else {
          throw res.errors
        }
      } catch (err) {
        hideFullPageLoader()
      }
    }

    if (groupInvitationToken && !groupInvitation) {
      fetchGroupDetails()
    }
  }, [groupInvitationToken, groupInvitation])

  const gaParams = getGaCookies()
  const utmParams = getUtmCookies()

  return showPaidCheckout ? (
    <RegistrationCheckout
      plan={membershipPlan}
      groupName={groupName}
      referrer={referrer}
      utmParams={utmParams}
      gaParams={gaParams}
    />
  ) : (
    <UnpaidRegistrationCheckout
      plan={membershipPlan}
      registrationToken={registrationToken}
      referrer={referrer}
      groupName={groupName}
      utmParams={utmParams}
      gaParams={gaParams}
    />
  )
}

export default RegistrationParent
