import React, { useContext } from 'react'

import { Divider } from '@mui/material'

import { OnboardingContext } from '../onboarding/onboardingContext'
import { AuthenticationContext } from '../authentication/authenticationContext'

import EditDetails from './EditDetails'
import EditSocialMedia from './EditSocialMedia'

import {
  AccountSettingsCard,
  AccountSettingsCardColumn,
  AccountSettingsCardInner,
  AccountSettingsCardTitle,
} from './AccountSettingsParent'

const SocialMediaSettings = () => {
  const { submitSocialMediaData } = useContext(OnboardingContext)
  const { authData } = useContext(AuthenticationContext)
  const { user } = authData

  const renderLinkedInValue = () => {
    const linkedInUrl = user?.socialLinks?.linkedin
    if (linkedInUrl && linkedInUrl.includes('linkedin.com')) {
      return linkedInUrl
    } else if (linkedInUrl) {
      return `linkedin.com/in/${linkedInUrl}`
    }
  }

  const renderYoutubeValue = () => {
    const youtubeUrl = user?.socialLinks?.youtube
    if (youtubeUrl && youtubeUrl.includes('youtube.com')) {
      return youtubeUrl
    } else if (youtubeUrl) {
      return `youtube.com/channel/${youtubeUrl}`
    }
  }

  return (
    <AccountSettingsCard id="social-links">
      <AccountSettingsCardTitle variant="h5">
        {user.isAssociate ? 'Account Owner' : 'Your'} Social Presence
      </AccountSettingsCardTitle>
      <AccountSettingsCardInner>
        <AccountSettingsCardColumn>
          <EditDetails
            handleUpdate={submitSocialMediaData}
            detailType="Connect To Twitter"
            detailValue={
              user?.socialLinks?.twitter && `@${user.socialLinks.twitter}`
            }>
            <EditSocialMedia
              socialPrefix="@"
              placeholderValue="<my-twitter-handle>"
              socialAttributeType="twitter"
              socialAttributeValue={user?.socialLinks?.twitter}
            />
          </EditDetails>
          <Divider light />
          <EditDetails
            handleUpdate={submitSocialMediaData}
            detailType="Connect To Instagram"
            detailValue={
              user?.socialLinks?.instagram && `@${user.socialLinks.instagram}`
            }>
            <EditSocialMedia
              socialPrefix="@"
              placeholderValue="<my-instagram-handle>"
              socialAttributeType="instagram"
              socialAttributeValue={user?.socialLinks?.instagram}
            />
          </EditDetails>
          <Divider light />
          <EditDetails
            handleUpdate={submitSocialMediaData}
            detailType="Connect To LinkedIn"
            detailValue={renderLinkedInValue()}>
            <EditSocialMedia
              placeholderValue="https://www.linkedin.com/in/your-name/"
              socialAttributeType="linkedin"
              socialAttributeValue={user?.socialLinks?.linkedin}
            />
          </EditDetails>
          <Divider light />
          <EditDetails
            handleUpdate={submitSocialMediaData}
            detailType="Connect To Youtube"
            detailValue={renderYoutubeValue()}>
            <EditSocialMedia
              placeholderValue="https://www.youtube.com/@channel-name"
              socialAttributeType="youtube"
              socialAttributeValue={user?.socialLinks?.youtube}
            />
          </EditDetails>
          <Divider light />
        </AccountSettingsCardColumn>
      </AccountSettingsCardInner>
    </AccountSettingsCard>
  )
}

export default SocialMediaSettings
