import React, { useContext } from 'react'
import GoogleLogin from 'react-google-login'
import { AuthenticationContext } from './authenticationContext'
import { GoogleLoginButton } from 'react-social-login-buttons'
import envConfig from '../../envConfig'
import { styled } from '@mui/system'
import { AUTHENTICATION_TYPES } from './OAuthButtons'

const GoogleButtonContainer = styled('div')({
  width: '100%',
  span: {
    fontSize: '19px',
  },
})

const GoogleOAuthButton = ({
  authenticationType,
  membershipPlan,
  registrationToken,
  redirect,
}) => {
  const { authenticateUserByGoogle, registerUserByGoogle, setNotification } =
    useContext(AuthenticationContext)

  const handleGoogleSuccess = async (googleData) => {
    if (authenticationType === AUTHENTICATION_TYPES.REGISTRATION) {
      await registerUserByGoogle(
        googleData,
        membershipPlan?.token,
        registrationToken
      )
    } else {
      await authenticateUserByGoogle(googleData, redirect)
    }
  }

  const handleGoogleFailure = (error, details) => {
    return setNotification(JSON.stringify(error), 'error')
  }

  const handleClick = (googleAuthFunction) => {
    return googleAuthFunction()
  }

  return (
    <GoogleButtonContainer>
      <GoogleLogin
        clientId={envConfig.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Log in with Google"
        onSuccess={handleGoogleSuccess}
        onFailure={handleGoogleFailure}
        accessType="offline"
        cookiePolicy={'single_host_origin'}
        render={(renderProps) => (
          <GoogleLoginButton
            onClick={() => handleClick(renderProps.onClick)}
            disabled={renderProps.disabled}>
            <span>
              {authenticationType === AUTHENTICATION_TYPES.REGISTRATION
                ? 'Register With Google'
                : 'Login With Google'}
            </span>
          </GoogleLoginButton>
        )}
      />
    </GoogleButtonContainer>
  )
}

export default GoogleOAuthButton
