import { styled } from '@mui/system'
import { CircularProgress } from '@mui/material'

const StyledLoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  svg: {
    marginRight: '0',
  },
}))

const LoadingSpinner = () => {
  return <StyledLoadingSpinner />
}

export default LoadingSpinner
