import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import UpgradeButton from './UpgradeButton'

const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}))

const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.navText,
  [theme.breakpoints.down('md')]: {
    fontSize: '2.0625rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}))

const PageHeader = ({ header, showUpgradeButton }) => {
  return (
    <HeaderWrapper>
      <HeaderText variant="h2" component="h1">
        {header}
      </HeaderText>
      {showUpgradeButton && <UpgradeButton />}
    </HeaderWrapper>
  )
}

export default PageHeader
