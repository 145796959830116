import React, { useContext, useRef } from 'react'
import SpeakerSlide from '../common/SpeakerSlide'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { Button } from '@mui/material'
import { downloadSlideAsPng } from '../common/helpers'
import { downloadedSlide } from '../../services/metrics'
import { GroupContext } from '../groups/groupContext'

const SurveyOfferSlide = ({ code }) => {
  const { authData } = useContext(AuthenticationContext)
  const { user } = authData
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const slideRef = useRef()

  const activeSelection = selectedGroup || user
  const shortLink = activeSelection?.shortlink.toUpperCase()

  return (
    <div className="code-slide-item">
      <SpeakerSlide
        avatar={activeSelection.avatar}
        surveyLink={activeSelection.shortlink}
        socialLinks={selectedGroup ? null : user.socialLinks}
        componentRef={slideRef}
        email={selectedGroup ? null : user.email}
        name={selectedGroup ? selectedGroup.name : user.first_name}
        size={'lg'}
        code={code}
        id={'test-dl'}
      />
      <Button
        className="orange-button"
        onClick={() => {
          downloadedSlide({
            speaker: user.email,
            slideType: selectedGroup ? 'chapter' : 'personal',
          })
          downloadSlideAsPng(slideRef, `Talkadot-Slide-${shortLink}-${code}`)
        }}>
        Click To Download Slide
      </Button>
    </div>
  )
}

export default SurveyOfferSlide
