import { useState } from 'react'
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Tooltip,
} from '@mui/material'

import { ReactComponent as EllipisIcon } from '../../icons/ellipsisIcon.svg'

const TableActionsDropdown = ({ menuItems, onOpen, onClose }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenDropdown = (e) => {
    onOpen && onOpen()

    setAnchorEl(e.currentTarget)
  }
  const handleCloseDropdown = () => {
    onClose && onClose()

    setAnchorEl(null)
  }
  const renderMenuItem = ({
    text,
    onClick,
    tooltipPlacement = 'bottom',
    disabled = false,
    tooltipText,
    icon,
    loading,
    visible,
    index,
  }) => {
    if (visible === false) return null

    const menuItem = (
      <MenuItem key={index} onClick={onClick} disabled={disabled || loading}>
        {icon && (
          <ListItemIcon>
            {loading ? <CircularProgress size={16} color="tertiary" /> : icon}
          </ListItemIcon>
        )}
        <ListItemText primary={text} />
      </MenuItem>
    )

    return tooltipText ? (
      <Tooltip
        title={tooltipText}
        placement={tooltipPlacement}
        disableInteractive
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14],
                },
              },
            ],
          },
        }}>
        <div>{menuItem}</div>
      </Tooltip>
    ) : (
      menuItem
    )
  }
  return (
    <>
      <IconButton onClick={handleOpenDropdown}>
        <EllipisIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {menuItems.map((item, index) => {
          return renderMenuItem({ ...item, index })
        })}
      </Menu>
    </>
  )
}

export default TableActionsDropdown
