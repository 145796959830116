import { useState, useContext } from 'react'
import moment from 'moment'
import { styled } from '@mui/system'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  InputAdornment,
  CircularProgress,
  Select,
  MenuItem,
  Typography,
  Alert,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import SurveyOfferSelect from './SurveyOfferSelect'
import EventSpeakersParent from '../eventPlanners/EventSpeakersParent'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'

import FormSection from '../common/FormSection'
import {
  onlyAllowPositiveNumbersAndDecimals,
  onlyAllowPositiveNumbersNoDecimals,
} from '../common/helpers'

const MAX_ORG_CHARACTER_LENGTH = 50
const MAX_TITLE_CHARACTER_LENGTH = 75
const MAX_EVENT_NAME_CHARACTER_LENGTH = 50

const OptionalSettingsContainer = styled('div')(({ theme }) => ({
  background: theme.palette.surface.subtle,
  h6: {
    color: 'orange',
    textTransform: 'uppercase',
  },
}))

const FormContainer = styled('div')({
  padding: '10px 25px',
})

const FormDialogContent = styled(DialogContent)({
  padding: '0px',
})

const SettingsAccordion = styled(Accordion)(({ theme }) => ({
  border: 'none',
  boxShadow: 'none',
  background: theme.palette.surface.subtle,
}))

const EventFormParent = ({
  eventInitialState,
  handleClose,
  createEvent,
  editEvent,
  eventGroupId,
  updateEventSpeakers,
}) => {
  const [event, setEvent] = useState(eventInitialState)
  const [isLoading, setIsLoading] = useState(false)
  const { authData } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const { user } = authData

  const SESSION_TYPES = ['WORKSHOP', 'KEYNOTE', 'GLOBAL']

  // custom Alpha Gamma Rho Jan 24 session types
  const AGR_SESSION_TYPES = [
    'AGR_JAN_24_MODULE_1',
    'AGR_JAN_24_MODULE_2',
    'AGR_JAN_24_MODULE_3',
    'AGR_JAN_24_MODULE_4',
    'AGR_JAN_24_GLOBAL',
  ]

  if (
    user?.isAdmin ||
    user?.email === 'amanda@alphagammarho.org' ||
    user?.email === 'dkroondijk+eventplanner@gmail.com'
  ) {
    SESSION_TYPES.push(...AGR_SESSION_TYPES)
  }

  const handleSubmit = async () => {
    const params = {
      event: {
        name: event.name,
        talk_title: event.talkTitle,
        in_person: event.inPerson,
        virtual: event.virtual,
        event_organization: event.eventOrganization,
        start_time: `${event.startTime}`,
        duration: event.duration,
        date: event.date,
        utc_offset: event.utcOffset,
        time_zone: event.timeZone,
        compensation: event.compensation,
        audience_size: event.audienceSize,
        event_type: event.eventType,
        event_code: event.lockedOfferCode,
        event_group_id: eventGroupId,
        event_name: event.eventName,
      },
    }

    if (!event.id) {
      return await _createEvent(params)
    } else {
      return await _editEvent(event.id, params)
    }
  }

  const _createEvent = async (params) => {
    setIsLoading(true)
    await createEvent(params)
    setIsLoading(false)
    handleClose()
  }

  const _editEvent = async (eventId, params) => {
    setIsLoading(true)
    await editEvent(eventId, params)
    setIsLoading(false)
    handleClose()
  }

  // This takes a time of "hh:mm AM/PM" and converts it to what
  // we need for the form
  const setTimeForInput = (time) => {
    return moment(time, ['h:mm A']).format('HH:mm')
  }

  const renderEventPlannerForm = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="events-form">
        <DialogTitle>
          {!event.id ? 'Create A New Session' : 'Edit Session'}
        </DialogTitle>
        <DialogContent>
          <FormSection stepNumber="1" description="Session Title:">
            <TextField
              required
              variant="standard"
              placeholder="Talk Title"
              value={event.talkTitle || ''}
              fullWidth
              onChange={(e) =>
                setEvent({ ...event, talkTitle: e.target.value })
              }
            />
          </FormSection>
          <FormSection stepNumber="2" description="What day is this session?">
            <div className="time-fields">
              <DesktopDatePicker
                inputFormat="MM/dd/yyyy"
                variant="standard"
                value={event.date ? moment(event.date).toDate() : ''}
                onChange={(e) => {
                  setEvent({
                    ...event,
                    date: moment(e).format('LL'),
                    utcOffset: moment(e).utcOffset(),
                  })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </FormSection>
          <FormSection
            stepNumber="3"
            description="What time does this session start?">
            <div className="time-fields">
              <TextField
                type="time"
                name="session-time"
                value={event.startTime ? setTimeForInput(event.startTime) : ''}
                onChange={(e) =>
                  setEvent({ ...event, startTime: e.target.value })
                }
              />
            </div>
          </FormSection>
          <FormSection
            stepNumber="4"
            description="What type of session is this?">
            <div className="time-fields">
              <FormControl variant="standard">
                <Select
                  sx={{ minWidth: '200px' }}
                  value={event.eventType ? event.eventType.toUpperCase() : ''}
                  label="Session Type"
                  onChange={(e) =>
                    setEvent({ ...event, eventType: e.target.value })
                  }>
                  {SESSION_TYPES.map((sessionType, i) => {
                    return (
                      <MenuItem value={sessionType} key={i}>
                        {sessionType}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          </FormSection>
          <FormSection
            stepNumber="5"
            description="How big is the audience for this session?">
            <TextField
              variant="standard"
              type="text"
              className="short"
              placeholder="Audience Size"
              value={event.audienceSize || ''}
              onChange={(e) =>
                setEvent({
                  ...event,
                  audienceSize: onlyAllowPositiveNumbersNoDecimals(
                    e.target.value
                  ),
                })
              }
            />
          </FormSection>
          <FormSection
            stepNumber="6"
            description="Add a code for your audience to easily identify this session (we'll autogenerate one for you if you don't fill this in).">
            <TextField
              variant="standard"
              type="text"
              className="short"
              placeholder="Use a short word"
              inputProps={{ maxLength: 12 }}
              value={event.eventCode}
              onChange={(e) =>
                setEvent({
                  ...event,
                  eventCode: e.target.value?.toUpperCase(),
                  lockedOfferCode: e.target.value?.toUpperCase(),
                })
              }
            />
          </FormSection>
          {
            /* only show if there's an existing event being edited */
            event.id && (
              <FormSection stepNumber="7" description="Who's speaking?">
                <EventSpeakersParent
                  event={event}
                  setEvent={setEvent}
                  updateEventSpeakers={updateEventSpeakers}
                />
              </FormSection>
            )
          }
        </DialogContent>
      </div>
    </LocalizationProvider>
  )

  let talkTitleCharactersRemaining = MAX_TITLE_CHARACTER_LENGTH
  let orgCharactersRemaining = MAX_ORG_CHARACTER_LENGTH
  let eventNameCharactersRemaining = MAX_EVENT_NAME_CHARACTER_LENGTH

  if (MAX_TITLE_CHARACTER_LENGTH - event.talkTitle?.length >= 0) {
    talkTitleCharactersRemaining =
      MAX_TITLE_CHARACTER_LENGTH - event.talkTitle?.length
  } else if (event.talkTitle?.length > 0) {
    talkTitleCharactersRemaining = 0
  }

  if (MAX_ORG_CHARACTER_LENGTH - event.eventOrganization?.length >= 0) {
    orgCharactersRemaining =
      MAX_ORG_CHARACTER_LENGTH - event.eventOrganization?.length
  } else if (event.eventOrganization?.length > 0) {
    orgCharactersRemaining = 0
  }

  if (MAX_EVENT_NAME_CHARACTER_LENGTH - event.eventName?.length >= 0) {
    eventNameCharactersRemaining =
      MAX_EVENT_NAME_CHARACTER_LENGTH - event.eventName?.length
  } else if (event.eventName?.length > 0) {
    eventNameCharactersRemaining = 0
  }

  const talkTitleHelperText = `${talkTitleCharactersRemaining} characters remaining`
  const orgNameHelperText = `${orgCharactersRemaining} characters remaining`
  const eventNameHelperText = `${eventNameCharactersRemaining} characters remaining`

  const renderTitle = () => {
    if (selectedGroup) {
      // Groups do not have a create title
      return 'Edit Chapter Event'
    } else if (event.id) {
      return 'Edit Speaking Engagement'
    } else {
      return 'Create A New Speaking Engagement'
    }
  }

  const renderSpeakerForm = () => {
    const stepNumbers = () => {
      if (selectedGroup) {
        return {
          talkTitle: 1,
          date: 2,
          virtual: 3,
          audienceSize: 4,
          eventSpeakers: 5, // Only renders if editing
          lockCode: event.id ? 6 : 5,
        }
      } else {
        return {
          talkTitle: 1,
          eventOrganization: 2,
          eventName: 3,
          date: 4,
          virtual: 5,
          audienceSize: 6,
          compensation: 7,
          lockCode: 8,
        }
      }
    }
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="events-form">
          <DialogTitle component="div">
            <Typography variant="h5">
              <b>{renderTitle()}</b>
            </Typography>
            <Typography variant="body2">
              Talks will also show up automatically when you use your QR code or
              profile link. <b>Everything is optional and can be added later</b>
              .
            </Typography>
          </DialogTitle>
          <FormDialogContent>
            <FormContainer>
              <FormSection
                stepNumber={stepNumbers().talkTitle}
                description={
                  selectedGroup
                    ? 'Name of your chapter’s training session?'
                    : "What's your talk title?"
                }>
                <TextField
                  required
                  variant="standard"
                  placeholder="Talk Title"
                  value={event.talkTitle || ''}
                  inputProps={{ maxLength: MAX_TITLE_CHARACTER_LENGTH }}
                  helperText={talkTitleHelperText}
                  fullWidth
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    setEvent({ ...event, talkTitle: e.target.value })
                  }
                />
              </FormSection>
              {!selectedGroup && (
                <FormSection
                  stepNumber={stepNumbers().eventOrganization}
                  description="Who's the event organizer or organization? ">
                  <TextField
                    required
                    variant="standard"
                    inputProps={{ maxLength: MAX_ORG_CHARACTER_LENGTH }}
                    helperText={orgNameHelperText}
                    fullWidth
                    placeholder="Event Organizer or Company"
                    value={event.eventOrganization || ''}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      setEvent({ ...event, eventOrganization: e.target.value })
                    }
                  />
                </FormSection>
              )}
              {!selectedGroup && (
                <FormSection
                  stepNumber={stepNumbers().eventName}
                  description="What's the name of the event or conference you're speaking at?">
                  <TextField
                    required
                    variant="standard"
                    inputProps={{ maxLength: MAX_EVENT_NAME_CHARACTER_LENGTH }}
                    helperText={eventNameHelperText}
                    fullWidth
                    placeholder="Event Or Conference Name"
                    value={event.eventName || ''}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      setEvent({ ...event, eventName: e.target.value })
                    }
                  />
                </FormSection>
              )}
              <FormSection
                stepNumber={stepNumbers().date}
                description={
                  selectedGroup ? 'Date of this session' : "When's your talk?"
                }>
                <div className="time-fields">
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    variant="standard"
                    value={moment(event.date)}
                    onChange={(e) => {
                      setEvent({
                        ...event,
                        date: moment(e).format('LL'),
                        utcOffset: moment(e).utcOffset(),
                      })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </FormSection>
              <FormSection
                stepNumber={stepNumbers().virtual}
                description="Is your talk in person or virtual?">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="controlled-radio-buttons-group"
                    className="radio-picker"
                    value={event.virtual ? 'Virtual' : 'In Person'}
                    onChange={(e) => {
                      if (e.target.value === 'Virtual') {
                        setEvent({ ...event, virtual: true, in_person: false })
                      } else {
                        setEvent({ ...event, virtual: false, in_person: true })
                      }
                    }}>
                    <FormControlLabel
                      value="In Person"
                      control={<Radio />}
                      label="In Person"
                      className={event.in_person ? 'radio-active' : ''}
                    />
                    <FormControlLabel
                      value="Virtual"
                      control={<Radio />}
                      label="Virtual"
                      className={event.virtual ? 'radio-active' : ''}
                    />
                  </RadioGroup>
                </FormControl>
              </FormSection>
              <FormSection
                stepNumber={stepNumbers().audienceSize}
                description="How many attendees were there?">
                <TextField
                  variant="standard"
                  type="text"
                  className="short"
                  placeholder="Audience Size"
                  value={event.audienceSize || ''}
                  onChange={(e) => {
                    setEvent({
                      ...event,
                      audienceSize: onlyAllowPositiveNumbersNoDecimals(
                        e.target.value
                      ),
                    })
                  }}
                />
              </FormSection>
              {!selectedGroup && (
                <FormSection
                  stepNumber={stepNumbers().compensation}
                  description="How much did you get paid for this talk?"
                  lastSection={true}>
                  <TextField
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    variant="standard"
                    type="text"
                    className="short"
                    placeholder="to track earnings"
                    value={event.compensation || ''}
                    onChange={(e) => {
                      setEvent({
                        ...event,
                        compensation: onlyAllowPositiveNumbersAndDecimals(
                          e.target.value
                        ),
                      })
                    }}
                  />
                </FormSection>
              )}
              {selectedGroup && event.id && (
                <FormSection
                  stepNumber={stepNumbers().eventSpeakers}
                  description="Who's speaking?">
                  <EventSpeakersParent
                    event={event}
                    setEvent={setEvent}
                    selectedGroup={selectedGroup}
                    updateEventSpeakers={updateEventSpeakers}
                  />
                </FormSection>
              )}
            </FormContainer>
            {!selectedGroup && (
              <OptionalSettingsContainer>
                <FormContainer>
                  <SettingsAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Advanced Settings
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormSection
                        stepNumber={stepNumbers().lockCode}
                        heading="Lock this talk to a specific offer code?"
                        headerComponent="div"
                        subheading="Every attendee who uses this code will have their survey answers put into this event until it's unlocked, regardless of the day or time they answer the survey.">
                        <Alert severity="warning">
                          <AlertTitle>
                            <b>Warning</b>
                          </AlertTitle>
                          This is only needed for advanced use cases!
                          <br />
                          <br />
                          Only use this setting if you have{' '}
                          <b>
                            multiple talks on the same day with different codes
                            that you are creating now
                          </b>{' '}
                          OR if this talk is a <b>recorded webinar</b>. You do
                          not need this setting otherwise as Talkadot will
                          automatically add responses to the correct event when
                          you give your talk for you. <br /> <br />
                          Using this setting in the wrong scenarios will result
                          in some of your responses being added to the wrong
                          events.
                          <br />
                          <br />
                          For more information on code locking, please reach out
                          to support at hello@talkadot.com.
                        </Alert>
                        <br />
                        <FormControl variant="standard">
                          <SurveyOfferSelect
                            setEvent={setEvent}
                            event={event}
                          />
                        </FormControl>
                      </FormSection>
                    </AccordionDetails>
                  </SettingsAccordion>
                </FormContainer>
              </OptionalSettingsContainer>
            )}
          </FormDialogContent>
        </div>
      </LocalizationProvider>
    )
  }

  return (
    <div>
      {user?.isEventPlanner ? renderEventPlannerForm() : renderSpeakerForm()}
      <DialogActions>
        {isLoading ? (
          <CircularProgress sx={{ color: 'orange' }} />
        ) : (
          <div>
            <Button style={{ color: 'orange' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              className="orange-button"
              onClick={handleSubmit}>
              {event.id ? 'Save Changes' : 'Create Event'}
            </Button>
          </div>
        )}
      </DialogActions>
    </div>
  )
}

export default EventFormParent
