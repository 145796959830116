import { styled } from '@mui/system'
import { Button } from '@mui/material'
import { motion } from 'framer-motion/dist/framer-motion'

const StyledButton = styled(motion(Button), {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ theme, width }) => ({
  color: theme.palette.base.white,
  borderRadius: '5px',
  padding: '0.5rem 1rem',
  fontFamily: 'Figtree, sans-serif',
  fontSize: '1.125rem',
  fontWeight: '600',
  textTransform: 'none',
  width: width,
}))

const AlertCardCta = ({ buttonText, buttonWidth, handleClick, loading }) => {
  const pulsatingAnimation = {
    scale: [1, 1.03, 1],
    transition: {
      repeat: Infinity,
      duration: 1.5,
      ease: 'easeInOut',
    },
  }

  return (
    <StyledButton
      variant="contained"
      color="primary"
      width={buttonWidth}
      onClick={handleClick}
      disabled={loading}
      animate={pulsatingAnimation}>
      {buttonText}
    </StyledButton>
  )
}

export default AlertCardCta
