import { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { styled } from '@mui/system'
import Api from '../../services/api'

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}))
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}))

const AdminAssociatesParent = () => {
  const [associates, setAssociates] = useState([])

  useEffect(() => {
    const getAssociates = async () => {
      try {
        const res = await Api.adminAssociateList()

        if (!res.errors) {
          setAssociates(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        alert('An error occurred: ' + err)
      }
    }

    getAssociates()
  }, [])

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Owner</StyledTableCell>
            <StyledTableCell>Associate</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {associates.map((associate, index) => (
            <TableRow key={index}>
              <TableCell>{associate.owner_email}</TableCell>
              <TableCell>{associate.associate_email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )
}

export default AdminAssociatesParent
