import { timeInUnix } from '../utils/dateTime'

function gtag() {
  window.dataLayer.push(arguments)
}

const trackEventWithGoogle = (eventName, params) => {
  try {
    gtag('event', eventName, params)
  } catch (err) {
    // handle errors
  }
}

const pushDataLayer = (eventName, params) => {
  try {
    window.dataLayer.push({
      event: eventName,
      ...params,
    })
  } catch (err) {
    // handle errors
    console.log('error', err)
  }
}

export const trackSignUp = (userId, userEmail) => {
  pushDataLayer('sign_up', { user_id: userId, user_email: userEmail })
}

export const trackLogin = (userId, userEmail) => {
  pushDataLayer('login', { user_id: userId, user_email: userEmail })
}

export const trackUser = (userId) => {
  pushDataLayer('user_id', { user_id: userId })
}

export const trackOnboardingComplete = ({ userId, benefits, nextTalk }) => {
  pushDataLayer('onboarding_complete', {
    user_id: userId,
    benefits,
    next_talk: nextTalk,
  })
}

export const trackPurchase = ({ transactionId, value, coupon, plan }) => {
  pushDataLayer('purchase', {
    ecommerce: {
      transaction_id: transactionId,
      value,
      currency: 'USD',
      coupon,
      items: [
        {
          item_id: plan.id,
          item_name: plan.name,
          price: plan.price,
          quantity: 1,
        },
      ],
    },
  })
}

export const trackFirstOfferCreated = (params) => {
  pushDataLayer('first_offer_created', params)
}

export const trackFirstEventCreated = (params) => {
  pushDataLayer('first_event_created', params)
}

// Upgrade Funnel
export const trackHitLimit = (params) => {
  return trackEventWithGoogle('hit_limit', params)
}

export const trackViewedUpgrades = (params) => {
  return trackEventWithGoogle('viewed_upgrades', params)
}

export const trackAddToCart = (params) => {
  return trackEventWithGoogle('add_to_cart', params)
}

export const trackConversion = (params) => {
  trackEventWithGoogle('conversion', params)
}

export const trackDowngradeContactSupport = (params) => {
  return trackEventWithGoogle('downgrade_contact_support', params)
}

// Other Metrics
export const trackExportLeads = (params) => {
  return trackEventWithGoogle('exported_leads', params)
}

export const trackSocialDataShare = (params) => {
  return trackEventWithGoogle('download_data_share', params)
}

export const trackSocialTestimonialShare = (params) => {
  return trackEventWithGoogle('download_testimonial_share', params)
}

export const trackExpandEventRow = (params) => {
  return trackEventWithGoogle('expand_event_row', params)
}

// Onboarding Metrics
export const downloadedSlide = (params) => {
  return trackEventWithGoogle('downloaded_slide', params)
}

export const testedSurvey = (params) => {
  return trackEventWithGoogle('tested_survey', params)
}
