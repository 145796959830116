import { useContext, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { OnboardingContext } from './onboardingContext'
import { validateEmailFormat, validateNameFormat } from '../../utils/validators'

const UserAttributeForm = ({
  formValue,
  formLabel,
  profileAttribute,
  helperText,
}) => {
  const { setProfileAttribute } = useContext(AuthenticationContext)
  const { toggleFormError, onboardingState } = useContext(OnboardingContext)
  const { formError } = onboardingState

  useEffect(() => {
    toggleFormError(null)
  }, [])

  const handleChange = (e) => {
    // if profile attribute is first name or last name, do not allow more than 25 characters
    if (profileAttribute === 'first_name' || profileAttribute === 'last_name') {
      const { valid, message } = validateNameFormat(e.target.value)

      if (!valid) {
        toggleFormError(message)

        // if the target value length is less than the current form value length, set the profile attribute
        // this allows the user to continue typing if they are deleting characters and the length is greater than 25
        if (e.target.value.length < formValue.length) {
          setProfileAttribute(profileAttribute, e.target.value)
        }

        // do not set profile attribute because string is too long
        return
      }
    }

    // if profile attribute is email, validate email format
    if (profileAttribute === 'email') {
      const { valid, message } = validateEmailFormat(e.target.value)

      if (!valid) {
        toggleFormError(message)
        // set profile attribute because user should be able to continue typing
        setProfileAttribute(profileAttribute, e.target.value)
        return
      }
    }

    toggleFormError(null)
    setProfileAttribute(profileAttribute, e.target.value)
  }

  return (
    <div className="full-name-form-container">
      <form noValidate autoComplete="off">
        <TextField
          required
          fullWidth
          id="filled-required"
          variant="standard"
          type="text"
          margin="normal"
          label={null}
          error={formError}
          helperText={formError || helperText}
          placeholder={formLabel}
          value={formValue || ''}
          inputRef={(input) => input && input.focus()}
          onChange={(e) => handleChange(e)}
          autoComplete="off"
        />
      </form>
    </div>
  )
}

export default UserAttributeForm
