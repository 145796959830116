import { styled } from '@mui/system'
import { useTheme, Typography, Tooltip } from '@mui/material'

import { ReactComponent as DragIcon } from '../../../icons/burgerIcon_16x16.svg'
import { ReactComponent as CustomSettingsIcon } from '../../../icons/customSettingsIcon_16x16.svg'

import CodeBuilderToggleQuestionButton from './CodeBuilderToggleQuestionButton'
import CodeBuilderDeleteButton from './CodeBuilderDeleteButton'

import { usePermissionHelper } from '../../../utils/permission_helper'

import { useSortable } from '@dnd-kit/sortable'

import { CSS } from '@dnd-kit/utilities'

const QuestionItemContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'selected' && prop !== 'isGroupChild' && prop !== 'disabled',
})(({ theme, selected, isGroupChild, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: theme.spacing(5.5),
  padding: theme.spacing(0, 1.75),
  marginLeft: isGroupChild ? theme.spacing(4) : 0,
  backgroundColor: selected
    ? theme.palette.neutral.main
    : theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xxs,
  border: `1px solid ${theme.palette.border.light}`,
  position: 'relative',
  // cursor: 'pointer',
  cursor: disabled ? 'not-allowed' : 'pointer',
}))

const DragHandleContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragging',
})(({ theme, isDragging }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: theme.spacing(1.75),
  minWidth: '28px',
  cursor: isDragging ? 'grabbing' : 'grab',
}))

const StyledDragIcon = styled(DragIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.text.disabled,
  },
}))

const StyledCustomizedIcon = styled(CustomSettingsIcon)(({ theme }) => ({
  path: {
    stroke: theme.palette.text.disabled,
  },
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  color: selected ? theme.palette.base.white : theme.palette.text.disabled,
  fontWeight: theme.fontWeights.semiBold,
  paddingLeft: theme.spacing(1.75),
  flex: 1,
}))

const NoDropOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0.5,
  backgroundColor: theme.palette.error.semiDark,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  zIndex: 10,
}))

const CodeBuilderPreviewQuestionItem = ({
  question,
  selected,
  movable,
  canToggleEnable = false,
  disabled = false,
  setCurrentQuestionId,
  questionNumber,
  updateQuestion,
  handleDeleteQuestion,
  isGroupChild,
  tooltipText,
  canReorderQuestions,
  isDropAllowed
}) => {
  const theme = useTheme()

  const { attributes, listeners, setNodeRef, transform, transition, active, over } =
    useSortable({ id: question.id })

  const { canAccessAction } = usePermissionHelper()

  const handleClick = () => {
    if (disabled || selected) {
      return
    }

    setCurrentQuestionId(question.id)
  }

  const updateQuestionEnabled = (questionId) => {
    updateQuestion(questionId, { enabled: !question.enabled })
  }

  const renderPreviewContent = () => {
    const content = `${questionNumber}. ${question.config?.previewName}`

    if (
      question.questionType === 'EMOJI_SCALE' &&
      question.config.isTalkadotQuestion
    ) {
      return `${content}: ${question.replacementValue}`
    }

    // For custom questions, show a truncated version of the
    // question content in the preview
    if (!question.config.isTalkadotQuestion && question.content) {
      return `${content} ${question.content?.substring(0, 10) + '...'}`
    }

    return content
  }

  const isBeingHovered = over?.id === question.id
  const showNoDropOverlay = isBeingHovered && !isDropAllowed
  const isDragging = active?.id === question.id

  const renderDragableIcon = () => {
    if (movable) {
      if (canReorderQuestions()) {
        return (
          <DragHandleContainer
            selected={selected}
            isDragging={isDragging}
            {...listeners}>
            {<StyledDragIcon />}
          </DragHandleContainer>
        )
      }

      return (
        <Tooltip
          title="Upgrade your plan to reorder questions"
          disableInteractive>
          <DragHandleContainer selected={selected}>
            {<StyledDragIcon />}
          </DragHandleContainer>
        </Tooltip>
      )
    }
  }

  return (
    <Tooltip title={tooltipText} placement="left">
      <QuestionItemContainer
        selected={selected}
        onClick={handleClick}
        isGroupChild={isGroupChild}
        disabled={disabled}
        ref={setNodeRef}
        {...attributes}
        style={{
          transform: CSS.Transform.toString(transform),
        }}>
        {showNoDropOverlay && <NoDropOverlay>🚫 Cannot Drop Here</NoDropOverlay>}
        {renderDragableIcon()}
        <StyledTypography variant="body1" selected={selected}>
          {renderPreviewContent()}
        </StyledTypography>
        {question.hasBeenCustomized && (
          <Tooltip title="This question has been customized from the default settings">
            <StyledCustomizedIcon />
          </Tooltip>
        )}
        {canToggleEnable && (
          <CodeBuilderToggleQuestionButton
            handleToggleQuestion={() => updateQuestionEnabled(question.id)}
            enabled={question.enabled}
            iconColor={theme.palette.text.disabled}
            canToggleVisibility={canAccessAction(question.config.toggleEnabledTier)}
            question={question}
          />
        )}
        {question.config?.canDelete && (
          <CodeBuilderDeleteButton
            handleDelete={() => handleDeleteQuestion(question.id)}
            iconColor={theme.palette.text.disabled}
          />
        )}
      </QuestionItemContainer>
    </Tooltip>
  )
}

export default CodeBuilderPreviewQuestionItem
