import { styled } from '@mui/system'
import { Skeleton } from '@mui/material'

const ResponseStatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  padding: '14px 0 14px 45px',
})

const StatWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
})

const SessionsCount = styled('div')({
  fontSize: '2.8125rem',
  marginLeft: '10px',
  fontWeight: 500,
})

const StatText = styled('div')({
  textAlign: 'left',
})

export const SessionsStatContent = ({ sessions, isLoading }) => {
  return isLoading ? (
    <Skeleton width={200} height={30} />
  ) : (
    <ResponseStatContainer>
      <StatWrapper>
        <SessionsCount>{sessions?.toLocaleString()}</SessionsCount>
      </StatWrapper>
      <StatText>Total sessions given</StatText>
    </ResponseStatContainer>
  )
}
