import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/system'
import { Box, Grid, Card, CardHeader, CardContent } from '@mui/material'
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined'
import Api from '../../services/api'
import * as qs from 'qs'

const MessageContainer = styled(Card)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  width: '358px',
  borderRadius: '0',
}))

const FullHeightCenteringGrid = styled(Grid)(({ theme }) => ({
  minHeight: '100vh',
}))

const BonusGiftParent = () => {
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    const getBonusUrl = async () => {
      try {
        const res = await Api.getBonusUrl({ token: urlParams.token })

        if (!res.errors) {
          window.location.href = res.url
        } else {
          throw res.errors
        }
      } catch (err) {
        setLoading(false)
      }
    }

    getBonusUrl()
  }, [urlParams.token])

  return (
    <>
      {!loading && (
        <>
          <FullHeightCenteringGrid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Grid item xs={3}>
              <MessageContainer variant="outlined">
                <CardHeader
                  title={
                    <Box textAlign="center">
                      <DraftsOutlinedIcon fontSize="large" />
                      <div>This Gift does not exist</div>
                    </Box>
                  }
                />
                <CardContent>
                  <Box>If the problem persists, please contact support.</Box>
                </CardContent>
              </MessageContainer>
            </Grid>
          </FullHeightCenteringGrid>
        </>
      )}
    </>
  )
}

export default BonusGiftParent
