import { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Skeleton, Typography } from '@mui/material'

import Api from '../../services/api'

import './SpeakerProfile.scss'

const SpeakerSummaryHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'socialShare',
})(({ socialShare, theme }) => ({
  marginBottom: socialShare ? '' : '20px',
  width: '90%',
  marginRight: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: socialShare ? 'center' : 'flex-end',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginRight: '0',
  },
}))

// TODO: add case for event report page
const SpeakerName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'socialShare' && prop !== 'fontSize',
})(({ socialShare, fontSize, theme }) => ({
  fontWeight: socialShare ? '900' : '',
  fontSize: socialShare ? fontSize : '',
  lineHeight: socialShare ? '45px' : '',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
  },
}))

const initialSpeakerSummaryState = {
  firstName: null,
  lastName: null,
}

const SpeakerHeader = ({ shareKey, id, socialShare }) => {
  const [speakerSummary, setSpeakerSummary] = useState(
    initialSpeakerSummaryState
  )
  const [isLoading, setIsLoading] = useState(false)
  const { firstName, lastName } = speakerSummary
  const speakerNameFontSize =
    firstName?.length > 20 || lastName?.length > 20 ? '38px' : '45px'

  const makeName = () => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    } else {
      return firstName
    }
  }

  useEffect(() => {
    const fetchSpeakerSummary = async () => {
      const params = {
        speaker_summary: {
          id: id,
          share_key: shareKey,
        },
      }

      try {
        setIsLoading(true)
        const res = await Api.getSpeakerSummary(params)

        if (!res.errors) {
          setSpeakerSummary(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
      }
    }

    fetchSpeakerSummary()
  }, [])

  return (
    <SpeakerSummaryHeader socialShare={socialShare}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={'100%'} height={30} />
      ) : (
        <SpeakerName
          variant="h4"
          component="div"
          socialShare={socialShare}
          fontSize={speakerNameFontSize}>
          {makeName()}
        </SpeakerName>
      )}
    </SpeakerSummaryHeader>
  )
}

export default SpeakerHeader
