import { styled } from '@mui/system'
import { TableCell, Typography } from '@mui/material'

export const TableCellContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

export const TableCellSecondaryContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1.4),
}))

export const CheckboxTableCell = styled(TableCell)({
  padding: '9px 0 9px 4px',
  width: '46px',
})

export const SessionFromEventIcon = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  padding: '20px',
  cursor: 'help',
  background: theme.palette.border.light,
  borderRadius: '50%',
  width: '24px',
  height: '24px',
}))

export const ActionsTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  width: '80px',
  svg: {
    width: '1.5rem',
    height: '1.5rem',
  },
}))

export const TableCellPrimaryContent = styled('span')(
  ({ newItem, boldPrimary }) => ({
    fontWeight: newItem ? '700' : boldPrimary ? '600' : '400',
  })
)
