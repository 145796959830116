import { useContext, useState, useEffect } from 'react'
import {
  AccountSettingsCard,
  AccountSettingsCardInner,
  AccountSettingsCardTitle,
} from './AccountSettingsParent'
import { Notification } from '../common/Notification'

import { AuthenticationContext } from '../authentication/authenticationContext'
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  Button,
  Typography,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
} from '@mui/material'
import { styled } from '@mui/system'
import PersonIcon from '@mui/icons-material/Person'
import { formatDate } from '../common/helpers'

import Api from '../../services/api'
import envConfig from '../../envConfig'

const stripePromise = loadStripe(envConfig.REACT_APP_STRIPE_P_KEY)

const PaymentFormContainer = styled('div')({
  width: '100%',
  button: {
    width: '100%',
  },
  '.MuiTypography-h5': {
    fontWeight: 'bold',
    marginBottom: '20px',
  },
})

const CardNameInput = styled(TextField)({
  input: {
    fontSize: '14px !important',
  },
  svg: {
    fill: 'lightgray',
  },
})

const CardInfoInput = styled('div')({
  marginTop: '20px',
  marginBottom: '20px',
  border: '1px solid lightgray',
  padding: '20px',
  marginBottom: '20px',
  borderRadius: '5px',
})

const PaymentButton = styled(Button)({
  color: 'white',
})

const BillingDataRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '10px',
  },
}))

const BillingDataColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
})

const CheckoutForm = ({ toggleShowCreditCardForm }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [cardName, setCardName] = useState()
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [cardNameError, setCardNameError] = useState()
  const {
    setUserData,
    setNotification,
    showFullPageLoader,
    hideFullPageLoader,
    authData,
  } = useContext(AuthenticationContext)
  const { user } = authData

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Dont allow double clicks
    if (checkoutLoading) {
      return false
    }

    if (!stripe || !elements) {
      setCheckoutLoading(false)
      return
    }

    if (!cardName) {
      setCheckoutLoading(false)
      return setCardNameError('Please fill out name on card')
    }

    setCheckoutLoading(true)
    showFullPageLoader('Hang Tight While We Update Your Payment Info!')

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: cardName,
      },
    })

    if (payload.error) {
      setCheckoutLoading(false)
      hideFullPageLoader()
      return setNotification(payload.error)
    }

    try {
      const params = {
        user_payment_method: {
          stripe_card_info: {
            ...payload.paymentMethod,
          },
        },
      }

      const res = await Api.updatePaymentMethod(params, user.id)

      if (!res.errors) {
        hideFullPageLoader()
        toggleShowCreditCardForm(false)
        setUserData({ billingInfo: res })
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      setCheckoutLoading(false)
      return setNotification(err)
    }
  }

  return (
    <PaymentFormContainer>
      <Typography variant="h5" align="center">
        Update Payment Information
      </Typography>
      <CardNameInput
        fullWidth
        variant="outlined"
        helperText={cardNameError && cardNameError}
        value={cardName}
        sx={{ fontSize: '14px' }}
        error={cardNameError}
        className="card-name"
        placeholder="Name On Card"
        onChange={(e) => {
          setCardNameError(null)
          setCardName(e.target.value)
        }}
        type="text"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
      />
      <CardInfoInput>
        <CardElement />
      </CardInfoInput>
      <PaymentButton
        variant="contained"
        color="primary"
        onClick={(e) => handleSubmit(e)}
        disabled={checkoutLoading}>
        Update
      </PaymentButton>
    </PaymentFormContainer>
  )
}

const SubscriptionSourceParent = () => {
  const [showCreditCardForm, toggleShowCreditCardForm] = useState(false)
  const [canceledAtPeriodEnd, setCanceledAtPeriodEnd] = useState(false)

  const { authData } = useContext(AuthenticationContext)
  const { user } = authData
  const showPaymentInfo =
    !!user.membership.plan?.price > 0 &&
    user.billingInfo &&
    user.billingInfo.brand
  const { billingInfo } = user

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const res = await Api.getSubscriptionStatus()

        if (!res.errors) {
          setCanceledAtPeriodEnd(res.canceledAtPeriodEnd)
        } else {
          throw res.errors
        }
      } catch (err) {
        // Don't do anything with errors on this section.
        // Just catch them so it doesn't error out.
      }
    }

    fetchSubscriptionStatus()
  }, [user])

  const renderBillingError = () => {
    let errorText

    if (user.billingInfo.isExpired) {
      errorText = 'Your credit card has expired. Please update it'
    }

    if (user.billingInfo.paymentErrorMessage) {
      errorText =
        'There was an issue charging your card. Please update your billing information or add a new card.'
    }

    if (errorText) {
      return (
        <Notification variant="redWarning" hideClose maxWidth>
          {errorText}
        </Notification>
      )
    }
  }

  const renderNextBillingData = () => (
    <Typography>
      {canceledAtPeriodEnd
        ? 'Subscription Cancelled. Current subscription active until '
        : 'Billing annually - Next Billing '}
      {user.membership.nextBillingDate}
    </Typography>
  )

  return (
    <AccountSettingsCard>
      {renderBillingError()}
      <AccountSettingsCardTitle variant="h5">
        Billing Details
      </AccountSettingsCardTitle>
      <AccountSettingsCardInner>
        <BillingDataRow>
          <BillingDataColumn>
            <Typography>
              <b>Member Since:</b>
            </Typography>
            <Typography>{formatDate(user.signUpDate)}</Typography>
          </BillingDataColumn>
          {showPaymentInfo && (
            <BillingDataColumn>
              <Typography>
                <b>Subscription:</b>
              </Typography>
              {renderNextBillingData()}
            </BillingDataColumn>
          )}
          {showPaymentInfo && (
            <BillingDataColumn>
              <Typography>
                <b>Payment Method:</b>
              </Typography>
              <Typography>
                {billingInfo?.brand} ending in {billingInfo?.last4} - exp{' '}
                {billingInfo?.expMonth}/{billingInfo?.expYear}{' '}
              </Typography>
              <Button
                onClick={() => toggleShowCreditCardForm(true)}
                variant="contained"
                color="secondary">
                Update
              </Button>
            </BillingDataColumn>
          )}
        </BillingDataRow>
      </AccountSettingsCardInner>
      <Dialog
        open={showCreditCardForm}
        fullWidth
        maxWidth="sm"
        onClose={() => toggleShowCreditCardForm(false)}>
        <DialogContent>
          <Elements stripe={stripePromise}>
            <CheckoutForm toggleShowCreditCardForm={toggleShowCreditCardForm} />
          </Elements>
        </DialogContent>
      </Dialog>
    </AccountSettingsCard>
  )
}

export default SubscriptionSourceParent
