import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const EmojiWrapper = styled('span')({
  fontSize: '1.5rem',
})

const YesOrNoAnswer = ({ answerValue, answerContent }) => (
  <div className="submission-answer">
    {answerValue?.toLowerCase().includes('yes') ? (
      <EmojiWrapper>👍</EmojiWrapper>
    ) : (
      <EmojiWrapper>👎</EmojiWrapper>
    )}
    <Typography variant="body1" component="div" style={{ marginLeft: '10px' }}>
      {answerContent}{' '}
    </Typography>
  </div>
)

export default YesOrNoAnswer
