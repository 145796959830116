import { useState } from 'react'
// import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import MergeIcon from '@mui/icons-material/Merge'
// import DeleteIcon from '@mui/icons-material/Delete'

import { formatDate } from '../common/helpers'

import ConfirmDialog from '../common/ConfirmDialog'

// const StyledMergeIcon = styled(MergeIcon)({
//   transform: 'rotate(90deg)',
// })

// const StyledButton = styled(Button)({
//   textTransform: 'none',
//   marginRight: '1rem',
// })

const EventBulkActions = ({ mergableEvents, primeEvent, mergeEvents }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)

  const theme = useTheme()

  const mergeContent = `${mergableEvents.length} event${
    mergableEvents?.length > 1 ? 's' : ''
  } into ${formatDate(primeEvent.date, primeEvent.utcOffset)} ${
    primeEvent.talkTitle ? `- ${primeEvent.talkTitle}` : ''
  }`

  const handleMergeEvents = async () => {
    await mergeEvents()
    setConfirmOpen(false)
  }

  const handleMergeEventsClick = () => {
    if (mergableEvents.length > 0) {
      setConfirmOpen(true)
    }
  }

  return (
    <div className="events-parent__merge-actions">
      <Typography
        onClick={handleMergeEventsClick}
        component="div"
        variant="body1"
        className="events-parent__merge-actions__action">
        <MergeIcon /> Merge {mergeContent}?
      </Typography>
      {/* <StyledButton
        startIcon={<StyledMergeIcon />}
        color="tertiary"
        onClick={handleMergeEventsClick}>
        Merge
      </StyledButton> */}
      {/* TODO: only show if there are selected events */}
      {/* <StyledButton startIcon={<DeleteIcon />} color="tertiary">
        Delete
      </StyledButton> */}
      <ConfirmDialog
        title="Merge Events?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleMergeEvents}
        buttonColor="primary"
        textColor={theme.palette.base.white}>
        <div>Are you sure you want to merge these events?</div>
        <div>
          Merging {mergeContent} is <b>NOT</b> reversible.
        </div>
      </ConfirmDialog>
    </div>
  )
}

export default EventBulkActions
