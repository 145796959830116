/**
 * This function properly formats regular messages or error messages of all types.
 *
 * Examples of errors it will handle automatically:
 *
 * - "Just a regular message" => Just a regular message
 * - ['Email has already been taken', 'Cannot contain special characters'] =>
 *   Email has already been taken, Cannot contain special characters
 * - { error: 'Email has already been taken' } => Email has already been taken
 * - { errors: ['Email has already been taken'] } => Email has already been taken
 * - { errors: { error: ['Multi-level', 'nesting'] } } => Multi-level, nesting
 * - { errors: {bet: 'you weren\'t expecting this'} } => {"bet":"you weren't expecting this"}
 * - undefined => Whoops an unexpected issue occurred. Please contact support if this continues.
 *
 * @param {any} error - The message or error to be humanized.
 * @returns {string} - Humanized message.
 */
export const humanizeError = (error) => {
  if (error === null || error === undefined) {
    return 'Whoops an unexpected issue occurred. Please contact support if this continues.'
  }

  if (!Array.isArray(error) && typeof error === 'object') {
    const { errors: primaryErrors, error: primaryError } = error

    // Set error to the primary error object if present
    error = primaryErrors || primaryError

    // In extreme cases it is a multi-level error object
    if (!Array.isArray(error) && typeof error === 'object') {
      const { errors: nestedErrors, error: nestedError } = error

      error = nestedErrors || nestedError
    }
  }

  if (error && Array.isArray(error)) {
    return error.join(', ')
  } else if (typeof error !== 'string') {
    // Edge case: Return non-string, non-array objects as JSON strings
    return JSON.stringify(error)
  } else {
    return error
  }
}
