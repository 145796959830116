import React, { useState, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { styled } from '@mui/system'
import {
  Typography,
  TextField,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { debounce } from '@mui/material/utils'
import { LoadingButton } from '@mui/lab'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import Api from '../../services/api'
import { humanizeError } from '../../utils/errorFormatter'
import isEmail from 'validator/lib/isEmail'

import EditAvatar from '../accountSettings/EditAvatar'
import ConfirmDialog from '../common/ConfirmDialog'

const AddSpeakerText = styled(Typography)(({ theme }) => ({
  color: theme.palette.tabBlue.main,
  fontWeight: 'bold',
  marginLeft: '15px',
  marginTop: '5px',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const SpeakerClickableIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  justifyContent: 'center',
  cursor: 'pointer',
  margin: '0px 3px',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const DisabledSpeakerIcon = styled(ListItemIcon)({
  minWidth: '0px',
  justifyContent: 'center',
  margin: '0px 3px',
  color: 'lightgrey',
})

const SpeakerListItem = styled(ListItem)({
  paddingLeft: '0px',
  paddingRight: '0px',
})

const StyledListItemAvatar = styled(ListItemAvatar)({
  height: !isMobileOnly && '80px',
  width: !isMobileOnly && '80px',
  marginRight: !isMobileOnly && '16px',
})

const StyledAvatar = styled(Avatar)({
  height: !isMobileOnly && '100%',
  width: !isMobileOnly && '100%',
})

const initialNewSpeakerState = {
  id: null,
  name: null,
  avatarUrl: null,
  shortlink: null,
  email: null,
}

const makeSearch = async (
  searchTerm,
  setSpeakerList,
  setIsLoading,
  eventId,
  endpoint,
  finalParamsFunc
) => {
  // reset on empty
  if (!searchTerm || searchTerm.length < 2) {
    return setSpeakerList([])
  }

  try {
    const params = {
      event_speaker: {
        full_name: searchTerm,
        event_id: eventId,
      },
    }

    setIsLoading(true)
    const res = await endpoint(finalParamsFunc(params))

    if (!res.errors) {
      setIsLoading(false)
      setSpeakerList(res)
    } else {
      throw res.errors
    }
  } catch (err) {
    setIsLoading(false)
  }
}

const debouncedMakeSearch = debounce(makeSearch, 1000)

const EventSpeakersParent = ({
  event,
  setEvent,
  selectedGroup,
  updateEventSpeakers,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [speakerList, setSpeakerList] = useState([])
  const [selectedSpeaker, setSelectedSpeaker] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [open, setOpen] = useState(false)
  const [newSpeaker, setNewSpeaker] = useState(initialNewSpeakerState)
  const [speakerLoading, setSpeakerLoading] = useState(false)
  const [newSpeakerError, setNewSpeakerError] = useState(null)
  const [newSpeakerEmailError, setNewSpeakerEmailError] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [removeSpeaker, setRemoveSpeaker] = useState(null)

  const apiList = () => {
    if (selectedGroup) {
      return {
        editEventSpeakerAvatar: Api.editGroupEventSpeakerAvatar,
        createNewSpeaker: Api.createGroupEventSpeaker,
        removeSpeakerFromEvent: Api.removeSpeakerFromGroupEvent,
        addExistingSpeakerToEvent: Api.addExistingSpeakerToGroupEvent,
        searchForSpeakers: Api.searchForGroupSpeakers,
      }
    } else {
      return {
        editEventSpeakerAvatar: Api.editEventSpeakerAvatar,
        createNewSpeaker: Api.createNewSpeaker,
        removeSpeakerFromEvent: Api.removeSpeakerFromEvent,
        addExistingSpeakerToEvent: Api.addExistingSpeakerToEvent,
        searchForSpeakers: Api.searchForSpeakers,
      }
    }
  }

  const finalParams = (params) => {
    if (!selectedGroup) {
      return params
    }

    return {
      ...params,
      group_id: selectedGroup.id,
    }
  }

  useEffect(() => {
    debouncedMakeSearch(
      searchTerm,
      setSpeakerList,
      setIsLoading,
      event.id,
      apiList().searchForSpeakers,
      finalParams
    )
  }, [searchTerm, debouncedMakeSearch])

  const handleClose = () => {
    setOpen(false)
    setNewSpeaker(initialNewSpeakerState)
  }

  const handleOnInputChange = async (value) => {
    setSearchTerm(value)
  }

  const handleCreateNewSpeaker = (e) => {
    setOpen(true)
  }

  const handleEditSpeaker = (speaker) => {
    setOpen(true)
    setNewSpeaker({
      id: speaker.id,
      name: `${speaker.first_name} ${speaker.last_name}`,
      email: speaker.email,
      avatar: speaker.avatar,
    })
  }

  const AddSpeakerButton = () => (
    <ListItem onClick={handleCreateNewSpeaker}>
      <AddSpeakerText>Create New Speaker</AddSpeakerText>
    </ListItem>
  )

  const submitAvatar = async (avatarLink) => {
    const params = {
      event_speaker: {
        speaker_id: newSpeaker.id,
        avatar: avatarLink,
        event_id: event.id,
      },
    }

    try {
      const res = await apiList().editEventSpeakerAvatar(finalParams(params))

      if (!res.errors) {
        updateEventSpeakers(res, event.id)
        setEvent({ ...event, speakers: res })
        handleClose()
      } else {
        throw res.errors
      }
    } catch (err) {
      handleClose()
      alert(humanizeError(err))
    }
  }

  const handleSubmitNewSpeaker = async () => {
    if (!newSpeaker.name) {
      return setNewSpeakerError('Please submit a name for this speaker')
    }

    if (newSpeaker.name.length < 2) {
      return setNewSpeakerError('Must be at least 2 characters')
    }

    if (!newSpeaker.email || !isEmail(newSpeaker.email)) {
      return setNewSpeakerEmailError('Please fill out a valid email')
    }

    const params = {
      event_speaker: {
        full_name: newSpeaker.name,
        email: newSpeaker.email,
        event_id: event.id,
      },
    }

    try {
      setSpeakerLoading(true)
      const res = await apiList().createNewSpeaker(finalParams(params))

      if (!res.errors) {
        updateEventSpeakers(res, event.id)
        setEvent({ ...event, speakers: res })
        handleClose()
        setNewSpeaker(initialNewSpeakerState)
        setSpeakerLoading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setSpeakerLoading(false)
      return alert(humanizeError(err))
    }
  }

  const confirmRemoveSpeaker = async () => {
    const params = {
      event_speaker: {
        speaker_id: removeSpeaker.id,
        event_id: event.id,
      },
    }

    try {
      setIsDeleting(true)
      const res = await apiList().removeSpeakerFromEvent(
        finalParams(params),
        removeSpeaker.id
      )

      if (!res.errors) {
        // TODO: we're currently not updating events through setEvents here which is why
        // the speakers list in the events table doesn't update when we remove a speaker.
        // This also applies to the add new speaker to event function
        updateEventSpeakers(res, event.id)
        setEvent({ ...event, speakers: res })
        setIsDeleting(false)
        setRemoveSpeaker(null)
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsDeleting(false)
      setRemoveSpeaker(null)
    }
  }

  const handleRemoveSpeakerFromEvent = (speaker) => {
    setRemoveSpeaker(speaker)
    setConfirmOpen(true)
  }

  const handleAddSpeakerToEvent = async (speaker) => {
    setSelectedSpeaker(speaker)

    if (!speaker?.id) {
      return
    }

    const params = {
      event_speaker: {
        speaker_id: speaker.id,
        event_id: event.id,
      },
    }

    try {
      const res = await apiList().addExistingSpeakerToEvent(finalParams(params))
      if (!res.errors) {
        updateEventSpeakers(res, event.id)
        setEvent({ ...event, speakers: res })
        setSearchTerm('')
        setSpeakerList([])
        setSelectedSpeaker(null)
      } else {
        throw res.errors
      }
    } catch (err) {
      alert(humanizeError(err))
    }
  }

  const renderEditEventSpeakerForm = () => (
    <EditAvatar
      currentAvatar={newSpeaker.avatar}
      submitOverrideFunction={submitAvatar}
    />
  )

  const renderCreateNewEventSpeakerForm = () => (
    <div>
      <TextField
        variant="standard"
        type="text"
        placeholder="Enter the speakers full name"
        value={newSpeaker.name || ''}
        error={!!newSpeakerError}
        helperText={newSpeakerError && newSpeakerError}
        fullWidth
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e) => {
          setNewSpeaker({ ...newSpeaker, name: e.target.value })
          setNewSpeakerError(null)
        }}
      />
      <br />
      <br />
      <TextField
        variant="standard"
        type="email"
        placeholder="Enter the speakers email"
        value={newSpeaker.email || ''}
        error={!!newSpeakerEmailError}
        helperText={newSpeakerEmailError && newSpeakerEmailError}
        onKeyDown={(e) => e.stopPropagation()}
        fullWidth
        onChange={(e) => {
          setNewSpeaker({ ...newSpeaker, email: e.target.value })
          setNewSpeakerEmailError(null)
        }}
      />
    </div>
  )

  return (
    <div>
      <Autocomplete
        id="speaker-search-picker"
        getOptionLabel={(option) =>
          option?.first_name ? option.first_name : ''
        }
        options={speakerList}
        autoComplete
        loading={isLoading}
        loadingText="Searching ..."
        value={selectedSpeaker}
        inputValue={searchTerm}
        blurOnSelect={true}
        noOptionsText={<AddSpeakerButton />}
        onChange={(event, value) => handleAddSpeakerToEvent(value)}
        onInputChange={(event, value) => handleOnInputChange(value)}
        filterOptions={(options, params) => {
          // return the add new button if it's not in a loading state
          // and there's at least 1 option
          if (!isLoading && options?.length > 0) {
            return [...options, { isAddNewButton: true }]
          }

          return options
        }}
        renderOption={(props, option, state) => {
          if (option.isAddNewButton) {
            return <AddSpeakerButton key="newSpeaker" />
          } else {
            return (
              <ListItem {...props} key={option.id}>
                <StyledListItemAvatar>
                  <StyledAvatar
                    alt={option.first_name}
                    src={option.avatar || option.first_name?.charAt(0)}
                  />
                </StyledListItemAvatar>
                <ListItemText>
                  {option.first_name} {option.last_name}
                </ListItemText>
              </ListItem>
            )
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            onKeyDown={(e) => e.stopPropagation()}
            placeholder="Type the email or name of the speaker ..."
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <List>
        {event.speakers?.map((speaker, i) => {
          return (
            <SpeakerListItem key={i}>
              <StyledListItemAvatar>
                <StyledAvatar
                  alt={speaker.first_name}
                  src={speaker.avatar || speaker.first_name?.charAt(0)}
                />
              </StyledListItemAvatar>
              <ListItemText>
                {speaker.first_name} {speaker.last_name}
              </ListItemText>
              {!speaker.isClaimed && (
                <Tooltip placement="top" title="Edit speaker avatar">
                  <SpeakerClickableIcon>
                    <EditIcon onClick={() => handleEditSpeaker(speaker)} />
                  </SpeakerClickableIcon>
                </Tooltip>
              )}

              {speaker.profile_url ? (
                <Tooltip
                  placement="top"
                  title="View this speaker's public profile.">
                  <SpeakerClickableIcon
                    onClick={() => window.open(speaker.profile_url, '_blank')}>
                    <VisibilityIcon />
                  </SpeakerClickableIcon>
                </Tooltip>
              ) : (
                <Tooltip
                  placement="top"
                  title="This speaker does not have a public profile.">
                  <DisabledSpeakerIcon>
                    <VisibilityOffIcon />
                  </DisabledSpeakerIcon>
                </Tooltip>
              )}

              <Tooltip placement="top" title="Remove speaker from session">
                <SpeakerClickableIcon
                  onClick={() => handleRemoveSpeakerFromEvent(speaker)}>
                  {isDeleting ? <CircularProgress /> : <DeleteIcon />}
                </SpeakerClickableIcon>
              </Tooltip>
            </SpeakerListItem>
          )
        })}
      </List>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>
          {newSpeaker.id ? (
            <span>
              Edit Speaker Avatar for <b>{newSpeaker.name}</b>
            </span>
          ) : (
            'Create New Speaker'
          )}
        </DialogTitle>
        <DialogContent>
          {newSpeaker.id
            ? renderEditEventSpeakerForm()
            : renderCreateNewEventSpeakerForm()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          {/* only show this if we're creating and not editing */}
          {!newSpeaker.id && (
            <LoadingButton
              variant="contained"
              sx={{ color: 'white' }}
              loading={speakerLoading}
              onClick={handleSubmitNewSpeaker}>
              Create Speaker
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title="Remove Speaker?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmRemoveSpeaker}
        onCancel={() => setRemoveSpeaker(null)}>
        <span>{`Are you sure you want to remove ${removeSpeaker?.first_name} from this session?`}</span>
      </ConfirmDialog>
    </div>
  )
}

export default EventSpeakersParent
