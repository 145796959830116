import * as ActionTypes from './actionTypes'

export const initialAdminState = {
  impersonation: {
    isActive: false,
    user: {},
  },
  availableMembershipPlans: {
    legacyPlans: [],
    basePlans: [],
    referralPlans: [],
  },
  availableMembershipStatusus: [],
  users: [],
  referrerUserOptions: [],
}

export const adminReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_USERS:
      return {
        ...state,
        users: action.payload,
      }
    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id ? { ...action.payload } : { ...user }
        ),
      }
    case ActionTypes.IMPERSONATE_USER:
      return {
        ...state,
        impersonation: {
          ...state.impersonation,
          isActive: true,
          user: action.payload,
        },
      }
    case ActionTypes.STOP_IMPERSONATION:
      return {
        ...state,
        impersonation: {
          ...state.impersonation,
          isActive: false,
          user: {},
        },
      }
    case ActionTypes.LOAD_AVAILABLE_MEMBERSHIP_PLANS:
      return {
        ...state,
        availableMembershipPlans: action.payload,
      }
    case ActionTypes.LOAD_AVAILABLE_MEMBERSHIP_STATUSUS:
      return {
        ...state,
        availableMembershipStatusus: action.payload,
      }
    case ActionTypes.SET_REFERRER_USER_OPTIONS:
      return {
        ...state,
        referrerUserOptions: action.payload,
      }
    default:
      return { ...state }
  }
}
