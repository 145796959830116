import { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Dialog, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import TeamMemberList from './TeamMemberList'
import TeamForm from './TeamForm'
import {
  AccountSettingsCard,
  AccountSettingsCardTitle,
  AccountSettingsCardColumn,
  AccountSettingsCardInner,
} from './AccountSettingsParent'
import PageContainer from '../common/PageContainer'
import ConfirmDialog from '../common/ConfirmDialog'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'

import './AccountSettings.scss'

const CloseIconButton = styled(IconButton)({
  position: 'absolute',
  right: 0,
  cursor: 'pointer',
})

const TeamParent = () => {
  const [associates, setAssociates] = useState([])
  const [associatesLoading, setAssociatesLoading] = useState(false)
  const [associateFormOpen, setAssociateFormOpen] = useState(false)
  const [deleteAssociate, setDeleteAssociate] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { setNotification } = useContext(AuthenticationContext)
  useEffect(() => {
    const fetchAssociates = async () => {
      try {
        setAssociatesLoading(true)
        const res = await Api.loadAssociates()

        if (!res.errors) {
          setAssociatesLoading(false)
          setAssociates(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        setAssociatesLoading(false)
        return setNotification(err)
      }
    }

    fetchAssociates()
  }, [])

  const handleOpenAssociateForm = (associate) => {
    setAssociateFormOpen(true)
  }
  const handleCloseAssociateForm = () => {
    setAssociateFormOpen(false)
  }
  const shouldShowAddButton = () => {
    return associates && associates.length < 3
  }
  const handleDeleteAssociate = async (associate) => {
    await setDeleteAssociate(associate)
    setConfirmOpen(true)
  }
  const createAssociate = async (params) => {
    try {
      const res = await Api.createAssociate(params)

      if (res.errors) {
        throw res.errors
      } else {
        setAssociates([...associates, res])
        setNotification('Email sent to new member!', 'success')
      }
    } catch (err) {
      setNotification(err)
    }
  }
  const handleConfirmDelete = async () => {
    if (deleteAssociate?.id) {
      try {
        const res = await Api.deleteAssociate(deleteAssociate.id)

        if (res.errors) {
          throw res.errors
        } else {
          const updatedAssociates = associates.filter(
            (a) => a.id !== deleteAssociate.id
          )
          setAssociates(updatedAssociates)
          setNotification(
            `${deleteAssociate.email} has been removed from your account.`,
            'success'
          )
          setDeleteAssociate(null)
          setConfirmOpen(false)
        }
      } catch (err) {
        setNotification(err)
        setDeleteAssociate(null)
        setConfirmOpen(false)
      }
    }
  }
  const renderMemberTable = () => {
    if (associatesLoading || !associates.length) {
      return
    }
    return (
      <AccountSettingsCardInner>
        <TeamMemberList
          associates={associates}
          handleDeleteAssociate={handleDeleteAssociate}
        />
      </AccountSettingsCardInner>
    )
  }

  return (
    <PageContainer>
      <ConfirmDialog
        title="Remove Team Member?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirmDelete}
        onCancel={() => setDeleteAssociate(null)}>
        <span>
          Are you sure you want to revoke this individual's access to your
          account?
        </span>
      </ConfirmDialog>
      <Dialog
        open={associateFormOpen}
        fullWidth
        maxWidth="sm"
        onClose={() => handleCloseAssociateForm()}>
        <CloseIconButton onClick={() => handleCloseAssociateForm()}>
          <CloseIcon />
        </CloseIconButton>
        <TeamForm
          createAssociate={createAssociate}
          handleClose={handleCloseAssociateForm}
        />
      </Dialog>
      <AccountSettingsCard>
        <AccountSettingsCardTitle variant="h5">
          Team Members
        </AccountSettingsCardTitle>
        {renderMemberTable()}
        <AccountSettingsCardInner>
          {shouldShowAddButton() && (
            <AccountSettingsCardColumn>
              <Button
                onClick={handleOpenAssociateForm}
                className="orange-button">
                Add Team Member
              </Button>
            </AccountSettingsCardColumn>
          )}
        </AccountSettingsCardInner>
      </AccountSettingsCard>
    </PageContainer>
  )
}

export default TeamParent
