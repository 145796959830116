import { useState } from 'react'
import { styled } from '@mui/system'
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Alert,
  AlertTitle,
} from '@mui/material'

import { ProfileSectionHeader } from './ProfileSectionHeader'
import ProfileEventListRow from './ProfileEventListRow'

const TableWrapper = styled('div')({
  marginTop: '34px',
  paddingBottom: '50px',
  fontFamily: 'Sora, sans-serif',
})

const StyledTableContainer = styled(TableContainer)({
  maxHeight: '75vh',
})

const DateCell = styled(TableCell)({
  minWidth: '90px',
  padding: '14px 16px 14px 30px',
  background: 'skyBlue',
  fontWeight: 'bold',
  fontFamily: 'Sora, sans-serif',
})

const ResponsesCell = styled(TableCell)({
  padding: '14px 30px 14px 16px',
  background: 'skyBlue',
  fontWeight: 'bold',
  fontFamily: 'Sora, sans-serif',
})

const StyledTableCell = styled(TableCell)({
  background: 'skyBlue',
  fontWeight: 'bold',
  fontFamily: 'Sora, sans-serif',
})

const PlaceHolder = styled('div')({
  fontSize: '1.2rem',
  textAlign: 'left',
})

const EventListCriteria = styled(Alert)({
  marginTop: '20px',
  textAlign: 'left',
})

const ProfileEventList = ({
  authUser,
  events,
  eventsLoading,
  speaker,
  shortlink,
}) => {
  const [showEventsCriteria, setShowEventsCriteria] = useState(true)

  const headerText = 'Recent Speaking Engagements'

  const renderSkeleton = () => {
    return [1, 2, 3, 4, 5].map((i) => {
      return (
        <TableRow key={i}>
          <TableCell colSpan={13}>
            <Skeleton />
          </TableCell>
        </TableRow>
      )
    })
  }

  const speakerAccountTableHeader = () => (
    <TableRow>
      <DateCell align="left">Date</DateCell>
      <StyledTableCell align="left">Talk</StyledTableCell>
      <StyledTableCell align="left">Organization</StyledTableCell>
      <StyledTableCell align="left">Location</StyledTableCell>
      <StyledTableCell align="center">
        <Tooltip
          title="Percent who answered yes to if they found your talk valuable."
          placement="top">
          <span>Rating</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center">
        <Tooltip
          title="Percent who answered positively to if they would attend another one of your talks."
          placement="top">
          <span>Reattend</span>
        </Tooltip>
      </StyledTableCell>
      <ResponsesCell align="center">
        <Tooltip
          title="Total number of attendees who answered at least 1 question in the review flow."
          placement="top">
          <span>Responses</span>
        </Tooltip>
      </ResponsesCell>
    </TableRow>
  )

  const renderEventList = () => {
    return events.map((event, i) => (
      <ProfileEventListRow
        key={i}
        event={event}
        index={i}
        shortlink={shortlink}
      />
    ))
  }

  const renderPlaceholder = () => {
    return (
      <PlaceHolder>
        We're still gathering feedback for this profile!
      </PlaceHolder>
    )
  }

  const handleAlertClose = () => {
    setShowEventsCriteria(false)
  }

  return (
    <>
      <ProfileSectionHeader headerText={headerText} />
      {authUser?.id === speaker?.id && showEventsCriteria && (
        <EventListCriteria severity="info" onClose={handleAlertClose}>
          <AlertTitle>Profile Events Criteria</AlertTitle>
          Your profile is currently showing all events that have a{' '}
          <strong>Talk Title</strong> and <strong>5</strong> or more responses
        </EventListCriteria>
      )}
      <TableWrapper>
        {eventsLoading || events?.length > 0 ? (
          <>
            <StyledTableContainer component={Paper}>
              <Table aria-label="collapsible table" size="small" stickyHeader>
                <TableHead>{speakerAccountTableHeader()}</TableHead>
                <TableBody>
                  {eventsLoading ? renderSkeleton() : renderEventList()}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </>
        ) : (
          renderPlaceholder()
        )}
      </TableWrapper>
    </>
  )
}

export default ProfileEventList
