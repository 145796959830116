import React, { useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { 
  TextField, 
  Button, 
  Typography, 
  CircularProgress 
} from '@mui/material'
import talkadotLogo from '../../icons/talkadot-logo.png'
import Api from '../../services/api'
import { AuthenticationContext } from './authenticationContext';


import './PasswordReset.scss'

const PasswordResetForm = () => {
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [passwordError, setPasswordError] = useState(null)
  const { passwordResetToken } = useParams()
  const [success, setSuccess] = useState(false)
  const { setNotification } = useContext(AuthenticationContext)

  const handleResetPassword = async () => {
    // Don't allow double clicks
    if (isLoading) {
      return
    }

    if (!password) {
      return setPasswordError("please fill out a valid password")
    }

    if (password.length < 8) {
      return setPasswordError("Password must be at least 8 characters")
    }

    const params = {
      password_reset: {
        token: passwordResetToken,
        password: password
      }
    }

    try {
      setIsLoading(true)
      const res = await Api.resetPasswordbyToken(params)

      if (!res.errors) {
        setIsLoading(false)
        return setSuccess(true)
      } else {
        throw res.errors
      }
    } catch(err) {
      setIsLoading(false)
      return setNotification(err, 'error')
    }
  }

  const renderPasswordResetForm = () => (
    <div className="password-reset-container">
      <img className="main-logo" src={talkadotLogo} alt="Talkadot!" />
      <Typography component="div" variant="h6">
        Please enter your new password:
      </Typography>
      <TextField required
                variant="standard"
                fullWidth
                type="password"
                placeholder="new password ..."
                value={password}
                error={!!passwordError}
                helperText={passwordError && passwordError}
                onChange={(e) => {
                  setPasswordError(null)
                  setPassword(e.target.value)}} 
                />
      <Button disabled={isLoading} className="orange-button" onClick={handleResetPassword}>{isLoading ? <span className="loading"><CircularProgress /></span> : "Reset Password"}</Button>
    </div>
  )

  const renderPasswordResetSuccess = () => (
    <div className="password-reset-container">
      <img className="main-logo" src={talkadotLogo} alt="Talkadot!" />
      <Typography component="div" variant="h6" className={success && 'success-alert'}>
        Your password has been updated successfully!
        <br />
        <Link to="/login">Please click here to go back to the login page</Link>
      </Typography>
    </div>
  )

  return success ? renderPasswordResetSuccess() : renderPasswordResetForm()

}

export default PasswordResetForm