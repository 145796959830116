export const renderCurrency = (value) => {
  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
}

export const renderPercentage = (value) => {
  return value
    ? (value / 100).toLocaleString('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
      })
    : ''
}
