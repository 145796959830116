import { styled } from '@mui/system'
import TeamMemberRow from './TeamMemberRow'
import { TeamTableCell } from './TeamMemberRow'
import { device } from '../../responsive'

const TeamMemberContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '0.875rem',
  width: '100%',
  maxHeight: '75vh',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  borderRadius: '4px',
})
const TeamMemberTableHeader = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '5fr 1fr',
  fontWeight: 'bold',
  backgroundColor: theme.palette.secondary.light,
  textAlign: 'left',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  padding: '0 1.5rem',
  [`@media ${device.mobile}`]: {
    gridTemplateColumns: '1fr auto',
    padding: '0 1rem',
  },
}))
const TeamTable = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  borderRadius: '4px'
})

const TeamMemberList = ({ associates, handleDeleteAssociate }) => {
  const renderAssociateRows = () => {
    return associates.map((associate, i) => (
      <TeamMemberRow
        key={i}
        associate={associate}
        deleteAssociate={handleDeleteAssociate}
      />
    ))
  }

  return (
    <TeamMemberContainer>
      <TeamTable>
        <TeamMemberTableHeader>
          <TeamTableCell>Email Address</TeamTableCell>
          <TeamTableCell centered>Actions</TeamTableCell>
        </TeamMemberTableHeader>
        {renderAssociateRows()}
      </TeamTable>
    </TeamMemberContainer>
  )
}

export default TeamMemberList
