import { useState } from 'react'
import { styled } from '@mui/system'

import { GroupTableRow, GroupTableCell } from './GroupUsersRow'
import GroupEventsRow from './GroupEventsRow'

import { device } from '../../responsive'

const GroupContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '0.875rem',
})

const GroupTable = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  borderRadius: '4px',
  minWidth: '312px',
})

const GroupTableHeader = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr 2fr repeat(3, 1fr) 60px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.secondary.light,
  textAlign: 'left',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  padding: '0 1.5rem',
  [`@media ${device.mobile}`]: {
    gridTemplateColumns: '1fr 2fr 2fr auto',
  },
}))

const GroupTableBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& > div:nth-of-type(even)': {
    background: theme.palette.tertiary.extraLight,
  },
  '& > div:last-child': {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}))

const GroupEventsTable = ({
  groupEvents,
  groupEventsLoading,
  selectedGroup,
}) => {
  const renderGroupEvents = () => {
    if (groupEventsLoading) {
      return (
        <GroupTableBody>
          <GroupTableRow>
            <GroupTableCell>Loading...</GroupTableCell>
          </GroupTableRow>
        </GroupTableBody>
      )
    }

    if (!groupEvents?.length) {
      return (
        <GroupTableBody>
          <GroupTableRow>
            <GroupTableCell>
              Chapter does not have any recorded events yet.
            </GroupTableCell>
          </GroupTableRow>
        </GroupTableBody>
      )
    }

    return (
      <GroupTableBody>
        {groupEvents?.map((event) => (
          <GroupEventsRow
            selectedGroup={selectedGroup}
            key={event?.id}
            event={event}
          />
        ))}
      </GroupTableBody>
    )
  }

  return (
    <GroupContainer>
      <GroupTable>
        <GroupTableHeader>
          <GroupTableCell mobile>Date</GroupTableCell>
          <GroupTableCell mobile>Event</GroupTableCell>
          <GroupTableCell mobile>Speakers</GroupTableCell>
          <GroupTableCell centered>Avg Rating</GroupTableCell>
          <GroupTableCell centered>Reattend %</GroupTableCell>
          <GroupTableCell centered>Responses</GroupTableCell>
          <GroupTableCell centered mobile>
            Actions
          </GroupTableCell>
        </GroupTableHeader>
        {renderGroupEvents()}
      </GroupTable>
    </GroupContainer>
  )
}

export default GroupEventsTable
