import { Chip } from '@mui/material'
import { styled } from '@mui/system'

const StyledBetaChip = styled(Chip)(({ theme }) => ({
  transform: 'rotate(350deg)',
  marginLeft: '10px',
}))

const BetaChip = () => <StyledBetaChip label="BETA" color="primary" />

export default BetaChip
