/**
 * Shortens a given string to a specified maximum character length, appending '...' if truncated.
 *
 * @param {string} str - The string to be shortened. If null or undefined, an empty string will be returned.
 * @param {number} [maxChars=12] - The maximum number of characters for the resulting string.
 *                               Must be greater than or equal to 8.
 * @returns {string} - The shortened string.
 * @throws {Error} - If maxChars is less than 8.
 *
 * @example
 *   shortenString('myreallylongemailthatishuge@gmail.com', 15) // Returns 'myreallylon...'
 *   shortenString('short@email.com', 15)                     // Returns 'short@email.com'
 */
export const truncText = (str, maxChars = 12) => {
  if (maxChars < 8) {
    throw new Error('maxChars should be at least 8.')
  }

  if (!str) return ""
  if (str.length <= maxChars) return str

  return `${str.substring(0, maxChars - 3)}...`
}
