import { styled } from '@mui/system'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const MaxWidthContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noNavPage',
})(({ noNavPage }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: noNavPage ? 'center' : '',
  justifyContent: noNavPage ? 'center' : '',
  width: '100%',
  height: noNavPage ? '100vh' : '',
  maxWidth: '1280px',
}))

const PageContainer = ({ children, noNavPage = false }) => {
  return (
    <Container>
      <MaxWidthContainer noNavPage={noNavPage}>{children}</MaxWidthContainer>
    </Container>
  )
}

export default PageContainer
