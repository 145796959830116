import { useState } from 'react'
import { ExportToCsv } from 'export-to-csv'
import { IoChatboxOutline } from 'react-icons/io5'
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@mui/material'

import Api from '../../services/api'

const EventRawDataDownloadMenuItem = ({ event, group }) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const cleanAttributeForCsv = (attribute) => {
    if (!attribute) {
      return ''
    }

    return attribute
  }

  const exportTitle = `talkadot-raw-data-export-${
    event.talkTitle ? event.talkTitle : 'unnamed-event'
  }-${event.date}`

  const downloadEventData = async () => {
    const params = {
      event_answers: {
        event_id: event.id,
      },
    }

    try {
      setIsDownloading(true)
      let res

      if (group) {
        res = await Api.fetchGroupEventRawAnswers({
          ...params,
          group_id: group.id,
        })
      } else {
        res = await Api.fetchEventRawAnswers(params)
      }

      if (!res.errors) {
        if (res?.length <= 0) {
          setIsDownloading(false)
          return alert('There are no testimonials for this session')
        }

        const headers = [
          'Question',
          'Answer',
          'Name',
          'Email',
          'Job Title',
          'Organization',
        ]

        const rows = res.map((answerData) => {
          return [
            cleanAttributeForCsv(answerData.question),
            cleanAttributeForCsv(answerData.answer),
            cleanAttributeForCsv(answerData.name),
            cleanAttributeForCsv(answerData.email),
            cleanAttributeForCsv(answerData.title),
            cleanAttributeForCsv(answerData.organization),
          ]
        })

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: false,
          title: exportTitle,
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
          headers: headers,
          filename: exportTitle,
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(rows)
        setIsDownloading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsDownloading(false)
      alert(err)
    }
  }

  return (
    <MenuItem key="4" onClick={downloadEventData} disabled={isDownloading}>
      <ListItemIcon>
        {isDownloading ? <CircularProgress size={25} /> : <IoChatboxOutline />}
      </ListItemIcon>
      <ListItemText>Download Testimonials</ListItemText>
    </MenuItem>
  )
}

export default EventRawDataDownloadMenuItem
