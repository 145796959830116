import * as ActionTypes from './actionTypes'

const INDUSTRIES = [
  'Motiviational',
  'Business',
  'Sales',
  'Startups',
  'Medicine',
  'Law',
  'Technology',
  'Energy',
]
export const initialOnboardingState = {
  profile: {
    firstName: null,
    lastName: null,
    avatar: null,
  },
  socialLinks: {
    instagram: null,
    twitter: null,
    youtube: null,
    linkedin: null,
  },
  surveyOffer: {
    id: null,
    link: null,
    name: null,
    code: null,
    fileKey: null,
    downloadUrl: null,
    fileName: null,
    offerFile: null,
    linkError: null,
    nameError: null,
    codeError: null,
  },
  formError: null,
  shortlink: null,
  currentStep: 1,
  isLoading: false,
  businessGoals: ['1-4', '5-10', '11-15', '15+'],
  industries: INDUSTRIES.map((industry, i) => ({
    name: industry,
    isActive: false,
  })),
  referrerData: {
    selectedReferrerOption: null,
    selectedReferrerOrganization: null,
    selectedReferrerChapter: null,
    additionalReferrerContext: null,
  },
}

export const onboardingReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          [action.payload.profileAttribute]: action.payload.value,
        },
      }
    case ActionTypes.SET_SOCIAL_MEDIA:
      return {
        ...state,
        socialLinks: {
          ...state.socialLinks,
          [action.payload.socialMediaType]: action.payload.value,
        },
      }
    case ActionTypes.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.payload,
      }
    }
    case ActionTypes.SET_SHORTLINK: {
      return {
        ...state,
        shortlink: action.payload,
      }
    }
    case ActionTypes.TOGGLE_ACTIVE_INDUSTRY: {
      return {
        ...state,
        industries: state.industries.map((industry, i) =>
          industry.name === action.payload
            ? { ...industry, isActive: !industry.isActive }
            : { ...industry }
        ),
      }
    }
    case ActionTypes.TOGGLE_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case ActionTypes.SET_FORM_ERROR: {
      return {
        ...state,
        formError: action.payload,
      }
    }
    case ActionTypes.SET_ONBOARDING_SURVEY_OFFER_ATTRIBUTE: {
      return {
        ...state,
        surveyOffer: {
          ...state.surveyOffer,
          [action.payload.attrType]: action.payload.attrValue,
        },
      }
    }
    case ActionTypes.SET_ONBOARDING_SURVEY_OFFER: {
      return {
        ...state,
        surveyOffer: {
          ...state.surveyOffer,
          ...action.payload,
        },
      }
    }
    case ActionTypes.SET_REFERRER_DATA_ATTRIBUTE: {
      return {
        ...state,
        referrerData: {
          ...state.referrerData,
          [action.payload.attrType]: action.payload.attrValue,
        },
      }
    }
    case ActionTypes.RESET_ONBOARDING_DATA: {
      return {
        ...initialOnboardingState,
      }
    }
    default:
      return { ...state }
  }
}
