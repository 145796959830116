import { useContext } from 'react'

import { GroupContext } from '../groups/groupContext'

import TalksTableRowSpeakerPlaceholder from './TalksTableRowSpeakerPlaceholder'

const TalksTablePlaceHolder = ({ user, handleOpenEventForm }) => {
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)

  const renderPlaceholderRows = () => {
    if (user?.isEventPlanner) {
      return <div>EventPlannerPlaceHolder</div>
    } else if (selectedGroup) {
      return <div>GroupPlaceHolder</div>
    } else {
      return (
        <TalksTableRowSpeakerPlaceholder
          handleOpenEventForm={handleOpenEventForm}
        />
      )
    }
  }

  return <>{renderPlaceholderRows()}</>
}

export default TalksTablePlaceHolder
