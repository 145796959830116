import { useContext } from 'react'
import { Button } from '@mui/material'

import { AuthenticationContext } from '../authentication/authenticationContext'

const UpgradeModalLauncher = ({
  content,
  variant,
  color,
  id,
  cx,
  upgradeHelperText,
  permissionType,
}) => {
  const { toggleUpgradeModal, authData } = useContext(AuthenticationContext)
  const { user } = authData
  const { membership } = user

  // Note: Might want to move the logic of if to show this or not out of here
  // and into the individual components where we render the launcher. For now it's ok
  return (
    membership.shouldShowUpgrades && (
      <Button
        onClick={() =>
          toggleUpgradeModal(true, upgradeHelperText, permissionType)
        }
        variant={variant}
        color={color || 'primary'}
        id={id}
        className={cx}>
        {content}
      </Button>
    )
  )
}

export default UpgradeModalLauncher
