import { styled } from '@mui/system'
import { Button } from '@mui/material'

import { ReactComponent as ArrowLeft } from '../../../icons/arrowLeft.svg'

const NavContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'singleButton',
})(({ theme, singleButton }) => ({
  display: 'flex',
  justifyContent: singleButton ? 'flex-end' : 'space-between',
  padding: theme.spacing(3, 0),
}))

const NavButton = styled(Button)(({ theme, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  color: theme.palette.base.white,
  svg: {
    minWidth: '16px',
    path: {
      stroke: disabled
        ? theme.palette.action.disabled
        : theme.palette.base.white,
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .button-text': {
      display: 'none',
    },
  },
}))

const SkipButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.fontWeights.regular,
}))

const RightButtonsWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'showBackButton' && prop !== 'showBuilderSkipButton',
})(({ theme, showBackButton, showBuilderSkipButton }) => ({
  display: 'flex',
  flex: 1,
  justifyContent:
    showBackButton || !showBuilderSkipButton ? 'flex-end' : 'space-between',
  gap: theme.spacing(2),
}))

const CodeBuilderNavigation = ({
  nextButtonContent,
  skipButtonContent,
  handleBack,
  handleNext,
  handleSkip,
  showBackButton,
  showBuilderSkipButton,
  loading,
}) => {
  return (
    <NavContainer singleButton={!showBackButton && !showBuilderSkipButton}>
      {showBackButton && (
        <NavButton variant="contained" onClick={handleBack} disabled={loading}>
          <ArrowLeft />
          <div className="button-text">Back</div>
        </NavButton>
      )}
      <RightButtonsWrapper
        showBackButton={showBackButton}
        showBuilderSkipButton={showBuilderSkipButton}>
        {showBuilderSkipButton && (
          <SkipButton
            variant="text"
            color="secondary"
            onClick={handleSkip}
            disabled={loading}>
            {skipButtonContent}
          </SkipButton>
        )}
        <NavButton variant="contained" onClick={handleNext} disabled={loading}>
          {nextButtonContent}
        </NavButton>
      </RightButtonsWrapper>
    </NavContainer>
  )
}

export default CodeBuilderNavigation
