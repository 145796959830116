import { useState } from 'react'
import { Tooltip } from '@mui/material'

const GateToolTip = ({ children, title }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [disableTooltipInteractions, setDisableTooltipInteractions] =
    useState(false)

  const handleTooltipOpen = () => {
    if (disableTooltipInteractions) return
    setTooltipOpen(true)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleChildOpen = () => {
    setDisableTooltipInteractions(true)
    handleTooltipClose()
  }

  const handleChildClose = () => {
    setDisableTooltipInteractions(false)
  }

  return (
    <Tooltip
      followCursor
      title={title}
      placement="top"
      open={tooltipOpen}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      disableHoverListener={disableTooltipInteractions}
      disableFocusListener={disableTooltipInteractions}>
      {children({
        onChildOpen: handleChildOpen,
        onChildClose: handleChildClose,
      })}
    </Tooltip>
  )
}

export default GateToolTip
