import { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/system'
import {
  Box,
  Paper,
  Skeleton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Button,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'

import Api from '../../services/api'
import { device } from '../../responsive'

import ScheduleUploader from '../eventPlanners/ScheduleUploader'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'

import EventListRow from './EventListRow'
import EventButton from './EventButton'

import eventDummyData from '../../icons/events-dummy-data-image.png'

const StyledTableContainer = styled(TableContainer)({
  maxHeight: '75vh',
})

const StyledTableCell = styled(TableCell)({
  // background: 'skyBlue',
})

const UploadActionsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
})

const GroupShareDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    minWidth: '500px',
  },
  [`@media ${device.mobile}`]: {
    '& .MuiDialog-paper': {
      minWidth: '90vw',
    },
  },
})

const DialogContentContainer = styled('div')({
  paddingTop: '5px',
  marginBottom: '1rem',
})

const ShareGroupsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1rem',
})

const ShareGroupRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 20%',
  alignItems: 'center',
})

const StyledH4 = styled(Typography)({
  [`@media ${device.mobile}`]: {
    fontSize: '1rem',
  },
})

const StyledH5 = styled(Typography)({
  [`@media ${device.mobile}`]: {
    fontSize: '0.875rem',
  },
})

const MobileEventButtonWrapper = styled('div')({
  display: 'flex',
  marginTop: '1rem',
})

const EventListParent = ({
  events,
  eventsLoading,
  setEvents,
  handleOpenEventForm,
  deleteEvent,
  eventGroupId,
  mergeEvents,
  mergableEvents,
  primeEvent,
}) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('date')
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [shareEvent, setShareEvent] = useState(null)
  const [availableGroups, setAvailableGroups] = useState([])
  const [availableGroupsLoading, setAvailableGroupsLoading] = useState(false)
  const [filteredGroups, setFilteredGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [shareGroups, setShareGroups] = useState([])
  const [shareGroupsLoading, setShareGroupsLoading] = useState(false)
  const { authData, setNotification } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const { user } = authData

  useEffect(() => {
    const filteredGroups = availableGroups.filter(
      (group) => !shareGroups.some((shareGroup) => shareGroup.id === group.id)
    )
    setFilteredGroups(filteredGroups)
  }, [availableGroups, shareGroups])

  const renderSkeleton = () => {
    return [1, 2, 3, 4, 5].map((i) => {
      return (
        <TableRow key={i}>
          <TableCell colSpan={13}>
            <Skeleton />
          </TableCell>
        </TableRow>
      )
    })
  }

  const renderEventPlannerEventsPlaceholder = () => (
    <div className="event-list-placeholder">
      <img src={eventDummyData} alt="placeholder" />
      <div className="event-list-placeholder__overlay">
        <div className="event-list-placeholder__overlay__action">
          <Typography variant="h4" component="div">
            <b>Your Session List And Data Will Appear Here!</b>
          </Typography>
        </div>
        <UploadActionsContainer>
          <ScheduleUploader setEvents={setEvents} eventGroupId={eventGroupId} />
          <EventButton event={null} handleOpenEventForm={handleOpenEventForm} />
        </UploadActionsContainer>
      </div>
    </div>
  )

  const renderEventsPlaceholder = () => (
    <>
      <div className="event-list-placeholder">
        <img src={eventDummyData} alt="placeholder" />
        <div className="event-list-placeholder__overlay">
          <div className="event-list-placeholder__overlay__action">
            <StyledH4 variant="h4" component="div">
              <b>Your Speaking Engagement Data Will Appear Here!</b>
            </StyledH4>
            <StyledH5 variant="h5">
              Make sure to add your Talkadot Slide (or QR code) to your talks to
              collect event data!
            </StyledH5>
            <StyledH5 variant="h5">
              You can also proactively create events in advance by clicking the
              button below.
            </StyledH5>

            {window.innerWidth > 960 && (
              <EventButton
                handleOpenEventForm={handleOpenEventForm}
                event={null}
              />
            )}
          </div>
        </div>
      </div>
      {window.innerWidth <= 960 && (
        <MobileEventButtonWrapper>
          <EventButton handleOpenEventForm={handleOpenEventForm} event={null} />
        </MobileEventButtonWrapper>
      )}
    </>
  )

  const selectEvent = (eventToSelect) => {
    // If there is no event selected, set this one to be event prime
    if (!events.some((event) => event.isPrimeEvent)) {
      setEvents(
        events.map((event) =>
          eventToSelect.id === event.id
            ? { ...event, isPrimeEvent: true, isSelected: true }
            : { ...event }
        )
      )
    } else {
      setEvents(
        events.map((event) =>
          eventToSelect.id === event.id
            ? { ...event, isSelected: true }
            : { ...event }
        )
      )
    }
  }

  const deselectAllEvents = () => {
    // Make sure to set isPrimeEvent to false as well
    setEvents(
      events.map(
        (event) => event && { ...event, isSelected: false, isPrimeEvent: false }
      )
    )
  }

  const deselectEvent = (eventToSelect) => {
    // If you deselect the prime event, deselect all of them
    if (eventToSelect.isPrimeEvent) {
      deselectAllEvents()
    } else {
      setEvents(
        events.map((event) =>
          eventToSelect.id === event.id
            ? { ...event, isSelected: false }
            : { ...event }
        )
      )
    }
  }

  // *****************************************8
  // For Table Sorting
  // Pulled from material-ui docs here:
  // https://mui.com/material-ui/react-table/
  // *******************************************
  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const handleSortDate = (order, orderBy) => {
    if (order === 'desc') {
      return (a, b) =>
        b.date?.localeCompare(a.date) ||
        new Date('1970/01/01 ' + a.startTime) -
          new Date('1970/01/01 ' + b.startTime)
    } else {
      return (a, b) =>
        a.date?.localeCompare(b.date) ||
        new Date('1970/01/01 ' + a.startTime) -
          new Date('1970/01/01 ' + b.startTime)
    }
  }

  function getComparator(order, orderBy) {
    if (orderBy === 'date') {
      return handleSortDate(order, orderBy)
    }

    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const eventPlannerHeaderCells = [
    {
      id: 'placeholder-checkbox',
      cx: 'fixed-w-xs',
      label: null,
      numeric: false,
      sortable: false,
      align: 'left',
    },
    {
      id: 'placeholder-empty',
      cx: 'fixed-w-xs',
      label: null,
      numeric: false,
      sortable: false,
      align: 'left',
    },
    {
      id: 'date',
      cx: 'fixed-w-m',
      label: 'Date',
      numeric: false,
      sortable: true,
      align: 'left',
    },
    {
      id: 'talk',
      cx: 'fixed-w-l',
      label: 'Talk',
      numeric: false,
      sortable: false,
      align: 'left',
    },
    {
      id: 'speakers',
      cx: 'fixed-w-l',
      label: 'Speakers',
      numeric: false,
      sortable: false,
      align: 'left',
    },
    {
      id: 'talk-type',
      cx: 'fixed-w-s',
      label: 'Type',
      numeric: false,
      sortable: false,
      align: 'center',
    },
    {
      id: 'reattend',
      cx: 'fixed-w-ms',
      label: 'Reattend',
      sortable: true,
      numeric: true,
      tooltip:
        'Percent who answered positively to if they would attend another one of your talks.',
      align: 'center',
    },
    {
      id: 'rating',
      cx: 'fixed-w-s',
      label: 'Rating',
      sortable: true,
      numeric: true,
      tooltip: 'Percent who answered yes to if they found your talk valuable.',
      align: 'center',
    },
    {
      id: 'responses',
      cx: 'fixed-w-ms',
      label: 'Responses',
      sortable: true,
      numeric: true,
      tooltip:
        'Total number of attendees who answered at least 1 question in the review flow.',
      align: 'center',
    },
    {
      id: 'actions',
      cx: 'fixed-w-s',
      label: 'Actions',
      sortable: false,
      numeric: false,
      align: 'center',
    },
  ]

  const eventPlannerTableHeader = () => (
    <TableRow>
      {eventPlannerHeaderCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.align ? headCell.align : 'center'}
          sortDirection={orderBy === headCell.id ? order : false}>
          {headCell.sortable ? (
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            headCell.label
          )}
        </TableCell>
      ))}
    </TableRow>
  )

  const speakerAccountTableHeader = () => (
    <TableRow>
      <StyledTableCell align="left" className="fixed-w-xs" />
      <StyledTableCell align="left" className="fixed-w-xs" />
      <StyledTableCell align="left" className="fixed-w-m">
        Date
      </StyledTableCell>
      <StyledTableCell align="left" className="fixed-w-l">
        Organization
      </StyledTableCell>
      <StyledTableCell align="left" className="fixed-w-l">
        Talk
      </StyledTableCell>
      <StyledTableCell align="left" className="fixed-w-m">
        Location
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-s">
        Rate
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-ms">
        <Tooltip
          title="Percent who answered positively to if they would attend another one of your talks."
          placement="top">
          <span>Reattend</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-s">
        <Tooltip
          title="Percent who answered yes to if they found your talk valuable."
          placement="top">
          <span>Rating</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-ms">
        <Tooltip
          title="Total number of attendees who answered at least 1 question in the review flow."
          placement="top">
          <span>Responses</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-s">
        <Tooltip
          title="Total number of attendees who want to either book or refer you. Email opt-in only contacts are not included in this total."
          placement="top">
          <span>Leads</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-ms">
        <Tooltip
          title="The offer code input by the audience for this talk."
          placement="top">
          <span>Code</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-s">
        Actions
      </StyledTableCell>
    </TableRow>
  )

  const groupGuestSpeakerTableHeader = () => (
    <TableRow className="table-header">
      <StyledTableCell align="left" className="fixed-w-xs" />
      <StyledTableCell align="left" className="fixed-w-xs" />
      <StyledTableCell align="left" className="fixed-w-m">
        Date
      </StyledTableCell>
      <StyledTableCell align="left" className="fixed-w-l">
        Talk
      </StyledTableCell>
      <StyledTableCell align="left" className="fixed-w-l">
        Speakers
      </StyledTableCell>
      <StyledTableCell align="left" className="fixed-w-s">
        Location
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-ms">
        <Tooltip
          title="Percent who answered positively if they would attend another talk by the speaker."
          placement="top">
          <span>Reattend</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-s">
        <Tooltip
          title="Percent who answered yes to if they found the talk valuable."
          placement="top">
          <span>Rating</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-ms">
        <Tooltip
          title="Total number of attendees who answered at least 1 question in the review flow."
          placement="top">
          <span>Responses</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-ms">
        <Tooltip
          title="The offer code input by the audience for this talk."
          placement="top">
          <span>Code</span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align="center" className="fixed-w-s">
        Actions
      </StyledTableCell>
    </TableRow>
  )

  const fetchShareGroups = async (eventId) => {
    try {
      const res = await Api.getShareGroups(eventId)
      if (!res.errors) {
        const groups = res.map((group) => ({
          label: group.name,
          id: group.id,
        }))
        setShareGroups(groups)
        setShareGroupsLoading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setShareGroupsLoading(false)
      setNotification(err)
    }
  }

  const fetchGroups = async (eventId) => {
    try {
      const res = await Api.getGroups()
      if (!res.errors) {
        const groups = res.groups.map((group) => ({
          label: group.name,
          id: group.id,
        }))
        setAvailableGroups(groups)
        setAvailableGroupsLoading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setAvailableGroupsLoading(false)
      setNotification(err)
    }
  }

  const handleShareEvent = async (event) => {
    setShareGroupsLoading(true)
    setAvailableGroupsLoading(true)
    setShareDialogOpen(true)

    await fetchShareGroups(event.id)
    fetchGroups(event.id)

    setShareEvent(event)
  }

  const renderSortableTable = () => {
    return events
      .sort(getComparator(order, orderBy))
      .map((event, i) => (
        <EventListRow
          key={i}
          event={event}
          handleOpenEventForm={handleOpenEventForm}
          deleteEvent={deleteEvent}
          selectEvent={selectEvent}
          deselectEvent={deselectEvent}
          handleShareEvent={handleShareEvent}
          mergeEvents={mergeEvents}
          mergableEvents={mergableEvents}
          primeEvent={primeEvent}
        />
      ))
  }

  const renderStandardTable = () => {
    return events.map((event, i) => (
      <EventListRow
        key={i}
        event={event}
        handleOpenEventForm={handleOpenEventForm}
        deleteEvent={deleteEvent}
        selectEvent={selectEvent}
        deselectEvent={deselectEvent}
        handleShareEvent={handleShareEvent}
        mergeEvents={mergeEvents}
        mergableEvents={mergableEvents}
        primeEvent={primeEvent}
      />
    ))
  }

  const renderEventList = () => {
    if (user?.isEventPlanner) {
      return renderSortableTable()
    } else {
      return renderStandardTable()
    }
  }

  const renderPlaceholder = () => {
    if (user.isEventPlanner) {
      return renderEventPlannerEventsPlaceholder()
    } else {
      return renderEventsPlaceholder()
    }
  }

  const handleOptionChange = (_event, values) => {
    setSelectedGroups(values.map((value) => value.id))
  }

  const handleSaveShareGroups = async () => {
    try {
      const params = {
        event_permission: {
          event_id: shareEvent.id,
          group_ids: selectedGroups,
        },
      }
      const res = await Api.createEventPermissions(params)
      if (!res.errors) {
        setShareGroups([
          ...shareGroups,
          ...availableGroups.filter((group) =>
            selectedGroups.includes(group.id)
          ),
        ])
        setSelectedGroups([])
      } else {
        setNotification(res)
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleRemoveShareGroup = async (groupId) => {
    const params = {
      event_permission: {
        event_id: shareEvent.id,
        group_id: groupId,
      },
    }
    const res = await Api.deleteEventPermission(params)
    if (!res.errors) {
      setShareGroups(shareGroups.filter((group) => group.id !== groupId))
    } else {
      setNotification(res)
    }
  }

  const renderHeader = () => {
    // TODO: Can we get rid of the event planner now?
    if (user?.isEventPlanner) {
      return eventPlannerTableHeader()
    } else if (selectedGroup) {
      return groupGuestSpeakerTableHeader()
    } else {
      return speakerAccountTableHeader()
    }
  }

  return (
    <div className="event-list">
      {eventsLoading || events?.length > 0 ? (
        <StyledTableContainer component={Paper}>
          <Table aria-label="collapsible table" size="small" stickyHeader>
            <TableHead>{renderHeader()}</TableHead>
            <TableBody>
              {eventsLoading ? renderSkeleton() : renderEventList()}
            </TableBody>
          </Table>
        </StyledTableContainer>
      ) : (
        renderPlaceholder()
      )}
      {!selectedGroup && (
        <GroupShareDialog
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          aria-labelledby="Event Share Dialog">
          <DialogTitle id="event-share-dialog-title">
            Share{' '}
            {shareEvent?.talkTitle ? `"${shareEvent?.talkTitle}"` : 'Event'}
          </DialogTitle>
          <DialogContent>
            <DialogContentContainer>
              <Autocomplete
                multiple
                value={selectedGroups.map((id) =>
                  availableGroups.find((group) => group.id === id)
                )}
                onChange={handleOptionChange}
                options={filteredGroups}
                getOptionLabel={(option) => option.label}
                loading={availableGroupsLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Add Groups"
                    placeholder="Select Groups"
                  />
                )}
              />
              <ShareGroupsContainer>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ fontWeight: 'bold' }}>
                  Groups with access
                </Typography>
                {shareGroupsLoading ? (
                  <Skeleton />
                ) : (
                  shareGroups.map((group) => (
                    <ShareGroupRow key={group.id}>
                      <Typography variant="body1" component="div">
                        {group.label}
                      </Typography>
                      <Button onClick={() => handleRemoveShareGroup(group.id)}>
                        Remove
                      </Button>
                    </ShareGroupRow>
                  ))
                )}
              </ShareGroupsContainer>
            </DialogContentContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShareDialogOpen(false)}>Done</Button>
            <Button
              variant="contained"
              disabled={selectedGroups.length === 0}
              sx={{ color: 'white' }}
              onClick={handleSaveShareGroups}>
              Save
            </Button>
          </DialogActions>
        </GroupShareDialog>
      )}
    </div>
  )
}

export default EventListParent
