import { styled } from '@mui/system'
import { TableCell } from '@mui/material'

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== 'color' &&
    prop !== 'textWrap' &&
    prop !== 'width' &&
    prop !== 'minWidth' &&
    prop !== 'padding' &&
    prop !== 'paddingLeft' &&
    prop !== 'displayBreakpoint',
})(
  ({
    textWrap,
    color,
    width,
    minWidth,
    padding,
    paddingLeft,
    displayBreakpoint,
    fontWeight,
    theme,
  }) => ({
    color: color || '',
    textWrap: textWrap || '',
    width: width || '',
    minWidth: minWidth || '',
    padding: padding || '',
    fontWeight: fontWeight || '',
    paddingLeft: paddingLeft ? theme.spacing(paddingLeft) : '',
    ...(displayBreakpoint && {
      [theme.breakpoints.down(displayBreakpoint)]: {
        display: 'none',
      },
    }),
  })
)

const TableCellStyled = ({
  textWrap,
  color,
  align,
  width,
  minWidth,
  padding,
  paddingLeft,
  displayBreakpoint,
  fontWeight,
  onClick,
  children,
}) => {
  return (
    <StyledTableCell
      onClick={onClick}
      textWrap={textWrap}
      color={color}
      align={align}
      width={width}
      minWidth={minWidth}
      padding={padding}
      paddingLeft={paddingLeft}
      displayBreakpoint={displayBreakpoint}
      fontWeight={fontWeight}>
      {children}
    </StyledTableCell>
  )
}

export default TableCellStyled
