import { styled } from '@mui/system'
import { Skeleton } from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import { useTheme } from '@mui/material/styles'

ChartJS.register(ArcElement, Tooltip)

const StatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  padding: '14px 0 14px 14px',
})

const StatWrapper = styled('div')({
  display: 'flex',
  flex: 1,
})

const InPersonPercentageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
})

const VirtualPercentageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

const InPersonPercentage = styled('div')({
  fontFamily: 'Sora, sans-serif',
  fontSize: '2.5rem',
  fontWeight: 400,
  span: {
    fontSize: '1.375rem',
  },
})

const VirtualPercentage = styled('div')({
  fontFamily: 'Sora, sans-serif',
  fontSize: '1.75rem',
  fontWeight: 400,
  textAlign: 'left',
})

const PercentageText = styled('div')({
  textAlign: 'left',
})

const CircleChartWrapper = styled('div')({
  alignSelf: 'center',
  marginLeft: '22px',
  marginRight: '16px',
  width: '100px',
  height: '100px',
})

export const LocationStatContent = ({ locationBreakdown, isLoading }) => {
  const theme = useTheme()
  const { totalEvents, inPerson, virtual } = locationBreakdown
  const inPersonRate = (inPerson / totalEvents) * 100 || 0
  const virtualRate = (virtual / totalEvents) * 100 || 0

  const chartData = {
    labels: ['Virtual', 'In Person'],
    datasets: [
      {
        data: [virtual, inPerson],
        backgroundColor: [
          theme.palette.profileWidget.chartLocationVirtual,
          theme.palette.profileWidget.green,
        ],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return isLoading ? (
    <Skeleton width={200} height={30} />
  ) : (
    <StatContainer>
      {inPersonRate > 0 && (
        <StatWrapper>
          <InPersonPercentageWrapper>
            <InPersonPercentage>
              {Math.round(inPersonRate)}
              <span>%</span>
            </InPersonPercentage>
            <PercentageText>In person</PercentageText>
          </InPersonPercentageWrapper>
          <CircleChartWrapper>
            <Doughnut data={chartData} options={options} />
          </CircleChartWrapper>
          <VirtualPercentageWrapper>
            <VirtualPercentage>{Math.round(virtualRate)}%</VirtualPercentage>
            <PercentageText>Virtual</PercentageText>
          </VirtualPercentageWrapper>
        </StatWrapper>
      )}
    </StatContainer>
  )
}
