import { useContext } from 'react'
import { styled } from '@mui/system'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { Typography, TextField } from '@mui/material'

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: '1',
  '.MuiTextField-root': {
    margin: '0',
  },
})

const EditSocialMedia = ({
  socialPrefix,
  placeholderValue,
  socialAttributeType,
  socialAttributeValue,
}) => {
  const { setSocialAttribute } = useContext(AuthenticationContext)

  return (
    <Container>
      <Typography variant="body1" component="div">
        {socialPrefix}
      </Typography>
      <TextField
        required
        fullWidth
        className="full-width"
        id="input-with-sx"
        variant="standard"
        placeholder={placeholderValue}
        type="text"
        margin="normal"
        value={socialAttributeValue || ''}
        onChange={(e) =>
          setSocialAttribute(socialAttributeType, e.target.value)
        }
      />
    </Container>
  )
}

export default EditSocialMedia
