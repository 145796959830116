import React, { useState, useContext } from 'react'
import { TextField, Button, Typography, CircularProgress } from '@mui/material'
import talkadotLogo from '../../icons/talkadot-logo.png'
import Api from '../../services/api'
import { AuthenticationContext } from './authenticationContext'

import './PasswordReset.scss'

const PasswordResetRequest = () => {
  const INITIAL_CAPTION = "Forgot your password? Please enter your email and we'll send you a link to reset it."
  
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { setNotification } = useContext(AuthenticationContext)

  const submitPasswordRequest = async () => {
    // Dont allow double clicks
    if (isLoading) {
      return
    }

    if (!email) {
      return setEmailError("Email cant be blank")
    }

    const params = {
      password_reset: {
        email: email
      }
    }

    try {
      setIsLoading(true)
      const res = await Api.requestPasswordReset(params)

      if (!res.errors) {
        setSuccess(true)
        setIsLoading(false)
      } else {
        throw res.errors
      }
    } catch(err) {
      setEmail('')
      setNotification(err, 'error')
      setIsLoading(false)
    }
  }

  return(
    <div className="password-reset-container">
      <img className="main-logo" src={talkadotLogo} alt="Talkadot!" />
      <Typography component="div" variant="h6" className={success && 'success-alert'}>
        { success ? `Success! Instructions have been sent to ${email}.` : INITIAL_CAPTION }
      </Typography>
      { !success && <TextField required
                variant="standard"
                fullWidth
                type="email"
                placeholder="Your Email Address"
                value={email}
                error={!!emailError}
                helperText={emailError && emailError}
                onChange={(e) => {
                  setEmailError(null)
                  setEmail(e.target.value)}} 
                /> }
      { !success && <Button disabled={isLoading} className="orange-button" onClick={submitPasswordRequest}>{isLoading ? <span className="loading"><CircularProgress /></span> : "Request Password Reset"}</Button> }
    </div>
  )

}

export default PasswordResetRequest