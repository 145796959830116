import { useContext, useState } from 'react'
import { styled } from '@mui/system'

import {
  Switch,
  Typography,
  Divider,
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material'
import { IoLockOpenOutline, IoLockClosedOutline } from 'react-icons/io5'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import {
  AccountSettingsCard,
  AccountSettingsCardColumn,
  AccountSettingsCardInner,
  AccountSettingsCardTitle,
} from './AccountSettingsParent'

const SettingWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '15px 0',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const LabelWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const ToggleLabelRight = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'checked',
})(({ theme, checked }) => ({
  minWidth: '100px',
  fontWeight: checked ? 'bold' : 'normal',
  color: checked ? 'inherit' : theme.palette.tertiary.light,
  textAlign: 'left',
  svg: {
    stroke: checked ? 'red' : '',
  },
}))

const ToggleLabelLeft = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'checked',
})(({ theme, checked }) => ({
  fontWeight: !checked ? 'bold' : 'normal',
  color: !checked ? 'inherit' : theme.palette.tertiary.light,
  svg: {
    stroke: !checked ? 'green' : '',
  },
}))

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

const intialFormValues = {
  password: '',
  password_confirmation: '',
}

const ProfileSettings = ({ user }) => {
  const { setNotification, setProfileAttribute } = useContext(
    AuthenticationContext
  )
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false)
  const [showPasswordFields, setShowPasswordFields] = useState(false)
  const [formValues, setFormValues] = useState(intialFormValues)
  const [passwordError, setPasswordError] = useState(false)

  const { isAssociate } = user

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)

  const handleClickShowNewConfirmPassword = () =>
    setShowNewConfirmPassword((show) => !show)

  const handleMouseDownPassword = (e) => {
    e.preventDefault()
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleBlur = () => {
    if (
      formValues.password_confirmation &&
      formValues.password !== formValues.password_confirmation
    ) {
      setPasswordError(true)
    } else if (
      passwordError &&
      formValues.password === formValues.password_confirmation
    ) {
      setPasswordError(false)
    }
  }

  const handleToggleProfileSetting = async (attribute, checked) => {
    const params = {
      profile_setting: {},
    }

    params.profile_setting[attribute] = checked

    try {
      const res = await Api.updateProfileSettings(params)
      if (!res.errors) {
        const profileSettings = {
          ...user.profileSettings,
          ...params.profile_setting,
        }
        setProfileAttribute('profileSettings', profileSettings)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handlePasswordSubmit = async (e) => {
    e.preventDefault()

    const params = {
      profile_setting: { ...formValues },
    }

    try {
      const res = await Api.updateProfileSettings(params)
      if (!res.errors) {
        const profileSettings = {
          ...user.profileSettings,
          password_set: true,
        }
        setProfileAttribute('profileSettings', profileSettings)
        setShowPasswordFields(false)
        setNotification('Profile Password Updated Successfully', 'success')
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const verifyPassword = () => {
    const { password, password_confirmation } = formValues
    if (password && password === password_confirmation) {
      return false
    }
    return true
  }

  const renderPasswordFields = () => {
    if (showPasswordFields || !user?.profileSettings?.password_set) {
      return (
        <form onSubmit={handlePasswordSubmit}>
          <SettingWrapper>
            <LabelWrapper>
              <Typography variant="body1" component="div">
                New Password
              </Typography>
            </LabelWrapper>
            <FormControl variant="outlined">
              <InputLabel htmlFor="new-password">New Password</InputLabel>
              <OutlinedInput
                id="new-password"
                type={showNewPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
                name="password"
                onChange={handleInputChange}
              />
            </FormControl>
          </SettingWrapper>
          <SettingWrapper>
            <LabelWrapper>
              <Typography variant="body1" component="div">
                Confirm New Password
              </Typography>
            </LabelWrapper>
            <FormControl variant="outlined">
              <InputLabel htmlFor="confirm-new-password">
                Confirm New Password
              </InputLabel>
              <OutlinedInput
                id="confirm-new-password"
                type={showNewConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showNewConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm New Password"
                name="password_confirmation"
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={passwordError}
              />
              {!!passwordError && (
                <FormHelperText error>password does not match</FormHelperText>
              )}
            </FormControl>
          </SettingWrapper>
          <ButtonWrapper>
            <Button
              variant="contained"
              type="submit"
              sx={{ color: 'white' }}
              disabled={verifyPassword()}>
              Save Password
            </Button>
          </ButtonWrapper>
        </form>
      )
    } else if (user?.profileSettings?.password_set && !showPasswordFields) {
      return (
        <ButtonWrapper>
          <Button
            variant="contained"
            onClick={() => setShowPasswordFields(true)}
            sx={{ color: 'white' }}>
            Update Password
          </Button>
        </ButtonWrapper>
      )
    }
  }

  const renderPasswordProtectedSwitch = () => {
    return (
      <Switch
        onChange={(e) =>
          handleToggleProfileSetting('password_protected', e.target.checked)
        }
        inputProps={{ 'aria-label': 'Search index toggle' }}
        checked={!!user?.profileSettings?.password_protected}
        disabled={!user?.profileSettings?.password_set}
      />
    )
  }

  const renderPasswordProtectedToggle = () => {
    if (!user?.profileSettings?.password_set) {
      return (
        <Tooltip title="Enter password to enable toggle">
          <span>{renderPasswordProtectedSwitch()}</span>
        </Tooltip>
      )
    }
    return renderPasswordProtectedSwitch()
  }

  return (
    <AccountSettingsCard>
      <AccountSettingsCardTitle variant="h5">
        {isAssociate ? 'Account Owner' : 'Your'} Profile Visibility
      </AccountSettingsCardTitle>
      <AccountSettingsCardInner>
        <AccountSettingsCardColumn>
          <SettingWrapper>
            <LabelWrapper>
              <Typography variant="body1" component="div">
                {isAssociate ? 'P' : 'Your p'}rofile is publicly accessible.
              </Typography>
            </LabelWrapper>
            <Stack direction="row" spacing={1} alignItems="center">
              <ToggleLabelLeft checked={user?.profileSettings?.is_public}>
                {user?.profileSettings?.is_public ? 'Make Private' : 'Private'}
              </ToggleLabelLeft>
              <Switch
                onChange={(e) =>
                  handleToggleProfileSetting('is_public', e.target.checked)
                }
                inputProps={{ 'aria-label': 'Talkadot public toggle' }}
                checked={!!user?.profileSettings?.is_public}
              />
              <ToggleLabelRight checked={user?.profileSettings?.is_public}>
                {user?.profileSettings?.is_public ? 'Public' : 'Make Public'}
              </ToggleLabelRight>
            </Stack>
          </SettingWrapper>
          <Divider light />
          <SettingWrapper>
            <LabelWrapper>
              <Typography variant="body1" component="div">
                {isAssociate ? 'P' : 'Your p'}rofile is searchable on Talkadot
              </Typography>
            </LabelWrapper>
            <Stack direction="row" spacing={1} alignItems="center">
              <ToggleLabelLeft
                checked={user?.profileSettings?.searchable_in_app}>
                {user?.profileSettings?.searchable_in_app
                  ? 'Make Private'
                  : 'Private'}
              </ToggleLabelLeft>
              <Switch
                onChange={(e) =>
                  handleToggleProfileSetting(
                    'searchable_in_app',
                    e.target.checked
                  )
                }
                inputProps={{ 'aria-label': 'Talkadot searchable toggle' }}
                checked={!!user?.profileSettings?.searchable_in_app}
              />
              <ToggleLabelRight
                checked={user?.profileSettings?.searchable_in_app}>
                {user?.profileSettings?.searchable_in_app
                  ? 'Searchable'
                  : 'Make Searchable'}
              </ToggleLabelRight>
            </Stack>
          </SettingWrapper>
          <Divider light />
          <SettingWrapper>
            <LabelWrapper>
              <Typography variant="body1" component="div">
                {isAssociate ? 'P' : 'Your p'}rofile is discoverable off
                Talkadot
              </Typography>
            </LabelWrapper>
            <Stack direction="row" spacing={1} alignItems="center">
              <ToggleLabelLeft checked={user?.profileSettings?.search_indexed}>
                {user?.profileSettings?.search_indexed
                  ? 'Make Private'
                  : 'Private'}
              </ToggleLabelLeft>
              <Switch
                onChange={(e) =>
                  handleToggleProfileSetting('search_indexed', e.target.checked)
                }
                inputProps={{ 'aria-label': 'Search index toggle' }}
                checked={!!user?.profileSettings?.search_indexed}
              />
              <ToggleLabelRight checked={user?.profileSettings?.search_indexed}>
                {user?.profileSettings?.search_indexed
                  ? 'Discoverable'
                  : 'Make Discoverable'}
              </ToggleLabelRight>
            </Stack>
          </SettingWrapper>
          <Divider light />
          <SettingWrapper>
            <LabelWrapper>
              <Typography variant="body1" component="div">
                {isAssociate ? 'P' : 'Your p'}rofile is password protected
              </Typography>
            </LabelWrapper>
            <Stack direction="row" spacing={1} alignItems="center">
              <ToggleLabelLeft
                checked={user?.profileSettings?.password_protected}>
                <IoLockOpenOutline />
              </ToggleLabelLeft>
              {renderPasswordProtectedToggle()}
              <ToggleLabelRight
                checked={user?.profileSettings?.password_protected}>
                <IoLockClosedOutline />
              </ToggleLabelRight>
            </Stack>
          </SettingWrapper>
          {renderPasswordFields()}
        </AccountSettingsCardColumn>
      </AccountSettingsCardInner>
    </AccountSettingsCard>
  )
}

export default ProfileSettings
