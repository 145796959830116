import { useState } from 'react'
import { styled, useTheme } from '@mui/system'
import { TableRow, TableCell, IconButton, Typography } from '@mui/material'
import YouTube from 'react-youtube'

import { ReactComponent as TrashIcon } from '../../icons/trash2Icon_16x16.svg'
import { ReactComponent as ArchiveIcon } from '../../icons/archiveIcon_16x16.svg'
import { ReactComponent as LeadContactedIcon } from '../../icons/itemCheckedIcon_16x16.svg'
import { ReactComponent as CaretDownIcon } from '../../icons/caretDown_16x16.svg'

import { ActionsTableCell, CheckboxTableCell } from '../common/TableComponents'
import LeadTypeIcon from './LeadTypeIcon'
import TableActionsDropdown from '../common/TableActionsDropdown'
import CheckBoxStyled from '../common/CheckBoxStyled'
import TableCellStyled from '../common/TableCellStyled'

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.lightest : '',
}))

const TableCellContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

const TableCellSecondaryContent = styled('span')(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

const StyledCaretIcon = styled(CaretDownIcon, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.2s ease-in-out',
  path: {
    stroke: theme.palette.neutral.semiLight,
  },
}))

const VideoTableRow = styled(TableRow)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
}))

const StyledHeaderWrapper = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}))

const CommunityLink = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '600',
  cursor: 'pointer',
  textDecoration: 'none',
}))

const StyledYouTube = styled(YouTube)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

const LeadsTableRowPlaceholder = () => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const color = theme.palette.neutral.light

  const lead = {
    placeholder: true,
  }

  const menuItems = [
    {
      text: 'Mark Contacted',
      icon: <LeadContactedIcon />,
      onClick: () => {},
      disabled: true,
    },
    {
      text: lead.archived ? 'Unarchive Lead' : 'Archive Lead',
      icon: <ArchiveIcon />,
      onClick: () => {},
      loading: false,
      disabled: true,
    },
    {
      text: 'Delete Lead',
      icon: <TrashIcon />,
      onClick: () => {},
      disabled: true,
    },
  ]

  const selected = false

  return (
    <>
      <StyledTableRow selected={selected}>
        <CheckboxTableCell>
          <CheckBoxStyled checked={false} disabled={true} />
        </CheckboxTableCell>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => {}}
            aria-expanded={open}
            aria-label="show more"
            disabled={true}>
            <StyledCaretIcon open={open} />
          </IconButton>
        </TableCell>
        <TableCellStyled textWrap="nowrap" color={color}>
          <TableCellContent>
            <span>Jan 18, 2024</span>
          </TableCellContent>
        </TableCellStyled>
        <TableCellStyled color={color} displayBreakpoint="md">
          Your leads will appear here
        </TableCellStyled>
        <TableCellStyled color={color} displayBreakpoint="lg">
          Attendee Org
        </TableCellStyled>
        <TableCellStyled color={color} displayBreakpoint="lg">
          <TableCellContent>
            <span>Attendee Name</span>
            <TableCellSecondaryContent>
              Attendee Title
            </TableCellSecondaryContent>
          </TableCellContent>
        </TableCellStyled>
        <TableCellStyled color={color}>
          <TableCellContent>
            <span>attendee@email.com</span>
            <TableCellSecondaryContent>(000)000-000</TableCellSecondaryContent>
          </TableCellContent>
        </TableCellStyled>
        <TableCellStyled color={color} displayBreakpoint="lg">
          <LeadTypeIcon lead={lead} />
        </TableCellStyled>
        <ActionsTableCell align="center">
          <TableActionsDropdown menuItems={menuItems} />
        </ActionsTableCell>
      </StyledTableRow>
      <VideoTableRow>
        <TableCell colSpan={10} align="center">
          <StyledHeaderWrapper>
            <StyledHeader variant="body2">
              Watch this video on how to collect leads for your business.
            </StyledHeader>
            <Typography variant="body1">
              Want more ideas?
              <CommunityLink
                href="https://talk.talkadot.com/sign_in"
                target="_blank">
                {' '}
                Head to our Talkadot Community{' '}
              </CommunityLink>
              to hear from fellow unicorn speakers.
            </Typography>
          </StyledHeaderWrapper>
          <StyledYouTube videoId="HCuKhP6Rz1U" />
        </TableCell>
      </VideoTableRow>
    </>
  )
}

export default LeadsTableRowPlaceholder
