import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthenticationContext } from './authenticationContext'
import Api from '../../services/api'
import RegistrationCheckout from './RegistrationCheckout'
import UnpaidRegistrationCheckout from './UnpaidRegistrationCheckout'
import * as qs from 'qs'
import { getGaCookies, getUtmCookies } from '../../services/cookies'

const ReferrerRegistrationParent = () => {
  const [membershipPlan, setMembershipPlan] = useState(null)
  const [referrer, setReferrer] = useState(null)
  const [referrerId, setReferrerId] = useState(null)

  const { showFullPageLoader, hideFullPageLoader, setNotification } =
    useContext(AuthenticationContext)

  const location = useLocation()
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const referrerShortlink = urlParams.via
  const showPaidCheckout =
    membershipPlan?.price > 0 && !membershipPlan?.trialPeriodDays
  const gaParams = getGaCookies()
  const utmParams = getUtmCookies()

  useEffect(() => {
    const validateReferrer = async () => {
      try {
        showFullPageLoader()
        const res = await Api.getReferrerPlan({
          referrer_shortlink: referrerShortlink,
        })

        if (!res.errors) {
          hideFullPageLoader()
          setMembershipPlan(res.membershipPlan)
          setReferrer(res.referrer)
          setReferrerId(res.referrerId)
        } else {
          throw res.errors
        }
      } catch (err) {
        hideFullPageLoader()
        setNotification(
          "Oops - looks like you're trying to sign up for a plan that doesn't exist. Please contact support at hello@talkadot.com"
        )
      }
    }

    if (referrerShortlink) {
      validateReferrer()
    }
  }, [referrerShortlink])

  return showPaidCheckout ? (
    <RegistrationCheckout
      plan={membershipPlan}
      referrer={referrer}
      referrerId={referrerId}
      utmParams={utmParams}
      gaParams={gaParams}
    />
  ) : (
    <UnpaidRegistrationCheckout
      plan={membershipPlan}
      referrer={referrer}
      referrerId={referrerId}
      utmParams={utmParams}
      gaParams={gaParams}
    />
  )
}

export default ReferrerRegistrationParent
