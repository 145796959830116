import QRCode from 'qrcode.react'
import envConfig from '../../envConfig'

const DownloadableQrCode = ({ surveyLink, code }) => {
  const url = `${envConfig.REACT_APP_SHORTLINK}/${surveyLink}`
  return (
    <QRCode
      level={'M'}
      value={`https://${url}?code=${code}`}
      size={180}
      id="qr-code-dl"
      title="talkadot-review-link"
    />
  )
}

export default DownloadableQrCode
