import { useContext } from 'react'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { Notification } from '../../common/Notification'
import { styled } from '@mui/system'

const UpgradeLink = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const CodeBuilderUpgradeNotification = ({
  currentQuestion,
  customizationPermission,
}) => {
  const { granularControls } = customizationPermission
  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  const questionHasFeatureUserCantAccess = () => {
    const {
      canEditContent,
      canToggleEnable,
      canEditViaDropdown,
      isTalkadotQuestion,
    } = currentQuestion?.config

    // For non-talkadot questions, as long as they have global customization permission
    // enabled they can change anything on this question.
    if (!isTalkadotQuestion) {
      return !customizationPermission.enabled
    }

    if (canEditContent) {
      return canEditContent !== granularControls.can_edit_content
    }

    if (canToggleEnable) {
      return canToggleEnable !== granularControls.can_toggle_enable
    }

    if (canEditViaDropdown) {
      return canEditViaDropdown !== granularControls.can_edit_via_dropdown
    }
  }

  const questionHasCustomizationOptions =
    currentQuestion?.config?.canEditContent ||
    currentQuestion?.config?.canToggleEnable ||
    currentQuestion?.config?.canEditViaDropwn

  const renderUpgradeLink = () => (
    <UpgradeLink
      onClick={() =>
        toggleUpgradeModal(
          true,
          'Upgrade for more customization options!',
          'CUSTOMIZATIONS'
        )
      }>
      upgrade your plan.
    </UpgradeLink>
  )

  // If the entire realm of customizations is not available for the speaker, show a different
  // message than if some of them are available and some are not
  const upgradeNotificationContent = () => {
    if (!customizationPermission.enabled) {
      return (
        <>To make changes or to skip this question, {renderUpgradeLink()}</>
      )
    }

    return <>To make changes to this question, {renderUpgradeLink()}</>
  }

  const showUpgradeNotice = () => {
    if (!questionHasCustomizationOptions) {
      return false
    }

    return questionHasFeatureUserCantAccess()
  }

  return (
    showUpgradeNotice() && (
      <Notification
        variant="blueWarning"
        hideIcon={false}
        hideClose={true}
        maxWidth={true}>
        {upgradeNotificationContent()}
      </Notification>
    )
  )
}

export default CodeBuilderUpgradeNotification
