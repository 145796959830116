import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import { MdInfo } from 'react-icons/md'

const AlertContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'backgroundColor' && prop !== 'color' && prop !== 'width',
})(({ width, theme }) => ({
  backgroundColor: theme.palette.base.white,
  fontFamily: 'Figtree, sans-serif',
  fontSize: '0.875rem',
  width: width ? `${width}px` : '270px',
  padding: '1.375rem 1.5rem',
  borderRadius: '10px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
}))

const TitleContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
  color: color || 'inherit',
}))

const Title = styled('div')({
  fontWeight: '600',
  marginLeft: '0.375rem',
})

const Content = styled('div')({})

const AlertCard = ({ children, title, severity, width }) => {
  const theme = useTheme()

  let color

  switch (severity) {
    case 'error':
      color = theme.palette.red.main
      break
    case 'info':
      color = theme.palette.secondary.main
      break
    default:
      color = theme.palette.primary.main
  }

  return (
    <AlertContainer width={width}>
      <TitleContainer color={color}>
        <MdInfo size={20} />
        <Title>{title}</Title>
      </TitleContainer>
      <Content>{children}</Content>
    </AlertContainer>
  )
}

export default AlertCard
