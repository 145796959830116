import { useRef, useEffect, useCallback } from 'react'
import { styled } from '@mui/system'
import { TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material'

import TableCellStyled from './TableCellStyled'
import TableHeaderCellSortable from '../common/TableHeaderCellSortable'

import {
  upperNavHeight,
  upperNavHeightMobile,
} from '../../styles/layoutNavConstants'

const StyledTableHead = styled(TableHead)({
  th: {
    position: 'sticky',
    top: '0px',
    zIndex: 1,
    fontSize: '1rem',
  },
})

const TableHeaderSortable = ({ headCells, order, orderBy, onRequestSort }) => {
  const headerRef = useRef(null)
  const theme = useTheme()

  const renderHeadCells = () => {
    return headCells.map((headCell) => {
      if (
        headCell.id === 'checkbox' ||
        headCell.id === 'actions' ||
        headCell.id === 'code settings' ||
        headCell.id === 'leadTypes' ||
        headCell.id === 'collapse'
      ) {
        return (
          <TableCellStyled
            key={headCell.id}
            align={headCell.align}
            displayBreakpoint={headCell.displayBreakpoint}>
            {headCell.label}
          </TableCellStyled>
        )
      }

      return (
        <TableHeaderCellSortable
          key={headCell.id}
          headCell={headCell}
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
        />
      )
    })
  }

  const navHeight = useMediaQuery(theme.breakpoints.down('sm'))
    ? upperNavHeightMobile
    : upperNavHeight

  const handleScroll = useCallback(() => {
    if (headerRef.current) {
      const headerElement = headerRef.current
      const headerTop = headerElement.getBoundingClientRect().top

      if (headerTop <= navHeight) {
        const thElements = headerElement.querySelectorAll('th')
        thElements.forEach((th) => {
          th.style.top = `${navHeight - headerTop}px`
        })
      } else {
        const thElements = headerElement.querySelectorAll('th')
        thElements.forEach((th) => {
          th.style.top = '0px'
        })
      }
    }
  }, [navHeight])

  useEffect(() => {
    const handleScrollDebounced = () => {
      window.requestAnimationFrame(handleScroll)
    }

    window.addEventListener('scroll', handleScrollDebounced)
    return () => {
      window.removeEventListener('scroll', handleScrollDebounced)
    }
  }, [handleScroll])

  return (
    <StyledTableHead ref={headerRef}>
      <TableRow>{renderHeadCells()}</TableRow>
    </StyledTableHead>
  )
}

export default TableHeaderSortable
