import { useContext } from 'react'
import { Typography, Button } from '@mui/material'
import { styled } from '@mui/system'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'

import * as Helpers from '../common/helpers'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { ReactComponent as LinkIcon } from '../../icons/linkIcon_16x16.svg'
import { ReactComponent as HeartIcon } from '../../icons/heartIcon_16x16.svg'

import { REFERRAL_AMOUNT } from '../../constants/referrals/values'

const MainContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  maxWidth: '600px',
  width: '100%',
}))

const InviteCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadius.xs,
  boxShadow: theme.shape.boxShadow.sm,
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    boxShadow: 'none',
  },
}))

const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
}))

const StyledHeartIcon = styled(HeartIcon)(({ theme }) => ({
  marginRight: theme.spacing(0.75),
  marginTop: theme.spacing(0.75),
  minWidth: '16px',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(0.5),
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
}))

const ShareLinkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const LinkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(0.25, 1),
  borderRadius: theme.shape.borderRadius.xxs,
  border: `1px solid ${theme.palette.neutral.veryLight}`,
  maxWidth: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

const StyledLinkIcon = styled(LinkIcon)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const LinkWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginRight: 'auto',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1.25),
}))

const CopyableLink = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.neutral.veryDark,
}))

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.base.white,
  textWrap: 'nowrap',
}))

const LinkLikeButton = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.secondary.dark,
  },
}))

const InviteComponent = ({ referralLink }) => {
  const { setNotification } = useContext(AuthenticationContext)

  const handleCopy = () => {
    Helpers.handleCopy(referralLink)
    return setNotification('Copied!', 'success')
  }

  return (
    <ShareLinkContainer>
      <LinkContainer>
        <StyledLinkIcon />
        <LinkWrapper>
          <CopyableLink variant="body1" onClick={handleCopy}>
            {referralLink.replace(/^https?:\/\//i, '')}
          </CopyableLink>
        </LinkWrapper>
      </LinkContainer>
      <StyledButton variant="contained" onClick={handleCopy}>
        Copy Link
      </StyledButton>
    </ShareLinkContainer>
  )
}

const ShareParent = ({ referralLink }) => {
  return (
    <MainContentContainer>
      <HeaderWrapper>
        <StyledHeartIcon />
        <StyledTypography variant="h5" component="h1">
          Talkadot? Spread the word and get ${REFERRAL_AMOUNT}.
        </StyledTypography>
      </HeaderWrapper>
      <StyledTypography variant="body1">
        Grow this community with us! Tell someone about Talkadot and receive $
        {REFERRAL_AMOUNT} for every speaker who upgrades to a Pro Plan or
        higher! ($50 for upgrades before August 18th)
      </StyledTypography>
      <InviteCard>
        <StyledTypography variant="h6">
          Copy and share your link
        </StyledTypography>
        <InviteComponent referralLink={referralLink} />
        <StyledTypography variant="body1">
          Share your link on{' '}
          <LinkedinShareButton
            url={referralLink}
            title="Sign up for Talkadot using this link and get your discount!"
            summary="If you're looking to book more speaking engagements and grow your professional brand, you need to check out Talkadot.
                     It's a game-changer for getting instant feedback and getting more leads and opportunities.
                     I've been using it and the results are incredible.  Give it a try for your speaking business
                     for free."
            source="talkadot"
            children={<LinkLikeButton>LinkedIn</LinkLikeButton>}
          />
          {' | '}
          <FacebookShareButton
            url={referralLink}
            quote="I've been using a fantastic tool that is helping me book more speaking engagements and get great feedback from my events – it's called Talkadot. I love sharing cool tools that work, if you are a speaker you can learn more and try it out for free with this link:"
            hashtag="talkadot"
            children={<LinkLikeButton>Facebook</LinkLikeButton>}
          />
          {' | '}
          <TwitterShareButton
            url={referralLink}
            title="I love @talkadot. They make getting speaking leads and real time feedback so easy. Try it out with special access using this link!"
            via="talkadot"
            hashtags={['talkadot', 'publicspeaking']}
            related={['surveys', 'feedback']}
            children={<LinkLikeButton>Twitter</LinkLikeButton>}
          />
        </StyledTypography>
      </InviteCard>
    </MainContentContainer>
  )
}

export default ShareParent
