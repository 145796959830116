import { useState } from 'react'
import { styled } from '@mui/system'
import { Tabs, Tab, Select, MenuItem } from '@mui/material'

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    borderBottom: 'none',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
  },
}))

const StyledTabswrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutral.extraLight,
  borderRadius: theme.shape.borderRadius.xs,
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 72,
  fontWeight: '400',
  color: theme.palette.text.disabled,
  backgroundColor: theme.palette.surface.subtle,
  marginRight: theme.spacing(1),
  borderRadius: theme.shape.borderRadius.xs,
  '&:last-child': {
    marginRight: 0,
  },
  '&.Mui-selected': {
    color: theme.palette.text.inputText,
    backgroundColor: theme.palette.base.white,
    fontWeight: '600',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}))

const SelectWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.neutral.extraLight} !important`,
  },
}))

const LeadsTabs = ({ setActiveTab, setPage }) => {
  const [value, setValue] = useState(0)

  const tabs = [
    { name: 'all', label: 'All Contacts' },
    { name: 'booking', label: 'Booking Leads' },
    { name: 'referral', label: 'Referral Leads' },
    { name: 'email', label: 'Email Opt Ins' },
    { name: 'archived', label: 'Archived' },
  ]

  const handleChange = (newValue) => {
    setValue(newValue)

    const activeTab = tabs[newValue].name
    setActiveTab(activeTab)
    setPage(0)
  }

  return (
    <StyledContainer>
      <StyledTabswrapper>
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(_, newValue) => handleChange(newValue)}
          aria-label="Leads tabs"
          TabIndicatorProps={{
            style: { display: 'none' },
          }}>
          {tabs.map((tab, index) => (
            <StyledTab key={index} label={tab.label} />
          ))}
        </Tabs>
      </StyledTabswrapper>
      <SelectWrapper>
        <Select
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          color="secondary">
          {tabs.map((tab, index) => (
            <StyledMenuItem
              key={index}
              value={index}
              selected={index === value}>
              {tab.label}
            </StyledMenuItem>
          ))}
        </Select>
      </SelectWrapper>
    </StyledContainer>
  )
}

export default LeadsTabs
