import React, { useState, useEffect, useCallback } from 'react'
import UserAttributeForm from './UserAttributeForm'
import { Typography, CircularProgress } from '@mui/material'
import Api from '../../services/api'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'

import { styled } from '@mui/system'

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

const LengthErrorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const TinyText = styled(Typography)({
  fontSize: '12px',
})

const ShortlinkPicker = ({ formValue, formLabel, profileAttribute }) => {
  const [isChecking, setIsChecking] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)

  const debouncedShortlink = useDebounce(formValue, 1000)

  useEffect(() => {
    const checkShortlink = async () => {
      if (debouncedShortlink) {
        setIsChecking(true)

        if (formValue?.length > 19) {
          setIsChecking(false)
          return
        }

        const params = {
          shortlink_availability: {
            shortlink: formValue,
          },
        }

        try {
          const res = await Api.checkShortlinkAvailability(params)

          if (!res.errors) {
            setIsAvailable(res.available)
            setIsChecking(false)
          } else {
            throw res.errors
          }
        } catch (err) {
          setIsChecking(false)
        }
      }
    }

    checkShortlink()
  }, [debouncedShortlink]) // Only call effect if debounced search term changes

  const renderAvailability = () => (
    <div className="availability-loader">
      {formValue && (
        <Typography variant="h6">
          {isAvailable && formValue?.length <= 19 ? (
            <span className="availability-loader__available">
              <CheckIcon />
              available!
            </span>
          ) : formValue?.length > 19 ? (
            <span className="availability-loader__unavailable">
              <CancelIcon />
              <LengthErrorContainer>
                <span>Too long</span>
                <TinyText>(19 chars or less)</TinyText>
              </LengthErrorContainer>
            </span>
          ) : (
            <span className="availability-loader__unavailable">
              <CancelIcon /> unavailable
            </span>
          )}
        </Typography>
      )}
    </div>
  )

  return (
    <div>
      <div className="shortlink-picker-form">
        <UserAttributeForm
          formValue={formValue}
          formLabel={formLabel}
          profileAttribute={profileAttribute}
        />
        {isChecking ? <CircularProgress /> : renderAvailability()}
      </div>
      <Typography className="orange extra-space" variant="h6">
        talk.ac/{formValue}
      </Typography>
    </div>
  )
}

export default ShortlinkPicker
