import { useState } from 'react'
import { styled } from '@mui/system'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material'
import FormSection from '../common/FormSection'
import isEmail from 'validator/lib/isEmail'

const FormDialogContent = styled(DialogContent)({
  padding: '0px',
})
const FormContainer = styled('div')({
  padding: '10px 25px',
})
const OrangeCircularProgress = styled(CircularProgress)({
  color: 'orange',
})
const CancelButton = styled(Button)(({ theme }) => ({
  color: 'orange',
}))
const SubmitButton = styled(Button)({
  backgroundColor: 'orange',
  border: '1px solid orange',
  borderRadius: '0px',
  color: 'white',
  cursor: 'pointer',
  '&:hover': {
      backgroundColor: 'orange',
  },
})

const TeamForm = ({
  createAssociate,
  handleClose
}) => {
  const [email, setEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [emailError, setEmailError] = useState(null)
  const handleSubmit = async () => {
    if (!email || !isEmail(email)) {
      return setEmailError('please fill out a valid email')
    }

    await _createAssociate()
  }
  const _createAssociate = async () => {
    setIsLoading(true)
    await createAssociate({associate: { email: email}})

    setIsLoading(false)
    handleClose()
  }

  return (
    <>
      <DialogTitle component="h5">
          <b>Invite Team Member</b>
      </DialogTitle>
      <FormDialogContent>
        <FormContainer>
            <Typography variant="body2">
              When you invite someone, they receive full access to your account. However, they cannot access the Subscription section or view/edit your profile information.
            </Typography>
            <FormSection>
              <TextField
                required
                fullWidth
                variant="standard"
                type="text"
                margin="normal"
                label={null}
                error={!!emailError}
                helperText={emailError && emailError}
                placeholder={'Email Address'}
                inputRef={(input) => input && input.focus()}
                onChange={(e) => {
                  setEmailError(null)
                  setEmail(e.target.value)
                }}
              />
            </FormSection>
        </FormContainer>
      </FormDialogContent>
      <DialogActions>
        {false ? (
          <OrangeCircularProgress />
        ) : (
          <>
            <CancelButton onClick={() => handleClose()}>
              Cancel
            </CancelButton>
            <SubmitButton
              disabled={isLoading}
              onClick={handleSubmit}>
              Invite Team Member
            </SubmitButton>
          </>
        )}
      </DialogActions>
    </>
  )
}

export default TeamForm
