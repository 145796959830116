import { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { CircularProgress, Typography } from '@mui/material'

import Api from '../../services/api'

import YesOrNoAnswer from '../common/YesOrNoAnswer'
import EngagementAnswers from '../common/EngagementAnswers'

const ContactInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'right',
  marginLeft: '1.5rem',
})

const NoShareBanner = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '1.25rem',
  marginTop: '-20px',
  marginLeft: '-20px',
  background: theme.palette.base.black,
  color: theme.palette.base.white,
  width: 'calc(100% + 40px)',
}))

const FeedbackAnswersParent = ({ feedback }) => {
  const [answers, setAnswers] = useState([])
  const [leadData, setLeadData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const talkValue = answers?.find((l) => l.question_type.includes('RATING'))
  const talkReattend = answers?.find((l) =>
    l.question_type.includes('REATTEND')
  )
  const talkEngagement = answers?.filter((l) =>
    l.question_type.includes('ENGAGEMENT')
  )
  const talkTakeaways = answers?.find((l) =>
    l.question_type.includes('TAKEAWAYS')
  )
  const otherFeedback = answers?.find((l) =>
    l.question_type.includes('PUBLIC_FEEDBACK')
  )

  const statSize = talkEngagement?.length
  const statSliceIndex = Math.ceil(statSize / 2)

  const statSet1 = talkEngagement?.slice(0, statSliceIndex)
  const statSet2 = talkEngagement?.slice(statSliceIndex)

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        setIsLoading(true)

        const params = {
          submission: {
            id: feedback.submission_id,
          },
        }

        const res = await Api.getAnswersBySubmission(params)

        if (!res.errors) {
          setAnswers(res.answers)
          setLeadData(res.leadData)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
      }
    }

    fetchAnswers()
  }, [feedback])

  return (
    <div className="submission-answers-parent">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div>
            {feedback?.public_share === false && (
              <NoShareBanner>
                <Typography variant="body1" component="div">
                  <strong>Sharing disabled.</strong> Permission was not granted.
                </Typography>
              </NoShareBanner>
            )}
          </div>
          <div className="submission-answers">
            <div className="submission-answers__section">
              <div className="submission-answers__section__row-section">
                <div>
                  <Typography variant="body1" component="div">
                    <b>{leadData?.name}</b>
                  </Typography>
                  <Typography variant="body1" component="div">
                    {leadData?.title}
                  </Typography>
                  <Typography variant="body1" component="div">
                    {leadData?.organization}
                  </Typography>
                </div>
                <ContactInfoWrapper>
                  {leadData?.phone && (
                    <Typography variant="body1" component="div">
                      {leadData.phone}
                    </Typography>
                  )}
                  <Typography variant="body1" component="div">
                    {leadData?.email}
                  </Typography>
                </ContactInfoWrapper>
              </div>
            </div>
            <div className="submission-answers__section">
              <YesOrNoAnswer
                answerValue={talkValue?.text_value}
                answerContent="Found talk valuable"
              />
              <YesOrNoAnswer
                answerValue={talkReattend?.text_value}
                answerContent="Would attend future talks"
              />
            </div>
            <div className="submission-answers__section">
              {talkEngagement?.length > 0 && (
                <div
                  className="submission-answers__section__row-section"
                  style={{ width: '80%' }}>
                  <div>
                    <EngagementAnswers engagementStats={statSet1} />
                  </div>
                  <div>
                    <EngagementAnswers engagementStats={statSet2} />
                  </div>
                </div>
              )}
            </div>
            <div className="submission-answers__section">
              {talkTakeaways && (
                <Typography variant="body2" component="div">
                  {talkTakeaways?.answer_value}
                </Typography>
              )}
            </div>
            <div className="submission-answers__section">
              {otherFeedback && (
                <Typography variant="body2" component="div">
                  {otherFeedback?.answer_value}
                </Typography>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default FeedbackAnswersParent
