import { styled } from '@mui/system'
import { Button } from '@mui/material'
import { motion } from 'framer-motion/dist/framer-motion'

const StyledButton = styled(motion(Button), {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'fontSize',
})(({ theme, width, fontSize }) => ({
  fontFamily: 'Figtree, sans-serif',
  color: theme.palette.base.white,
  textTransform: 'none',
  fontSize: fontSize || '1.5rem',
  fontWeight: '600',
  width: width,
  padding: '0.5rem 3rem',
  borderRadius: '5px',
}))

const SurveyOffersOnboardingCta = ({
  buttonText,
  buttonWidth,
  fontSize,
  handleClick,
  loading,
}) => {
  const pulsatingAnimation = {
    scale: [1, 1.02, 1],
    transition: {
      repeat: Infinity,
      duration: 1.5,
      ease: 'easeInOut',
    },
  }
  return (
    <StyledButton
      variant="contained"
      width={buttonWidth}
      fontSize={fontSize}
      onClick={handleClick}
      disabled={loading}
      animate={pulsatingAnimation}>
      {buttonText}
    </StyledButton>
  )
}

export default SurveyOffersOnboardingCta
