export const SET_PROFILE = 'SET_PROFILE'
export const SET_SOCIAL_MEDIA = 'SET_SOCIAL_MEDIA'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const SET_INDUSTRIES = 'SET_INDUSTRIES'
export const SET_GOALS = 'SET_GOALS'
export const SET_SHORTLINK = 'SET_SHORTLINK'
export const TOGGLE_ACTIVE_INDUSTRY = 'TOGGLE_ACTIVE_INDUSTRY'
export const TOGGLE_IS_LOADING = 'TOGGLE_IS_LOADING'
export const SET_FORM_ERROR = 'SET_FORM_ERROR'
export const SET_ONBOARDING_SURVEY_OFFER_ATTRIBUTE =
  'SET_ONBOARDING_SURVEY_OFFER_ATTRIBUTE'
export const SET_ONBOARDING_SURVEY_OFFER = 'SET_ONBOARDING_SURVEY_OFFER'
export const RESET_ONBOARDING_DATA = 'RESET_ONBOARDING_DATA'
export const SET_SELECTED_REFERRER_OPTION = 'SET_SELECTED_REFERRER_OPTION'
export const SET_ADDITIONAL_REFERRER_CONTEXT = 'SET_ADDITIONAL_REFERRER_CONTEXT'
export const SET_REFERRER_DATA_ATTRIBUTE = 'SET_REFERRER_DATA_ATTRIBUTE'
