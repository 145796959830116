import { styled } from '@mui/system'

const PaintGraphic = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundUrl',
})(({ theme, backgroundUrl }) => ({
  backgroundImage: `url(${backgroundUrl})`,
  [theme.breakpoints.down(1028)]: {
    display: 'none',
  },
}))

export const InnerPaintGraphic = styled('div')({
  textAlign: 'center',
  padding: '150px 30px 0px 30px',
})

export const YellowPaintGraphic = ({ children }) => {
  const backgroundUrl =
    'https://d3qvkz8495pjoq.cloudfront.net/talkadot-bg-yellow.png'
  return <PaintGraphic backgroundUrl={backgroundUrl}>{children}</PaintGraphic>
}

export const BluePaintGraphic = () => {
  const backgroundUrl =
    'https://d3qvkz8495pjoq.cloudfront.net/talkadot-bg-blue.png'
  return <PaintGraphic backgroundUrl={backgroundUrl} />
}
