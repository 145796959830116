import { TableSortLabel, Tooltip } from '@mui/material'

import TableCellStyled from './TableCellStyled'

import { ReactComponent as tableSortIcon } from '../../icons/tableSortIcon.svg'

const TableHeaderCellSortable = ({
  headCell,
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableCellStyled
      key={headCell.id}
      paddingLeft={headCell.paddingLeft}
      align={headCell.align}
      displayBreakpoint={headCell.displayBreakpoint}
      sortDirection={orderBy === headCell.id ? order : false}>
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={() => onRequestSort(headCell.id)}
        IconComponent={tableSortIcon}>
        {headCell.tooltip ? (
          <Tooltip title={headCell.tooltip} placement="top">
            <span>{headCell.label}</span>
          </Tooltip>
        ) : (
          headCell.label
        )}
      </TableSortLabel>
    </TableCellStyled>
  )
}

export default TableHeaderCellSortable
