import React from 'react'
import GoogleOAuthButton from './GoogleOAuthButton'
import LinkedinOAuthButton from './LinkedinOAuthButton'
import { styled } from '@mui/system'

const OAuthButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '80%',
  margin: '0 auto',
})

export const AUTHENTICATION_TYPES = {
  REGISTRATION: 'REGISTRATION',
  SIGNIN: 'SIGNIN',
}

const OAuthButtons = ({
  authenticationType,
  membershipPlan,
  registrationToken,
  redirect,
}) => (
  <OAuthButtonContainer>
    <GoogleOAuthButton
      authenticationType={authenticationType}
      membershipPlan={membershipPlan}
      registrationToken={registrationToken}
      redirect={redirect}
    />
    <LinkedinOAuthButton
      authenticationType={authenticationType}
      membershipPlan={membershipPlan}
      registrationToken={registrationToken}
      redirect={redirect}
    />
  </OAuthButtonContainer>
)

export default OAuthButtons
