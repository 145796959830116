import React, { useState, useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { styled } from '@mui/system'
import { TextField, Alert } from '@mui/material'

import isEmail from 'validator/lib/isEmail'

import { AuthenticationContext } from './authenticationContext'
import { GroupContext } from '../groups/groupContext'
import Api from '../../services/api'
import { trackLogin } from '../../services/metrics'

import * as Messages from '../common/AlertMessages'
import { BluePaintGraphic } from '../common/SidePaintGraphics'
import OAuthButtons, { AUTHENTICATION_TYPES } from './OAuthButtons'
import { GoToRegistrationPageButton } from './AuthLinkButtons'
import {
  AuthButton,
  RegistrationContainer,
  InnerContainer,
  CheckoutForm,
  Divider,
  InnerDivider,
  FormWrapper,
} from './RegistrationParent'
import RegistrationHeader from './Header'
import { humanizeError } from '../../utils/errorFormatter'
import * as qs from 'qs'

const PasswordResetLink = styled('div')({
  marginTop: '10px',
})

const LoginForm = ({ redirect }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [genericInlineError, setGenericInlineError] = useState(null)
  const {
    authData,
    showFullPageLoader,
    hideFullPageLoader,
    handleAuthenticationSuccess,
    setGroupInvitations,
  } = useContext(AuthenticationContext)
  const { setGroups } = useContext(GroupContext)

  const location = useLocation()
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const groupInvitationToken = urlParams.git
  const groupInvitation = authData?.user?.groupInvitations[0]
  const welcomeMessage = groupInvitation
    ? `Welcome Back! Sign in to accept your ${groupInvitation.groupName} invite!`
    : 'Welcome Back!'

  useEffect(() => {
    const fetchGroupDetails = async () => {
      const reqParams = {
        group_invitation: {
          invitation_token: groupInvitationToken,
        },
      }

      try {
        showFullPageLoader()
        const res = await Api.getUnauthGroupInvitationDetails(reqParams)

        if (!res.errors) {
          hideFullPageLoader()
          setGroupInvitations({
            groupName: res.groupName,
            id: res.id,
            invitationToken: groupInvitationToken,
          })
        } else {
          throw res.errors
        }
      } catch (err) {
        hideFullPageLoader()
      }
    }

    // It's possible the user lands here directly from a group invitation link redirect from
    // the registration page, in which
    // case we don't want to double up fetching the invitation data
    if (groupInvitationToken && !groupInvitation) {
      fetchGroupDetails()
    }
  }, [groupInvitationToken, groupInvitation])

  const handleSubmit = async () => {
    if (!email || !isEmail(email)) {
      return setEmailError('please fill out a valid email')
    }

    if (!password) {
      return setPasswordError('please fill out a valid password')
    }

    return await loginUser(email, password)
  }

  const loginUser = async (email, password) => {
    const { fullPageLoader } = authData

    // Prevent Double Clicks
    if (fullPageLoader?.isActive) {
      return
    }

    showFullPageLoader(Messages.AUTH_MESSAGE)

    const params = {
      authentication: {
        email: email,
        password: password,
      },
    }

    try {
      const res = await Api.loginUser(params)

      if (!res.errors && res.authData?.auth_token) {
        hideFullPageLoader()
        trackLogin(res.authData?.user?.id, res.authData?.user?.email)
        setGroups(res.groupData)
        return handleAuthenticationSuccess(res.authData, redirect)
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return setGenericInlineError(humanizeError(err))
    }
  }

  return (
    <RegistrationContainer>
      <BluePaintGraphic />
      <InnerContainer>
        <GoToRegistrationPageButton />
        <FormWrapper>
          <RegistrationHeader
            genericWelcomeMessage={welcomeMessage}
            hideSubheader={true}
          />
          {genericInlineError && (
            <Alert severity="error">{genericInlineError}</Alert>
          )}
          <CheckoutForm>
            <TextField
              required
              error={!!emailError}
              id="filled-required"
              variant="outlined"
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              helperText={emailError && emailError}
              onChange={(e) => {
                setGenericInlineError(null)
                setEmailError(null)
                setEmail(e.target.value)
              }}
            />
            <TextField
              required
              fullWidth
              error={!!passwordError}
              margin="normal"
              id="standard-password-input"
              variant="outlined"
              label="Password"
              type="password"
              autoComplete="current-password"
              value={password}
              helperText={passwordError && passwordError}
              onChange={(e) => {
                setGenericInlineError(null)
                setPasswordError(null)
                setPassword(e.target.value)
              }}
            />
            <AuthButton
              variant="contained"
              value="submit"
              name="submit"
              color="secondary"
              onClick={() => handleSubmit()}>
              Sign In
            </AuthButton>
            <PasswordResetLink>
              <Link to="/request-password-reset">Forgot password?</Link>
            </PasswordResetLink>
          </CheckoutForm>
          <Divider>
            <InnerDivider />
            <div>OR</div>
            <InnerDivider />
          </Divider>
          <OAuthButtons
            authenticationType={AUTHENTICATION_TYPES.LOGIN}
            redirect={redirect}
          />
        </FormWrapper>
      </InnerContainer>
    </RegistrationContainer>
  )
}

export default LoginForm
