import Select from 'react-select'
import { getCode, getNames } from 'country-list'
import { useTheme } from '@mui/material'
import { addAlpha } from '../../utils/color'

// Step 1: Generate the countries array once
const countries = getNames().map((name) => ({
  label: name,
  value: getCode(name),
  flag: `https://flagcdn.com/w20/${getCode(name).toLowerCase()}.png`,
}))

// Step 2: Define prioritized country codes
const prioritizedCountryCodes = ['US', 'CA']

// Step 3: Sort countries by code, prioritizing specified countries
const sortedCountries = countries.sort((a, b) => {
  const indexA = prioritizedCountryCodes.indexOf(a.value)
  const indexB = prioritizedCountryCodes.indexOf(b.value)

  if (indexA !== -1 && indexB === -1) return -1 // a is prioritized
  if (indexA === -1 && indexB !== -1) return 1 // b is prioritized
  if (indexA === -1 && indexB === -1) return a.label.localeCompare(b.label) // both are not prioritized, sort alphabetically
  return indexA - indexB // both are prioritized, keep order as defined in prioritizedCountryCodes
})

const CountrySelect = ({ value, onChange }) => {
  const theme = useTheme()

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '44px',
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      borderColor: state.isFocused
        ? theme.palette.neutral.semiLight
        : provided.borderColor,
      fontFamily: 'Figtree, sans-serif',
      '&:hover': {
        borderColor: state.isFocused
          ? theme.palette.neutral.semiLight
          : provided['&:hover'].borderColor,
      },
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Figtree, sans-serif',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: '400',
      color: addAlpha(theme.palette.neutral.main, 0.5),
    }),
  }

  const formatOptionLabel = ({ label, flag }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={flag} alt={label} style={{ marginRight: '10px' }} />
      {label}
    </div>
  )

  return (
    <Select
      options={sortedCountries}
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
      value={sortedCountries.find((country) => country.value === value)}
      menuPlacement="auto"
      menuPosition="fixed"
      onChange={(selectedOption) =>
        onChange(selectedOption ? selectedOption.value : '')
      }
    />
  )
}

export default CountrySelect
