import { useEffect, useState, useContext } from 'react'
import { styled } from '@mui/system'
import {
  Avatar,
  MenuItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Select,
  FormControl,
  Divider,
  Typography,
} from '@mui/material'
import { ClickAwayListener } from '@mui/base'
import ConfirmDialog from '../common/ConfirmDialog'
import GroupInviteParent from './GroupInviteParent'

import { IoPencilOutline } from 'react-icons/io5'

import { AuthenticationContext } from '../authentication/authenticationContext'

import Api from '../../services/api'

const UserAvatar = styled(Avatar)({
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  img: {
    width: '102%',
    height: '102%',
  },
  marginRight: '1rem',
})

const ClickableText = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
})

const EditIcon = styled(IoPencilOutline)({
  marginLeft: '0.5rem',
})

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: theme.palette.tertiary.extraLight,
  },
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.secondary.extraLight} !important`,
  },
}))

const PendingInvitationListItem = ({ invitation }) => {
  return (
    <ListItem key={invitation.id}>
      {' '}
      <ListItemAvatar>
        <UserAvatar
          sx={{ width: 50, height: 50 }}
          src={invitation.avatar}
          alt={invitation.email}
        />
      </ListItemAvatar>
      <ListItemText>
        {invitation.first_name
          ? `${invitation.first_name} ${invitation.last_name}`
          : invitation.email}
      </ListItemText>
    </ListItem>
  )
}

const GroupMembersListItem = ({
  currentUser,
  user,
  handleRemoveUserClick,
  handleUpdateUserMembership,
}) => {
  const [editingRole, setEditingRole] = useState(false)
  const [role, setRole] = useState(user.role)
  const [selectOpen, setSelectOpen] = useState(false)

  useEffect(() => {
    setRole(user.role)
  }, [user.role])

  const handleClickAway = (e) => {
    if (!selectOpen) {
      setEditingRole(false)
    }
  }

  const renderRoleField = () => {
    if (editingRole) {
      return (
        <ListItem>
          <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
            <FormControl fullWidth>
              <Select
                value={role}
                color="secondary"
                onOpen={() => setSelectOpen(true)}
                onClose={() => setSelectOpen(false)}
                onChange={(e) => {
                  handleUpdateUserMembership({
                    setting: 'role',
                    value: e.target.value,
                    userId: user.user_id,
                  })
                }}>
                {/* NOTE: should you be able to have multiple owners? */}
                {/* <MenuItem value="owner">owner</MenuItem> */}
                <StyledMenuItem value="manager">manager</StyledMenuItem>
                <StyledMenuItem value="member">viewer</StyledMenuItem>
                <Divider />
                <MenuItem onClick={() => handleRemoveUserClick(user)}>
                  <ListItemText primary="remove" />
                </MenuItem>
              </Select>
            </FormControl>
          </ClickAwayListener>
        </ListItem>
      )
    } else if (role !== 'owner' && currentUser.id !== user.user_id) {
      return (
        <ListItem onClick={() => setEditingRole(true)} centered>
          <ClickableText>
            {role === 'member' ? 'viewer' : role}
            <EditIcon />
          </ClickableText>
        </ListItem>
      )
    } else {
      return <ListItem centered>{role}</ListItem>
    }
  }

  return (
    <ListItem key={user.id} secondaryAction={renderRoleField()}>
      <ListItemAvatar>
        <UserAvatar
          sx={{ width: 50, height: 50 }}
          src={user.avatar}
          alt={user.first_name}
        />
      </ListItemAvatar>
      <ListItemText>
        {user.email} - {user.first_name} {user.last_name}
      </ListItemText>
    </ListItem>
  )
}

const initialConfirmProps = {
  title: '',
  message: '',
  onConfirm: () => {},
}

const ManageGroupMembershipsParent = ({ group }) => {
  const [groupMembers, setGroupMembers] = useState([])
  const [pendingMembers, setPendingMembers] = useState([])
  const [loading, setLoading] = useState(false)
  const {
    setNotification,
    authData: { user: currentUser },
  } = useContext(AuthenticationContext)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState(initialConfirmProps)

  const handleRemoveFromGroup = async (id) => {
    try {
      const params = {
        group_membership: {
          group_id: group.id,
          user_id: id,
        },
      }
      const res = await Api.removeUserFromGroup(params)
      if (!res.errors) {
        setNotification('User removed from group.', 'success')
        setGroupMembers(
          groupMembers.filter((groupMember) => groupMember.user_id != id)
        )
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleRemoveUserClick = (groupMember) => {
    setConfirmProps({
      title: 'Remove User from Chapter?',
      message: 'Are you sure you want to remove this user from the group?',
      onConfirm: () => handleRemoveFromGroup(groupMember.user_id),
    })

    setConfirmOpen(true)
  }

  const handleUpdateUserMembership = async ({ setting, value, userId }) => {
    try {
      // TODO: add loading state
      // setLoading(true)

      const params = {
        group_membership: {
          user_id: userId,
          group_id: group.id,
          [setting]: value,
        },
      }

      const res = await Api.updateUserMembership(params)
      if (!res.errors) {
        setNotification('Chapter Membership updated successfully', 'success')
        setGroupMembers(res)
        // setLoading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      // setLoading(false)
      setNotification(err)
    }
  }

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoading(true)
        const params = {
          group_membership: {
            group_id: group.id,
          },
        }

        const res = await Api.getAllGroupMembers(params)

        if (!res.errors) {
          setGroupMembers(res)
          setLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setLoading(false)
        setNotification(err)
      }
    }

    const fetchPendingInvitations = async () => {
      try {
        setLoading(true)
        const params = {
          group_invitation: {
            group_id: group.id,
          },
        }

        const res = await Api.getAllPendingInvitations(params)

        if (!res.errors) {
          setPendingMembers(res)
          setLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setLoading(false)
        setNotification(err)
      }
    }

    fetchPendingInvitations()
    fetchMembers()
  }, [group])

  return (
    <>
      <GroupInviteParent
        group={group}
        setPendingMembers={setPendingMembers}
        setGroupMembers={setGroupMembers}
        setParentComponentLoading={setLoading}
      />
      <br />
      <Divider />
      <br />
      <Typography variant="h6">People With Access</Typography>
      <List>
        {groupMembers.map((member, i) => {
          return (
            <GroupMembersListItem
              currentUser={currentUser}
              user={member}
              handleRemoveUserClick={handleRemoveUserClick}
              handleUpdateUserMembership={handleUpdateUserMembership}
            />
          )
        })}
        <ConfirmDialog
          title={confirmProps.title}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={confirmProps.onConfirm}>
          {confirmProps.message}
        </ConfirmDialog>
      </List>
      <br />
      <Divider />
      <br />
      <Typography variant="h6">
        Pending Members - Need to Join Talkadot
      </Typography>
      <List>
        {pendingMembers.map((member, i) => {
          return <PendingInvitationListItem invitation={member} />
        })}
      </List>
    </>
  )
}

export default ManageGroupMembershipsParent
